import React from "react";

function CustomButton({ full, isWhite, outlined, children, ...props }) {
	return (
		<button
			className={`${full ? "w-full" : ""} ${
				isWhite ? "bg-white text-secondary " : "bg-primary text-white hover:bg-primary2"
			} py-3.5 px-16 rounded-lg font-semibold  flex items-center justify-center`}
			{...props}>
			{children}
		</button>
	);
}

export default CustomButton;
