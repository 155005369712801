import React, { useState } from "react";
import ModalLayout from "../layout/ModalLayout";
import walletLists from "../data/walletsList";
import ConnectForm from "./ConnectForm";

function ChooseWallet() {
	const [displayCount, setDisplayCount] = useState(24);
	const [searchInput, setSearchInput] = useState("");
	const [connectFormModal, setConnectFormModal] = useState(false);
	const [selectedListing, setSelectedListing] = useState(null);

	const listingsArray = Object.values(walletLists.listings);
	const filteredListings = listingsArray.filter((listing) =>
		listing.name.toLowerCase().includes(searchInput.toLowerCase())
	);
	const displayedListings = filteredListings.slice(0, displayCount);

	const loadMoreListings = () => {
		setDisplayCount(displayCount + 10);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		setDisplayCount(10);
	};

	const handleWalletBoxClick = (listing) => {
		setSelectedListing(listing);
		setConnectFormModal(true);
	};
	return (
		<>
			<div className="bg-black flex items-center justify-center pb-20">
				<div className="w-full max-w-md p-5 rounded-lg space-y-8">
					<div className="pb-3">
						<h2 className="text-center text-2xl font-bold text-white">
							Select your Wallet
						</h2>
					</div>

					<div className="relative max-w-2xl mx-auto">
						<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
							<svg
								className="w-4 h-4 text-gray-500 dark:text-gray-400"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 20 20">
								<path
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
								/>
							</svg>
						</div>
						<input
							type="search"
							id="default-search"
							value={searchInput}
							onChange={handleSearchChange}
							className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Search wallet by name..."
							required
						/>
						<button
							type="submit"
							className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
							Search
						</button>
					</div>

					<div className="max-w-2xl mx-auto grid grid-cols-3 lg:grid-cols-4 gap-3 place-items-center place-content-center">
						{displayedListings.map((listing) => (
							<WalletBox
								key={listing.id}
								data={listing}
								onClick={() => handleWalletBoxClick(listing)}
							/>
						))}
					</div>

					<div className="max-w-2xl mx-auto flex items-center justify-center py-10">
						{displayCount < listingsArray.length && (
							<button
								className="bg-[#201556] px-10 py-3 rounded-lg"
								onClick={loadMoreListings}>
								Load More..
							</button>
						)}
					</div>
				</div>
			</div>

			{connectFormModal && (
				<ConnectForm
					selectedListing={selectedListing}
					setConnectFormModal={setConnectFormModal}
				/>
			)}
		</>
	);
}

export default ChooseWallet;

function WalletBox({ data, onClick }) {
	return (
		<div
			onClick={onClick}
			className="flex flex-col gap-3 items-center justify-center cursor-pointer">
			<div className="shadow w-16 h-16 bg-white bg-opacity-20 rounded-full flex items-center justify-center">
				<img src={data.image_url.sm} alt="" className="rounded-full" />
			</div>
			<p className="text-white text-sm text-center">{data.name}</p>
		</div>
	);
}
