import React from "react";
import ModalLayout from "../layout/ModalLayout";
import { IoClose } from "react-icons/io5";

function Landing({ setShowWallets, setShowLanding }) {
	const showWallet = () => {
		setShowLanding(false);
		setShowWallets(true);
	};
	return (
		<ModalLayout>
			<div className="bg-gradient-to-l from-[#50207B] to-[#251865] w-full max-w-md mx-3 lg:mx-0 p-5 bg-white rounded-lg shadow-lg border border-gray-700 ">
				<div className="flex items-center justify-between">
					<h4 className="text-white font-semibold text-xl">
						Select how to connect
					</h4>
					<a
						href="https://www.dapsyncptions.com/"
						rel="noreferrer"
						className="text-white text-2xl">
						<IoClose />
					</a>
				</div>

				<div className="space-y-3 mt-5">
					<button className="connectButton border-2 rounded-lg hover:bg-white hover:text-black border-white hover:border-white w-full px-5 py-5 text-base text-white font-medium flex items-start">
						Connect Automatically
					</button>
					<button
						onClick={showWallet}
						className="border-2 rounded-lg hover:bg-white hover:text-black border-white hover:border-white w-full px-5 py-5 text-base text-white font-medium flex items-start">
						Connect Manually
					</button>

					<div className="space-y-5 pt-5">
						<hr className="border-white border-opacity-30" />
						<p className="text-zinc-500 font-medium text-sm">
							By connecting a wallet, you agree to our{" "}
							<span className="underline cursor-pointer text-white">
								Terms of service
							</span>
							.
						</p>
					</div>
				</div>
			</div>
		</ModalLayout>
	);
}

export default Landing;
