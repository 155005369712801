const walletLists = {
	listings: {
		c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96: {
			id: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
			name: "MetaMask",
			slug: "metamask",
			description:
				"Whether you are an experienced user or brand new to blockchain, MetaMask helps you connect to the decentralized web: a new internet.",
			homepage: "https://metamask.io/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5195e9db-94d8-4579-6f11-ef553be95100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5195e9db-94d8-4579-6f11-ef553be95100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5195e9db-94d8-4579-6f11-ef553be95100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5195e9db-94d8-4579-6f11-ef553be95100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/metamask/id1438144202",
				android: "https://play.google.com/store/apps/details?id=io.metamask",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
				firefox:
					"https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US",
				opera: "https://addons.opera.com/en-gb/extensions/details/metamask-10/",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isMetaMask",
				},
			],
			rdns: "io.metamask",
			mobile: {
				native: "metamask://",
				universal: "https://metamask.app.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MetaMask",
				colors: {
					primary: "#ffffff",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0": {
			id: "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
			name: "Trust Wallet",
			slug: "trust-wallet",
			description:
				"Trust Wallet supports over 10 Million tokens including Ethereum, Solana, Polygon Matic, BNB, and Avalanche.",
			homepage: "https://trustwallet.com/",
			chains: [
				"cosmos:cosmoshub-4",
				"cosmos:kava-4",
				"cosmos:thorchain-mainnet-v1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:108",
				"eip155:1101",
				"eip155:128",
				"eip155:137",
				"eip155:2020",
				"eip155:288",
				"eip155:321",
				"eip155:324",
				"eip155:361",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:4689",
				"eip155:56",
				"eip155:56288",
				"eip155:59144",
				"eip155:60",
				"eip155:820",
				"eip155:88",
				"eip155:9001",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7677b54f-3486-46e2-4e37-bf8747814f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7677b54f-3486-46e2-4e37-bf8747814f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7677b54f-3486-46e2-4e37-bf8747814f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7677b54f-3486-46e2-4e37-bf8747814f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1288339409",
				android:
					"https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isTrust",
				},
				{
					namespace: "eip155",
					injected_id: "isTrustWallet",
				},
			],
			rdns: "com.trustwallet.app",
			mobile: {
				native: "trust://",
				universal: "https://link.trustwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Trust",
				colors: {
					primary: "#0500FF",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12+00:00",
		},
		"4622a2b2d6af1a6aa24cd7b23099efac1b2fd875da31a0": {
			id: "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
			name: "Wallet Connect",
			slug: "wallet-connect",
			description:
				"Trust Wallet supports over 10 Million tokens including Ethereum, Solana, Polygon Matic, BNB, and Avalanche.",
			homepage: "https://trustwallet.com/",
			chains: [
				"cosmos:cosmoshub-4",
				"cosmos:kava-4",
				"cosmos:thorchain-mainnet-v1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:108",
				"eip155:1101",
				"eip155:128",
				"eip155:137",
				"eip155:2020",
				"eip155:288",
				"eip155:321",
				"eip155:324",
				"eip155:361",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:4689",
				"eip155:56",
				"eip155:56288",
				"eip155:59144",
				"eip155:60",
				"eip155:820",
				"eip155:88",
				"eip155:9001",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7677b54f-3486-46e2-4e37-bf8747814f00",
			image_url: {
				sm: "https://altcoinsbox.com/wp-content/uploads/2023/04/wallet-connect-logo.jpg",
				md: "https://altcoinsbox.com/wp-content/uploads/2023/04/wallet-connect-logo.jpg",
				lg: "https://altcoinsbox.com/wp-content/uploads/2023/04/wallet-connect-logo.jpg",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1288339409",
				android:
					"https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isTrust",
				},
				{
					namespace: "eip155",
					injected_id: "isTrustWallet",
				},
			],
			rdns: "com.trustwallet.app",
			mobile: {
				native: "trust://",
				universal: "https://link.trustwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Trust",
				colors: {
					primary: "#0500FF",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12+00:00",
		},
		"225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f": {
			id: "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
			name: "Safe",
			slug: "safe",
			description: "The most trusted platform to manage digital assets.",
			homepage: "https://safe.global/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1313161554",
				"eip155:137",
				"eip155:246",
				"eip155:4",
				"eip155:42161",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:73799",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "3913df81-63c2-4413-d60b-8ff83cbed500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3913df81-63c2-4413-d60b-8ff83cbed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3913df81-63c2-4413-d60b-8ff83cbed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3913df81-63c2-4413-d60b-8ff83cbed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.safe.global/",
				ios: "https://apps.apple.com/app/id1515759131",
				android: "https://play.google.com/store/apps/details?id=io.gnosis.safe",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "safe://",
				universal: "https://app.safe.global/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Safe",
				colors: {
					primary: "#12FF80",
					secondary: "#121312",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369": {
			id: "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
			name: "Rainbow",
			slug: "rainbow",
			description:
				"Rainbow is a fun, simple, and secure way to get started with crypto and explore the new world of Ethereum",
			homepage: "https://rainbow.me/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
				"eip155:7777777",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1457119021?pt=119997837&ct=wc&mt=8",
				android:
					"https://play.google.com/store/apps/details?id=me.rainbow&referrer=utm_source%3Dwc%26utm_medium%3Dconnector%26utm_campaign%3Dwc",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/rainbow/opfgelmcmbiajamepnmloijbpoleiama?utm_source=wc&utm_medium=connector&utm_campaign=wc",
				firefox:
					"https://addons.mozilla.org/en-US/firefox/addon/rainbow-extension/?utm_source=wc&utm_medium=connector&utm_campaign=wc",
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/rainbow/cpojfbodiccabbabgimdeohkkpjfpbnf?utm_source=wc&utm_medium=connector&utm_campaign=wc",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isRainbow",
				},
			],
			rdns: "me.rainbow",
			mobile: {
				native: "rainbow://",
				universal: "https://rnbwapp.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Rainbow",
				colors: {
					primary: "#001e59",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12+00:00",
		},
		c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a: {
			id: "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a",
			name: "Uniswap Wallet",
			slug: "uniswap-wallet",
			description:
				"Built by the most trusted team in DeFi, Uniswap Wallet allows you to maintain full custody and control of your assets. ",
			homepage: "https://uniswap.org",
			chains: ["eip155:1", "eip155:10", "eip155:137", "eip155:42161"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bff9cf1f-df19-42ce-f62a-87f04df13c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bff9cf1f-df19-42ce-f62a-87f04df13c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bff9cf1f-df19-42ce-f62a-87f04df13c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bff9cf1f-df19-42ce-f62a-87f04df13c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/uniswap-wallet/id6443944476",
				android:
					"https://play.google.com/store/apps/details?id=com.uniswap.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "uniswap://",
				universal: "https://uniswap.org/app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Uniswap",
				colors: {
					primary: "#F42BD2",
					secondary: "#FAD8F8",
				},
			},
			updatedAt: "2023-03-03T13:00:30+00:00",
		},
		ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18: {
			id: "ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18",
			name: "Zerion",
			slug: "zerion",
			description: "Smart Web3 Wallet",
			homepage: "https://zerion.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1313161554",
				"eip155:137",
				"eip155:200",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "73f6f52f-7862-49e7-bb85-ba93ab72cc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/73f6f52f-7862-49e7-bb85-ba93ab72cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/73f6f52f-7862-49e7-bb85-ba93ab72cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/73f6f52f-7862-49e7-bb85-ba93ab72cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.zerion.io",
				ios: "https://apps.apple.com/app/id1456732565",
				android:
					"https://play.google.com/store/apps/details?id=io.zerion.android&hl=en&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/zerion-wallet-for-web3-nf/klghhnkeealcohjjanjjdaeeggmfmlpl",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isZerion",
				},
			],
			rdns: "io.zerion.wallet",
			mobile: {
				native: "zerion://",
				universal: "https://wallet.zerion.io",
			},
			desktop: {
				native: "zerion://",
				universal: "https://wallet.zerion.io",
			},
			metadata: {
				shortName: "Zerion",
				colors: {
					primary: null,
					secondary: "#2962ef",
				},
			},
			updatedAt: "2022-06-28T14:02:53.355604+00:00",
		},
		ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef: {
			id: "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef",
			name: "imToken",
			slug: "imtoken",
			description:
				"imToken is an easy and secure digital wallet trusted by millions.",
			homepage: "https://token.im/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1001",
				"eip155:128",
				"eip155:137",
				"eip155:1666600000",
				"eip155:1666700000",
				"eip155:25",
				"eip155:256",
				"eip155:338",
				"eip155:420",
				"eip155:42220",
				"eip155:43110",
				"eip155:43114",
				"eip155:44787",
				"eip155:5",
				"eip155:56",
				"eip155:65",
				"eip155:66",
				"eip155:80001",
				"eip155:8217",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "99520548-525c-49d7-fb2f-5db65293b000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/99520548-525c-49d7-fb2f-5db65293b000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/99520548-525c-49d7-fb2f-5db65293b000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/99520548-525c-49d7-fb2f-5db65293b000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://token.im/",
				ios: "https://apps.apple.com/us/app/imtoken2/id1384798940",
				android: "https://play.google.com/store/apps/details?id=im.token.app",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "imtokenv2://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "imToken",
				colors: {
					primary: "#098DE6",
					secondary: "#00B6CC",
				},
			},
			updatedAt: "2023-06-05T12:11:46.829668+00:00",
		},
		bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6: {
			id: "bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6",
			name: "Argent",
			slug: "argent",
			description:
				"Buy, earn, stake and trade on Ethereum Layer 2 with low fees & bulletproof security.",
			homepage: "https://www.argent.xyz",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "215158d2-614b-49c9-410f-77aa661c3900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/215158d2-614b-49c9-410f-77aa661c3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/215158d2-614b-49c9-410f-77aa661c3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/215158d2-614b-49c9-410f-77aa661c3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/argent-defi-in-a-tap/id1358741926",
				android:
					"https://play.google.com/store/apps/details?id=im.argent.contractwalletclient&hl=en&gl=US&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "argent://app/",
				universal: "https://www.argent.xyz/app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Argent",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-03-08T09:52:55.902621+00:00",
		},
		"74f8092562bd79675e276d8b2062a83601a4106d30202f2d509195e30e19673d": {
			id: "74f8092562bd79675e276d8b2062a83601a4106d30202f2d509195e30e19673d",
			name: "Spot",
			slug: "spot",
			description:
				"Spot is a mobile & secure non-custodial wallet for Ethereum, Polygon, Solana, Bitcoin, Tezos & NFTs. Access web3 & DeFi with WalletConnect.",
			homepage: "https://www.spot-wallet.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1bf33a89-b049-4a1c-d1f6-4dd7419ee400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1bf33a89-b049-4a1c-d1f6-4dd7419ee400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1bf33a89-b049-4a1c-d1f6-4dd7419ee400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1bf33a89-b049-4a1c-d1f6-4dd7419ee400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/spot/pfdaepphglddodhkmcfoefimbcnkipmn",
				ios: "https://apps.apple.com/us/app/buy-bitcoin-spot-wallet-app/id1390560448",
				android: "https://play.google.com/store/apps/details?id=com.spot.spot",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/spot/pfdaepphglddodhkmcfoefimbcnkipmn",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isSpotEthWallet",
				},
			],
			rdns: "com.spot-wallet",
			mobile: {
				native: "spot://",
				universal: "https://spot.so",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Spot",
				colors: {
					primary: "#29C0FF",
					secondary: "#29C0FF",
				},
			},
			updatedAt: "2022-03-04T18:07:50.190371+00:00",
		},
		"38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662": {
			id: "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662",
			name: "Bitget Wallet",
			slug: "bitkeep",
			description: "Bitget Wallet",
			homepage: "https://web3.bitget.com",
			chains: [
				"eip155:1",
				"eip155:100",
				"eip155:128",
				"eip155:137",
				"eip155:56",
				"eip155:66",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "68e8063a-ff69-4941-3b40-af09e2fcd700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/68e8063a-ff69-4941-3b40-af09e2fcd700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/68e8063a-ff69-4941-3b40-af09e2fcd700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/68e8063a-ff69-4941-3b40-af09e2fcd700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://bitkeep.com",
				ios: "https://web3.bitget.com/en/wallet-download?type=0",
				android: "https://web3.bitget.com/en/wallet-download?type=0",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bitkeep-crypto-nft-wallet/jiidiaalihmmhddjgbnbgdfflelocpak",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBitKeep",
				},
			],
			rdns: null,
			mobile: {
				native: "bitkeep://",
				universal: "https://bkapp.vip",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitget Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-08-04T14:16:32.550706+00:00",
		},
		f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d: {
			id: "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
			name: "Crypto.com | DeFi Wallet",
			slug: "cryptocom-defi-wallet",
			description:
				"A non-custodial wallet that gives you access to a full suite of DeFi services in one place.",
			homepage: "https://crypto.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:108",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:42161",
				"eip155:42220",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7c5ff577-a68d-49c5-02cd-3d83637b0b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7c5ff577-a68d-49c5-02cd-3d83637b0b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7c5ff577-a68d-49c5-02cd-3d83637b0b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7c5ff577-a68d-49c5-02cd-3d83637b0b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/US/app/id1512048310?mt=8",
				android:
					"https://play.google.com/store/apps/details?id=com.defi.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "dfw://",
				universal: "https://wallet.crypto.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Crypto.com",
				colors: {
					primary: "#1199FA",
					secondary: "#EAEEF4",
				},
			},
			updatedAt: "2021-07-30T17:48:12+00:00",
		},
		"971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709": {
			id: "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
			name: "OKX Wallet",
			slug: "okx-wallet",
			description: "One Web3 portal to rule them all",
			homepage: "https://www.okx.com/web3",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "45f2f08e-fc0c-4d62-3e63-404e72170500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/45f2f08e-fc0c-4d62-3e63-404e72170500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/45f2f08e-fc0c-4d62-3e63-404e72170500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/45f2f08e-fc0c-4d62-3e63-404e72170500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.okx.com/download",
				ios: "https://apps.apple.com/us/app/okx-buy-bitcoin-eth-crypto/id1327268470",
				android:
					"https://play.google.com/store/apps/details?id=com.okinc.okex.gp",
				mac: "https://www.okx.com/download",
				windows: "https://www.okx.com/download",
				linux: "https://www.okx.com/download",
				chrome:
					"https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isPLC",
				},
				{
					namespace: "solana",
					injected_id: "isPLC",
				},
			],
			rdns: "com.okex.wallet",
			mobile: {
				native: "okex://main",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "OKX Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-04-20T09:26:32.43819+00:00",
		},
		"20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66": {
			id: "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66",
			name: "TokenPocket",
			slug: "tokenpocket",
			description:
				"The leading multi-chain self-custodial wallet, which supports mainstream networks including BTC, ETH, BSC, TRON, zkSync Era∎, etc.",
			homepage: "https://tokenpocket.pro/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10000",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:1666600000",
				"eip155:170",
				"eip155:250",
				"eip155:321",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:59",
				"eip155:61",
				"eip155:65",
				"eip155:66",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f3119826-4ef5-4d31-4789-d4ae5c18e400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f3119826-4ef5-4d31-4789-d4ae5c18e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f3119826-4ef5-4d31-4789-d4ae5c18e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f3119826-4ef5-4d31-4789-d4ae5c18e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/tokenpocket/mfgccjchihfkkindfppnaooecgfneiii",
				ios: "https://apps.apple.com/us/app/tp-wallet/id6444625622?l=en",
				android:
					"https://play.google.com/store/apps/details?id=vip.mytokenpocket",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/tokenpocket/mfgccjchihfkkindfppnaooecgfneiii",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isTokenPocket",
				},
			],
			rdns: "pro.tokenpocket",
			mobile: {
				native: "tpoutside://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "TokenPocket",
				colors: {
					primary: "#2982fe",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"8837dd9413b1d9b585ee937d27a816590248386d9dbf59f5cd3422dbbb65683e": {
			id: "8837dd9413b1d9b585ee937d27a816590248386d9dbf59f5cd3422dbbb65683e",
			name: "Robinhood Wallet",
			slug: "robinhood-wallet",
			description: "Robinhood’s Web3 Wallet",
			homepage: "https://robinhood.com/web3-wallet/",
			chains: ["eip155:1", "eip155:10", "eip155:137", "eip155:42161"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "dfe0e3e3-5746-4e2b-12ad-704608531500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/dfe0e3e3-5746-4e2b-12ad-704608531500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/dfe0e3e3-5746-4e2b-12ad-704608531500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/dfe0e3e3-5746-4e2b-12ad-704608531500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://robinhood.com/web3-wallet/",
				ios: "https://robinhood.com/web3-wallet/",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://robinhood.com/web3-wallet/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isRobinhoodMobileWallet",
				},
			],
			rdns: "com.robinhood.wallet",
			mobile: {
				native: "robinhood-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Robinhood Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-12-19T17:42:54.751016+00:00",
		},
		"85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041": {
			id: "85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041",
			name: "Frontier",
			slug: "frontier",
			description:
				"The unified non-custodial wallet to Send, Stake, Swap, Bridge Crypto & NFTs. Interact with DeFi apps, 50+ Blockchains & ecosystems.",
			homepage: "https://www.frontier.xyz",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:kava-4",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:122",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:1666600000",
				"eip155:25",
				"eip155:250",
				"eip155:288",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:4689",
				"eip155:56",
				"eip155:8217",
				"eip155:88",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "a78c4d48-32c1-4a9d-52f2-ec7ee08ce200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a78c4d48-32c1-4a9d-52f2-ec7ee08ce200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a78c4d48-32c1-4a9d-52f2-ec7ee08ce200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a78c4d48-32c1-4a9d-52f2-ec7ee08ce200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.frontier.xyz/download",
				ios: "https://apps.apple.com/us/app/frontier-defi-wallet/id1482380988",
				android:
					"https://play.google.com/store/apps/details?id=com.frontierwallet&hl=en_IN&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/frontier-wallet/kppfdiipphfccemcignhifpjkapfbihd",
				firefox: null,
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/frontier-wallet/kppfdiipphfccemcignhifpjkapfbihd",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isFrontier",
				},
				{
					namespace: "solana",
					injected_id: "isFrontier",
				},
			],
			rdns: "xyz.frontier.wallet",
			mobile: {
				native: "frontier://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Frontier",
				colors: {
					primary: "#CC703C",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-10-24T18:12:03.077252+00:00",
		},
		"84b43e8ddfcd18e5fcb5d21e7277733f9cccef76f7d92c836d0e481db0c70c04": {
			id: "84b43e8ddfcd18e5fcb5d21e7277733f9cccef76f7d92c836d0e481db0c70c04",
			name: "Blockchain.com",
			slug: "blockchaincom-1",
			description:
				"The only crypto app you’ll ever need. Buy, store, and do more with your crypto.",
			homepage: "https://www.blockchain.com/en/app",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6f913b80-86c0-46f9-61ca-cc90a1805900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6f913b80-86c0-46f9-61ca-cc90a1805900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6f913b80-86c0-46f9-61ca-cc90a1805900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6f913b80-86c0-46f9-61ca-cc90a1805900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/blockchain-bitcoin-wallet/id493253309",
				android:
					"https://play.google.com/store/apps/details?id=piuk.blockchain.android",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "blockchain-wallet://",
				universal: "https://www.blockchain.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Blockchain",
				colors: {
					primary: "#0C6CF2",
					secondary: "#65A5FF",
				},
			},
			updatedAt: "2023-06-15T08:52:25.491035+00:00",
		},
		"0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150": {
			id: "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
			name: "SafePal",
			slug: "safepal",
			description:
				"SafePal is a cryptocurrency wallet that aims to provide a secure and user-friendly crypto management platform for the masses. ",
			homepage: "https://safepal.com/",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:kava-4",
				"eip155:1",
				"eip155:10000",
				"eip155:122",
				"eip155:1313161554",
				"eip155:137",
				"eip155:14",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:19",
				"eip155:200",
				"eip155:25",
				"eip155:250",
				"eip155:288",
				"eip155:30",
				"eip155:361",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:56",
				"eip155:6",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"stellar:pubnet",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "252753e7-b783-4e03-7f77-d39864530900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/252753e7-b783-4e03-7f77-d39864530900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/252753e7-b783-4e03-7f77-d39864530900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/252753e7-b783-4e03-7f77-d39864530900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/safepal-extension-wallet/lgmpcpglpngdoalbgeoldeajfclnhafa",
				ios: "https://apps.apple.com/app/safepal-wallet/id1548297139",
				android:
					"https://play.google.com/store/apps/details?id=io.safepal.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/safepal-extension-wallet/lgmpcpglpngdoalbgeoldeajfclnhafa",
				firefox:
					"https://addons.mozilla.org/firefox/addon/safepal-extension-wallet",
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/safepal%E6%8F%92%E4%BB%B6%E9%92%B1%E5%8C%85/apenkfbbpmhihehmihndmmcdanacolnh",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isSafePal",
				},
			],
			rdns: null,
			mobile: {
				native: "safepalwallet://",
				universal: "https://link.safepal.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SafePal",
				colors: {
					primary: "#4A21EF",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		afbd95522f4041c71dd4f1a065f971fd32372865b416f95a0b1db759ae33f2a7: {
			id: "afbd95522f4041c71dd4f1a065f971fd32372865b416f95a0b1db759ae33f2a7",
			name: "Omni",
			slug: "omni",
			description: "Multi chain, self custodial DeFi wallet",
			homepage: "https://omni.app",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:kava-4",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:1666600000",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2cd67b4c-282b-4809-e7c0-a88cd5116f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2cd67b4c-282b-4809-e7c0-a88cd5116f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2cd67b4c-282b-4809-e7c0-a88cd5116f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2cd67b4c-282b-4809-e7c0-a88cd5116f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/de/app/steakwallet/id1569375204?l=en",
				android:
					"https://play.google.com/store/apps/details?id=fi.steakwallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "omni://",
				universal: "https://links.omni.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Omni",
				colors: {
					primary: "#FFFFFF",
					secondary: "#000000",
				},
			},
			updatedAt: "2022-01-27T08:21:37.928+00:00",
		},
		"9414d5a85c8f4eabc1b5b15ebe0cd399e1a2a9d35643ab0ad22a6e4a32f596f0": {
			id: "9414d5a85c8f4eabc1b5b15ebe0cd399e1a2a9d35643ab0ad22a6e4a32f596f0",
			name: "Zengo Wallet",
			slug: "zengo-wallet",
			description: "Web3, DeFi and Dapp wallet",
			homepage: "https://zengo.com/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "6133c399-ae32-4eba-0c5a-0fb84492bf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6133c399-ae32-4eba-0c5a-0fb84492bf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6133c399-ae32-4eba-0c5a-0fb84492bf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6133c399-ae32-4eba-0c5a-0fb84492bf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/zengo-crypto-bitcoin-wallet/id1440147115",
				android:
					"https://play.google.com/store/apps/details?id=com.zengo.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "zengo://get.zengo.com/",
				universal: "https://get.zengo.com/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Zengo",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-23T07:53:07.19703+00:00",
		},
		c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576: {
			id: "c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576",
			name: "1inch Wallet",
			slug: "1inch-wallet-1",
			description:
				"The 1inch Wallet is a multichain non-custodial DeFi crypto wallet with an easy interface for secure storage and transactions.",
			homepage: "http://wallet.1inch.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1313161554",
				"eip155:137",
				"eip155:250",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "52b1da3c-9e72-40ae-5dac-6142addd9c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/52b1da3c-9e72-40ae-5dac-6142addd9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/52b1da3c-9e72-40ae-5dac-6142addd9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/52b1da3c-9e72-40ae-5dac-6142addd9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/1inch-defi-wallet/id1546049391",
				android:
					"https://play.google.com/store/apps/details?id=io.oneinch.android",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "oneinch://",
				universal: "https://wallet.1inch.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "1inch",
				colors: {
					primary: "#2F8AF5",
					secondary: "#6C86AD",
				},
			},
			updatedAt: "2023-06-20T19:27:11.298138+00:00",
		},
		"8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4": {
			id: "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4",
			name: "Binance Web3 Wallet",
			slug: "binance-defi-wallet",
			description:
				"Binance Web3 Wallet is a keyless, seedless, multi-chain and semi-custody wallet.",
			homepage: "https://www.binance.com/en/web3wallet",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:324",
				"eip155:42161",
				"eip155:56",
				"eip155:59144",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "ebac7b39-688c-41e3-7912-a4fefba74600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ebac7b39-688c-41e3-7912-a4fefba74600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ebac7b39-688c-41e3-7912-a4fefba74600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ebac7b39-688c-41e3-7912-a4fefba74600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.binance.com/en/web3wallet",
				ios: "https://www.binance.com/en/download",
				android: "https://www.binance.com/en/download",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bnc://app.binance.com/cedefi/",
				universal: "https://app.binance.com/cedefi",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Binance",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-04-18T15:40:25.222323+00:00",
		},
		e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4: {
			id: "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4",
			name: "Exodus",
			slug: "exodus",
			description: "Best Crypto Wallet for Desktop, Mobile, Browser, Hardware",
			homepage: "https://exodus.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4c16cad4-cac9-4643-6726-c696efaf5200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4c16cad4-cac9-4643-6726-c696efaf5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4c16cad4-cac9-4643-6726-c696efaf5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4c16cad4-cac9-4643-6726-c696efaf5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://exodus.com/download/",
				ios: "https://apps.apple.com/us/app/exodus-crypto-bitcoin-wallet/id1414384820",
				android:
					"https://play.google.com/store/apps/details?id=exodusmovement.exodus&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/exodus-web3-wallet/aholpfdialjgjfhomihkjbmgjidlcdno",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isExodus",
				},
			],
			rdns: null,
			mobile: {
				native: "exodus://",
				universal: "https://exodus.com/m",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Exodus",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-08-01T16:34:47.618033+00:00",
		},
		"19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927": {
			id: "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
			name: "Ledger Live",
			slug: "ledger-live",
			description:
				"Web3 Wallet from the company that produced the world's most secure crypto hardware device.",
			homepage: "https://www.ledger.com/ledger-live",
			chains: ["eip155:1", "eip155:137", "eip155:4", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a7f416de-aa03-4c5e-3280-ab49269aef00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a7f416de-aa03-4c5e-3280-ab49269aef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a7f416de-aa03-4c5e-3280-ab49269aef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a7f416de-aa03-4c5e-3280-ab49269aef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/id1361671700",
				android:
					"https://play.google.com/store/apps/details?id=com.ledger.live",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ledgerlive://",
				universal: null,
			},
			desktop: {
				native: "ledgerlive://",
				universal: null,
			},
			metadata: {
				shortName: "Ledger",
				colors: {
					primary: "#000000",
					secondary: "#FF5300",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565+00:00",
		},
		f5b4eeb6015d66be3f5940a895cbaa49ef3439e518cd771270e6b553b48f31d2: {
			id: "f5b4eeb6015d66be3f5940a895cbaa49ef3439e518cd771270e6b553b48f31d2",
			name: "MEW wallet",
			slug: "mew-wallet",
			description:
				"Buy Ethereum & cryptocurrency, trade tokens, collect NFTs and explore web3",
			homepage: "https://mewwallet.com",
			chains: ["eip155:1", "eip155:137", "eip155:56", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "e2024511-2c9b-46d7-3111-52df3d241700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e2024511-2c9b-46d7-3111-52df3d241700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e2024511-2c9b-46d7-3111-52df3d241700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e2024511-2c9b-46d7-3111-52df3d241700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://download.mewwallet.com/?source=wc",
				ios: "https://apps.apple.com/app/id1464614025",
				android:
					"https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: "https://apps.apple.com/app/id1464614025",
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isMEWwallet",
				},
			],
			rdns: null,
			mobile: {
				native: "mewwallet://",
				universal: "https://mewwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MEW wallet",
				colors: {
					primary: "#05C0A5",
					secondary: null,
				},
			},
			updatedAt: "2023-02-28T18:05:38.651805+00:00",
		},
		"138f51c8d00ac7b9ac9d8dc75344d096a7dfe370a568aa167eabc0a21830ed98": {
			id: "138f51c8d00ac7b9ac9d8dc75344d096a7dfe370a568aa167eabc0a21830ed98",
			name: "AlphaWallet",
			slug: "alphawallet",
			description:
				"AlphaWallet is a production-ready and easy to customise Ethereum Wallet for your business.",
			homepage: "https://alphawallet.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:11297108099",
				"eip155:11297108109",
				"eip155:137",
				"eip155:200",
				"eip155:256",
				"eip155:3",
				"eip155:338",
				"eip155:4",
				"eip155:4002",
				"eip155:42",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:61",
				"eip155:69",
				"eip155:77",
				"eip155:97",
				"eip155:99",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5b1cddfb-056e-4e78-029a-54de5d70c500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5b1cddfb-056e-4e78-029a-54de5d70c500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5b1cddfb-056e-4e78-029a-54de5d70c500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5b1cddfb-056e-4e78-029a-54de5d70c500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/alphawallet-eth-wallet/id1358230430",
				android:
					"https://play.google.com/store/apps/details?id=io.stormbird.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "awallet://",
				universal: "https://aw.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "AlphaWallet",
				colors: {
					primary: "#ffffff",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565+00:00",
		},
		"47bb07617af518642f3413a201ec5859faa63acb1dd175ca95085d35d38afb83": {
			id: "47bb07617af518642f3413a201ec5859faa63acb1dd175ca95085d35d38afb83",
			name: "KEYRING PRO",
			slug: "keyring-pro",
			description:
				"KEYRING PRO brings possibilities to reality by offering a simple cross-chain environment, where user can experience multiple chains at once.",
			homepage: "https://keyring.app/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:128",
				"eip155:137",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:250",
				"eip155:256",
				"eip155:4002",
				"eip155:42",
				"eip155:42161",
				"eip155:421611",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:61",
				"eip155:69",
				"eip155:80001",
				"eip155:88",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "dda0f0fb-34e8-4a57-dcea-b008e7d1ff00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/dda0f0fb-34e8-4a57-dcea-b008e7d1ff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/dda0f0fb-34e8-4a57-dcea-b008e7d1ff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/dda0f0fb-34e8-4a57-dcea-b008e7d1ff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://keyring.app/",
				ios: "https://apps.apple.com/us/app/keyring-pro-wallet-management/id1546824976",
				android:
					"https://play.google.com/store/apps/details?id=co.bacoor.keyring",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "keyring://",
				universal: "https://keyring.app/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "KEYRING PRO",
				colors: {
					primary: "#00D2C9",
					secondary: "#FFAA55",
				},
			},
			updatedAt: "2022-03-23T11:15:13.552008+00:00",
		},
		"76a3d548a08cf402f5c7d021f24fd2881d767084b387a5325df88bc3d4b6f21b": {
			id: "76a3d548a08cf402f5c7d021f24fd2881d767084b387a5325df88bc3d4b6f21b",
			name: "LOBSTR Wallet",
			slug: "lobstr-wallet",
			description:
				"LOBSTR is a leading platform for managing Stellar Lumens and other assets issued on the Stellar network.",
			homepage: "https://lobstr.co/",
			chains: ["stellar:pubnet"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0dafcaab-0852-47f7-85dd-436b86491d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0dafcaab-0852-47f7-85dd-436b86491d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0dafcaab-0852-47f7-85dd-436b86491d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0dafcaab-0852-47f7-85dd-436b86491d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://lobstr.co/",
				ios: "https://apps.apple.com/us/app/lobstr-stellar-wallet/id1404357892",
				android:
					"https://play.google.com/store/apps/details?id=com.lobstr.client",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "lobstr://",
				universal: "https://lobstr.co/uni/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "LOBSTR",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T11:07:49.350038+00:00",
		},
		dceb063851b1833cbb209e3717a0a0b06bf3fb500fe9db8cd3a553e4b1d02137: {
			id: "dceb063851b1833cbb209e3717a0a0b06bf3fb500fe9db8cd3a553e4b1d02137",
			name: "ONTO",
			slug: "onto",
			description:
				"A #DID-based #Web3 gateway for 1 million+ users on 30+ popular #blockchains, supporting 700+ dApps.",
			homepage: "https://onto.app/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1024",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1313161554",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:288",
				"eip155:42161",
				"eip155:4689",
				"eip155:50",
				"eip155:56",
				"eip155:58",
				"eip155:66",
				"neo3:mainnet",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d22b2a4b-5562-49ba-506b-6d5986914600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d22b2a4b-5562-49ba-506b-6d5986914600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d22b2a4b-5562-49ba-506b-6d5986914600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d22b2a4b-5562-49ba-506b-6d5986914600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/onto-an-ontology-dapp/id1436009823",
				android:
					"https://play.google.com/store/apps/details?id=com.github.ontio.onto",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ontoprovider://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ONTO",
				colors: {
					primary: "#ffffff",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26": {
			id: "7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26",
			name: "MathWallet",
			slug: "mathwallet",
			description: "The Multichain Wallet for Web3",
			homepage: "https://mathwallet.org/",
			chains: [
				"cosmos:cosmoshub-4",
				"cosmos:irishub-1",
				"cosmos:kava-4",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1024",
				"eip155:1139",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:1666600000",
				"eip155:288",
				"eip155:30",
				"eip155:321",
				"eip155:336",
				"eip155:40",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:44",
				"eip155:4689",
				"eip155:50",
				"eip155:52",
				"eip155:56",
				"eip155:59",
				"eip155:61",
				"eip155:66",
				"eip155:686",
				"eip155:787",
				"eip155:86",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "26a8f588-3231-4411-60ce-5bb6b805a700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/26a8f588-3231-4411-60ce-5bb6b805a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/26a8f588-3231-4411-60ce-5bb6b805a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/26a8f588-3231-4411-60ce-5bb6b805a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/math-wallet/afbcbjpbpfadlkmhmclhkeeodmamcflc",
				ios: "https://apps.apple.com/us/app/mathwallet5/id1582612388",
				android:
					"https://play.google.com/store/apps/details?id=com.mathwallet.android",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/math-wallet/afbcbjpbpfadlkmhmclhkeeodmamcflc",
				firefox: null,
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/math-wallet/dfeccadlilpndjjohbjdblepmjeahlmm",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isMathWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "mathwallet://",
				universal: "https://www.mathwallet.org",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MathWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"8308656f4548bb81b3508afe355cfbb7f0cb6253d1cc7f998080601f838ecee3": {
			id: "8308656f4548bb81b3508afe355cfbb7f0cb6253d1cc7f998080601f838ecee3",
			name: "Unstoppable Domains",
			slug: "unstoppable-domains",
			description: "Your identity for Web3",
			homepage: "https://unstoppabledomains.com/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Social",
			image_id: "4725dda0-4471-4d0f-7adf-6bbe8b929c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4725dda0-4471-4d0f-7adf-6bbe8b929c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4725dda0-4471-4d0f-7adf-6bbe8b929c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4725dda0-4471-4d0f-7adf-6bbe8b929c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://unstoppabledomains.com/",
				ios: "https://apps.apple.com/us/app/unstoppable-domains/id1544748602",
				android:
					"https://play.google.com/store/apps/details?id=com.unstoppabledomains.manager",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "unstoppabledomains://",
				universal: "https://unstoppabledomains.com/mobile",
			},
			desktop: {
				native: "",
				universal: "https://unstoppabledomains.com/mobile",
			},
			metadata: {
				shortName: "Unstoppable",
				colors: {
					primary: "#0D67FE",
					secondary: "#192B55",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"031f0187049b7f96c6f039d1c9c8138ff7a17fd75d38b34350c7182232cc29aa": {
			id: "031f0187049b7f96c6f039d1c9c8138ff7a17fd75d38b34350c7182232cc29aa",
			name: "Obvious",
			slug: "obvious",
			description:
				"Obvious is a self-custody wallet that brings together assets across EVM chains",
			homepage: "https://obvious.technology",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:106",
				"eip155:1101",
				"eip155:122",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:42161",
				"eip155:421613",
				"eip155:42220",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:8453",
				"eip155:9000",
				"eip155:9001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fe1b9394-55af-4828-a70d-5c5b7de6b200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fe1b9394-55af-4828-a70d-5c5b7de6b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fe1b9394-55af-4828-a70d-5c5b7de6b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fe1b9394-55af-4828-a70d-5c5b7de6b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/in/app/obvious-crypto-wallet/id1643088398",
				android:
					"https://play.google.com/store/apps/details?id=com.hashhalli.obvious",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "obvious://",
				universal: "https://wallet.obvious.technology",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Obvious",
				colors: {
					primary: "#7FEABD",
					secondary: "#000000",
				},
			},
			updatedAt: "2023-02-23T10:53:48.332937+00:00",
		},
		"5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489": {
			id: "5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489",
			name: "Fireblocks",
			slug: "fireblocks",
			description: "#1 Crypto and Digital Asset Platform for Institutions",
			homepage: "https://www.fireblocks.com/",
			chains: [
				"cosmos:columbus-4",
				"eip155:1",
				"eip155:10",
				"eip155:10000",
				"eip155:10001",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:19",
				"eip155:250",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:4",
				"eip155:42",
				"eip155:42161",
				"eip155:421611",
				"eip155:42220",
				"eip155:43113",
				"eip155:43114",
				"eip155:44787",
				"eip155:5",
				"eip155:50",
				"eip155:51",
				"eip155:56",
				"eip155:59",
				"eip155:61",
				"eip155:62320",
				"eip155:63",
				"eip155:69",
				"eip155:97",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "7e1514ba-932d-415d-1bdb-bccb6c2cbc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7e1514ba-932d-415d-1bdb-bccb6c2cbc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7e1514ba-932d-415d-1bdb-bccb6c2cbc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7e1514ba-932d-415d-1bdb-bccb6c2cbc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://console.fireblocks.io/",
				ios: "https://apps.apple.com/us/app/fireblocks/id1439296596",
				android:
					"https://play.google.com/store/apps/details?id=com.fireblocks.client&gl=IL",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fireblocks-wc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://console.fireblocks.io/v2/",
			},
			metadata: {
				shortName: "Fireblocks",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-31T07:16:55.630149+00:00",
		},
		"2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568": {
			id: "2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568",
			name: "Ambire Wallet",
			slug: "ambire-wallet",
			description:
				"Ambire Wallet is a full featured non-custodial open-source wallet focused on DeFi and ease of use.",
			homepage: "https://www.ambire.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "c39b3a16-1a38-4588-f089-cb7aeb584700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c39b3a16-1a38-4588-f089-cb7aeb584700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c39b3a16-1a38-4588-f089-cb7aeb584700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c39b3a16-1a38-4588-f089-cb7aeb584700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.ambire.com",
				ios: "https://apps.apple.com/bg/app/ambire-smart-crypto-wallet/id6444863857",
				android:
					"https://play.google.com/store/apps/details?id=com.ambire.wallet&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ambire://",
				universal: "https://mobile.ambire.com",
			},
			desktop: {
				native: "",
				universal: "https://wallet.ambire.com",
			},
			metadata: {
				shortName: "Ambire",
				colors: {
					primary: "#aa6aff",
					secondary: "#80ffdb",
				},
			},
			updatedAt: "2021-12-20T13:00:01.498413+00:00",
		},
		"802a2041afdaf4c7e41a2903e98df333c8835897532699ad370f829390c6900f": {
			id: "802a2041afdaf4c7e41a2903e98df333c8835897532699ad370f829390c6900f",
			name: "Infinity Wallet",
			slug: "infinity-wallet",
			description:
				"Infinity Wallet is a leading all-in-one one-stop DeFi and Web3 crypto wallet & the 1st Web3 Browser!",
			homepage: "https://infinitywallet.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:25",
				"eip155:321",
				"eip155:43114",
				"eip155:50",
				"eip155:56",
				"eip155:66",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "9f259366-0bcd-4817-0af9-f78773e41900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9f259366-0bcd-4817-0af9-f78773e41900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9f259366-0bcd-4817-0af9-f78773e41900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9f259366-0bcd-4817-0af9-f78773e41900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://infinitywallet.io/",
				ios: null,
				android: null,
				mac: "https://infinitywallet.io/desktop",
				windows: "https://infinitywallet.io/desktop",
				linux: "https://infinitywallet.io/desktop",
				chrome: "https://infinitywallet.io/download/",
				firefox: "https://infinitywallet.io/download/",
				safari: "https://infinitywallet.io/download/",
				edge: "https://infinitywallet.io/download/",
				opera: "https://infinitywallet.io/download/",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isInfinityWallet",
				},
				{
					namespace: "solana",
					injected_id: "infinitywallet",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "infinity://",
				universal: "https://infinitywallet.io/",
			},
			metadata: {
				shortName: "Infinity Wallet",
				colors: {
					primary: "#197fe1",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"7424d97904535b14fe34f09f63d8ca66935546f798758dabd5b26c2309f2b1f9": {
			id: "7424d97904535b14fe34f09f63d8ca66935546f798758dabd5b26c2309f2b1f9",
			name: "Bridge Wallet",
			slug: "bridge-wallet-1",
			description: "The Swiss app to invest in crypto the easy way",
			homepage: "https://mtpelerin.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:250",
				"eip155:30",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "20c3072e-c92e-4902-d4b9-cb2b6ab29100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/20c3072e-c92e-4902-d4b9-cb2b6ab29100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/20c3072e-c92e-4902-d4b9-cb2b6ab29100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/20c3072e-c92e-4902-d4b9-cb2b6ab29100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/bridge-wallet/id1481859680",
				android:
					"https://play.google.com/store/apps/details?id=com.mtpelerin.bridge&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://bridge.mtpelerin.com/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BridgeWallet",
				colors: {
					primary: "#4ec9fa",
					secondary: null,
				},
			},
			updatedAt: "2023-05-29T15:05:38.776329+00:00",
		},
		dd43441a6368ec9046540c46c5fdc58f79926d17ce61a176444568ca7c970dcd: {
			id: "dd43441a6368ec9046540c46c5fdc58f79926d17ce61a176444568ca7c970dcd",
			name: "Internet Money Wallet",
			slug: "internet-money-wallet",
			description:
				"EVM Wallet. Connect to All EVM Chains. First Tokenized Crypto Wallet of It's Kind. ",
			homepage: "https://internetmoney.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:106",
				"eip155:1285",
				"eip155:25",
				"eip155:3",
				"eip155:338",
				"eip155:4",
				"eip155:4002",
				"eip155:42",
				"eip155:420",
				"eip155:42161",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:6",
				"eip155:61",
				"eip155:62",
				"eip155:63",
				"eip155:80001",
				"eip155:940",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "204b2240-5ce4-4996-6ec4-f06a22726900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/204b2240-5ce4-4996-6ec4-f06a22726900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/204b2240-5ce4-4996-6ec4-f06a22726900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/204b2240-5ce4-4996-6ec4-f06a22726900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1641771042",
				android:
					"https://play.google.com/store/apps/details?id=com.internetmoneywallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/ckklhkaabbmdjkahiaaplikpdddkenic",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "internetmoney://",
				universal: "https://internetmoney.io",
			},
			desktop: {
				native: "",
				universal: "https://internetmoney.io",
			},
			metadata: {
				shortName: "InternetMoney",
				colors: {
					primary: "#000000",
					secondary: "#FBA81A",
				},
			},
			updatedAt: "2023-03-23T04:36:03.121747+00:00",
		},
		c482dfe368d4f004479977fd88e80dc9e81107f3245d706811581a6dfe69c534: {
			id: "c482dfe368d4f004479977fd88e80dc9e81107f3245d706811581a6dfe69c534",
			name: "NOW Wallet",
			slug: "now-wallet",
			description: "Cryptocurrency Fort Knox in your pocket",
			homepage: "https://walletnow.app/",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b6ee4efc-f53e-475b-927b-a7ded6211700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b6ee4efc-f53e-475b-927b-a7ded6211700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b6ee4efc-f53e-475b-927b-a7ded6211700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b6ee4efc-f53e-475b-927b-a7ded6211700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/now-wallet-bitcoin-crypto/id1591216386",
				android: "https://play.google.com/store/apps/details?id=com.nowwallet",
				mac: "https://apps.apple.com/app/now-wallet-bitcoin-crypto/id1591216386",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "nowwallet://",
				universal: "https://walletnow.app.link",
			},
			desktop: {
				native: "nowwallet://",
				universal: "https://walletnow.app.link",
			},
			metadata: {
				shortName: "NOW Wallet",
				colors: {
					primary: "#00C26F",
					secondary: "#4E95FF",
				},
			},
			updatedAt: "2022-06-03T15:15:36.265227+00:00",
		},
		"107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c": {
			id: "107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c",
			name: "Bitcoin.com Wallet",
			slug: "bitcoincom-wallet",
			description:
				"Buy, sell, store, trade, and use cryptocurrency with the Bitcoin.com Wallet, trusted by millions.",
			homepage: "https://www.bitcoin.com/",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0d7938e1-9b3b-4d8b-177b-98188c4cf400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0d7938e1-9b3b-4d8b-177b-98188c4cf400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0d7938e1-9b3b-4d8b-177b-98188c4cf400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0d7938e1-9b3b-4d8b-177b-98188c4cf400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.bitcoin.com/",
				ios: "https://apps.apple.com/us/app/bitcoin-wallet-by-bitcoin-com/id1252903728",
				android:
					"https://play.google.com/store/apps/details?id=com.bitcoin.mwallet",
				mac: "https://apps.apple.com/us/app/bitcoin-wallet-by-bitcoin-com/id1252903728",
				windows: "https://wallet.bitcoin.com/",
				linux: "https://wallet.bitcoin.com/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bitcoincom://",
				universal: "https://wallet.bitcoin.com/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitcoin.com Wallet",
				colors: {
					primary: "#0AC18E",
					secondary: "#0AC18E",
				},
			},
			updatedAt: "2022-06-22T12:21:06.997337+00:00",
		},
		"053ac0ac602e0969736941cf5aa07a3af57396d4601cb521a173a626e1015fb1": {
			id: "053ac0ac602e0969736941cf5aa07a3af57396d4601cb521a173a626e1015fb1",
			name: "αU wallet",
			slug: "αu-wallet",
			description: "Safely manage NFTs and cryptocurrencies with Wallet",
			homepage: "https://web.wallet.alpha-u.io/intro.html",
			chains: ["eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "40489ba1-6eb0-49a1-a9d4-439fefe52000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/40489ba1-6eb0-49a1-a9d4-439fefe52000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/40489ba1-6eb0-49a1-a9d4-439fefe52000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/40489ba1-6eb0-49a1-a9d4-439fefe52000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/jp/app/%CE%B1u-wallet/id6444401106",
				android:
					"https://play.google.com/store/apps/details?id=com.kddi.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: "nothing",
				firefox: "nothing",
				safari: "nothing",
				edge: "nothing",
				opera: "nothing",
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "alpha-u-wallet://",
				universal:
					"https://alphauwallet.page.link/?apn=com.kddi.wallet&ibi=com.kddi.wallet&isi=6444401106&link=https://web.wallet.alpha-u.io/intro.html",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "αU wallet",
				colors: {
					primary: "#784296",
					secondary: null,
				},
			},
			updatedAt: "2023-05-29T10:01:08.339579+00:00",
		},
		"2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01": {
			id: "2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01",
			name: "Coin98 Super App",
			slug: "coin98-super-app",
			description:
				"Coin98 Wallet is the #1 non-custodial, multi-chain wallet, and DeFi gateway",
			homepage: "https://coin98.com/wallet",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:122",
				"eip155:128",
				"eip155:1284",
				"eip155:1313161554",
				"eip155:1666600000",
				"eip155:199",
				"eip155:200",
				"eip155:25",
				"eip155:250",
				"eip155:288",
				"eip155:321",
				"eip155:324",
				"eip155:361",
				"eip155:42161",
				"eip155:56",
				"eip155:61",
				"eip155:66",
				"eip155:86",
				"eip155:88",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fc460647-ea95-447a-99f0-1bff8fa4be00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fc460647-ea95-447a-99f0-1bff8fa4be00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fc460647-ea95-447a-99f0-1bff8fa4be00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fc460647-ea95-447a-99f0-1bff8fa4be00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/coin98-wallet/id1561969966",
				android:
					"https://play.google.com/store/apps/details?id=coin98.crypto.finance.media&hl=vi&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isCoin98",
				},
			],
			rdns: "com.coin98",
			mobile: {
				native: "coin98://",
				universal: "https://coin98.com/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Coin98",
				colors: {
					primary: "#CDA349",
					secondary: "#202020",
				},
			},
			updatedAt: "2023-05-29T14:23:38.681225+00:00",
		},
		b956da9052132e3dabdcd78feb596d5194c99b7345d8c4bd7a47cabdcb69a25f: {
			id: "b956da9052132e3dabdcd78feb596d5194c99b7345d8c4bd7a47cabdcb69a25f",
			name: "ABC Wallet",
			slug: "abc-wallet",
			description:
				"Secure your crypto with ABC Wallet! MPC tech, recoverable keys. Manage ETH, Klaytn, Polygon, BSC in one place.",
			homepage: "https://myabcwallet.io/",
			chains: [
				"eip155:1",
				"eip155:1001",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:8217",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f9854c79-14ba-4987-42e1-4a82abbf5700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f9854c79-14ba-4987-42e1-4a82abbf5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f9854c79-14ba-4987-42e1-4a82abbf5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f9854c79-14ba-4987-42e1-4a82abbf5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/abc-wallet-safe-web3-wallet/id1642837445",
				android:
					"https://play.google.com/store/apps/details?id=io.myabcwallet.mpc&hl=en&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "abc-wallet://abcwc",
				universal:
					"https://abcwalletconnect.page.link/?apn=io.myabcwallet.mpc&ibi=io.myabcwallet.mpc&isi=1642837445&efr=1&ofl=https://myabcwallet.io/download&link=https://myabcwallet.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ABC Wallet",
				colors: {
					primary: "#04B4BF",
					secondary: "#2761D8",
				},
			},
			updatedAt: "2023-05-30T15:42:22.750716+00:00",
		},
		"8059e5b1f76701e121e258cf86eec9bbace9428eabec5bde8efec565c63fba90": {
			id: "8059e5b1f76701e121e258cf86eec9bbace9428eabec5bde8efec565c63fba90",
			name: "Ottr Finance",
			slug: "ottr-finance",
			description: "The Wallet for Everyone",
			homepage: "https://ottr.finance",
			chains: ["solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7025146c-c341-473f-a79c-62ec48eef800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7025146c-c341-473f-a79c-62ec48eef800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7025146c-c341-473f-a79c-62ec48eef800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7025146c-c341-473f-a79c-62ec48eef800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/id1628669270",
				android:
					"https://play.google.com/store/apps/details?id=finance.ottr.android",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://links.ottr.finance",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ottr",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-04-28T12:25:02.019023+00:00",
		},
		"0e4915107da5b3408b38e248f7a710f4529d54cd30e9d12ff0eb886d45c18e92": {
			id: "0e4915107da5b3408b38e248f7a710f4529d54cd30e9d12ff0eb886d45c18e92",
			name: "Arculus Wallet",
			slug: "arculus-wallet",
			description: "Cold Storage Crypto Wallet",
			homepage: "https://www.arculus.co",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f78dab27-7165-4a3d-fdb1-fcff06c0a700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f78dab27-7165-4a3d-fdb1-fcff06c0a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f78dab27-7165-4a3d-fdb1-fcff06c0a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f78dab27-7165-4a3d-fdb1-fcff06c0a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/arculus-wallet/id1575425801",
				android:
					"https://play.google.com/store/apps/details?id=co.arculus.wallet.android&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "arculuswc://",
				universal: "https://gw.arculus.co/app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Arculus Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-16T18:55:59.507402+00:00",
		},
		c87c562ce7f3a3ff9f4eed5f5a0edbcbd812db5aed4d14c7e6c044d8b6795d84: {
			id: "c87c562ce7f3a3ff9f4eed5f5a0edbcbd812db5aed4d14c7e6c044d8b6795d84",
			name: "Opera Crypto Browser",
			slug: "opera-crypto-browser",
			description:
				"The Opera Crypto Browser offers a secure and optimized Web3 browser to revolutionize internet experiences for the blockchain community.",
			homepage: "http://opera.com",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:3",
				"eip155:4",
				"eip155:42",
				"eip155:56",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "877fa1a4-304d-4d45-ca8e-f76d1a556f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/877fa1a4-304d-4d45-ca8e-f76d1a556f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/877fa1a4-304d-4d45-ca8e-f76d1a556f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/877fa1a4-304d-4d45-ca8e-f76d1a556f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/itunes-u/id1604311726?action=write-review",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://www.opera.com/crypto/next",
				firefox: "https://www.opera.com/crypto/next",
				safari: "https://www.opera.com/crypto/next",
				edge: "https://www.opera.com/crypto/next",
				opera: "https://www.opera.com/crypto/next",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isOpera",
				},
			],
			rdns: null,
			mobile: {
				native: "cryptobrowser://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Opera Crypto Browser",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-04-25T11:00:32.704145+00:00",
		},
		ff97af0ad5fca162553ebbd76f2564b7f7b04569c131e972b75bbff2dc13c1a9: {
			id: "ff97af0ad5fca162553ebbd76f2564b7f7b04569c131e972b75bbff2dc13c1a9",
			name: "Cobalt Wallet",
			slug: "cobalt-wallet",
			description: "Horizen's EVM wallet",
			homepage: "https://www.horizen.io",
			chains: ["eip155:3", "eip155:4", "eip155:5"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "29d914e5-9daa-4342-33cd-169155c5a600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/29d914e5-9daa-4342-33cd-169155c5a600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/29d914e5-9daa-4342-33cd-169155c5a600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/29d914e5-9daa-4342-33cd-169155c5a600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/cobalt/hekbjgfncacdinlajhgiakpaieajpfph/related",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/cobalt/hekbjgfncacdinlajhgiakpaieajpfph/related",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "horizen",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cobalt",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-30T19:57:37.210552+00:00",
		},
		"70d09ca3f616bcb1488542830055ec82d270ce17986a97b1f72f15a6af9f0b3f": {
			id: "70d09ca3f616bcb1488542830055ec82d270ce17986a97b1f72f15a6af9f0b3f",
			name: "Chain",
			slug: "chain",
			description: "Buy Bitcoin, NFTs & Crypto",
			homepage: "https://chain.com",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f9f3d8da-e791-47d2-98c2-031712617e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f9f3d8da-e791-47d2-98c2-031712617e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f9f3d8da-e791-47d2-98c2-031712617e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f9f3d8da-e791-47d2-98c2-031712617e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id6444779277",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "chainapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Chain",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-01-20T12:59:08.183407+00:00",
		},
		"1b63efdee42329f05599ace3205efe387a4a92a331646c86a9193743d22509d7": {
			id: "1b63efdee42329f05599ace3205efe387a4a92a331646c86a9193743d22509d7",
			name: "Huddln",
			slug: "huddln",
			description: "Web3's social gateway",
			homepage: "https://www.huddln.io",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7ba1571c-10c4-4284-b438-04dac27cb700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7ba1571c-10c4-4284-b438-04dac27cb700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7ba1571c-10c4-4284-b438-04dac27cb700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7ba1571c-10c4-4284-b438-04dac27cb700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/huddln-nft-social-network/id1503825604",
				android:
					"https://play.google.com/store/apps/details?id=com.huddln&hl=en_US&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "huddln://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Huddln",
				colors: {
					primary: "#C6C7FF",
					secondary: "#F9ABE7",
				},
			},
			updatedAt: "2023-05-02T14:18:36.598621+00:00",
		},
		"0563e0724f434298dda37acaa704857ab293b48f1b39b765569a0072de43c0cf": {
			id: "0563e0724f434298dda37acaa704857ab293b48f1b39b765569a0072de43c0cf",
			name: "Verso",
			slug: "verso",
			description: "The easiest crypto wallet",
			homepage: "https://get-verso.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "109d7c90-86ed-4ee0-e17d-3c87624ddf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/109d7c90-86ed-4ee0-e17d-3c87624ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/109d7c90-86ed-4ee0-e17d-3c87624ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/109d7c90-86ed-4ee0-e17d-3c87624ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://get-verso.com",
				ios: "https://apps.apple.com/app/btu-protocol/id1539304605",
				android:
					"https://play.google.com/store/apps/details?id=com.btuprotocol.btu_wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "verso://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Verso",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-05-20T14:15:17.094308+00:00",
		},
		"13e0cd9c08c3b5788030abce5337e2acaaba259bc93f279332202d4078be8f58": {
			id: "13e0cd9c08c3b5788030abce5337e2acaaba259bc93f279332202d4078be8f58",
			name: "Jade Wallet",
			slug: "jade-wallet",
			description: "Eliminate Single Point of Failures with MPC.",
			homepage: "https://www.jadewallet.io/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "280cd57b-24f4-4700-8d53-94fe292fab00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/280cd57b-24f4-4700-8d53-94fe292fab00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/280cd57b-24f4-4700-8d53-94fe292fab00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/280cd57b-24f4-4700-8d53-94fe292fab00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/jade-wallet-bitcoin-defi/id1544207492",
				android: null,
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Jade Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-29T14:35:47.116815+00:00",
		},
		"719bd888109f5e8dd23419b20e749900ce4d2fc6858cf588395f19c82fd036b3": {
			id: "719bd888109f5e8dd23419b20e749900ce4d2fc6858cf588395f19c82fd036b3",
			name: "HaHa",
			slug: "haha",
			description: "Wallet and Portfolio Tracker",
			homepage: "https://www.haha.me",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "79285c9f-2630-451e-0680-c71b42fb7400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/79285c9f-2630-451e-0680-c71b42fb7400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/79285c9f-2630-451e-0680-c71b42fb7400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/79285c9f-2630-451e-0680-c71b42fb7400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.haha.me",
				ios: "https://apps.apple.com/us/app/haha-crypto-portfolio-tracker/id1591158244",
				android:
					"https://play.google.com/store/apps/details?id=com.permutize.haha",
				mac: "https://apps.apple.com/us/app/haha-crypto-portfolio-tracker/id1591158244",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "haha://",
				universal: "https://haha.me",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "HaHa",
				colors: {
					primary: "#6B46D2",
					secondary: "#007BFF",
				},
			},
			updatedAt: "2023-05-31T08:16:22.473931+00:00",
		},
		c5bba8af012b2139c406cc667a7b67a1503d984aeb0cdd2ef02e667c4abba6fe: {
			id: "c5bba8af012b2139c406cc667a7b67a1503d984aeb0cdd2ef02e667c4abba6fe",
			name: "Modular Wallet Prod",
			slug: "modular-wallet-prod",
			description: "The first modular wallet.",
			homepage: "https://modular.pk",
			chains: ["eip155:42161"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "70485da2-2568-463d-722c-25082997cc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/70485da2-2568-463d-722c-25082997cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/70485da2-2568-463d-722c-25082997cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/70485da2-2568-463d-722c-25082997cc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://modular.pk",
				ios: "https://testflight.apple.com/join/Zbf6wZaP",
				android: "https://play.google.com/store/apps/details?id=com.modular",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "modularwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Modular",
				colors: {
					primary: "#17171A",
					secondary: null,
				},
			},
			updatedAt: "2023-06-01T09:01:43.710038+00:00",
		},
		"6b2e623f231f3794db2fcb7cfff2d1cc1d902bff70d946980d62956cd880cacc": {
			id: "6b2e623f231f3794db2fcb7cfff2d1cc1d902bff70d946980d62956cd880cacc",
			name: "Kelp",
			slug: "kelp",
			description:
				"A non-custodial cryptocurrency wallet to use during the Kelp multi-phase launch.",
			homepage: "https://kelp.finance",
			chains: ["eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "02d9143d-deed-4336-0cae-f4b8b1091f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/02d9143d-deed-4336-0cae-f4b8b1091f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/02d9143d-deed-4336-0cae-f4b8b1091f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/02d9143d-deed-4336-0cae-f4b8b1091f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/kelp/id1632857274",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "link.kelp.finance://walletconnect",
				universal: "https://link.kelp.finance/walletconnect",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kelp",
				colors: {
					primary: "#1CC46C",
					secondary: null,
				},
			},
			updatedAt: "2023-06-06T09:52:39.701608+00:00",
		},
		"3c5602ac8e040c6dfe26aad1b183a848f86486094d18c8a5e19993d9c87ca52f": {
			id: "3c5602ac8e040c6dfe26aad1b183a848f86486094d18c8a5e19993d9c87ca52f",
			name: "Numio",
			slug: "numio",
			description:
				"Send Crypto Instantly and Save up to 100x on Ethereum Fees Cheap. Fast. Secure. Download for free and start saving money today.",
			homepage: "https://numio.one",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "416ee463-6699-43f7-c0e3-396f0ad3d300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/416ee463-6699-43f7-c0e3-396f0ad3d300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/416ee463-6699-43f7-c0e3-396f0ad3d300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/416ee463-6699-43f7-c0e3-396f0ad3d300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://numio.one",
				ios: "https://apps.apple.com/us/app/numio-ethereum-wallet-defi/id1538072952",
				android: "https://play.google.com/store/apps/details?id=com.numio.pay",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Numio",
				colors: {
					primary: "#222222",
					secondary: "#3ad15d",
				},
			},
			updatedAt: "2022-11-14T20:52:13.224863+00:00",
		},
		"942d0e22a7e6b520b0a03abcafc4dbe156a1fc151876e3c4a842f914277278ef": {
			id: "942d0e22a7e6b520b0a03abcafc4dbe156a1fc151876e3c4a842f914277278ef",
			name: "Cling Wallet",
			slug: "cling-wallet",
			description:
				"Cling Wallet is a safe digital wallet that enables users to handle custom tokens and NFTs on various blockchain networks.",
			homepage: "https://clingon.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:43113",
				"eip155:43114",
				"eip155:71393",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2d8006c3-852b-458a-d6b0-916c5ba76800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2d8006c3-852b-458a-d6b0-916c5ba76800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2d8006c3-852b-458a-d6b0-916c5ba76800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2d8006c3-852b-458a-d6b0-916c5ba76800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/cling-wallet/kppgpfphbmbcgeglphjnhnhibonmebkn?hl=ko",
				ios: "https://apps.apple.com/us/app/cling-wallet/id6443952504",
				android:
					"https://play.google.com/store/apps/details?id=com.carrieverse.cling.wallet&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "cling://",
				universal: "https://cling.carrieverse.com/apple-app-site-association",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cling Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-02T14:07:17.507034+00:00",
		},
		"8ff6eccefefa7506339201bc33346f92a43118d6ff7d6e71d499d8187a1c56a2": {
			id: "8ff6eccefefa7506339201bc33346f92a43118d6ff7d6e71d499d8187a1c56a2",
			name: "Broearn Wallet",
			slug: "broearn",
			description:
				"Broearn Wallet supports over 8 Million tokens including PUT, Ethereum, Solana, Polygon Matic, BNB, and Avalanche.",
			homepage: "https://www.broearn.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:128",
				"eip155:137",
				"eip155:250",
				"eip155:256",
				"eip155:4002",
				"eip155:420",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:6",
				"eip155:61",
				"eip155:65",
				"eip155:66",
				"eip155:80001",
				"eip155:97",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b3c2c77c-a8cf-46e1-095a-77f0a3891500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b3c2c77c-a8cf-46e1-095a-77f0a3891500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b3c2c77c-a8cf-46e1-095a-77f0a3891500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b3c2c77c-a8cf-46e1-095a-77f0a3891500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.broearn.com",
				ios: "https://apps.apple.com/us/app/broearn/id6444156587",
				android:
					"https://play.google.com/store/apps/details?id=com.broearn.browser",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "broearn://wallet/",
				universal: "https://www.broearn.com/link/wallet",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Broearn",
				colors: {
					primary: "#6322F2",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2023-05-31T13:26:34.89853+00:00",
		},
		"15d7610042217f691385d20e640869dc7273e991b04e8f476417cdc5ec856955": {
			id: "15d7610042217f691385d20e640869dc7273e991b04e8f476417cdc5ec856955",
			name: "Coinomi",
			slug: "coinomi",
			description:
				"The blockchain wallet trusted by millions. Securely store, manage, and exchange Bitcoin, Ethereum, and more than 1,770 Tokens and Altcoins.",
			homepage: "https://www.coinomi.com/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "3b446d16-a908-40c8-5835-9a6efe90dd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3b446d16-a908-40c8-5835-9a6efe90dd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3b446d16-a908-40c8-5835-9a6efe90dd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3b446d16-a908-40c8-5835-9a6efe90dd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/coinomi-wallet/id1333588809",
				android:
					"https://play.google.com/store/apps/details?id=com.coinomi.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "coinomi://",
				universal: "https://coinomi.page.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Coinomi",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-05T13:38:35.617168+00:00",
		},
		"1896aa67ce33d5bde764369c7541a75074baa1b8da97e703c9ee3a4b61e56e65": {
			id: "1896aa67ce33d5bde764369c7541a75074baa1b8da97e703c9ee3a4b61e56e65",
			name: "Ripio Portal",
			slug: "ripio-portal-mobile-wallet",
			description:
				"Start interacting with blockchain apps in the easiest and safest way with our Web3 wallet",
			homepage: "https://ripio.com/portal",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fd56c695-ce58-4df5-1625-767571c80700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fd56c695-ce58-4df5-1625-767571c80700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fd56c695-ce58-4df5-1625-767571c80700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fd56c695-ce58-4df5-1625-767571c80700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ar/app/ripio-comprar-bitcoin-y-eth/id1221006761",
				android:
					"https://play.google.com/store/apps/details?id=com.ripio.android&hl=en&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/ripio-portal/ddamhapapianibkkkcclabgicmpnpdnj",
				firefox: null,
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/ripio-portal/ddamhapapianibkkkcclabgicmpnpdnj",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isPortal",
				},
			],
			rdns: null,
			mobile: {
				native: "ripio://portal",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ripio Wallet",
				colors: {
					primary: "#64FFB5",
					secondary: "#26263F",
				},
			},
			updatedAt: "2023-05-31T18:02:41.919908+00:00",
		},
		"6577b7c91453a7047f1c31c5897bd59087a8cca35181e069656079255542abb4": {
			id: "6577b7c91453a7047f1c31c5897bd59087a8cca35181e069656079255542abb4",
			name: "Sabay Wallet App",
			slug: "sabay-wallet-app",
			description: "Blochain Wallet to connect with MySabay",
			homepage: "https://wallet.sabay.com",
			chains: ["eip155:1", "eip155:56"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c4df7014-abaf-4016-8180-fb994804b400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c4df7014-abaf-4016-8180-fb994804b400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c4df7014-abaf-4016-8180-fb994804b400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c4df7014-abaf-4016-8180-fb994804b400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kh/app/sabay-wallet/id6449341309",
				android:
					"https://play.google.com/store/apps/details?id=kh.com.sabay.sabaywallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "myApp://kh.com.sabay.sabaywallet",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MySabay",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T10:12:31.272456+00:00",
		},
		dacf9f750e579e7aa93a752117fb3870a5bfc2e967441b477cef5e6f1458e33d: {
			id: "dacf9f750e579e7aa93a752117fb3870a5bfc2e967441b477cef5e6f1458e33d",
			name: "Tokoin | My-T Wallet",
			slug: "tokoin-my-t-wallet",
			description:
				"It is the first official digital wallet from Tokoin where you can store and transfer your TOKO token.",
			homepage: "https://tokoin.io",
			chains: ["eip155:1", "eip155:5", "eip155:56", "eip155:97"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "88a2518c-16c2-4ee3-4699-1a1c6903bc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/88a2518c-16c2-4ee3-4699-1a1c6903bc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/88a2518c-16c2-4ee3-4699-1a1c6903bc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/88a2518c-16c2-4ee3-4699-1a1c6903bc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/my/app/tokow/id1489276175",
				android:
					"https://play.google.com/store/apps/details?id=com.tokoin.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "mtwallet://app",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "My-T Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T10:38:30.263009+00:00",
		},
		b0cc0007eefed734c0f0c58d3a1bdf52969fb978d32da96928aa3209c18e3c54: {
			id: "b0cc0007eefed734c0f0c58d3a1bdf52969fb978d32da96928aa3209c18e3c54",
			name: "Impersonator",
			slug: "impersonator",
			description: "Log-in to dapps as ANY Ethereum Address in view-only mode",
			homepage: "https://www.impersonator.xyz/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:200",
				"eip155:250",
				"eip155:4002",
				"eip155:420",
				"eip155:42161",
				"eip155:421611",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:69",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "b072a0c6-1bc2-4a80-6f05-50a4ebbf0700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b072a0c6-1bc2-4a80-6f05-50a4ebbf0700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b072a0c6-1bc2-4a80-6f05-50a4ebbf0700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b072a0c6-1bc2-4a80-6f05-50a4ebbf0700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/impersonator/hgihfkmoibhccfdohjdbklmmcknjjmgl",
				firefox: null,
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/impersonator/hgihfkmoibhccfdohjdbklmmcknjjmgl",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isImpersonator",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Impersonator",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T19:49:41.197109+00:00",
		},
		"105bc5cd0cf9126c1050e6f88fbdcc3e6b47bbfe4ff08b79ed189198374008c9": {
			id: "105bc5cd0cf9126c1050e6f88fbdcc3e6b47bbfe4ff08b79ed189198374008c9",
			name: "Fncy Mobile Wallet",
			slug: "fncy-mobile-wallet",
			description: "Fncy Mobile Wallet",
			homepage: "https://fncy.world",
			chains: ["eip155:1", "eip155:5", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c1c8d374-dff3-419c-96af-3515d0192100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c1c8d374-dff3-419c-96af-3515d0192100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c1c8d374-dff3-419c-96af-3515d0192100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c1c8d374-dff3-419c-96af-3515d0192100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/fncy-blockchain-platform/id1613707166",
				android:
					"https://play.google.com/store/apps/details?id=com.metaverse.world.cube&hl=en_US&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "metaCubeWallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Fncy Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-19T10:04:42.113577+00:00",
		},
		"07f99a5d9849bb049d74830012b286f8b238e72b0337933ef22b84947409db80": {
			id: "07f99a5d9849bb049d74830012b286f8b238e72b0337933ef22b84947409db80",
			name: "Copiosa",
			slug: "copiosa",
			description:
				"The Copiosa Wallet is more than just a digital wallet, it's an innovative tool that makes managing cryptocurrencies easy and secure",
			homepage: "https://copiosa.io",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "cae1be94-9f53-4eba-b915-f6e381d5a500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cae1be94-9f53-4eba-b915-f6e381d5a500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cae1be94-9f53-4eba-b915-f6e381d5a500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cae1be94-9f53-4eba-b915-f6e381d5a500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/gb/app/copiosa-crypto-wallet/id6443951470",
				android:
					"https://play.google.com/store/apps/details?id=io.copiosa.exchange",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "copiosa://",
				universal: "https://copiosa.io/action/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Copiosa",
				colors: {
					primary: "#4564FD",
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T09:05:27.214168+00:00",
		},
		b6329af78b11719de52ca0426fb50d64b9b965335fc53dafed994ec22680614e: {
			id: "b6329af78b11719de52ca0426fb50d64b9b965335fc53dafed994ec22680614e",
			name: "Imota ",
			slug: "imota",
			description:
				"Imota is a non-custodial, multi-chain wallet with the most cutting-edge technologies, aiming to accelerate Blockchain mass adoption",
			homepage: "https://imota.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "c81f5bbf-ce66-42bd-3436-f1baaaa18b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c81f5bbf-ce66-42bd-3436-f1baaaa18b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c81f5bbf-ce66-42bd-3436-f1baaaa18b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c81f5bbf-ce66-42bd-3436-f1baaaa18b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/imota/id6444327204",
				android:
					"https://play.google.com/store/apps/details?id=com.nft5.imota&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Imota",
				colors: {
					primary: "#68DEBB",
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T08:53:33.439933+00:00",
		},
		b7cd38c9393f14b8031bc10bc0613895d0d092c33d836547faf8a9b782f6cbcc: {
			id: "b7cd38c9393f14b8031bc10bc0613895d0d092c33d836547faf8a9b782f6cbcc",
			name: "Libera",
			slug: "libera",
			description:
				"Libera is the key to empowering financially unserved and underserved people around the world.",
			homepage: "https://liberawallet.com",
			chains: ["eip155:42220"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "9485d17f-c413-47fe-ebee-a876a9dc9100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9485d17f-c413-47fe-ebee-a876a9dc9100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9485d17f-c413-47fe-ebee-a876a9dc9100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9485d17f-c413-47fe-ebee-a876a9dc9100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.impactmarket.mobile&&pli=1",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "libera://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Libera",
				colors: {
					primary: "#2362FB",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-06-15T10:41:00.781008+00:00",
		},
		c733d32f3b974c4a96e0cd5a3b6f7e186e2f6379182ac6640fdbab4f9ef489b0: {
			id: "c733d32f3b974c4a96e0cd5a3b6f7e186e2f6379182ac6640fdbab4f9ef489b0",
			name: "Certhis",
			slug: "certhis",
			description: "The Ultimate Web3 Solution",
			homepage: "https://certhis.io",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "fbd441cc-e861-46dc-48ae-a04228ddb500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fbd441cc-e861-46dc-48ae-a04228ddb500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fbd441cc-e861-46dc-48ae-a04228ddb500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fbd441cc-e861-46dc-48ae-a04228ddb500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://explorer.certhis.io",
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: "https://certhis.io/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://certhis.io/",
			},
			desktop: {
				native: "",
				universal: "https://certhis.io",
			},
			metadata: {
				shortName: "Certhis",
				colors: {
					primary: "#000000",
					secondary: "#72F6FE",
				},
			},
			updatedAt: "2023-06-19T10:20:59.004294+00:00",
		},
		"8821748c25de9dbc4f72a691b25a6ddad9d7df12fa23333fd9c8b5fdc14cc819": {
			id: "8821748c25de9dbc4f72a691b25a6ddad9d7df12fa23333fd9c8b5fdc14cc819",
			name: "Burrito Wallet",
			slug: "burrito-wallet",
			description: "Let's wrap it up with Burrito Wallet!",
			homepage: "https://burritowallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:2020",
				"eip155:324",
				"eip155:42161",
				"eip155:56",
				"eip155:7518",
				"eip155:8217",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7eec7187-3f48-4fda-53bb-b0ad55749a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7eec7187-3f48-4fda-53bb-b0ad55749a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7eec7187-3f48-4fda-53bb-b0ad55749a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7eec7187-3f48-4fda-53bb-b0ad55749a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/burrito-wallet/id6449563083",
				android:
					"https://play.google.com/store/apps/details?id=com.burritowallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "burrito://",
				universal: "https://app.burritowallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Burrito Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-15T08:56:58.266204+00:00",
		},
		"7de190d03faf1f15027a834801f045bc66640045b0d5a0daa4686d7fa89fab74": {
			id: "7de190d03faf1f15027a834801f045bc66640045b0d5a0daa4686d7fa89fab74",
			name: "Ancrypto",
			slug: "ancrypto",
			description: "Your gateway to web3",
			homepage: "https://www.ancrypto.io/",
			chains: ["eip155:1", "eip155:137", "eip155:250", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8dee1c33-b277-4a5a-5ddd-5e70fd9d1800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8dee1c33-b277-4a5a-5ddd-5e70fd9d1800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8dee1c33-b277-4a5a-5ddd-5e70fd9d1800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8dee1c33-b277-4a5a-5ddd-5e70fd9d1800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/in/app/ancrypto/id1660898349",
				android:
					"https://play.google.com/store/apps/details?id=com.ancryptoWallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ancrypto://app",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "AnCrypto",
				colors: {
					primary: "#E4991B",
					secondary: "#181920",
				},
			},
			updatedAt: "2023-06-15T10:41:24.16349+00:00",
		},
		"058e750fda11f3a5a46b3ae90cd413fc2a4e5b8679a3c01e9a640fcc756a0167": {
			id: "058e750fda11f3a5a46b3ae90cd413fc2a4e5b8679a3c01e9a640fcc756a0167",
			name: "Cypherock cySync",
			slug: "cypherock-cysync",
			description: "Desktop companion application for Cypherock X1",
			homepage: "https://www.cypherock.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:1666600000",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:61",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "7fd5a23a-3a01-4cfb-3c8b-9f43ae414400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7fd5a23a-3a01-4cfb-3c8b-9f43ae414400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7fd5a23a-3a01-4cfb-3c8b-9f43ae414400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7fd5a23a-3a01-4cfb-3c8b-9f43ae414400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.cypherock.com/get-started",
				ios: null,
				android: null,
				mac: null,
				windows: "https://www.cypherock.com/get-started",
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "cypherock://",
				universal: null,
			},
			metadata: {
				shortName: "Cypherock",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-19T11:53:06.388125+00:00",
		},
		"835dc63f69f65113220e700112363fef2a5f1d72d6c0eef4f2c7dc66bf64b955": {
			id: "835dc63f69f65113220e700112363fef2a5f1d72d6c0eef4f2c7dc66bf64b955",
			name: "CVL Wallet",
			slug: "cvl-wallet",
			description:
				"CVL Wallet is the easiest way to store, send, receive and exchange crypto, fiat currencies, metals and stocks at a speed never seen before.",
			homepage: "https://cvl.network",
			chains: [
				"eip155:1",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "e4eff15a-35d5-49fe-047f-33e331f46400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e4eff15a-35d5-49fe-047f-33e331f46400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e4eff15a-35d5-49fe-047f-33e331f46400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e4eff15a-35d5-49fe-047f-33e331f46400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.cvl.network/",
				ios: "https://apps.apple.com/ru/app/cvl-wallet/id6444357628",
				android:
					"https://play.google.com/store/apps/details?id=llp.bc_group.cvl_wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.cvl.network/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "CVL Wallet",
				colors: {
					primary: "#48F2BB",
					secondary: "#202F2C",
				},
			},
			updatedAt: "2023-05-02T12:34:09.511204+00:00",
		},
		"44ca80bba6838e116e8d0a2c1a1f37041ea322379cc65a71479b6a240b6fcab2": {
			id: "44ca80bba6838e116e8d0a2c1a1f37041ea322379cc65a71479b6a240b6fcab2",
			name: "Cypher Wallet",
			slug: "cypher-wallet",
			description:
				"Non Custodial Multichain Wallet Mobile , Chrome Extension, Crypto Card, \nEVM + Cosmos Wallet /  Inter-chain Bridge, Swap. ",
			homepage: "https://cypherwallet.io",
			chains: [
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:10",
				"eip155:1101",
				"eip155:137",
				"eip155:250",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7bce0965-a4cc-4aad-6217-009d51017500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7bce0965-a4cc-4aad-6217-009d51017500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7bce0965-a4cc-4aad-6217-009d51017500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7bce0965-a4cc-4aad-6217-009d51017500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/cypherd-wallet/id1604120414",
				android:
					"https://play.google.com/store/apps/details?id=com.cypherd.androidwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/cypher-wallet/niiaamnmgebpeejeemoifgdndgeaekhe",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "cypherwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cypher Wallet",
				colors: {
					primary: "#FFDE59",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-06-15T08:59:07.955963+00:00",
		},
		af9a6dfff9e63977bbde28fb23518834f08b696fe8bff6dd6827acad1814c6be: {
			id: "af9a6dfff9e63977bbde28fb23518834f08b696fe8bff6dd6827acad1814c6be",
			name: "Status",
			slug: "status",
			description:
				"Status is a private messenger, secure crypto wallet, and Ethereum Web3 DApp browser—in short, one powerful communication tool.",
			homepage: "https://status.app/",
			chains: ["eip155:1", "eip155:100", "eip155:3", "eip155:4"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "e131fa98-8c4f-4680-f5b6-6fb77189c900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e131fa98-8c4f-4680-f5b6-6fb77189c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e131fa98-8c4f-4680-f5b6-6fb77189c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e131fa98-8c4f-4680-f5b6-6fb77189c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/status-private-communication/id1178893006",
				android:
					"https://play.google.com/store/apps/details?id=im.status.ethereum&hl=en&gl=US",
				mac: "https://status.im/get/",
				windows: "https://status.im/get/",
				linux: "https://status.im/get/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Status",
				colors: {
					primary: "#4360df",
					secondary: null,
				},
			},
			updatedAt: "2022-03-16T13:58:24.557161+00:00",
		},
		bdc9433ffdaee55d31737d83b931caa1f17e30666f5b8e03eea794bac960eb4a: {
			id: "bdc9433ffdaee55d31737d83b931caa1f17e30666f5b8e03eea794bac960eb4a",
			name: "Enjin Wallet",
			slug: "enjin-wallet",
			description:
				"Enjin Wallet is a secure non-custodial wallet to store, send, manage NFTs, FTs, and digital collectables.",
			homepage: "https://enjin.io/products/wallet",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "add9626b-a5fa-4c12-178c-e5584e6dcd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/add9626b-a5fa-4c12-178c-e5584e6dcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/add9626b-a5fa-4c12-178c-e5584e6dcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/add9626b-a5fa-4c12-178c-e5584e6dcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/enjin-nft-crypto-wallet/id1349078375",
				android:
					"https://play.google.com/store/apps/details?id=com.enjin.mobile.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "enjinwallet://",
				universal: "https://deeplink.wallet.enjin.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Enjin Wallet",
				colors: {
					primary: "#7567CE",
					secondary: null,
				},
			},
			updatedAt: "2023-05-02T13:33:10.513487+00:00",
		},
		"022e8ff84519e427bff394b3a58308bc9838196a8efb45158da0ab7c3228abfb": {
			id: "022e8ff84519e427bff394b3a58308bc9838196a8efb45158da0ab7c3228abfb",
			name: "Essentials",
			slug: "essentials",
			description: "Crypto and Decentralized Identity",
			homepage: "https://www.trinity-tech.io/essentials",
			chains: [
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:122",
				"eip155:128",
				"eip155:137",
				"eip155:170",
				"eip155:20",
				"eip155:22",
				"eip155:23",
				"eip155:25",
				"eip155:256",
				"eip155:32659",
				"eip155:338",
				"eip155:40",
				"eip155:4002",
				"eip155:41",
				"eip155:42161",
				"eip155:421611",
				"eip155:43113",
				"eip155:43114",
				"eip155:4689",
				"eip155:4690",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:9000",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "058878f4-7364-4e01-434f-2cc09a15cf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/058878f4-7364-4e01-434f-2cc09a15cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/058878f4-7364-4e01-434f-2cc09a15cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/058878f4-7364-4e01-434f-2cc09a15cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/elastos-essentials/id1568931743",
				android:
					"https://play.google.com/store/apps/details?id=org.elastos.essentials.app",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://essentials.web3essentials.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Essentials",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-02T15:15:47.926242+00:00",
		},
		"438b07441c5273c60e415efd227862d9b1344ef378d9ee7d1b3bfa8b33384eff": {
			id: "438b07441c5273c60e415efd227862d9b1344ef378d9ee7d1b3bfa8b33384eff",
			name: "Everspace",
			slug: "everspace",
			description: "Non-custodial multichain crypto wallet",
			homepage: "https://everspace.app",
			chains: ["tvm:42"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "80eaa630-6392-4b0a-a604-0a0f808e4d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/80eaa630-6392-4b0a-a604-0a0f808e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/80eaa630-6392-4b0a-a604-0a0f808e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/80eaa630-6392-4b0a-a604-0a0f808e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://everspace.app/",
				ios: "https://apps.apple.com/ru/app/everspace/id1585434994?l=en",
				android:
					"https://play.google.com/store/apps/details?id=com.oberton.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "everspace://",
				universal: "https://everspace.app/deeplink",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "everspace",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-02T15:28:50.412373+00:00",
		},
		"1aa28414c95f5024133faf5766d376bb9c853c280d158cd3e22dc2b7b0a95a2d": {
			id: "1aa28414c95f5024133faf5766d376bb9c853c280d158cd3e22dc2b7b0a95a2d",
			name: "BlockWallet",
			slug: "blockwallet",
			description:
				"BlockWallet is a self-custodial wallet making it easy to secure digital assets, protect identity, and experience Web3.",
			homepage: "https://blockwallet.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:200",
				"eip155:250",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:4",
				"eip155:4002",
				"eip155:420",
				"eip155:42161",
				"eip155:421611",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:69",
				"eip155:80001",
				"eip155:97",
			],
			versions: [],
			sdks: [],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "ef825629-9828-4a5a-b376-62ab4ee81f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ef825629-9828-4a5a-b376-62ab4ee81f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ef825629-9828-4a5a-b376-62ab4ee81f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ef825629-9828-4a5a-b376-62ab4ee81f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/blockwallet/bopcbmipnjdcdfflfgjdgdjejmgpoaab",
				firefox: null,
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/blockwallet/bopcbmipnjdcdfflfgjdgdjejmgpoaab",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBlockWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BlockWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-08T08:42:50.941943+00:00",
		},
		"01925725cdc7a5008824c8f19eff85769903fbcc53c62639feb0d4f8d3a6cf52": {
			id: "01925725cdc7a5008824c8f19eff85769903fbcc53c62639feb0d4f8d3a6cf52",
			name: "Kriptomat",
			slug: "kriptomat",
			description: "The simplest way to use Web3 apps.",
			homepage: "https://kriptomat.io/web3/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "774110aa-70f6-4d0c-210f-ab434838fa00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/774110aa-70f6-4d0c-210f-ab434838fa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/774110aa-70f6-4d0c-210f-ab434838fa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/774110aa-70f6-4d0c-210f-ab434838fa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1440135740",
				android:
					"https://play.google.com/store/apps/details?id=io.kriptomat.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kriptomatapp://wallet-connect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kriptomat",
				colors: {
					primary: "#6E52FF",
					secondary: "#36FF68",
				},
			},
			updatedAt: "2023-05-03T13:28:08.272759+00:00",
		},
		"159b0423ce9075d5662f588f805931d989627affab3e63e4dd7ebc62b9c6738c": {
			id: "159b0423ce9075d5662f588f805931d989627affab3e63e4dd7ebc62b9c6738c",
			name: "Oxalus Wallet",
			slug: "oxalus-wallet",
			description: "The true wallet for people",
			homepage: "https://oxalus.io/wallet",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42",
				"eip155:43113",
				"eip155:43114",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a6e22fcb-6b69-45d2-b52d-a4a347a21e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a6e22fcb-6b69-45d2-b52d-a4a347a21e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a6e22fcb-6b69-45d2-b52d-a4a347a21e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a6e22fcb-6b69-45d2-b52d-a4a347a21e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://oxalus.io/",
				ios: "https://apps.apple.com/vn/app/oxalus-wallet/id1620111723",
				android:
					"https://play.google.com/store/apps/details?id=io.oxalus.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "oxalus://",
				universal:
					"https://oxalus.page.link/?apn=io.oxalus.wallet&isi=1620111723&ibi=io.oxalus.wallet&link=https://deeplink.oxalus.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Oxalus",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-07T08:34:54.708843+00:00",
		},
		"43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652": {
			id: "43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652",
			name: "Theta Wallet",
			slug: "theta-wallet",
			description: "Official Theta Wallet",
			homepage: "https://www.thetatoken.org/wallet",
			chains: ["eip155:361", "eip155:363", "eip155:364", "eip155:365"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d4afb810-5925-4f00-4ebb-d180fcf29000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d4afb810-5925-4f00-4ebb-d180fcf29000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d4afb810-5925-4f00-4ebb-d180fcf29000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d4afb810-5925-4f00-4ebb-d180fcf29000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.thetatoken.org",
				ios: "https://apps.apple.com/app/theta-wallet/id1451094550",
				android:
					"https://play.google.com/store/apps/details?id=org.theta.wallet&pli=1",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Theta Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-10T22:28:44.321258+00:00",
		},
		"647ced4fad747a3a613abfe160fed7deb4e85d8623ac9329e94b24dd0d86bf00": {
			id: "647ced4fad747a3a613abfe160fed7deb4e85d8623ac9329e94b24dd0d86bf00",
			name: "Dawn Wallet",
			slug: "dawn-wallet",
			description:
				"A gateway to DeFi, culture and governance on the layers of Ethereum.",
			homepage: "https://www.dawnwallet.xyz",
			chains: ["eip155:1", "eip155:10", "eip155:137", "eip155:42161"],
			versions: [],
			sdks: [],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "dcb4a287-a6f5-4e81-cbab-2d0eb27b2f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/dcb4a287-a6f5-4e81-cbab-2d0eb27b2f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/dcb4a287-a6f5-4e81-cbab-2d0eb27b2f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/dcb4a287-a6f5-4e81-cbab-2d0eb27b2f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/dawn-ethereum-wallet/id1673143782",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari:
					"https://apps.apple.com/us/app/dawn-ethereum-wallet/id1673143782",
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isDawn",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Dawn",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-12T14:37:01.727758+00:00",
		},
		"18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1": {
			id: "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1",
			name: "Rabby",
			slug: "rabby",
			description: "The game-changing wallet for Ethereum and all EVM chains",
			homepage: "https://rabby.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:250",
				"eip155:30",
				"eip155:40",
				"eip155:42161",
				"eip155:42220",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "255e6ba2-8dfd-43ad-e88e-57cbb98f6800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/255e6ba2-8dfd-43ad-e88e-57cbb98f6800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/255e6ba2-8dfd-43ad-e88e-57cbb98f6800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/255e6ba2-8dfd-43ad-e88e-57cbb98f6800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/rabby/acmacodkjbdgmoleebolmdjonilkdbch",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/rabby-wallet/acmacodkjbdgmoleebolmdjonilkdbch",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isRabby",
				},
			],
			rdns: "io.rabby",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Rabby",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T07:51:55.444076+00:00",
		},
		"3ed8cc046c6211a798dc5ec70f1302b43e07db9639fd287de44a9aa115a21ed6": {
			id: "3ed8cc046c6211a798dc5ec70f1302b43e07db9639fd287de44a9aa115a21ed6",
			name: "Leap Cosmos Wallet",
			slug: "leap-cosmos-wallet",
			description: "A crypto super wallet for Cosmos blockchains",
			homepage: "https://leapwallet.io/",
			chains: ["cosmos:cosmoshub-4", "cosmos:irishub-1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "73e6b2b2-8c02-42e9-84f5-82a859978200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/73e6b2b2-8c02-42e9-84f5-82a859978200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/73e6b2b2-8c02-42e9-84f5-82a859978200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/73e6b2b2-8c02-42e9-84f5-82a859978200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/in/app/leap-cosmos/id1642465549/",
				android:
					"https://play.google.com/store/apps/details?id=io.leapwallet.cosmos",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "leapcosmos://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Leap Cosmos",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-19T13:50:51.812588+00:00",
		},
		"226d8a12a2e6e5c4185fa9c24313824bfb144c2a180325bddbd121844f497afa": {
			id: "226d8a12a2e6e5c4185fa9c24313824bfb144c2a180325bddbd121844f497afa",
			name: "ISLAMIwallet",
			slug: "islamiwallet",
			description: "Swap, Vote & recovery system",
			homepage: "https://islamicoin.finance/islamiwallet",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8d723c78-28ad-4610-901f-ea391d7e8d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8d723c78-28ad-4610-901f-ea391d7e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8d723c78-28ad-4610-901f-ea391d7e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8d723c78-28ad-4610-901f-ea391d7e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://islamiwallet.com",
				ios: "https://apps.apple.com/lb/app/islamiwallet/id1631212925",
				android:
					"https://play.google.com/store/apps/details?id=com.islami.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "islamiwallet://islami.com/path/",
				universal: "https://islamicoin.finance/.well-known/assetlinks.json",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ISLAMI",
				colors: {
					primary: "#24262E",
					secondary: "#00D2D4",
				},
			},
			updatedAt: "2022-11-16T14:08:00.179018+00:00",
		},
		"65fb5ef9b1fd74d001027a10ede38de96a1704a0ec82994bb47f995d10d6df85": {
			id: "65fb5ef9b1fd74d001027a10ede38de96a1704a0ec82994bb47f995d10d6df85",
			name: "UPBOND Wallet",
			slug: "upbond-wallet",
			description:
				"Keep track, manage, and transfer your unique assets including Tokens and NFT on UPBOND Wallet, utilizing Social Login",
			homepage: "https://www.upbond.io/",
			chains: ["eip155:1", "eip155:137", "eip155:420", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "698e08f3-b452-4c91-9f65-299939396a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/698e08f3-b452-4c91-9f65-299939396a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/698e08f3-b452-4c91-9f65-299939396a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/698e08f3-b452-4c91-9f65-299939396a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.upbond.io/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://wallet.upbond.io/wallet/home",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "UPBOND Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-01-31T08:35:49.864265+00:00",
		},
		f6beeb5941e6853084ca2177339120e1c55a28a19ec4e504553cf402ed65c815: {
			id: "f6beeb5941e6853084ca2177339120e1c55a28a19ec4e504553cf402ed65c815",
			name: "VIVE Wallet",
			slug: "vive-wallet",
			description:
				"Your seamless gateway to the Web3 world. Manage your crypto assets and claim your identity in VIVERSE.",
			homepage: "https://www.viverse.com/apps/wallet",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5ef7e40e-1f02-4da2-54bf-992e3e83e100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5ef7e40e-1f02-4da2-54bf-992e3e83e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5ef7e40e-1f02-4da2-54bf-992e3e83e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5ef7e40e-1f02-4da2-54bf-992e3e83e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/vive-wallet/id6444718696",
				android:
					"https://play.google.com/store/apps/details?id=com.htc.vivewallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "VIVE Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-03-24T15:08:24.965055+00:00",
		},
		b2ce31fb31735fa886270806340de999f72342a7c29484badd8d4d013d77c8b8: {
			id: "b2ce31fb31735fa886270806340de999f72342a7c29484badd8d4d013d77c8b8",
			name: "Wirex Wallet",
			slug: "wirex-wallet",
			description:
				"Wirex Wallet is a super-secure, non-custodial way to send, store and receive digital assets. Biometric backup, multi-blockchain capability",
			homepage: "https://wwallet.app.link/W1YKPgySZsb",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "769739aa-ff45-4db5-c6e6-70590741ec00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/769739aa-ff45-4db5-c6e6-70590741ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/769739aa-ff45-4db5-c6e6-70590741ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/769739aa-ff45-4db5-c6e6-70590741ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wirexapp.com/wirex-wallet",
				ios: "https://apps.apple.com/app/wirex-wallet-crypto-and-defi/id1594165139",
				android:
					"https://play.google.com/store/apps/details?id=com.wirex.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wirexwallet://",
				universal:
					"https://wwallet.app.link/wc?uri=wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1?bridge=https%3A%2F%2Fbridge.walletconnect.org&key=91303dedf64285cbbaf9120f6e9d160a5c8aa3deb67017a3874cd272323f48ae",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Wirex Wallet",
				colors: {
					primary: "#C9FFC6",
					secondary: "#0F110F",
				},
			},
			updatedAt: "2022-11-14T20:32:09.180875+00:00",
		},
		"08739356e3fc0efd9498696b7831e8b42b0ad7390af663cd3ba3c30866195b34": {
			id: "08739356e3fc0efd9498696b7831e8b42b0ad7390af663cd3ba3c30866195b34",
			name: "BCERTin wallet",
			slug: "bcertin-wallet",
			description:
				"The BCERTin wallet comes with a set of amazing tools to manage your business & life with unlimited cloud access",
			homepage: "https://blockcerts.com",
			chains: ["eip155:1", "eip155:137", "eip155:3", "eip155:4", "eip155:5"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "e321346d-5ce7-4e75-371e-e4f0bf923900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e321346d-5ce7-4e75-371e-e4f0bf923900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e321346d-5ce7-4e75-371e-e4f0bf923900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e321346d-5ce7-4e75-371e-e4f0bf923900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.blockcerts.BlockCerts&hl=en_US&gl",
				mac: "https://blockcerts.com/download-mac/?type=mac",
				windows: "https://blockcerts.com/download-windows/?type=windows",
				linux: "https://blockcerts.com/download-debian/?type=debian",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BCERTin wallet",
				colors: {
					primary: "#76b53b",
					secondary: "#495849",
				},
			},
			updatedAt: "2022-02-02T08:24:27.934939+00:00",
		},
		a18aeec9fab0c08ca41e7bdaae06cac5700bb628ec75c6381bacd9b2df574895: {
			id: "a18aeec9fab0c08ca41e7bdaae06cac5700bb628ec75c6381bacd9b2df574895",
			name: "Monarch Wallet",
			slug: "monarch-wallet",
			description:
				"Secure, Decentralized, DAPP Gateway, *NFTs, Buy, Sell, Earn, Swap & *Recurring & Custom Crypto Payments — Over 1 Million Wallets Generated",
			homepage: "https://monarchwallet.com",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c664d955-8a1e-4460-3917-4cfcf198f000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c664d955-8a1e-4460-3917-4cfcf198f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c664d955-8a1e-4460-3917-4cfcf198f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c664d955-8a1e-4460-3917-4cfcf198f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/monarch-wallet/id1386397997",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://monarchwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Monarch",
				colors: {
					primary: "#F9A900",
					secondary: "#282629",
				},
			},
			updatedAt: "2022-03-10T15:47:26.509766+00:00",
		},
		f2dca938b70ea7965ffbc3ef49f3e21701d1fc4f1c543d4b05801c126416466b: {
			id: "f2dca938b70ea7965ffbc3ef49f3e21701d1fc4f1c543d4b05801c126416466b",
			name: "FILWallet",
			slug: "filwallet",
			description:
				"Decentralized digital asset wallet build on web3.0 ecosystem",
			homepage: "https://filwallet.co/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f400f6c2-ca6c-487b-654d-e119af247500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f400f6c2-ca6c-487b-654d-e119af247500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f400f6c2-ca6c-487b-654d-e119af247500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f400f6c2-ca6c-487b-654d-e119af247500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/filwallet-io/id1572930901",
				android: "https://filwallet.co/",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://h5.filwallet.co",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "FILWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-03-03T17:21:10.895221+00:00",
		},
		d01c7758d741b363e637a817a09bcf579feae4db9f5bb16f599fdd1f66e2f974: {
			id: "d01c7758d741b363e637a817a09bcf579feae4db9f5bb16f599fdd1f66e2f974",
			name: "Valora",
			slug: "valora",
			description:
				"Valora is a mobile crypto wallet that enables global payments and easy access to decentralized apps.",
			homepage: "https://valoraapp.com",
			chains: ["eip155:42220"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a03bfa44-ce98-4883-9b2a-75e2b68f5700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a03bfa44-ce98-4883-9b2a-75e2b68f5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a03bfa44-ce98-4883-9b2a-75e2b68f5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a03bfa44-ce98-4883-9b2a-75e2b68f5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1520414263",
				android:
					"https://play.google.com/store/apps/details?id=co.clabs.valora",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "celo://wallet",
				universal: "https://valoraapp.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Valora",
				colors: {
					primary: "#42D689",
					secondary: "#FBCC5C",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"36d8d9c0c7fe2957149ce8e878f3a01a8611521983362d9b651fb6e508325583": {
			id: "36d8d9c0c7fe2957149ce8e878f3a01a8611521983362d9b651fb6e508325583",
			name: "CoinCircle",
			slug: "coincircle",
			description: "Earn, Pay, Buy, Borrow Crypto",
			homepage: "https://coincircle.com",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "eae63a23-c7ba-4f7e-24b3-e6fc69215d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eae63a23-c7ba-4f7e-24b3-e6fc69215d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eae63a23-c7ba-4f7e-24b3-e6fc69215d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eae63a23-c7ba-4f7e-24b3-e6fc69215d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://coincircle.com",
				ios: "https://coincircle.com/app",
				android: "https://coincircle.com/app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://coincircle.com/app/walletconnect",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "CoinCircle",
				colors: {
					primary: "#2499c4",
					secondary: "#1c2d5a",
				},
			},
			updatedAt: "2022-01-27T09:05:54.957689+00:00",
		},
		"23d57b0a48df6cec411e609ddedaa290dae4a844ea90909ddd33aca794574603": {
			id: "23d57b0a48df6cec411e609ddedaa290dae4a844ea90909ddd33aca794574603",
			name: "MyWalliD",
			slug: "mywallid",
			description:
				"MyWalliD lets the user store and manage their own identities assets on the browser local memory or to authenticate on the web3.",
			homepage: "https://www.wallid.io/",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "e6cff623-9671-4a39-acc7-1c2292d7e100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e6cff623-9671-4a39-acc7-1c2292d7e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e6cff623-9671-4a39-acc7-1c2292d7e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e6cff623-9671-4a39-acc7-1c2292d7e100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/wallid-wallet/eknlkogikcabnjbjnhmjllabckeapdii",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MyWalliD",
				colors: {
					primary: "#009FB1",
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T12:41:28.953186+00:00",
		},
		f2dcbeb246b4e4d37d748a7b2be54bbd93bdbe3e351d0badc1cbd3ea262d8466: {
			id: "f2dcbeb246b4e4d37d748a7b2be54bbd93bdbe3e351d0badc1cbd3ea262d8466",
			name: "BRISE Wallet",
			slug: "brise-wallet",
			description:
				"You can send, receive and store coins and many other cryptocurrencies and digital assets safely and securely.",
			homepage: "https://bitgert.com",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "09a4e1d9-e4de-44fa-f248-5495ba9ab300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/09a4e1d9-e4de-44fa-f248-5495ba9ab300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/09a4e1d9-e4de-44fa-f248-5495ba9ab300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/09a4e1d9-e4de-44fa-f248-5495ba9ab300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.brise.wallet&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BRISE Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-28T07:26:10.907351+00:00",
		},
		"51334e444ea1ba3d23c96063b8600c94af89233bd3f8f3685123c46e0348766c": {
			id: "51334e444ea1ba3d23c96063b8600c94af89233bd3f8f3685123c46e0348766c",
			name: "Snowball",
			slug: "snowball",
			description:
				"Gain access to high-yield stablecoin vaults and generate interest in real-time via DeFi",
			homepage: "https://snowball.money/",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "313faea4-af8c-41f4-0ed8-98be5d048e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/313faea4-af8c-41f4-0ed8-98be5d048e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/313faea4-af8c-41f4-0ed8-98be5d048e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/313faea4-af8c-41f4-0ed8-98be5d048e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/snowball-smart-defi-wallet/id1449662311",
				android:
					"https://play.google.com/store/apps/details?id=money.snowball.defi",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.snowball.exchange/app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Snowball",
				colors: {
					primary: "#292E39",
					secondary: null,
				},
			},
			updatedAt: "2022-01-28T09:02:54.61885+00:00",
		},
		d99d67379a0af80a39ef8fa79ad477debfe5abb71bd7cf92d12f30d6ffa69506: {
			id: "d99d67379a0af80a39ef8fa79ad477debfe5abb71bd7cf92d12f30d6ffa69506",
			name: "GameStop Wallet",
			slug: "gamestop-wallet",
			description:
				"GameStop Wallet is a simple and secure way to get started with Web3. Use your GameStop Wallet to buy, hold, swap. Power to the players!",
			homepage: "https://wallet.gamestop.com/wallets",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c12536e0-dff1-4a1a-6c8f-c7247d6aa200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c12536e0-dff1-4a1a-6c8f-c7247d6aa200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c12536e0-dff1-4a1a-6c8f-c7247d6aa200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c12536e0-dff1-4a1a-6c8f-c7247d6aa200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.gamestop.com/wallets",
				ios: "https://wallet.gamestop.com/wallets",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "GameStop Wallet",
				colors: {
					primary: "#000000",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2022-07-28T14:36:55.983097+00:00",
		},
		ca1d3f91b9233ff1f3a64fbaf2bd4a718e9ea0489ec71938d9da030a9f98ef8f: {
			id: "ca1d3f91b9233ff1f3a64fbaf2bd4a718e9ea0489ec71938d9da030a9f98ef8f",
			name: "ParaSwap Wallet",
			slug: "paraswap-wallet",
			description:
				"ParaSwap Wallet is a multichain DeFi wallet for trading at the best rates, with the highest efficiency and security in a friendly interface",
			homepage: "https://paraswap.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "73dc6b30-b644-46e6-020c-5926851df600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/73dc6b30-b644-46e6-020c-5926851df600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/73dc6b30-b644-46e6-020c-5926851df600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/73dc6b30-b644-46e6-020c-5926851df600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/paraswap-multichain-wallet/id1584610690",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "paraswap://",
				universal: "https://wallet.paraswap.io/#/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ParaSwap",
				colors: {
					primary: "#2669F5",
					secondary: null,
				},
			},
			updatedAt: "2022-09-02T06:04:31.481094+00:00",
		},
		"584538f059b079deecc80dface062cf40c33afd45dca02c7edca134a8225556d": {
			id: "584538f059b079deecc80dface062cf40c33afd45dca02c7edca134a8225556d",
			name: "Ballet Crypto",
			slug: "ballet-crypto",
			description:
				"Ballet Crypto is the world’s first multi-currency, non-electronic cryptocurrency wallet.",
			homepage: "https://www.balletcrypto.com/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "542094e6-70d6-4b0d-4c8f-b61cc2c38500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/542094e6-70d6-4b0d-4c8f-b61cc2c38500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/542094e6-70d6-4b0d-4c8f-b61cc2c38500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/542094e6-70d6-4b0d-4c8f-b61cc2c38500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ballet-crypto/id1474912942",
				android:
					"https://play.google.com/store/apps/details?id=com.balletcrypto&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: null,
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-03-04T07:57:59.588935+00:00",
		},
		f94a60403cdffa9a521dd12f9ec1004a887755c62ecf7bb4e9b8ee6806c26b58: {
			id: "f94a60403cdffa9a521dd12f9ec1004a887755c62ecf7bb4e9b8ee6806c26b58",
			name: "UvToken",
			slug: "uvtoken",
			description:
				"A safe, convenient and efficient decentralized digital asset management community",
			homepage: "https://www.uvtoken.com",
			chains: [
				"eip155:1",
				"eip155:128",
				"eip155:56",
				"eip155:65",
				"eip155:66",
				"eip155:97",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a0057241-cd91-4a53-7175-016b76bfd900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a0057241-cd91-4a53-7175-016b76bfd900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a0057241-cd91-4a53-7175-016b76bfd900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a0057241-cd91-4a53-7175-016b76bfd900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.uvtoken.com",
				ios: "https://apps.apple.com/hk/app/uvtoken/id1552556395",
				android:
					"https://wallet.uvtoken.com/static/download/android/uvtoken.apk",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: null,
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-03-08T08:51:56.935571+00:00",
		},
		"23db748bbf7ba1e737921bee04f54d53356e95533e0ed66c39113324873294e7": {
			id: "23db748bbf7ba1e737921bee04f54d53356e95533e0ed66c39113324873294e7",
			name: "RealT Wallet",
			slug: "realt-wallet",
			description:
				"RealT Wallet is a user-friendly, reliable mobile wallet that gives you complete control over your RealTokens and cryptocurrency.",
			homepage: "https://realt.co",
			chains: ["eip155:1", "eip155:100", "eip155:3", "eip155:4", "eip155:5"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bf1f251b-08a5-4b27-ae4a-201a5f698900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bf1f251b-08a5-4b27-ae4a-201a5f698900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bf1f251b-08a5-4b27-ae4a-201a5f698900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bf1f251b-08a5-4b27-ae4a-201a5f698900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/realt-wallet/id1545585469",
				android:
					"https://play.google.com/store/apps/details?id=co.realt.bridge",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "RealT Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-05-05T09:10:53.231159+00:00",
		},
		"5265dcf66be0553328dbc727414ab329e22f9a480e593bd2e927b279e4ab244d": {
			id: "5265dcf66be0553328dbc727414ab329e22f9a480e593bd2e927b279e4ab244d",
			name: "Sahal Wallet",
			slug: "sahalwallet",
			description:
				"Non-custodial multi-chain wallet serving as the gateway to MRHB Network.",
			homepage: "https://mrhb.network",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:338",
				"eip155:4",
				"eip155:4002",
				"eip155:43113",
				"eip155:43114",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "afa1e46a-331a-418f-ef1f-a29f76def100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/afa1e46a-331a-418f-ef1f-a29f76def100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/afa1e46a-331a-418f-ef1f-a29f76def100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/afa1e46a-331a-418f-ef1f-a29f76def100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://mrhb.network",
				ios: "https://apps.apple.com/gb/app/sahal-wallet/id1602366920",
				android:
					"https://play.google.com/store/apps/details?id=sahal.wallet.app&gl=GB",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "sahalwallet://",
				universal: null,
			},
			desktop: {
				native: "sahalwallet://",
				universal: "https://sahalwallet.app",
			},
			metadata: {
				shortName: "MRHB Network",
				colors: {
					primary: "#cd6638",
					secondary: null,
				},
			},
			updatedAt: "2022-03-23T09:04:39.581157+00:00",
		},
		"21b071705a9b9de1646e6a3a0e894d807d0fa4a208e88fc003ee056021f208e1": {
			id: "21b071705a9b9de1646e6a3a0e894d807d0fa4a208e88fc003ee056021f208e1",
			name: "ApolloX",
			slug: "apollox",
			description:
				"ApolloX is a mobile wallet that combines security and anonymity and it allows users to send, receive and store BEP20 (BSC) tokens.",
			homepage: "https://www.apollox.com/en",
			chains: ["eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "80ab63a2-1b32-4140-3577-9fbc8ea82e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/80ab63a2-1b32-4140-3577-9fbc8ea82e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/80ab63a2-1b32-4140-3577-9fbc8ea82e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/80ab63a2-1b32-4140-3577-9fbc8ea82e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.apollox.com/en",
				ios: "https://apps.apple.com/us/app/apx-apollox/id1589405398",
				android:
					"https://play.google.com/store/apps/details?id=com.apollox.android",
				mac: "https://www.apollox.com/en",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.apollox.finance",
			},
			desktop: {
				native: "",
				universal: "https://app.apollox.finance",
			},
			metadata: {
				shortName: "ApolloX",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-03-28T07:58:06.562401+00:00",
		},
		add46dfc2dc09c855fc8c14d950353528e184a8f4346886129c990074450ae9c: {
			id: "add46dfc2dc09c855fc8c14d950353528e184a8f4346886129c990074450ae9c",
			name: "Enno Wallet",
			slug: "enno-wallet",
			description:
				"A non-custodial multi chain mobile crypto wallet & DeFi aggregator.",
			homepage: "https://www.ennowallet.com",
			chains: ["eip155:1", "eip155:43114", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ae4f5167-0b61-43bd-7d76-1f8579271000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ae4f5167-0b61-43bd-7d76-1f8579271000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ae4f5167-0b61-43bd-7d76-1f8579271000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ae4f5167-0b61-43bd-7d76-1f8579271000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/enno-wallet/id1577011660#iosmph",
				android:
					"https://play.google.com/store/apps/details?id=com.app.awqsome.ennowallet#gpmph",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ennowallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "EnnoWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-08-25T12:31:24.418626+00:00",
		},
		ed657064daf740734d4a4ae31406cb294a17dc2dbd422ce90a86ed9816f0ded4: {
			id: "ed657064daf740734d4a4ae31406cb294a17dc2dbd422ce90a86ed9816f0ded4",
			name: "Nitrogen Wallet",
			slug: "nitrogen-wallet",
			description: "DeFi and GameFi Solana wallet",
			homepage: "https://nitrogen.org/",
			chains: [
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "af185895-cda5-4eaf-e31b-28b6fe4b0800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/af185895-cda5-4eaf-e31b-28b6fe4b0800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/af185895-cda5-4eaf-e31b-28b6fe4b0800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/af185895-cda5-4eaf-e31b-28b6fe4b0800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/nitrogen-wallet/id1595123469",
				android:
					"https://play.google.com/store/apps/details?id=org.nitrogen.mobile_wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://nitrogen.org/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Nitrogen",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-05-09T12:12:24.387442+00:00",
		},
		"3968c3f5e1aa69375e71bfc3da08a1d24791ac0b3d1c3b1c7e3a2676d175c856": {
			id: "3968c3f5e1aa69375e71bfc3da08a1d24791ac0b3d1c3b1c7e3a2676d175c856",
			name: "Loopring",
			slug: "loopring-wallet",
			description: "Loopring Smart Wallet",
			homepage: "https://loopring.io/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2103feda-4fc8-4635-76a7-02a4ed998000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2103feda-4fc8-4635-76a7-02a4ed998000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2103feda-4fc8-4635-76a7-02a4ed998000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2103feda-4fc8-4635-76a7-02a4ed998000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/loopring-smart-wallet/id1550921126",
				android:
					"https://play.google.com/store/apps/details?id=loopring.defi.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "loopring://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Loopring",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-01-19T13:14:39.435477+00:00",
		},
		"63076bf87200069fa799f5c75c578ff963e0a6c23489b65cc8721d3cbc7ad5e2": {
			id: "63076bf87200069fa799f5c75c578ff963e0a6c23489b65cc8721d3cbc7ad5e2",
			name: "A4 Wallet",
			slug: "a4-wallet",
			description:
				"A4 wallet is a non-custodial multi-currency crypto wallet from A4 Finance to securely store, exchange and manage your cryptocurrencies",
			homepage: "https://a4.finance",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"eip155:97",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7a788c03-daf7-4d93-fa3a-f94e2b719900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7a788c03-daf7-4d93-fa3a-f94e2b719900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7a788c03-daf7-4d93-fa3a-f94e2b719900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7a788c03-daf7-4d93-fa3a-f94e2b719900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/a4-finance-crypto-web3-wallet/id1623005090",
				android:
					"https://play.google.com/store/apps/details?id=finance.a4.mobile",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "A4 Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T13:48:30.12779+00:00",
		},
		e1882224c4c09a84575c533867d434267c46384f5a365b889605d28b061747c4: {
			id: "e1882224c4c09a84575c533867d434267c46384f5a365b889605d28b061747c4",
			name: "BeeWallet",
			slug: "beewallet",
			description:
				"Web3 wallet from BeeDAO, your bridge for entering Metaverse.",
			homepage: "https://www.bee.com/en",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "77743ed9-5ac6-48f7-867d-0f98e481b500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/77743ed9-5ac6-48f7-867d-0f98e481b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/77743ed9-5ac6-48f7-867d-0f98e481b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/77743ed9-5ac6-48f7-867d-0f98e481b500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1529988919",
				android:
					"https://play.google.com/store/apps/details?id=network.bee.app",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bee-wallet/nankopfjhdflikcokhgohiaoehnjfako",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBeeWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "bee://",
				universal: "https://main.apple.bee9527.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BeeWallet",
				colors: {
					primary: "#ffc04d",
					secondary: "#ffc04d",
				},
			},
			updatedAt: "2022-03-14T08:35:01.989765+00:00",
		},
		e2c884737858154c28ff2c543f96331f8987f58734a9c9eaff6d2daef8cd2917: {
			id: "e2c884737858154c28ff2c543f96331f8987f58734a9c9eaff6d2daef8cd2917",
			name: "Dohrnii Wallet",
			slug: "dohrnii-wallet",
			description: "The official wallet of the Dohrnii DAO",
			homepage: "https://dohrnii.io/",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1bb51ed9-68ed-4012-3082-72dcb7754300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1bb51ed9-68ed-4012-3082-72dcb7754300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1bb51ed9-68ed-4012-3082-72dcb7754300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1bb51ed9-68ed-4012-3082-72dcb7754300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://dohrnii.io/",
				ios: "https://apps.apple.com/ch/app/dohrnii-wallet/id1624702756?l=en",
				android:
					"https://play.google.com/store/apps/details?id=io.dohrnii.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Dohrnii Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T15:02:40.704034+00:00",
		},
		a3e4e54d0266116e3b51cdb89630dacb1b45c5a40d3ae7c98ca329489bf2e15a: {
			id: "a3e4e54d0266116e3b51cdb89630dacb1b45c5a40d3ae7c98ca329489bf2e15a",
			name: "LocalTrade Wallet",
			slug: "localtrade-wallet",
			description:
				"Created as an addition to our interconnected ecosystem, this wallet is the definitive gateway to the world of decentralized finance",
			homepage: "https://lab.localtrade.cc",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fcc60983-74ae-484a-4242-87cb6f05f100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fcc60983-74ae-484a-4242-87cb6f05f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fcc60983-74ae-484a-4242-87cb6f05f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fcc60983-74ae-484a-4242-87cb6f05f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://docs.localtrade.cc/products/defi-wallet-mvp-for-ios",
				ios: "https://apps.apple.com/app/localtrade-defi-wallet/id1602772298",
				android:
					"https://play.google.com/store/apps/details?id=com.localtrade.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://ws.lab.localtrade.cc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "LocalTrade",
				colors: {
					primary: "#00957B",
					secondary: "#F0F0F5",
				},
			},
			updatedAt: "2022-06-03T08:40:30.449721+00:00",
		},
		"24fa79ebaafca330af474d828d3d1d4b20b4d7f93f7d2fd4986ddafee5e51b14": {
			id: "24fa79ebaafca330af474d828d3d1d4b20b4d7f93f7d2fd4986ddafee5e51b14",
			name: "Xcapit",
			slug: "xcapit",
			description:
				"The first smart wallet, easy and simple to use for decentralized finance, without government restrictions.",
			homepage: "https://xcapit.com/",
			chains: ["eip155:1", "eip155:137", "eip155:30", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "17f59b75-21b0-4b3f-b024-fe4b9b8d2300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/17f59b75-21b0-4b3f-b024-fe4b9b8d2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/17f59b75-21b0-4b3f-b024-fe4b9b8d2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/17f59b75-21b0-4b3f-b024-fe4b9b8d2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.xcapit.com/",
				ios: "https://apps.apple.com/ar/app/xcapit/id1545648148",
				android: "https://play.google.com/store/apps/details?id=com.xcapit.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.xcapit.com/links",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Xcapit",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T13:56:53.746565+00:00",
		},
		"4b2604c8e0f5022d0fbfbc67685dd5e87426bbfe514eebcce6c5f3638f2e1d81": {
			id: "4b2604c8e0f5022d0fbfbc67685dd5e87426bbfe514eebcce6c5f3638f2e1d81",
			name: "BC Vault",
			slug: "bc-vault",
			description: "BC Vault Hardware Crypto Wallet",
			homepage: "https://bc-vault.com",
			chains: [
				"eip155:1",
				"eip155:106",
				"eip155:128",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "56995d82-a980-4dfc-2611-0f91d88c5700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/56995d82-a980-4dfc-2611-0f91d88c5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/56995d82-a980-4dfc-2611-0f91d88c5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/56995d82-a980-4dfc-2611-0f91d88c5700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: "https://bc-vault.com/download/",
				windows: "https://bc-vault.com/download/",
				linux: "https://bc-vault.com/download/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "bcvault://",
				universal: null,
			},
			metadata: {
				shortName: "BC Vault",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-18T15:05:02.772775+00:00",
		},
		fe2535202d208d96607955fe18e98d4564838200c3498c8cd1736b46291355f2: {
			id: "fe2535202d208d96607955fe18e98d4564838200c3498c8cd1736b46291355f2",
			name: "Safematrix",
			slug: "safematrix",
			description:
				"Safematrix app is mcp wallet used for signing transactions, viewing operation records, and other functions.",
			homepage: "https://safematrix.io/",
			chains: ["eip155:1", "eip155:4", "eip155:56", "eip155:97"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "48ea5de9-869a-4994-2402-97afba060900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/48ea5de9-869a-4994-2402-97afba060900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/48ea5de9-869a-4994-2402-97afba060900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/48ea5de9-869a-4994-2402-97afba060900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/sg/app/safematrix/id1613333481",
				android: "https://download.safematrix.io/abm/safematrix.apk",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://safematrix.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Safematrix",
				colors: {
					primary: "#ff3366",
					secondary: null,
				},
			},
			updatedAt: "2022-07-05T05:25:27.401335+00:00",
		},
		f039a4bdf6d5a54065b6cc4b134e32d23bed5d391ad97f25aab5627de26a0784: {
			id: "f039a4bdf6d5a54065b6cc4b134e32d23bed5d391ad97f25aab5627de26a0784",
			name: "Neon Wallet",
			slug: "neon-wallet",
			description: "Light wallet for the NEO blockchain",
			homepage: "https://neonwallet.com/",
			chains: ["neo3:mainnet"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "322bd6f0-09b5-4595-cb15-0dfab8054800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/322bd6f0-09b5-4595-cb15-0dfab8054800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/322bd6f0-09b5-4595-cb15-0dfab8054800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/322bd6f0-09b5-4595-cb15-0dfab8054800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://neonwallet.com/",
				ios: "https://apps.apple.com/my/app/neon-wallet-mobile/id1530111452",
				android:
					"https://play.google.com/store/apps/details?id=neo.org.freewallet.app&hl=pt_BR&gl=US",
				mac: "https://neonwallet.com/",
				windows: "https://neonwallet.com/",
				linux: "https://neonwallet.com/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Neon Wallet",
				colors: {
					primary: "#4cffb3",
					secondary: "#1E252A",
				},
			},
			updatedAt: "2022-07-12T19:22:17.266192+00:00",
		},
		bfa6967fd05add7bb2b19a442ac37cedb6a6b854483729194f5d7185272c5594: {
			id: "bfa6967fd05add7bb2b19a442ac37cedb6a6b854483729194f5d7185272c5594",
			name: "Absolute Wallet",
			slug: "absolutewallet",
			description:
				"Multi-chains Crypto Wallet.  Store, manage, stake and earn cryptocurrencies from your favorite messaging App!",
			homepage: "https://absolutewallet.com/dashboard/bridge",
			chains: [
				"eip155:1",
				"eip155:100",
				"eip155:122",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:1666600000",
				"eip155:199",
				"eip155:25",
				"eip155:250",
				"eip155:321",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:50",
				"eip155:56",
				"eip155:61",
				"eip155:66",
				"eip155:8",
				"eip155:820",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "03797059-fc49-4adc-7b93-503290b62300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/03797059-fc49-4adc-7b93-503290b62300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/03797059-fc49-4adc-7b93-503290b62300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/03797059-fc49-4adc-7b93-503290b62300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://t.me/AbsoluteWalletBot",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://t.me/AbsoluteWalletBot",
			},
			metadata: {
				shortName: "AbsoluteWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-19T15:42:34.746103+00:00",
		},
		"6c90dec3879127b46e162146e88cc272a79654648392d6de0feaa4b5127f38eb": {
			id: "6c90dec3879127b46e162146e88cc272a79654648392d6de0feaa4b5127f38eb",
			name: "Locker Token",
			slug: "locker-token",
			description:
				"Multi-currency digital wallet platform connecting you with the crypto-world.",
			homepage: "https://locker-token.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:30",
				"eip155:31",
				"eip155:80001",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "37401d35-3fa1-451c-802d-604940315800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/37401d35-3fa1-451c-802d-604940315800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/37401d35-3fa1-451c-802d-604940315800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/37401d35-3fa1-451c-802d-604940315800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.locker-token.com/",
				ios: "https://apps.apple.com/ar/app/locker-token/id1619140841?l=en",
				android:
					"https://play.google.com/store/apps/details?id=com.lockertoken",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "LockerToken",
				colors: {
					primary: "#9B5AF2",
					secondary: "#161425",
				},
			},
			updatedAt: "2022-10-28T15:26:57.165731+00:00",
		},
		"1f711d32b1df0f84741fafa2ad1d81599b01297cc7d22d153272cb3ef4232f19": {
			id: "1f711d32b1df0f84741fafa2ad1d81599b01297cc7d22d153272cb3ef4232f19",
			name: "Sequence Wallet",
			slug: "sequence-wallet",
			description:
				"Sequence is the smartest web3 wallet and developer platform. Sequence makes building user-friendly web3 applications easy for developers.",
			homepage: "https://sequence.xyz/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:4",
				"eip155:42161",
				"eip155:421611",
				"eip155:43113",
				"eip155:43114",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "b2d5c39c-a485-4efa-5736-a782204e4a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b2d5c39c-a485-4efa-5736-a782204e4a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b2d5c39c-a485-4efa-5736-a782204e4a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b2d5c39c-a485-4efa-5736-a782204e4a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://sequence.app",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://sequence.app/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://sequence.app",
			},
			desktop: {
				native: "",
				universal: "https://sequence.app",
			},
			metadata: {
				shortName: "Sequence",
				colors: {
					primary: "#4E47F5",
					secondary: "#DD14D5",
				},
			},
			updatedAt: "2022-06-30T15:58:48.885558+00:00",
		},
		dd8ee41915d967e547c80266e883d77ee808427405f4e8026a85ac1308104221: {
			id: "dd8ee41915d967e547c80266e883d77ee808427405f4e8026a85ac1308104221",
			name: "Linen",
			slug: "linen",
			description:
				"Linen users are protected by the Safe technology used by large crypto holders.",
			homepage: "https://linen.app/",
			chains: [
				"eip155:1",
				"eip155:100",
				"eip155:137",
				"eip155:5",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "aff3e4e1-92a9-4066-f48f-3591947cf200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/aff3e4e1-92a9-4066-f48f-3591947cf200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/aff3e4e1-92a9-4066-f48f-3591947cf200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/aff3e4e1-92a9-4066-f48f-3591947cf200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/linen-app-investing-in-defi/id1480509067",
				android:
					"https://play.google.com/store/apps/details?id=app.linen.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "linen://",
				universal: "https://linen.app/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Linen",
				colors: {
					primary: "#000000",
					secondary: "#BBFF33",
				},
			},
			updatedAt: "2021-12-20T13:00:01+00:00",
		},
		c679c2a1267c8cfb51d5d28a1a21f3eca5e8d7556332b9b9eb400c1f371844c9: {
			id: "c679c2a1267c8cfb51d5d28a1a21f3eca5e8d7556332b9b9eb400c1f371844c9",
			name: "Nabox",
			slug: "nabox",
			description: "Nabox is a cross-chain DID application built for Web3.  ",
			homepage: "https://nabox.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1313161554",
				"eip155:4689",
				"eip155:50",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "3b75e9f7-2ca8-4a33-ed2b-4e8a0c048d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3b75e9f7-2ca8-4a33-ed2b-4e8a0c048d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3b75e9f7-2ca8-4a33-ed2b-4e8a0c048d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3b75e9f7-2ca8-4a33-ed2b-4e8a0c048d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/nabox-wallet/nknhiehlklippafakaeklbeglecifhad?hl=en",
				ios: "https://testflight.apple.com/join/Ux18h5Nv",
				android:
					"https://play.google.com/store/apps/details?id=com.wallet.nabox",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://nabox.io/app/*",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Nabox",
				colors: {
					primary: "#53B8A9",
					secondary: null,
				},
			},
			updatedAt: "2022-10-28T15:23:26.189031+00:00",
		},
		"959c4774921adfcd49b30c88eb53f3831df6cc8c2f65577fbdd65c26a342577e": {
			id: "959c4774921adfcd49b30c88eb53f3831df6cc8c2f65577fbdd65c26a342577e",
			name: "Marble",
			slug: "marble",
			description:
				"A fully customizable non-custodial wallet that takes seconds for your users to set up. No seed phrases or extensions needed.",
			homepage: "https://www.marblewallet.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:3",
				"eip155:4",
				"eip155:420",
				"eip155:5",
				"eip155:6",
				"eip155:69",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "eb6de921-6824-4f35-6331-8a8b031e7100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eb6de921-6824-4f35-6331-8a8b031e7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eb6de921-6824-4f35-6331-8a8b031e7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eb6de921-6824-4f35-6331-8a8b031e7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.marblewallet.com/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.marblewallet.com/",
			},
			metadata: {
				shortName: "Marble",
				colors: {
					primary: "#F24B01",
					secondary: "#FFF1EB",
				},
			},
			updatedAt: "2023-01-04T09:07:14.763666+00:00",
		},
		bd0a2043ad2de4b70567d990d76c4bff7e483f6cda88814ee502b4ff25471293: {
			id: "bd0a2043ad2de4b70567d990d76c4bff7e483f6cda88814ee502b4ff25471293",
			name: "Spatium",
			slug: "spatium-1",
			description:
				"Your Crypto Wallet Solution For Business and Financial Services ",
			homepage: "https://spatium.net",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "51867bee-2963-4071-d67a-1fdcaa451f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/51867bee-2963-4071-d67a-1fdcaa451f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/51867bee-2963-4071-d67a-1fdcaa451f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/51867bee-2963-4071-d67a-1fdcaa451f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://spatium.net",
				ios: "https://apps.apple.com/ru/app/spatium/id1404844195",
				android:
					"https://play.google.com/store/apps/details?id=capital.spatium.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "spatium://",
				universal: "https://spatium.net",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Spatium",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-11-14T20:47:07.67763+00:00",
		},
		bae2ab14ef450f307f24a395a3c2766a2ef8a9c0e61856985d23f6445e8db03f: {
			id: "bae2ab14ef450f307f24a395a3c2766a2ef8a9c0e61856985d23f6445e8db03f",
			name: "Cryptnox Wallet",
			slug: "cryptnox-wallet",
			description: "IOS app to use our smartcards as hardware wallet via NFC",
			homepage: "https://cryptnox.com",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2947b7c8-8966-4485-a98d-25fe43c16700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2947b7c8-8966-4485-a98d-25fe43c16700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2947b7c8-8966-4485-a98d-25fe43c16700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2947b7c8-8966-4485-a98d-25fe43c16700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://cryptnox.com",
				ios: "https://apps.apple.com/app/id1583011693",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cryptnox Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T12:08:21.296984+00:00",
		},
		dcdcfb02dd871af8633875431eb42f095370b104304c35fe2ac77f8ae2045dad: {
			id: "dcdcfb02dd871af8633875431eb42f095370b104304c35fe2ac77f8ae2045dad",
			name: "Ownbit",
			slug: "ownbit-1",
			description: "Bitcoin Ethereum Tron MultiSig & Cold Wallet",
			homepage: "https://ownbit.io",
			chains: [
				"eip155:1",
				"eip155:43114",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "19923b08-7208-4539-9c2d-c43db22bce00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/19923b08-7208-4539-9c2d-c43db22bce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/19923b08-7208-4539-9c2d-c43db22bce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/19923b08-7208-4539-9c2d-c43db22bce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://ownbit.io",
				ios: "https://apps.apple.com/hk/app/ownbit-%E5%86%B7%E9%8C%A2%E5%8C%85-%E5%A4%9A%E7%B0%BD%E9%8C%A2%E5%8C%85/id1321798216",
				android:
					"https://play.google.com/store/apps/details?id=com.bitbill.www&hl=zh&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ownbit",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-28T15:32:29.410158+00:00",
		},
		"6289878a2ef8c49944cf428184bba626b161b0974f0b4f8b31a15e0317d8861c": {
			id: "6289878a2ef8c49944cf428184bba626b161b0974f0b4f8b31a15e0317d8861c",
			name: "ID Pocket",
			slug: "id-pocket",
			description:
				"Your private digital pocket for your identity and credentials",
			homepage: "https://rktechworks.com/idpocket",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c227ee0a-5127-4707-ded9-c3cd81348d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c227ee0a-5127-4707-ded9-c3cd81348d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c227ee0a-5127-4707-ded9-c3cd81348d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c227ee0a-5127-4707-ded9-c3cd81348d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id-pocket/id1549462128",
				android:
					"https://play.google.com/store/apps/details?id=com.rktechworks.idpocket",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ID Pocket digital identity wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-28T15:35:22.161186+00:00",
		},
		"792fbacfe787d67595dd4eb38ac308e14b3bbc810393db56f477a92e5ac8764b": {
			id: "792fbacfe787d67595dd4eb38ac308e14b3bbc810393db56f477a92e5ac8764b",
			name: "Assure",
			slug: "assure",
			description: "A decentralized wallet of digital currency",
			homepage: "https://www.assure.pro",
			chains: [
				"eip155:1",
				"eip155:128",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "64db7104-c8b7-44ea-e102-11ce87124200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/64db7104-c8b7-44ea-e102-11ce87124200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/64db7104-c8b7-44ea-e102-11ce87124200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/64db7104-c8b7-44ea-e102-11ce87124200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "http://itunes.apple.com/app/id1604825026",
				android:
					"https://play.google.com/store/apps/details?id=com.neuxs.assure",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "assure://",
				universal: "https://www.assure.pro/Official",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Assure",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-03-21T09:49:59.556952+00:00",
		},
		"184f15ea2cd5f65e25ed72efdd2ed1979179eddf0c0741ab0dd23c1eb7e0eee5": {
			id: "184f15ea2cd5f65e25ed72efdd2ed1979179eddf0c0741ab0dd23c1eb7e0eee5",
			name: "Flooz",
			slug: "flooz",
			description: "Your all-in-one crypto wallet",
			homepage: "https://wallet.flooz.trade",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0a04f368-4f56-4c12-0bfa-93b14bb20800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0a04f368-4f56-4c12-0bfa-93b14bb20800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0a04f368-4f56-4c12-0bfa-93b14bb20800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0a04f368-4f56-4c12-0bfa-93b14bb20800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1621027074",
				android:
					"https://play.google.com/store/apps/details?id=wallet.flooz.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "floozwallet://",
				universal: "https://wallet.flooz.trade/wc",
			},
			desktop: {
				native: "",
				universal: "https://wallet.flooz.trade/",
			},
			metadata: {
				shortName: "Flooz",
				colors: {
					primary: "#5E38F4",
					secondary: "#444455",
				},
			},
			updatedAt: "2022-11-14T19:51:34.878225+00:00",
		},
		"631c29ea78dabcfd0addef077c496b18689c7c8ac8a6643e3bef93555a8555f9": {
			id: "631c29ea78dabcfd0addef077c496b18689c7c8ac8a6643e3bef93555a8555f9",
			name: "ATON",
			slug: "aton",
			description:
				"ATON is a safe and easy to use mobile wallet, and the first one that supports PlatON's network.",
			homepage: "https://www.platon.network/en/wallet",
			chains: ["eip155:201030", "eip155:210309"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2e85f1d1-f498-4cae-bb54-1d40614ee300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2e85f1d1-f498-4cae-bb54-1d40614ee300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2e85f1d1-f498-4cae-bb54-1d40614ee300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2e85f1d1-f498-4cae-bb54-1d40614ee300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.platon.network/en/wallet",
				ios: "https://apps.apple.com/us/app/aton-platon-network%E9%92%B1%E5%8C%85/id1473112418?l=zh",
				android:
					"https://play.google.com/store/apps/details?id=com.platon.aton",
				mac: null,
				windows: "https://www.platon.network/en/wallet",
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ATON",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-11-14T20:43:18.791504+00:00",
		},
		"6adb6082c909901b9e7189af3a4a0223102cd6f8d5c39e39f3d49acb92b578bb": {
			id: "6adb6082c909901b9e7189af3a4a0223102cd6f8d5c39e39f3d49acb92b578bb",
			name: "Keplr",
			slug: "keplr",
			description:
				"Keplr is the largest Interchain wallet in the Cosmos ecosystem, supporting ",
			homepage: "https://keplr.app",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:irishub-1",
				"cosmos:kava-4",
				"cosmos:likecoin-mainnet-2",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "527324b0-3849-462b-9a1a-72b53bdfea00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/527324b0-3849-462b-9a1a-72b53bdfea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/527324b0-3849-462b-9a1a-72b53bdfea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/527324b0-3849-462b-9a1a-72b53bdfea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.keplr.app",
				ios: "https://apps.apple.com/us/app/keplr-wallet/id1567851089",
				android:
					"https://play.google.com/store/apps/details?id=com.chainapsis.keplr&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en",
				firefox: "https://addons.mozilla.org/en-US/firefox/addon/keplr/",
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/keplr/ocodgmmffbkkeecmadcijjhkmeohinei",
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "keplrwallet://wcV2",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Keplr",
				colors: {
					primary: "#314FDF",
					secondary: "#864FFC",
				},
			},
			updatedAt: "2023-02-15T14:03:40.111519+00:00",
		},
		"163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3": {
			id: "163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3",
			name: "Brave Wallet",
			slug: "brave-wallet",
			description: "The secure multi-chain crypto wallet",
			homepage: "https://brave.com/wallet/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1313161554",
				"eip155:137",
				"eip155:250",
				"eip155:4",
				"eip155:42220",
				"eip155:44787",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: [],
			sdks: [],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "8cecad66-73e3-46ee-f45f-01503c032f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8cecad66-73e3-46ee-f45f-01503c032f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8cecad66-73e3-46ee-f45f-01503c032f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8cecad66-73e3-46ee-f45f-01503c032f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://brave.com/wallet/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBraveWallet",
				},
				{
					namespace: "solana",
					injected_id: "isBraveWallet",
				},
			],
			rdns: "com.brave.wallet",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Brave",
				colors: {
					primary: "#FF2000",
					secondary: null,
				},
			},
			updatedAt: "2023-02-20T10:27:21.785808+00:00",
		},
		"87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659": {
			id: "87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659",
			name: "Crossmint",
			slug: "crossmint",
			description: "Making NFTs accessible to everyone",
			homepage: "https://www.crossmint.com/",
			chains: [
				"cip-34:1-764824073",
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "8ad627ec-cbcd-4878-ec5c-3df588055200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8ad627ec-cbcd-4878-ec5c-3df588055200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8ad627ec-cbcd-4878-ec5c-3df588055200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8ad627ec-cbcd-4878-ec5c-3df588055200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.crossmint.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://www.crossmint.com",
			},
			metadata: {
				shortName: "Crossmint",
				colors: {
					primary: "#22C392",
					secondary: "#20343e",
				},
			},
			updatedAt: "2022-11-15T13:42:47.404555+00:00",
		},
		abe0fdeae7b922a07bd0ced268cae04c1195ca1a50a478ca82b6b34a74b83700: {
			id: "abe0fdeae7b922a07bd0ced268cae04c1195ca1a50a478ca82b6b34a74b83700",
			name: "Gryfyn",
			slug: "gryfyn",
			description:
				"Gryfyn is a custodial wallet that gives you access to Web3 experiences without needing to worry about the security of your private keys.",
			homepage: "https://gryfyn.io/",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "51bb1507-45a1-4d21-15f2-1cc2ebe69400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/51bb1507-45a1-4d21-15f2-1cc2ebe69400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/51bb1507-45a1-4d21-15f2-1cc2ebe69400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/51bb1507-45a1-4d21-15f2-1cc2ebe69400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://gryfyn.io/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Gryfyn",
				colors: {
					primary: "#12ABEC",
					secondary: "#0A2930",
				},
			},
			updatedAt: "2023-03-02T14:52:11.152296+00:00",
		},
		fa82693d6253e73be14a572f4d0d66bee9e9d3f6bceaa49104987b4ba66ee398: {
			id: "fa82693d6253e73be14a572f4d0d66bee9e9d3f6bceaa49104987b4ba66ee398",
			name: "pier",
			slug: "pier",
			description:
				"Come to the pier and discover Web3: a place to explore, transact and interact directly with others.",
			homepage: "https://www.pierwallet.com",
			chains: [
				"eip155:137",
				"eip155:1666600000",
				"eip155:280",
				"eip155:324",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "cf3f0da1-40ec-4940-aebe-df075513d100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cf3f0da1-40ec-4940-aebe-df075513d100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cf3f0da1-40ec-4940-aebe-df075513d100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cf3f0da1-40ec-4940-aebe-df075513d100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.pierwallet.com",
				ios: "https://apps.apple.com/lb/app/id1613187762",
				android: "https://play.google.com/store/apps/details?id=one.nobank.app",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://pierwallet.xyz/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "pier",
				colors: {
					primary: "#221C16",
					secondary: "#5085DA",
				},
			},
			updatedAt: "2022-09-27T16:37:39.156386+00:00",
		},
		f323633c1f67055a45aac84e321af6ffe46322da677ffdd32f9bc1e33bafe29c: {
			id: "f323633c1f67055a45aac84e321af6ffe46322da677ffdd32f9bc1e33bafe29c",
			name: "Core",
			slug: "core",
			description: "Bridge, Buy, Swap and Send Crypto Easily with Core.",
			homepage:
				"https://core.app/?utm_source=referral&utm_medium=website&utm_campaign=walletconnect",
			chains: [
				"eip155:1",
				"eip155:4",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "35f9c46e-cc57-4aa7-315d-e6ccb2a1d600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/35f9c46e-cc57-4aa7-315d-e6ccb2a1d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/35f9c46e-cc57-4aa7-315d-e6ccb2a1d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/35f9c46e-cc57-4aa7-315d-e6ccb2a1d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/core-crypto-wallet-nfts/id6443685999",
				android:
					"https://play.google.com/store/apps/details?id=com.avaxwallet&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/core-crypto-wallet-nft-ex/agoakfejjabomempkjlepdflaleeobhb",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isAvalanche",
				},
			],
			rdns: null,
			mobile: {
				native: "core://",
				universal: "https://core.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Core",
				colors: {
					primary: "#000000",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2022-08-19T07:14:11.729061+00:00",
		},
		cf14642fb8736a99b733ada71863241c823743b16e2a822b3dba24e2fa25014d: {
			id: "cf14642fb8736a99b733ada71863241c823743b16e2a822b3dba24e2fa25014d",
			name: "Taho",
			slug: "taho",
			description: "First community-owned web3 wallet",
			homepage: "https://taho.xyz/",
			chains: ["eip155:1", "eip155:5"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "13416950-f73f-4a4c-2f22-d494ed5df800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/13416950-f73f-4a4c-2f22-d494ed5df800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/13416950-f73f-4a4c-2f22-d494ed5df800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/13416950-f73f-4a4c-2f22-d494ed5df800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/taho/eajafomhmkipbjmfmhebemolkcicgfmd",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isTally",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Taho",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-03-22T09:41:56.441609+00:00",
		},
		"3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010": {
			id: "3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010",
			name: "Torus",
			slug: "torus-1",
			description:
				"Torus is the most secure passwordless authentication and private key management platform with the security guarantees.",
			homepage: "https://app.tor.us",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1001",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:30",
				"eip155:31",
				"eip155:420",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:65",
				"eip155:66",
				"eip155:8217",
				"eip155:97",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "1caa462e-dcf5-4c56-d180-094c81444f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1caa462e-dcf5-4c56-d180-094c81444f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1caa462e-dcf5-4c56-d180-094c81444f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1caa462e-dcf5-4c56-d180-094c81444f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.tor.us",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.tor.us",
			},
			metadata: {
				shortName: "Torus",
				colors: {
					primary: "#0364ff",
					secondary: "#5495F7",
				},
			},
			updatedAt: "2023-03-22T10:38:15.994676+00:00",
		},
		a9751f17a3292f2d1493927f0555603d69e9a3fcbcdf5626f01b49afa21ced91: {
			id: "a9751f17a3292f2d1493927f0555603d69e9a3fcbcdf5626f01b49afa21ced91",
			name: "Frame",
			slug: "frame",
			description:
				"A privacy focused Ethereum wallet that runs natively on macOS, Windows and Linux",
			homepage: "https://frame.sh/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "29b4f569-c1e8-4144-132e-629bf5290f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/29b4f569-c1e8-4144-132e-629bf5290f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/29b4f569-c1e8-4144-132e-629bf5290f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/29b4f569-c1e8-4144-132e-629bf5290f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/frame-companion/ldcoohedfbjoobcadoglnnmmfbdlmmhf",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isFrame",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Frame",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-03-22T09:41:29.452937+00:00",
		},
		c3309233b29cc49323e2622383539b85dc62db2785874010e13d945d66eab880: {
			id: "c3309233b29cc49323e2622383539b85dc62db2785874010e13d945d66eab880",
			name: "Keeper",
			slug: "keeper",
			description:
				"Your entry point to the Waves blockchain and Waves-powered dApps",
			homepage: "https://keeper-wallet.app",
			chains: ["waves:083", "waves:084", "waves:087"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "41f6ac85-8f4e-4d9f-b37b-92b43fa7f400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/41f6ac85-8f4e-4d9f-b37b-92b43fa7f400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/41f6ac85-8f4e-4d9f-b37b-92b43fa7f400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/41f6ac85-8f4e-4d9f-b37b-92b43fa7f400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://chrome.google.com/webstore/detail/keeper-wallet/lpilbniiabackdjcionkobglmddfbcjo",
				ios: "https://apps.apple.com/us/app/keeper-wallet/id6444443920",
				android:
					"https://play.google.com/store/apps/details?id=app.keeper.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://link.keeper-wallet.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Keeper",
				colors: {
					primary: "#1F5AF6",
					secondary: null,
				},
			},
			updatedAt: "2023-02-14T12:51:41.551548+00:00",
		},
		"3a9973b9ee638a3aac3e1d001cabe425bf307602a61faee67942fda314736610": {
			id: "3a9973b9ee638a3aac3e1d001cabe425bf307602a61faee67942fda314736610",
			name: "Uniblow",
			slug: "uniblow",
			description: "A universal blockchain wallet for cryptos",
			homepage: "https://uniblow.org/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:3",
				"eip155:4",
				"eip155:42",
				"eip155:42161",
				"eip155:421611",
				"eip155:42220",
				"eip155:43113",
				"eip155:43114",
				"eip155:44787",
				"eip155:5",
				"eip155:56",
				"eip155:59",
				"eip155:62320",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "3aa86daa-b885-4686-c443-83355e1b3b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3aa86daa-b885-4686-c443-83355e1b3b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3aa86daa-b885-4686-c443-83355e1b3b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3aa86daa-b885-4686-c443-83355e1b3b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: "https://uniblow.org/get",
				windows: "https://uniblow.org/get",
				linux: "https://uniblow.org/get",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Uniblow",
				colors: {
					primary: "#6D57FF",
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T09:07:02.274887+00:00",
		},
		a1f506a38f39b672b369bd13b68abbbd81f83a0489e6625f2bf12aa0389c22ae: {
			id: "a1f506a38f39b672b369bd13b68abbbd81f83a0489e6625f2bf12aa0389c22ae",
			name: "D'CENT Wallet",
			slug: "dcent-wallet",
			description: "The most advanced cryptocurrency wallet",
			homepage: "https://dcentwallet.com",
			chains: [
				"eip155:1",
				"eip155:100",
				"eip155:128",
				"eip155:137",
				"eip155:14",
				"eip155:16",
				"eip155:19",
				"eip155:246",
				"eip155:25",
				"eip155:288",
				"eip155:30",
				"eip155:31",
				"eip155:321",
				"eip155:4",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:5",
				"eip155:50",
				"eip155:56",
				"eip155:66",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c68b81d1-a400-4a07-6d9d-28edda986d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c68b81d1-a400-4a07-6d9d-28edda986d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c68b81d1-a400-4a07-6d9d-28edda986d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c68b81d1-a400-4a07-6d9d-28edda986d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/dcent-hardware-wallet/id1447206611",
				android:
					"https://play.google.com/store/apps/details?id=com.kr.iotrust.dcent.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://link.dcentwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "D'CENT",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-03-02T12:07:49.702359+00:00",
		},
		"4d0cf1b635a59175b4ad6d6522b0b748ee920b1f8c32030fa704c00926efdf3e": {
			id: "4d0cf1b635a59175b4ad6d6522b0b748ee920b1f8c32030fa704c00926efdf3e",
			name: "Paper",
			slug: "paper",
			description: "Connect an app's embedded wallet powered by Paper.",
			homepage: "https://withpaper.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:420",
				"eip155:5",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "37d7a10f-d94d-4a56-c30e-267e8afbd500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/37d7a10f-d94d-4a56-c30e-267e8afbd500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/37d7a10f-d94d-4a56-c30e-267e8afbd500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/37d7a10f-d94d-4a56-c30e-267e8afbd500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://withpaper.com",
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://withpaper.com",
			},
			desktop: {
				native: "",
				universal: "https://withpaper.com",
			},
			metadata: {
				shortName: "Paper",
				colors: {
					primary: "#6BCDFA",
					secondary: "#051013",
				},
			},
			updatedAt: "2022-08-05T19:45:14.382281+00:00",
		},
		fbea6f68df4e6ce163c144df86da89f24cb244f19b53903e26aea9ab7de6393c: {
			id: "fbea6f68df4e6ce163c144df86da89f24cb244f19b53903e26aea9ab7de6393c",
			name: "Klever Wallet",
			slug: "klever-wallet",
			description:
				"Klever app is a simple, powerful, smart and secure crypto wallet for BTC, ETH, BNB and other crypto assets serving over 2 million users.",
			homepage: "https://klever.finance/wallet/",
			chains: ["eip155:1", "eip155:128", "eip155:137", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8f5bbad8-6a14-4b2c-5343-cc1fca6e4d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8f5bbad8-6a14-4b2c-5343-cc1fca6e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8f5bbad8-6a14-4b2c-5343-cc1fca6e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8f5bbad8-6a14-4b2c-5343-cc1fca6e4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1525584688",
				android:
					"https://play.google.com/store/apps/details?id=cash.klever.blockchain.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kleverwallet://",
				universal: "https://klever.page.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Klever",
				colors: {
					primary: "#DC3F89",
					secondary: "#903EDD",
				},
			},
			updatedAt: "2022-01-28T07:29:48.459191+00:00",
		},
		"0c5bba82e70a2b62405871af809020a077d110d765c0798eb660ad5d3131b328": {
			id: "0c5bba82e70a2b62405871af809020a077d110d765c0798eb660ad5d3131b328",
			name: "Edge Wallet",
			slug: "edge-wallet",
			description:
				" Edge is a powerful and easy to use cryptocurrency wallet that allows users to easily control their own private keys.",
			homepage: "https://edge.app/",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f601bc29-4298-422f-dbf7-34dac2884f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f601bc29-4298-422f-dbf7-34dac2884f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f601bc29-4298-422f-dbf7-34dac2884f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f601bc29-4298-422f-dbf7-34dac2884f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/edge-bitcoin-wallet/id1344400091",
				android:
					"https://play.google.com/store/apps/details?id=co.edgesecure.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "edge://",
				universal: "https://deep.edge.app/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Edge",
				colors: {
					primary: "#66EDA8",
					secondary: "#0D2145",
				},
			},
			updatedAt: "2022-01-28T09:02:35.681389+00:00",
		},
		"91628e2ae2228af2145bfac21093ad7be682810ec16af540a9e017ad6b933a81": {
			id: "91628e2ae2228af2145bfac21093ad7be682810ec16af540a9e017ad6b933a81",
			name: "NeftiWallet",
			slug: "neftiwallet",
			description:
				"The platform's NFT WALLET allows storing of the NFTs securely on the blockchain.",
			homepage: "https://neftipedia.com/",
			chains: ["eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1f812dec-be3d-446c-52f7-a79eb0dd5400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1f812dec-be3d-446c-52f7-a79eb0dd5400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1f812dec-be3d-446c-52f7-a79eb0dd5400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1f812dec-be3d-446c-52f7-a79eb0dd5400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.NEFTiPEDiA.mp",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "nefti://nefti.id/asset/",
				universal: "https://nefti.id/asset/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "NeftiWallet",
				colors: {
					primary: "#37747E",
					secondary: "#58595B",
				},
			},
			updatedAt: "2023-03-31T09:51:44.726685+00:00",
		},
		"746fa59e214ba46cfa688e9540a6b3450b514e89f39bd9c5a00b5a7fdaba8351": {
			id: "746fa59e214ba46cfa688e9540a6b3450b514e89f39bd9c5a00b5a7fdaba8351",
			name: "GoldBit",
			slug: "goldbit",
			description:
				"GoldBit 因應數位貨幣而生，作為儲存虛擬幣的冷錢包，支援Bitcoin、Ethereum和TRON三大主鏈的幣種，提供快速且便利的操作，並且擁有市面少有多錢包機制，可以輕鬆且安全存放虛擬貨幣。",
			homepage: "http://goldbit.io/gbapp.php",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "11974ef1-21ab-4806-a2b1-362c31499900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/11974ef1-21ab-4806-a2b1-362c31499900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/11974ef1-21ab-4806-a2b1-362c31499900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/11974ef1-21ab-4806-a2b1-362c31499900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/tw/app/goldbit錢包/id1551914030",
				android:
					"https://play.google.com/store/apps/details?id=com.goldbitpro.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "goldbit://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "GoldBit",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T11:23:59.150613+00:00",
		},
		"5859076ade608fbc4e9d3fe2f95e8527de80f8451ecbb1dced54ca84deae0dd6": {
			id: "5859076ade608fbc4e9d3fe2f95e8527de80f8451ecbb1dced54ca84deae0dd6",
			name: "Coingrig",
			slug: "coingrig",
			description:
				"A powerful crypto wallet for everyone. Private, secure and open source.",
			homepage: "https://coingrig.com",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:25",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "18e38e41-a387-4402-ca31-6d2d5eb91100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/18e38e41-a387-4402-ca31-6d2d5eb91100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/18e38e41-a387-4402-ca31-6d2d5eb91100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/18e38e41-a387-4402-ca31-6d2d5eb91100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/coingrig-crypto-btc-wallet/id1583464451",
				android: "https://play.google.com/store/apps/details?id=com.coingrig",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "coingrig://",
				universal: "https://link.coingrig.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Coingrig",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-28T09:02:30.126156+00:00",
		},
		c39d8ee468e50474fdf3a0bd6b981be404d4671e2702a3d633aae95bcbaa032a: {
			id: "c39d8ee468e50474fdf3a0bd6b981be404d4671e2702a3d633aae95bcbaa032a",
			name: "XFUN Wallet",
			slug: "xfun-wallet",
			description:
				"XFUN Wallet is a non-custodial wallet that can store, send, and receive FUN, XFUN, other ERC-20 tokens, and BTC.",
			homepage: "https://xfun.io",
			chains: ["eip155:1", "eip155:137", "eip155:3", "eip155:80001"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a665f8f3-09ef-4d17-2bd0-26dca4518400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a665f8f3-09ef-4d17-2bd0-26dca4518400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a665f8f3-09ef-4d17-2bd0-26dca4518400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a665f8f3-09ef-4d17-2bd0-26dca4518400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/xfun-wallet/id1612225910",
				android:
					"https://play.google.com/store/apps/details?id=com.xfun.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "xfunwallet://",
				universal: "https://xfun.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "XFUN Wallet",
				colors: {
					primary: "#FFFFFF",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2022-03-18T07:41:22.81718+00:00",
		},
		c40b9bcef32fa6ce4e0df98be1420628bbc4957646f742380fe618fcb4ab74f1: {
			id: "c40b9bcef32fa6ce4e0df98be1420628bbc4957646f742380fe618fcb4ab74f1",
			name: "RiceWallet",
			slug: "ricewallet",
			description: "Investing in DeFi for Everyone",
			homepage: "https://ricewallet.io/",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "df94578e-19be-4f00-258f-2470343e7b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/df94578e-19be-4f00-258f-2470343e7b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/df94578e-19be-4f00-258f-2470343e7b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/df94578e-19be-4f00-258f-2470343e7b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://ricewallet.io",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ricewallet://",
				universal: "https://ricewallet.io",
			},
			desktop: {
				native: "",
				universal: "https://ricewallet.io",
			},
			metadata: {
				shortName: "RICE Wallet",
				colors: {
					primary: "#3361f4",
					secondary: null,
				},
			},
			updatedAt: "2022-03-21T09:28:25.751354+00:00",
		},
		c29c9237e92bc18e141e52aba3aa6d04b1afbe9952a0ab2f96dbd8653645c1df: {
			id: "c29c9237e92bc18e141e52aba3aa6d04b1afbe9952a0ab2f96dbd8653645c1df",
			name: "Ancrypto Wallet",
			slug: "ancrypto-wallet",
			description:
				"Ancrypto Wallet is mnemonics based highly secured mobile wallet to store crypto assets. ",
			homepage: "https://www.antiersolutions.com/",
			chains: [
				"eip155:1",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d4382329-e288-4d7a-0ac8-3eb0facfb900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d4382329-e288-4d7a-0ac8-3eb0facfb900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d4382329-e288-4d7a-0ac8-3eb0facfb900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d4382329-e288-4d7a-0ac8-3eb0facfb900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/in/app/ancrypto-wallet/id1453657650",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ancrypto://app",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ancrypto Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-27T11:08:01.47023+00:00",
		},
		"4f5de5333fed2ccf47c690579aba3b9128aea78175339ff51ef61704bde7502a": {
			id: "4f5de5333fed2ccf47c690579aba3b9128aea78175339ff51ef61704bde7502a",
			name: "Okse Wallet",
			slug: "okse-wallet",
			description: "Okse Wallet & Card App",
			homepage: "https://okse.io",
			chains: [
				"eip155:1",
				"eip155:106",
				"eip155:128",
				"eip155:1313161554",
				"eip155:250",
				"eip155:3",
				"eip155:4",
				"eip155:40",
				"eip155:42",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:88",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8a1b36d5-7f40-403a-7000-5d30f9181200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8a1b36d5-7f40-403a-7000-5d30f9181200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8a1b36d5-7f40-403a-7000-5d30f9181200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8a1b36d5-7f40-403a-7000-5d30f9181200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://okse.io",
				ios: "https://apps.apple.com/us/app/okse-wallet-card/id1555914591",
				android: "https://play.google.com/store/apps/details?id=wallet.okse.io",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "oksewallet://",
				universal: "https://okse.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "OkseWallet",
				colors: {
					primary: "#e51b23",
					secondary: "#f6b1b3",
				},
			},
			updatedAt: "2022-07-07T04:49:23.940186+00:00",
		},
		"19ad8334f0f034f4176a95722b5746b539b47b37ce17a5abde4755956d05d44c": {
			id: "19ad8334f0f034f4176a95722b5746b539b47b37ce17a5abde4755956d05d44c",
			name: "Aktionariat",
			slug: "aktionariat",
			description:
				"A fully automated, blockchain-based market tool. Embedded in your company's website.",
			homepage: "https://aktionariat.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6d18e8ea-b536-4038-c5bf-94a499d5a400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6d18e8ea-b536-4038-c5bf-94a499d5a400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6d18e8ea-b536-4038-c5bf-94a499d5a400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6d18e8ea-b536-4038-c5bf-94a499d5a400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ch/app/aktionariat/id1518326813",
				android:
					"https://play.google.com/store/apps/details?id=com.aktionariat.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "aktionariat://",
				universal: "https://app.aktionariat.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Aktionariat",
				colors: {
					primary: "#000000",
					secondary: "#5869ff",
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"797c615e2c556b610c048eb35535f212c0dd58de5d03e763120e90a7d1350a77": {
			id: "797c615e2c556b610c048eb35535f212c0dd58de5d03e763120e90a7d1350a77",
			name: "iToken Wallet",
			slug: "itoken-wallet",
			description:
				"For and from those who place top priority on asset security.",
			homepage: "https://www.itoken.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5cd60c34-038d-470c-c024-d58f64260200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5cd60c34-038d-470c-c024-d58f64260200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5cd60c34-038d-470c-c024-d58f64260200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5cd60c34-038d-470c-c024-d58f64260200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1433883012",
				android:
					"https://play.google.com/store/apps/details?id=com.huobionchainwallet.gp",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "huobiwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "iToken Wallet",
				colors: {
					primary: "#2d67f8",
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565+00:00",
		},
		"00e39f835988d1bb783b2a0748e18bc6278dec03492d00b0e102a466cd8b3d77": {
			id: "00e39f835988d1bb783b2a0748e18bc6278dec03492d00b0e102a466cd8b3d77",
			name: "Zelus",
			slug: "zelus",
			description: "The most user-friendly NFT and crypto multichain wallet",
			homepage: "https://zelus.io",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "aeba2105-6c84-4642-f441-b3f5817ac400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/aeba2105-6c84-4642-f441-b3f5817ac400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/aeba2105-6c84-4642-f441-b3f5817ac400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/aeba2105-6c84-4642-f441-b3f5817ac400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/zelus/id1588430343",
				android:
					"https://play.google.com/store/apps/details?id=com.zelus.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "zeluswallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Zelus",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-04-13T09:25:08.638847+00:00",
		},
		"75ca1aafd91026f435803f9a11e8e4278388e189aa30dc93e532244ade262c57": {
			id: "75ca1aafd91026f435803f9a11e8e4278388e189aa30dc93e532244ade262c57",
			name: "Talk+",
			slug: "talk",
			description:
				"TALK+ provides end-to-end encrypted for high privacy messaging, with multi-cryptocurrency wallet functionality. ",
			homepage: "https://www.talkapp.org/",
			chains: ["eip155:1", "eip155:4"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d24cdd56-6f55-42da-631b-c25974c36f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d24cdd56-6f55-42da-631b-c25974c36f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d24cdd56-6f55-42da-631b-c25974c36f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d24cdd56-6f55-42da-631b-c25974c36f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.talkapp.org/",
				ios: "https://apps.apple.com/hk/app/talk-send-crypto-with-friends/id1547227377?l=en",
				android:
					"https://play.google.com/store/apps/details?id=org.talkapp&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "talkapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Talk+",
				colors: {
					primary: "#FCD86C",
					secondary: null,
				},
			},
			updatedAt: "2022-04-14T14:18:05.832165+00:00",
		},
		"11c5487e4d8dd8bf32d4c92222363df8296a27307b2531be1e25770365392ecb": {
			id: "11c5487e4d8dd8bf32d4c92222363df8296a27307b2531be1e25770365392ecb",
			name: "Card Wallet",
			slug: "card-wallet",
			description:
				"Fast, easy & cheap payments for Web3 Use your mobile wallet for access, payments, identity and approvals.",
			homepage: "https://cardstack.com/earn-together",
			chains: ["eip155:1", "eip155:100"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "325428cf-c212-4d83-a434-7f48902d2c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/325428cf-c212-4d83-a434-7f48902d2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/325428cf-c212-4d83-a434-7f48902d2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/325428cf-c212-4d83-a434-7f48902d2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://cardstack.com/ios",
				android: "https://cardstack.com/android",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "cardwallet://",
				universal: "https://wallet.cardstack.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Card",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-04-27T07:54:04.528271+00:00",
		},
		"5dc61e9d57489bccc11306365361614dac3de1d8eab2a9a7877a95970f68712f": {
			id: "5dc61e9d57489bccc11306365361614dac3de1d8eab2a9a7877a95970f68712f",
			name: "PayBolt",
			slug: "paybolt",
			description:
				"World’s first Web3 cross-chain crypto payment ecosystem that accepts ALL tokens.",
			homepage: "https://www.paybolt.com",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "cc8f4e0c-56a8-465a-6cb6-3e9d60846500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cc8f4e0c-56a8-465a-6cb6-3e9d60846500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cc8f4e0c-56a8-465a-6cb6-3e9d60846500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cc8f4e0c-56a8-465a-6cb6-3e9d60846500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.paybolt.com",
				ios: "https://apps.apple.com/app/paybolt-crypto/id1599880290",
				android:
					"https://play.google.com/store/apps/details?id=com.fincrypt.paybolt",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "paybolt://Wallet",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PayBolt",
				colors: {
					primary: "#000000",
					secondary: null,
				},
			},
			updatedAt: "2022-04-21T11:30:39.271841+00:00",
		},
		c6f3d04a4e1a51e7d2045f347a5ebdab30fc600950a740fca21f0c92e230ee05: {
			id: "c6f3d04a4e1a51e7d2045f347a5ebdab30fc600950a740fca21f0c92e230ee05",
			name: "Arianee Wallet",
			slug: "arianee-wallet",
			description:
				"With the Arianee Wallet, you can use digital passports / NFTs to verify and prove the authenticity of your most precious items.",
			homepage: "https://arianee.org",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:77",
				"eip155:80001",
				"eip155:99",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ace938a9-c906-4b9e-f683-b85f1ab72800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ace938a9-c906-4b9e-f683-b85f1ab72800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ace938a9-c906-4b9e-f683-b85f1ab72800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ace938a9-c906-4b9e-f683-b85f1ab72800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/fr/app/arianee-wallet/id1435782507",
				android:
					"https://play.google.com/store/apps/details?id=com.arianee.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "com.arianee.wallet://",
				universal: "https://arianee.net",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: null,
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T13:18:07.02472+00:00",
		},
		b823fb0d7228ef8e3c0bc9607df9ed79dae2ab3a2811d33f22ade4f573c18232: {
			id: "b823fb0d7228ef8e3c0bc9607df9ed79dae2ab3a2811d33f22ade4f573c18232",
			name: "Slavi Wallet",
			slug: "slavi-wallet",
			description:
				"Cross-chain decentralized SuperDApp with 30+ blockchains & one-click access to Web 3.0, PlayToEarn and NFT services",
			homepage: "https://slavi.io/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "282ce060-0beb-4236-b7b0-1b34cc6c8f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/282ce060-0beb-4236-b7b0-1b34cc6c8f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/282ce060-0beb-4236-b7b0-1b34cc6c8f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/282ce060-0beb-4236-b7b0-1b34cc6c8f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/en/app/slavi-wallet/id1610125496?l=en",
				android:
					"https://play.google.com/store/apps/details?id=com.defiwalletmobile",
				mac: "https://apps.apple.com/en/app/slavi-wallet/id1610125496?l=en",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "slaviwallet://",
				universal: "https://www.slaviwallet.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Slavi Wallet",
				colors: {
					primary: "#F2C75B",
					secondary: "#4D1F87",
				},
			},
			updatedAt: "2022-07-07T07:50:25.790188+00:00",
		},
		cbe13eb482c76f1fa401ff4c84d9acd0b8bc9af311ca0620a0b192fb28359b4e: {
			id: "cbe13eb482c76f1fa401ff4c84d9acd0b8bc9af311ca0620a0b192fb28359b4e",
			name: "Plasma Wallet",
			slug: "plasma-wallet",
			description: "Non-custodial iOS crypto wallet for DeFi, NFT and Web3",
			homepage: "https://plasma-wallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:25",
				"eip155:250",
				"eip155:28",
				"eip155:288",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c268e78d-ffb0-4c8b-5cad-04c3add48500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c268e78d-ffb0-4c8b-5cad-04c3add48500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c268e78d-ffb0-4c8b-5cad-04c3add48500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c268e78d-ffb0-4c8b-5cad-04c3add48500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/plasmapay-defi-crypto-wallet/id1461735396",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "plasmawallet://",
				universal: "https://plasma-wallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Plasma Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-09-15T16:28:29.043095+00:00",
		},
		"1a5f2435e8e31c4034f1d142e85d9f7d3be2a09ddf710e5ef1ad4e36c719d3c0": {
			id: "1a5f2435e8e31c4034f1d142e85d9f7d3be2a09ddf710e5ef1ad4e36c719d3c0",
			name: "ioPay",
			slug: "iopay-1",
			description:
				"Multi-Chain Crypto Wallet. Supports IoTeX, Ethereum, BNB Chain, Polygon.",
			homepage: "https://iopay.me/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:42",
				"eip155:42161",
				"eip155:4689",
				"eip155:4690",
				"eip155:56",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "18891f5a-fd0f-4126-7d1a-452be6714700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/18891f5a-fd0f-4126-7d1a-452be6714700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/18891f5a-fd0f-4126-7d1a-452be6714700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/18891f5a-fd0f-4126-7d1a-452be6714700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://iopay.me/",
				ios: "https://apps.apple.com/app/apple-store/id1478086371",
				android:
					"https://play.google.com/store/apps/details?id=io.iotex.iopay.gp",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "iopay://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ioPay",
				colors: {
					primary: "#617AFF",
					secondary: "#FFEB34",
				},
			},
			updatedAt: "2022-09-20T12:06:32.542326+00:00",
		},
		"1986e7c874bb906f057d5d64a4806c004e021689536e5228c74d64a6058e8bac": {
			id: "1986e7c874bb906f057d5d64a4806c004e021689536e5228c74d64a6058e8bac",
			name: "Defiant",
			slug: "defiant-1",
			description: "Defiant is your gateway to a decentralized world.",
			homepage: "https://www.defiantapp.tech/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:250",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:4002",
				"eip155:42",
				"eip155:42161",
				"eip155:421611",
				"eip155:42220",
				"eip155:43113",
				"eip155:43114",
				"eip155:44787",
				"eip155:56",
				"eip155:69",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "11a96ca4-3592-42ae-c781-2b7265ec9200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/11a96ca4-3592-42ae-c781-2b7265ec9200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/11a96ca4-3592-42ae-c781-2b7265ec9200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/11a96ca4-3592-42ae-c781-2b7265ec9200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/defiant-wallet/id1559622756",
				android:
					"https://play.google.com/store/apps/details?id=ar.com.andinasmart.defiant&hl=en",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "defiantapp://",
				universal: "https://defiantapp.tech/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Defiant",
				colors: {
					primary: "#00bd57",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2022-10-11T21:37:34.258761+00:00",
		},
		"6534bbb4ccab1db9ed84ae4069b7c9577dd0b3ea211d4fdec2ef4f9ce186f38a": {
			id: "6534bbb4ccab1db9ed84ae4069b7c9577dd0b3ea211d4fdec2ef4f9ce186f38a",
			name: "StrikeX Wallet",
			slug: "strikex-wallet",
			description:
				"The StrikeX Wallet makes buying, selling, swapping, transferring, and tracking your favourite crypto projects — slick, secure and simple. ",
			homepage: "https://tradestrike.io/",
			chains: [
				"eip155:1",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "cae46de2-b432-4002-8bc8-1f0e7380b200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cae46de2-b432-4002-8bc8-1f0e7380b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cae46de2-b432-4002-8bc8-1f0e7380b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cae46de2-b432-4002-8bc8-1f0e7380b200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/gb/app/strikex-defi-crypto-wallet/id6443517613",
				android:
					"https://play.google.com/store/apps/details?id=com.tradestrike",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "strikex://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "StrikeX Wallet",
				colors: {
					primary: "#5C60FF",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2022-10-26T16:26:47.879353+00:00",
		},
		"94f785c0c8fb8c4f38cd9cd704416430bcaa2137f27e1468782d624bcd155a43": {
			id: "94f785c0c8fb8c4f38cd9cd704416430bcaa2137f27e1468782d624bcd155a43",
			name: "Avacus",
			slug: "avacus",
			description:
				"Crypto wallet integrated with token exchanges, browser DApps, multichain wallet and many other utilities",
			homepage: "https://avacus.cc",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a7106965-91cc-4a73-4688-c5c72ae0ed00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a7106965-91cc-4a73-4688-c5c72ae0ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a7106965-91cc-4a73-4688-c5c72ae0ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a7106965-91cc-4a73-4688-c5c72ae0ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id6449657442",
				android:
					"https://play.google.com/store/apps/details?id=io.sowaka.avacus",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "avacus://",
				universal: "https://avacus.app.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Avacus",
				colors: {
					primary: "#FA8731",
					secondary: null,
				},
			},
			updatedAt: "2023-01-13T09:20:27.217772+00:00",
		},
		"7468ebbf5e14bd146c4fa12a08fb1a0d8d9af3b66409a5b682b64cffc4f21919": {
			id: "7468ebbf5e14bd146c4fa12a08fb1a0d8d9af3b66409a5b682b64cffc4f21919",
			name: "ByteBank",
			slug: "bytebank",
			description:
				"Storing and managing your cryptocurrencies made easier. No matter where you are, no matter which digital currency you hold.",
			homepage: "https://www.bytebank.org/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bc7aacd6-b2e2-4146-7d21-06e0c5d44f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bc7aacd6-b2e2-4146-7d21-06e0c5d44f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bc7aacd6-b2e2-4146-7d21-06e0c5d44f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bc7aacd6-b2e2-4146-7d21-06e0c5d44f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/sg/app/hideout-wallet/id1620315192?l=zh",
				android:
					"https://play.google.com/store/apps/details?id=com.hideout.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "hideoutWallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ByteBank",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T14:54:31.353273+00:00",
		},
		"1f69170bf7a9bdcf89403ec012659b7124e158f925cdd4a2be49274c24cf5e5d": {
			id: "1f69170bf7a9bdcf89403ec012659b7124e158f925cdd4a2be49274c24cf5e5d",
			name: "CoolWallet",
			slug: "coolwallet",
			description: "Explore the Web3 universe with the coolest wallet.",
			homepage: "https://coolwallet.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:108",
				"eip155:137",
				"eip155:14",
				"eip155:25",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:59144",
				"eip155:66",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f581365d-e844-4d21-8e35-44a755a32d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f581365d-e844-4d21-8e35-44a755a32d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f581365d-e844-4d21-8e35-44a755a32d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f581365d-e844-4d21-8e35-44a755a32d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/us/app/coolwallet-s-2018/id1328764142",
				android:
					"https://play.google.com/store/apps/details?id=com.coolbitx.cwsapp",
				mac: "https://itunes.apple.com/us/app/coolwallet-s-2018/id1328764142",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.coolbitx.cwsapp",
			mobile: {
				native: "coolwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "CoolWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2021-07-30T17:48:12.565532+00:00",
		},
		"9504a1c1a86cc0702b2d3e47049e1389b373fb2ff22de3208c748d62912433a4": {
			id: "9504a1c1a86cc0702b2d3e47049e1389b373fb2ff22de3208c748d62912433a4",
			name: "Opto Wallet",
			slug: "opto-wallet",
			description: "The wallet built for NEAR Protocol & Octopus Network",
			homepage: "https://optowallet.com/",
			chains: ["near:mainnet", "near:testnet"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "3df102e4-e435-49dd-d4b1-5ea74ebed500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3df102e4-e435-49dd-d4b1-5ea74ebed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3df102e4-e435-49dd-d4b1-5ea74ebed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3df102e4-e435-49dd-d4b1-5ea74ebed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/opto-wallet/id6443854537",
				android:
					"https://play.google.com/store/apps/details?id=app.opto.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "opto://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Opto Wallet",
				colors: {
					primary: "#0caade",
					secondary: null,
				},
			},
			updatedAt: "2022-10-15T20:50:59.687005+00:00",
		},
		e3787ea98d014ca77e2c3794db97c02ef8bcb39347705f5e79502a55434a1ecf: {
			id: "e3787ea98d014ca77e2c3794db97c02ef8bcb39347705f5e79502a55434a1ecf",
			name: "TK Finance",
			slug: "tk-finance",
			description:
				"Entering the great Trustkeys Finance Ecosystem. Fast, safe, secure trading experience with Hybrid Change.",
			homepage: "https://trustkeys.network/",
			chains: ["eip155:1", "eip155:3", "eip155:56", "eip155:97"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c4066f68-2247-49bf-ac8a-a677bfa81800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c4066f68-2247-49bf-ac8a-a677bfa81800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c4066f68-2247-49bf-ac8a-a677bfa81800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c4066f68-2247-49bf-ac8a-a677bfa81800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/tk-finance/id1601968967",
				android:
					"https://play.google.com/store/apps/details?id=com.trustkeysnetwork",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "tk://",
				universal: "https://trustkeys.network",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "TK Finance",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-01-31T07:22:16.111704+00:00",
		},
		"2cca8c1b0bea04ba37dee4017991d348cdb7b826804ab2bd31073254f345b715": {
			id: "2cca8c1b0bea04ba37dee4017991d348cdb7b826804ab2bd31073254f345b715",
			name: "Bee Wallet",
			slug: "bee-wallet",
			description:
				"Bee Wallet is a mobile-first crypto wallet that lets you manage funds, swap tokens safely, and track your NFT collection.",
			homepage: "https://www.beewallet.app",
			chains: ["eip155:1", "eip155:250", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f90bc33f-f085-40cf-7538-fae5ae84f900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f90bc33f-f085-40cf-7538-fae5ae84f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f90bc33f-f085-40cf-7538-fae5ae84f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f90bc33f-f085-40cf-7538-fae5ae84f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/lt/app/bee-for-uniswap-pancakeswap/id1617257467",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "beewallet.app://",
				universal: "https://beewallet.app/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bee",
				colors: {
					primary: "#14181E",
					secondary: "#FEE600",
				},
			},
			updatedAt: "2023-01-06T13:02:20.382523+00:00",
		},
		"540148afe64558bb238cab6c43bd963055ed9248c094eaebff94d7bbb59f9aba": {
			id: "540148afe64558bb238cab6c43bd963055ed9248c094eaebff94d7bbb59f9aba",
			name: "MDAO Wallet",
			slug: "mdao-wallet",
			description:
				"MDAO Wallet is the best app with a friendly user and convenient interface to keep and manage your digital assets.",
			homepage: "https://ttmwallet.io/",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "82014e92-838b-4e75-e77e-76cdc5539d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/82014e92-838b-4e75-e77e-76cdc5539d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/82014e92-838b-4e75-e77e-76cdc5539d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/82014e92-838b-4e75-e77e-76cdc5539d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ru/app/ttm-wallet/id1540851562",
				android:
					"https://play.google.com/store/apps/details?id=com.ttmbank.wallet.app&hl=ru&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ttmwalletapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MDAO Wallet",
				colors: {
					primary: "#1BD099",
					secondary: "#1BD099",
				},
			},
			updatedAt: "2022-04-14T06:58:08.798045+00:00",
		},
		"576c90ceaea34f29ff0104837cf2b2e23d201be43be1433feeb18d375430e1fd": {
			id: "576c90ceaea34f29ff0104837cf2b2e23d201be43be1433feeb18d375430e1fd",
			name: "PLTwallet",
			slug: "pltwallet",
			description: "PLTwallet is a wallet for ethereum and PLT",
			homepage: "https://pltwallet.io/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a5d9dd15-8cef-42de-8bed-09e01a8b0200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a5d9dd15-8cef-42de-8bed-09e01a8b0200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a5d9dd15-8cef-42de-8bed-09e01a8b0200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a5d9dd15-8cef-42de-8bed-09e01a8b0200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1581055631",
				android:
					"https://play.google.com/store/apps/details?id=com.palettechain.wallet&hl=ja&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pltwallet://",
				universal: "https://pltwallet.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PLTwallet",
				colors: {
					primary: "#ffffff",
					secondary: null,
				},
			},
			updatedAt: "2022-03-04T12:13:11.318685+00:00",
		},
		"48e53d96460308a1734614b5d4fdf7ea169e6f998e01eb7b4e18014f57904d67": {
			id: "48e53d96460308a1734614b5d4fdf7ea169e6f998e01eb7b4e18014f57904d67",
			name: "helix id",
			slug: "helix-id",
			description: "Digital Identity Service Provider",
			homepage: "https://helixid.io/",
			chains: ["eip155:1", "eip155:137", "eip155:4", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4083ef71-8389-4682-ded6-0099236d2e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4083ef71-8389-4682-ded6-0099236d2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4083ef71-8389-4682-ded6-0099236d2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4083ef71-8389-4682-ded6-0099236d2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/de/app/helix-id/id1469238013?l=en",
				android:
					"https://play.google.com/store/apps/details?id=com.io.helix.id&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "helix-id://helix-id.com",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "helixid",
				colors: {
					primary: "#0021FF",
					secondary: "#FA3C50",
				},
			},
			updatedAt: "2022-04-22T12:53:23.035841+00:00",
		},
		a0718f5fb1493e4aaac72cff62d162cb85db40ed68fd8700298f36f1d5c4b73d: {
			id: "a0718f5fb1493e4aaac72cff62d162cb85db40ed68fd8700298f36f1d5c4b73d",
			name: "AirGap Wallet",
			slug: "airgap-wallet",
			description:
				"Self custody made simple and secure. Based on a two device approach, increasing security and usability with YOU in the driver’s seat",
			homepage: "https://airgap.it",
			chains: ["eip155:1"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "76bfe8cd-cf3f-4341-c33c-60da01065000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/76bfe8cd-cf3f-4341-c33c-60da01065000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/76bfe8cd-cf3f-4341-c33c-60da01065000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/76bfe8cd-cf3f-4341-c33c-60da01065000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.airgap.it",
				ios: "https://itunes.apple.com/us/app/airgap-wallet/id1420996542?l=de&ls=1&mt=8",
				android:
					"https://play.google.com/store/apps/details?id=it.airgap.wallet",
				mac: "https://github.com/airgap-it/airgap-wallet/releases",
				windows: "https://github.com/airgap-it/airgap-wallet/releases",
				linux: "https://github.com/airgap-it/airgap-wallet/releases",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "airgap-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "AirGap",
				colors: {
					primary: "#50249f",
					secondary: "#00e8d0",
				},
			},
			updatedAt: "2021-12-20T13:00:01.498413+00:00",
		},
		"9034d54985807aaf3d7780f50f155f954daa468fb58d7b14b216fc79d68bbd14": {
			id: "9034d54985807aaf3d7780f50f155f954daa468fb58d7b14b216fc79d68bbd14",
			name: "Qubic Wallet",
			slug: "qubic-wallet",
			description: "More Than Just A Wallet",
			homepage: "https://wallet.qubic.app",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "535c91a5-a43c-4104-233c-439449ffcd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/535c91a5-a43c-4104-233c-439449ffcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/535c91a5-a43c-4104-233c-439449ffcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/535c91a5-a43c-4104-233c-439449ffcd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.qubic.app",
				ios: "https://apps.apple.com/app/qubic-%E6%9C%80%E7%B0%A1%E5%96%AE%E4%B8%8A%E6%89%8B%E7%9A%84%E8%99%9B%E6%93%AC%E8%B2%A8%E5%B9%A3%E9%8C%A2%E5%8C%85/id1563987988?itsct=apps_box_link&itscg=30200",
				android:
					"https://play.google.com/store/apps/details?id=app.qubic.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "qubic://",
				universal: "https://wallet.qubic.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Qubic",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-04-01T07:36:31.669933+00:00",
		},
		"86b9fa7c8101bf4c9a7968b0c56df37a64a2b6fb39e902173dce5db2e34fa9fd": {
			id: "86b9fa7c8101bf4c9a7968b0c56df37a64a2b6fb39e902173dce5db2e34fa9fd",
			name: "Haven Wallet",
			slug: "haven-wallet",
			description: "Multi-user Enterprise Wallet.",
			homepage: "https://havensuite.io",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "b41fc3f2-a874-45ae-4d4f-cdf47da89500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b41fc3f2-a874-45ae-4d4f-cdf47da89500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b41fc3f2-a874-45ae-4d4f-cdf47da89500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b41fc3f2-a874-45ae-4d4f-cdf47da89500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/sg/app/haven-wallet/id1634596545",
				android:
					"https://play.google.com/store/apps/details?id=io.wallet.haven",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Haven Wallet",
				colors: {
					primary: "#194AF9",
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:01:22.053712+00:00",
		},
		b83a346877b71c02b8531f53485ce12bc00033eabcc1213ca3329cbc744813a5: {
			id: "b83a346877b71c02b8531f53485ce12bc00033eabcc1213ca3329cbc744813a5",
			name: "Holdstation Wallet",
			slug: "holdstation-wallet",
			description:
				"Holdstation Wallet is a self-custodial wallet working with all EVM. Holdstation aims to be a secured wallet with the best user experience.",
			homepage: "https://www.holdstation.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "dba228fc-d0c9-497b-903e-843ad1076e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/dba228fc-d0c9-497b-903e-843ad1076e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/dba228fc-d0c9-497b-903e-843ad1076e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/dba228fc-d0c9-497b-903e-843ad1076e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/holdstation-web3-wallet/id6444925618",
				android: "https://play.google.com/store/apps/details?id=io.holdstation",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "holdstation://",
				universal:
					"https://staging-referral.holdstation.com/wallet-connect?uri=",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Holdstation Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-01-03T13:58:48.255619+00:00",
		},
		"53dd23581ff2ac3473a517c2995ad41cb214e105ebc99a122bda032051bb54c6": {
			id: "53dd23581ff2ac3473a517c2995ad41cb214e105ebc99a122bda032051bb54c6",
			name: "Earth Wallet",
			slug: "earth-wallet",
			description:
				"Earth Wallet is an open source, self-custody digital asset wallet for Internet Computer, Polygon, Bitcoin and Ethereum.",
			homepage: "https://www.earthwallet.io/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d3f724c4-f99b-476f-10f8-12aa4af13800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d3f724c4-f99b-476f-10f8-12aa4af13800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d3f724c4-f99b-476f-10f8-12aa4af13800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d3f724c4-f99b-476f-10f8-12aa4af13800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/earth-wallet/id1638414929",
				android:
					"https://play.google.com/store/apps/details?id=earth.wallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "earthwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Earth Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-08-19T07:23:46.820593+00:00",
		},
		"394631c96f2c3201ffd5b3f3f249028163a893ae2ff9076882350e70f3450cb0": {
			id: "394631c96f2c3201ffd5b3f3f249028163a893ae2ff9076882350e70f3450cb0",
			name: "MetaOne",
			slug: "metaone",
			description: "Explore Web3 & metaverses intuitively with MetaOne",
			homepage: "https://getmeta.one/",
			chains: ["eip155:1", "eip155:137", "eip155:1666600000", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b869d966-4699-44de-eadb-4eb39a580600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b869d966-4699-44de-eadb-4eb39a580600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b869d966-4699-44de-eadb-4eb39a580600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b869d966-4699-44de-eadb-4eb39a580600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://getmeta.one/",
				ios: "https://apps.apple.com/us/app/metaone-wallet/id1627212812",
				android:
					"https://play.google.com/store/apps/details?id=ventures.aag.metaone",
				mac: "https://apps.apple.com/lt/app/metaone-wallet/id1627212812",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "metaone://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MetaOne",
				colors: {
					primary: "#2000F0",
					secondary: "#7870FF",
				},
			},
			updatedAt: "2023-03-17T17:12:37.381584+00:00",
		},
		"6d1d5b892e02d4c992ae67f18f522398481360c64269f5cdf5e4b80435b20e3d": {
			id: "6d1d5b892e02d4c992ae67f18f522398481360c64269f5cdf5e4b80435b20e3d",
			name: "3S Wallet",
			slug: "3s-wallet",
			description: "Mobile crypto wallet",
			homepage: "https://3swallet.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f3b6a89d-ec8f-49dc-e07f-6bf723e1e500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f3b6a89d-ec8f-49dc-e07f-6bf723e1e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f3b6a89d-ec8f-49dc-e07f-6bf723e1e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f3b6a89d-ec8f-49dc-e07f-6bf723e1e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://3swallet.com/",
				ios: "https://apps.apple.com/us/app/3s-wallet-crypto-wallet/id1622316272",
				android:
					"https://play.google.com/store/apps/details?id=network.bho.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bhcwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "3S",
				colors: {
					primary: "#D10D79",
					secondary: "#FD8440",
				},
			},
			updatedAt: "2022-07-05T05:20:13+00:00",
		},
		dc5415b6ea8114db518ae91195b027d690a11a1d2bfdd1a904e93c5cb746380e: {
			id: "dc5415b6ea8114db518ae91195b027d690a11a1d2bfdd1a904e93c5cb746380e",
			name: "SimpleHold",
			slug: "simplehold",
			description:
				"SimpleHold is a non-custodial multicurrency wallet that empowers you to receive, send, exchange and store your cryptocurrencies.",
			homepage: "https://simplehold.io/",
			chains: [
				"cosmos:columbus-4",
				"cosmos:kava-4",
				"eip155:1",
				"eip155:137",
				"eip155:1666600003",
				"eip155:250",
				"eip155:361",
				"eip155:43114",
				"eip155:4689",
				"eip155:50",
				"eip155:56",
				"eip155:61",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a9f1ba96-b658-4d13-f71f-226b6389f000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a9f1ba96-b658-4d13-f71f-226b6389f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a9f1ba96-b658-4d13-f71f-226b6389f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a9f1ba96-b658-4d13-f71f-226b6389f000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.simplehold.io/",
				ios: "https://apps.apple.com/gb/app/simplehold-crypto-wallet/id1589064973",
				android:
					"https://play.google.com/store/apps/details?id=com.simplehold.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "simplehold://",
				universal: "https://simplehold.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SimpleHold",
				colors: {
					primary: "#3FBB7D",
					secondary: "#E9F5EE",
				},
			},
			updatedAt: "2022-03-29T10:09:12.847997+00:00",
		},
		d6fbaf5c2026e050920ed6e6ffbf96c8a6145b93a8b79d102def9653bedc9821: {
			id: "d6fbaf5c2026e050920ed6e6ffbf96c8a6145b93a8b79d102def9653bedc9821",
			name: "Payperless",
			slug: "payperless",
			description:
				"We believe everyone should have access to the benefits of Bitcoin and other crypto currencies, so we make it easy and safe. ",
			homepage: "https://payperless.com",
			chains: ["eip155:1", "eip155:137", "eip155:56", "eip155:61"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4a867e30-44c9-4627-6281-33457b8e2100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4a867e30-44c9-4627-6281-33457b8e2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4a867e30-44c9-4627-6281-33457b8e2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4a867e30-44c9-4627-6281-33457b8e2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://payperless.com",
				ios: "https://apps.apple.com/us/app/payperless-wallet/id1552741313",
				android:
					"https://play.google.com/store/apps/details?id=com.payperless.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Payperless",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-11T21:37:09.970168+00:00",
		},
		"49bb9d698dbdf2c3d4627d66f99dd9fe90bba1eec84b143f56c64a51473c60bd": {
			id: "49bb9d698dbdf2c3d4627d66f99dd9fe90bba1eec84b143f56c64a51473c60bd",
			name: "Minerva Wallet",
			slug: "minerva-wallet-1",
			description:
				"Minerva Wallet is a user friendly app for sovereign identities, data and money.",
			homepage: "https://minerva.digital/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:420",
				"eip155:42161",
				"eip155:42220",
				"eip155:43113",
				"eip155:43114",
				"eip155:44787",
				"eip155:5",
				"eip155:56",
				"eip155:62320",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b57b2163-1bd8-4f6b-3311-470767e6d200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b57b2163-1bd8-4f6b-3311-470767e6d200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b57b2163-1bd8-4f6b-3311-470767e6d200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b57b2163-1bd8-4f6b-3311-470767e6d200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=digital.minerva",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "minerva://",
				universal: "https://minerva.digital",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Minerva",
				colors: {
					primary: "#6141d2",
					secondary: "#252525",
				},
			},
			updatedAt: "2023-04-25T19:04:06.325416+00:00",
		},
		d9c7ec94218de654cabce846bb26e6ca0ed8495b4cea9d39def83ba377caab20: {
			id: "d9c7ec94218de654cabce846bb26e6ca0ed8495b4cea9d39def83ba377caab20",
			name: "Volt: DeFi",
			slug: "volt-defi",
			description: "Your new home for DeFi",
			homepage: "https://voltage.finance/app",
			chains: ["eip155:122"],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "51d783cb-0686-4ffa-e661-edca0c380000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/51d783cb-0686-4ffa-e661-edca0c380000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/51d783cb-0686-4ffa-e661-edca0c380000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/51d783cb-0686-4ffa-e661-edca0c380000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/volt-defi/id6444159237",
				android:
					"https://play.google.com/store/apps/details?id=finance.voltage.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "volt://",
				universal: "https://get.voltage.finance",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Volt: DeFi",
				colors: {
					primary: "#70E000",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2022-10-28T15:21:44.818016+00:00",
		},
		dc8ac638c6fd002950f9404dbb0639ae25bab667bf1a60a419bf8f44a89ed3a7: {
			id: "dc8ac638c6fd002950f9404dbb0639ae25bab667bf1a60a419bf8f44a89ed3a7",
			name: "Lif3 Wallet",
			slug: "lif3-wallet",
			description: "Explore the possibilities of DeFi with Lif3 Wallet.",
			homepage: "https://lif3.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:3",
				"eip155:338",
				"eip155:4",
				"eip155:42",
				"eip155:420",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:44787",
				"eip155:5",
				"eip155:5000",
				"eip155:5001",
				"eip155:56",
				"eip155:62320",
				"eip155:65",
				"eip155:66",
				"eip155:8453",
				"eip155:84531",
				"eip155:9000",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1a89c0ec-9059-4515-afb6-8204d49f0900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1a89c0ec-9059-4515-afb6-8204d49f0900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1a89c0ec-9059-4515-afb6-8204d49f0900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1a89c0ec-9059-4515-afb6-8204d49f0900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/lif3/id6444389674",
				android:
					"https://play.google.com/store/apps/details?id=com.lif3.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "lif3wallet://walletview",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Lif3",
				colors: {
					primary: "#2A4874",
					secondary: "#79A5C6",
				},
			},
			updatedAt: "2023-05-15T22:02:53.501609+00:00",
		},
		"51d2c02e306827d2d0f0dadaa00f22575c623aa19c403f09f4b9c42c67098bb1": {
			id: "51d2c02e306827d2d0f0dadaa00f22575c623aa19c403f09f4b9c42c67098bb1",
			name: "Shinobi-Wallet",
			slug: "shinobi-wallet-1",
			description: "Secure multi-chain crypto wallet and DeFi aggregator",
			homepage: "https://shinobi-wallet.net/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:3",
				"eip155:4",
				"eip155:420",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "685c986c-3e80-4701-cec6-cd247ba1a700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/685c986c-3e80-4701-cec6-cd247ba1a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/685c986c-3e80-4701-cec6-cd247ba1a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/685c986c-3e80-4701-cec6-cd247ba1a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/fgraph/id1572609905",
				android:
					"https://play.google.com/store/apps/details?id=io.fgraph.shinobiwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "shinobi-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Shinobi",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-10-28T15:34:16.898695+00:00",
		},
		"19418ecfd44963883e4d6abca1adeb2036f3b5ffb9bee0ec61f267a9641f878b": {
			id: "19418ecfd44963883e4d6abca1adeb2036f3b5ffb9bee0ec61f267a9641f878b",
			name: "KryptoGO Wallet",
			slug: "kryptogo-wallet",
			description:
				"The 1st decentralized DeFi Wallet with full compliance. Unlock DeFi earning in your hand.",
			homepage: "https://kryptogo.com/wallet",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:2020",
				"eip155:321",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4eb31988-f494-403c-6127-cfcef036ac00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4eb31988-f494-403c-6127-cfcef036ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4eb31988-f494-403c-6127-cfcef036ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4eb31988-f494-403c-6127-cfcef036ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://kryptogo.com/wallet",
				ios: "https://apps.apple.com/il/app/kryptogo/id1593830910",
				android:
					"https://play.google.com/store/apps/details?id=com.kryptogo.walletapp",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bgaihnkooadagpjddlcaleaopmkjadfl",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kryptogo://",
				universal: "https://kryptogo.page.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "KryptoGO",
				colors: {
					primary: "#FFC211",
					secondary: "#001F58",
				},
			},
			updatedAt: "2022-02-09T10:17:34.658999+00:00",
		},
		f593f4eb9755ff047681a37ebc46706e0e915cf1c2fe0102f5ae47c9f6aa4082: {
			id: "f593f4eb9755ff047681a37ebc46706e0e915cf1c2fe0102f5ae47c9f6aa4082",
			name: "Feral File",
			slug: "autonomy-digital-art-wallet",
			description: "Formerly Autonomy",
			homepage: "https://app.feralfile.com",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0fc3d016-d52a-4e84-2bb0-5d0a12964500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0fc3d016-d52a-4e84-2bb0-5d0a12964500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0fc3d016-d52a-4e84-2bb0-5d0a12964500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0fc3d016-d52a-4e84-2bb0-5d0a12964500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://autonomy.io",
				ios: "https://apps.apple.com/us/app/autonomy-app/id1544022728",
				android:
					"https://play.google.com/store/apps/details?id=com.bitmark.autonomy_client",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "autonomy-wc://",
				universal: "https://autonomy.io/apps/wc",
			},
			desktop: {
				native: "autonomy://",
				universal: null,
			},
			metadata: {
				shortName: "Feral File",
				colors: {
					primary: "#ECFF0C",
					secondary: null,
				},
			},
			updatedAt: "2022-11-14T21:03:08.04745+00:00",
		},
		"37a686ab6223cd42e2886ed6e5477fce100a4fb565dcd57ed4f81f7c12e93053": {
			id: "37a686ab6223cd42e2886ed6e5477fce100a4fb565dcd57ed4f81f7c12e93053",
			name: "Bifrost Wallet",
			slug: "bifrost-wallet",
			description:
				"A multi-chain wallet for decentralized finance and NFTs on Songbird, Flare, Ethereum and beyond.",
			homepage: "https://bifrostwallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:14",
				"eip155:16",
				"eip155:19",
				"eip155:250",
				"eip155:4002",
				"eip155:420",
				"eip155:42161",
				"eip155:421611",
				"eip155:5",
				"eip155:56",
				"eip155:69",
				"eip155:97",
				"xrpl:0",
				"xrpl:1",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "86be07e2-6652-4fd1-5f33-651682c95400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/86be07e2-6652-4fd1-5f33-651682c95400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/86be07e2-6652-4fd1-5f33-651682c95400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/86be07e2-6652-4fd1-5f33-651682c95400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/bifrost-wallet/id1577198351",
				android:
					"https://play.google.com/store/apps/details?id=com.bifrostwallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bifrostwallet://",
				universal: "https://app.bifrostwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bifrost Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-11-14T22:20:32.566806+00:00",
		},
		dccbd717df77b395445cc6080e01fffada9d8b92dacfda312a26c70c2e9af673: {
			id: "dccbd717df77b395445cc6080e01fffada9d8b92dacfda312a26c70c2e9af673",
			name: "Nufinetes",
			slug: "nufinetes",
			description: "Nufinetes - Multi-Chain Crypto Wallet",
			homepage: "https://www.nufinetes.com",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "4bb6c1ca-4196-4ba3-ece2-c3d335e1f800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4bb6c1ca-4196-4ba3-ece2-c3d335e1f800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4bb6c1ca-4196-4ba3-ece2-c3d335e1f800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4bb6c1ca-4196-4ba3-ece2-c3d335e1f800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.nufinetes.com",
				ios: "https://apps.apple.com/us/app/nufinetes/id1609562349",
				android:
					"https://play.google.com/store/apps/details?id=com.vimworld.wallet",
				mac: "https://apps.apple.com/us/app/nufinetes-desktop/id1629768725",
				windows:
					"https://d3va9f6jgm4z2y.cloudfront.net/nufinetes-prod/Nufinetes_Windows_latest.exe",
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "vimwallet://",
				universal: "https://apple.vimworld.org",
			},
			desktop: {
				native: "vimwallet://",
				universal: null,
			},
			metadata: {
				shortName: "nufinetes",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2022-11-14T20:26:42.796722+00:00",
		},
		"34c19e0afafeb86ffa75df1c04445b8840450217e79d30abc6def9aa537fb7d6": {
			id: "34c19e0afafeb86ffa75df1c04445b8840450217e79d30abc6def9aa537fb7d6",
			name: "Wallet 3",
			slug: "wallet-3",
			description:
				"Wallet 3 is a digital wallet designed specifically for Ethereum users.",
			homepage: "https://wallet3.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:1666600000",
				"eip155:2020",
				"eip155:25",
				"eip155:250",
				"eip155:28",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
				"eip155:8217",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "34ab7558-9e64-4436-f4e6-9069f2533d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/34ab7558-9e64-4436-f4e6-9069f2533d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/34ab7558-9e64-4436-f4e6-9069f2533d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/34ab7558-9e64-4436-f4e6-9069f2533d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet3.io",
				ios: "https://itunes.apple.com/us/app/wallet-3/id1597395741",
				android:
					"https://play.google.com/store/apps/details?id=jp.co.chainbow.wallet3.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wallet3://",
				universal: null,
			},
			desktop: {
				native: "wallet3://",
				universal: null,
			},
			metadata: {
				shortName: "Wallet3",
				colors: {
					primary: "#6186ff",
					secondary: null,
				},
			},
			updatedAt: "2022-06-28T16:11:07.559508+00:00",
		},
		c8c8f44329b9b826ded9a2ac330745f584a61aed6b1d0ed2a093b64bca7fc3bb: {
			id: "c8c8f44329b9b826ded9a2ac330745f584a61aed6b1d0ed2a093b64bca7fc3bb",
			name: "Abra Wallet",
			slug: "abra-wallet",
			description: "Abra DeFi Wallet",
			homepage: "https://abra.com",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2219db01-e0c9-471c-5def-fd3b4e7a7a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2219db01-e0c9-471c-5def-fd3b4e7a7a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2219db01-e0c9-471c-5def-fd3b4e7a7a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2219db01-e0c9-471c-5def-fd3b4e7a7a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://abra.com",
				ios: "https://abra.com",
				android: "https://abra.com",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "abra://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Abra ",
				colors: {
					primary: "#ffffff",
					secondary: "#4A148C",
				},
			},
			updatedAt: "2022-06-22T12:16:17.600628+00:00",
		},
		"2aca85b74f2fc6af554036e22e4f7f2eeada83023388087aee4488f5d9697973": {
			id: "2aca85b74f2fc6af554036e22e4f7f2eeada83023388087aee4488f5d9697973",
			name: "iMe",
			slug: "ime",
			description:
				"Intelligent DeFi & AI Platform Powered by Telegram API with non-custodial Crypto Wallet",
			homepage: "https://imem.app/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:3",
				"eip155:4002",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "25aa3abf-901b-4d82-bb89-c5ade54c0c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/25aa3abf-901b-4d82-bb89-c5ade54c0c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/25aa3abf-901b-4d82-bb89-c5ade54c0c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/25aa3abf-901b-4d82-bb89-c5ade54c0c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ime-messenger/id1450480822",
				android:
					"https://play.google.com/store/apps/details?id=com.iMe.android",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "iMe",
				colors: {
					primary: "#0154D6",
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:03:26.849872+00:00",
		},
		"5b8e33346dfb2a532748c247876db8d596734da8977905a27b947ba1e2cf465b": {
			id: "5b8e33346dfb2a532748c247876db8d596734da8977905a27b947ba1e2cf465b",
			name: "PREMA Wallet",
			slug: "prema-wallet",
			description:
				"PREMA offers a seamless multichain experience as a full-fledged Blockchain platform.",
			homepage: "https://premanft.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:128",
				"eip155:137",
				"eip155:1666600000",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:88",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6487869b-1165-4f30-aa3a-115665be8300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6487869b-1165-4f30-aa3a-115665be8300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6487869b-1165-4f30-aa3a-115665be8300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6487869b-1165-4f30-aa3a-115665be8300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://premanft.com/",
				ios: "https://apps.apple.com/us/app/prema%E3%82%A6%E3%82%A9%E3%83%AC%E3%83%83%E3%83%88-%E3%83%9E%E3%83%AB%E3%83%81%E3%83%81%E3%82%A7%E3%83%BC%E3%83%B3%E3%82%A2%E3%83%97%E3%83%AA/id1603556315",
				android:
					"https://play.google.com/store/apps/details?id=co.jp.xcreation.premawallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "premawallet://",
				universal: "https://premanft.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PREMA",
				colors: {
					primary: "#0e58a6",
					secondary: "#417bb7",
				},
			},
			updatedAt: "2022-09-26T15:56:05.392927+00:00",
		},
		"1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c": {
			id: "1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c",
			name: "OneKey",
			slug: "onekey",
			description:
				"Open source multi-chain crypto wallet runs on all platforms: iOS, Android, Windows, macOS, Linux, Chrome, Firefox... and more.",
			homepage: "https://onekey.so",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:128",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:137",
				"eip155:250",
				"eip155:256",
				"eip155:28",
				"eip155:3",
				"eip155:338",
				"eip155:4",
				"eip155:42161",
				"eip155:42220",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:6",
				"eip155:61",
				"eip155:62",
				"eip155:62320",
				"eip155:63",
				"eip155:65",
				"eip155:66",
				"eip155:69",
				"eip155:80001",
				"eip155:97",
				"near:mainnet",
				"near:testnet",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "12bebb3f-8030-4892-8452-c60a6bac1500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/12bebb3f-8030-4892-8452-c60a6bac1500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/12bebb3f-8030-4892-8452-c60a6bac1500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/12bebb3f-8030-4892-8452-c60a6bac1500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://onekey.so",
				ios: "https://apps.apple.com/us/app/onekey-open-source-wallet/id1609559473",
				android:
					"https://play.google.com/store/apps/details?id=so.onekey.app.wallet&hl=en_US&gl=US",
				mac: "https://github.com/OneKeyHQ/app-monorepo/releases",
				windows: "https://github.com/OneKeyHQ/app-monorepo/releases",
				linux: "https://github.com/OneKeyHQ/app-monorepo/releases",
				chrome:
					"https://chrome.google.com/webstore/detail/onekey/jnmbobjmhlngoefaiojfljckilhhlhcj",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "window.$onekey.ethereum.isOneKey",
				},
			],
			rdns: "so.onekey.wallet",
			mobile: {
				native: "onekey-wallet://",
				universal: "https://app.onekey.so/wc/connect",
			},
			desktop: {
				native: "onekey-wallet://",
				universal: "https://app.onekey.so/wc/connect",
			},
			metadata: {
				shortName: "OneKey",
				colors: {
					primary: "#00B812",
					secondary: null,
				},
			},
			updatedAt: "2022-09-26T18:20:17.786466+00:00",
		},
		d23de318f0f56038c5edb730a083216ff0cce00c1514e619ab32231cc9ec484b: {
			id: "d23de318f0f56038c5edb730a083216ff0cce00c1514e619ab32231cc9ec484b",
			name: "Slingshot Wallet",
			slug: "slingshot-wallet",
			description:
				"Slingshot Wallet is a self-custody defi wallet designed for both experienced crypto traders and web3 newcomers. ",
			homepage: "https://slingshot.finance/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "10c75467-6612-48ad-b97b-63985e922200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/10c75467-6612-48ad-b97b-63985e922200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/10c75467-6612-48ad-b97b-63985e922200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/10c75467-6612-48ad-b97b-63985e922200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1633406472",
				android:
					"https://play.google.com/store/apps/details?id=com.slingshot.finance",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "slingshot://",
				universal: "https://app.slingshot.finance",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Slingshot",
				colors: {
					primary: "#000000",
					secondary: "#000000",
				},
			},
			updatedAt: "2023-03-31T16:31:04.702998+00:00",
		},
		"50df7da345f84e5a79aaf617df5167335a4b6751626df2e8a38f07029b3dde7b": {
			id: "50df7da345f84e5a79aaf617df5167335a4b6751626df2e8a38f07029b3dde7b",
			name: "Kriptonio",
			slug: "kriptonio",
			description: "All-in-One Web3 Platform",
			homepage: "https://kriptonio.com",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "363fae03-882a-4d81-a721-6e6f6e9ac500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/363fae03-882a-4d81-a721-6e6f6e9ac500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/363fae03-882a-4d81-a721-6e6f6e9ac500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/363fae03-882a-4d81-a721-6e6f6e9ac500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://kriptonio.com",
				ios: "https://apps.apple.com/hr/app/kriptonio/id6444807361",
				android:
					"https://play.google.com/store/apps/details?id=com.kriptonio.mobile.android",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kriptonio://",
				universal: "https://app.kriptonio.com/mobile",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kriptonio",
				colors: {
					primary: "#4881C9",
					secondary: null,
				},
			},
			updatedAt: "2023-03-24T17:06:51.637568+00:00",
		},
		"9751385960bca290c13b443155288f892f62ee920337eda8c5a8874135daaea8": {
			id: "9751385960bca290c13b443155288f892f62ee920337eda8c5a8874135daaea8",
			name: "Timeless Wallet",
			slug: "timeless-wallet",
			description: "Web3 made simple. secure. social.",
			homepage: "https://timelesswallet.xyz",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:1666600000",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "32e89601-0490-42fc-0cc4-8627d62a2000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/32e89601-0490-42fc-0cc4-8627d62a2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/32e89601-0490-42fc-0cc4-8627d62a2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/32e89601-0490-42fc-0cc4-8627d62a2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/timeless-wallet/id1592807339",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "timeless-wallet://",
				universal: "https://timelesswallet.xyz",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Timeless",
				colors: {
					primary: "#2D3A66",
					secondary: "#B35F8D",
				},
			},
			updatedAt: "2023-03-18T23:17:36.084213+00:00",
		},
		a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c: {
			id: "a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c",
			name: "Venly",
			slug: "venly",
			description:
				"Onboard users in seconds! Venly Wallet allows your users to interact with Web3 familiarly, while we take care of the security & complexity.",
			homepage: "https://www.venly.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:31337",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:60",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["2"],
			sdks: ["sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "d8c846d0-5164-4520-d10f-e1c27d69ce00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d8c846d0-5164-4520-d10f-e1c27d69ce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d8c846d0-5164-4520-d10f-e1c27d69ce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d8c846d0-5164-4520-d10f-e1c27d69ce00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.venly.io",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://walletconnect.venly.io",
			},
			metadata: {
				shortName: "Venly",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-04-06T13:39:27.600705+00:00",
		},
		a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393: {
			id: "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393",
			name: "Phantom",
			slug: "phantom",
			description:
				"Phantom makes it safe & easy for you to store, buy, send, receive, swap tokens and collect NFTs on the Solana blockchain.",
			homepage: "https://phantom.app/",
			chains: [
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "c38443bb-b3c1-4697-e569-408de3fcc100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c38443bb-b3c1-4697-e569-408de3fcc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c38443bb-b3c1-4697-e569-408de3fcc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c38443bb-b3c1-4697-e569-408de3fcc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/phantom-solana-wallet/1598432977",
				android: "https://play.google.com/store/apps/details?id=app.phantom",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa",
				firefox: "https://addons.mozilla.org/en-US/firefox/addon/phantom-app/",
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isPhantom",
				},
				{
					namespace: "solana",
					injected_id: "isPhantom",
				},
			],
			rdns: "app.phantom",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Phantom",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-04-12T07:26:09.06459+00:00",
		},
		fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa: {
			id: "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
			name: "Coinbase Wallet",
			slug: "coinbase-wallet",
			description: "Your key to the world of crypto",
			homepage: "https://www.coinbase.com/wallet/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a5ebc364-8f91-4200-fcc6-be81310a0000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a5ebc364-8f91-4200-fcc6-be81310a0000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a5ebc364-8f91-4200-fcc6-be81310a0000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a5ebc364-8f91-4200-fcc6-be81310a0000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1278383455",
				android: "https://play.google.com/store/apps/details?id=org.toshi",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isCoinbaseWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "cbwallet://wsegue",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Coinbase",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-04-13T12:50:44.958376+00:00",
		},
		"3b9f67c2c0887f71e4f9ba1bd2bf5b4eb6cda94419abd3f0c5c12931a60928b0": {
			id: "3b9f67c2c0887f71e4f9ba1bd2bf5b4eb6cda94419abd3f0c5c12931a60928b0",
			name: "Bitski",
			slug: "bitski",
			description:
				"The wallet for everyone - The convenience of a hot wallet; The security of a cold wallet.",
			homepage: "https://bitski.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "94d94cb5-a94f-47cf-70e6-fe8d3f1c3700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/94d94cb5-a94f-47cf-70e6-fe8d3f1c3700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/94d94cb5-a94f-47cf-70e6-fe8d3f1c3700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/94d94cb5-a94f-47cf-70e6-fe8d3f1c3700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.bitski.com",
				ios: "https://apps.apple.com/us/app/bitski-wallet/id1587199538",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bitski/feejiigddaafeojfddjjlmfkabimkell",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBitski",
				},
			],
			rdns: "com.brave.wallet",
			mobile: {
				native: "bitski://",
				universal: "https://wallet.bitski.com/walletconnect/wc",
			},
			desktop: {
				native: "",
				universal:
					"https://chrome.google.com/webstore/detail/bitski/feejiigddaafeojfddjjlmfkabimkell",
			},
			metadata: {
				shortName: "Bitski",
				colors: {
					primary: "#FF245A",
					secondary: null,
				},
			},
			updatedAt: "2022-02-04T11:19:28.932201+00:00",
		},
		"51a26fedf6e6c8c48187c66a66297647b046dbf03b2a08d8150f8acb31498488": {
			id: "51a26fedf6e6c8c48187c66a66297647b046dbf03b2a08d8150f8acb31498488",
			name: "MPCWallet",
			slug: "mpcwallet",
			description: "Metaverse-Ready Wallet",
			homepage: "https://www.mpcwallet.xyz/",
			chains: [
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:128",
				"eip155:137",
				"eip155:210309",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:61",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "636ff7d4-79ce-41d6-ede5-85c9f8a1d900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/636ff7d4-79ce-41d6-ede5-85c9f8a1d900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/636ff7d4-79ce-41d6-ede5-85c9f8a1d900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/636ff7d4-79ce-41d6-ede5-85c9f8a1d900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/mindtrust/id6443522988",
				android:
					"https://play.google.com/store/apps/details?id=xyz.mpcwallet.pro&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MPCWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:05:20.573189+00:00",
		},
		f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f: {
			id: "f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f",
			name: "XDEFI Wallet",
			slug: "xdefi-wallet",
			description:
				"XDEFI is a multichain wallet that allows you to securely store, swap, and send Crypto and NFTs across 17 blockchains.",
			homepage: "https://www.xdefi.io/",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:10000",
				"eip155:1313161554",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"near:mainnet",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "efec6318-7f96-4b30-9287-6c287660cd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/efec6318-7f96-4b30-9287-6c287660cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/efec6318-7f96-4b30-9287-6c287660cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/efec6318-7f96-4b30-9287-6c287660cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.xdefi.io/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf?hl=en",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "__XDEFI",
				},
			],
			rdns: "io.xdefi",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "XDEFI Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-02-26T11:14:51.27556+00:00",
		},
		"8c833aef87349fe7c187318e60db600a1f81035fdda18372ebda1f039ea02733": {
			id: "8c833aef87349fe7c187318e60db600a1f81035fdda18372ebda1f039ea02733",
			name: "TREASURE",
			slug: "treasure",
			description:
				"TREASURE WALLET Is Your Gateway To Crypto World To Earning Money From Everything Like DeFi, DAO, NFTs, Tasks, And More.",
			homepage: "https://treasurewallet.co/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:128",
				"eip155:137",
				"eip155:200",
				"eip155:25",
				"eip155:250",
				"eip155:4",
				"eip155:42220",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6b5d45f6-117c-44a0-d7b0-71c28864a100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6b5d45f6-117c-44a0-d7b0-71c28864a100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6b5d45f6-117c-44a0-d7b0-71c28864a100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6b5d45f6-117c-44a0-d7b0-71c28864a100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.crypto.treasure",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "TREASURE",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:05:49.540057+00:00",
		},
		"5a2b2b6e41df46ea80709c11b4f902d31271f01f660f1c892102107fbc2bf88c": {
			id: "5a2b2b6e41df46ea80709c11b4f902d31271f01f660f1c892102107fbc2bf88c",
			name: "Streakk Wallet",
			slug: "streakk-wallet",
			description: "Streakk unlocks the potential of your cryptocurrencies.",
			homepage: "https://streakk.io/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "45ec6eb9-d7fe-4b9b-6dbf-cc675c5d1d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/45ec6eb9-d7fe-4b9b-6dbf-cc675c5d1d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/45ec6eb9-d7fe-4b9b-6dbf-cc675c5d1d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/45ec6eb9-d7fe-4b9b-6dbf-cc675c5d1d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/in/app/streakk-wallet/id1636671089",
				android: "https://play.google.com/store/apps/details?id=com.streakk",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "streakk://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Streakk Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:10:29.064732+00:00",
		},
		"765d15cde3a02e088fbc712cabd527ec7d8efc32c5177fd47337760b1a5e7ec7": {
			id: "765d15cde3a02e088fbc712cabd527ec7d8efc32c5177fd47337760b1a5e7ec7",
			name: "Sender",
			slug: "sender",
			description:
				"Sender is a web3 wallet that is compatible with Ethereum and NEAR, allowing you to control your cryptocurrency, NFTs, DeFi activities.",
			homepage: "https://sender.org/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"near:mainnet",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6fb46282-3d15-4c8a-41ae-0d52115e3f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6fb46282-3d15-4c8a-41ae-0d52115e3f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6fb46282-3d15-4c8a-41ae-0d52115e3f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6fb46282-3d15-4c8a-41ae-0d52115e3f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/sender-wallet/id1637821762",
				android:
					"https://play.google.com/store/apps/details?id=com.sender_wallet_mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/sender-wallet/epapihdplajcdnnkdeiahlgigofloibg?utm_source=chrome-ntp-icon",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Sender",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:12:02.454713+00:00",
		},
		"5a32122dd183770b1253d8e3bb0954ced0be9f2dfd9654fe773cc80be79a57ca": {
			id: "5a32122dd183770b1253d8e3bb0954ced0be9f2dfd9654fe773cc80be79a57ca",
			name: "SaitaPro",
			slug: "saitapro",
			description:
				"SaitaPro is here to make crypto and decentralized finance simple and safe for you. Buying crypto, trading assets, staking .",
			homepage: "https://www.saitamatoken.com/saitapro/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "65bdc812-5692-441f-abcb-a389b754a700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/65bdc812-5692-441f-abcb-a389b754a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/65bdc812-5692-441f-abcb-a389b754a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/65bdc812-5692-441f-abcb-a389b754a700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/saitapro/id1636523777",
				android:
					"https://play.google.com/store/apps/details?id=com.saitapro&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "SaitaPro://app",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SaitaPro",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-05-15T22:12:30.050155+00:00",
		},
		e6a8cb6abe47f88d7511b0698829eab01d76e78bad82b8ccca66004055055d89: {
			id: "e6a8cb6abe47f88d7511b0698829eab01d76e78bad82b8ccca66004055055d89",
			name: "Flow Wallet",
			slug: "lilico",
			description:
				"Flow Wallet is the non-custodial wallet on Flow blockchain.  It enables you to access Web 3.0, NFTs, tokens and dApps.",
			homepage: "https://core.flow.com/",
			chains: ["flow:mainnet", "flow:testnet"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f67a1db8-5704-4353-ead8-bd85c02a8700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f67a1db8-5704-4353-ead8-bd85c02a8700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f67a1db8-5704-4353-ead8-bd85c02a8700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f67a1db8-5704-4353-ead8-bd85c02a8700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/lilico/id1644169603",
				android:
					"https://play.google.com/store/apps/details?id=io.outblock.lilico",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/flow-reference/hpclkefagolihohboafpheddmmgdffjm",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "frw://",
				universal: "https://frw-link.lilico.app/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Flow Wallet",
				colors: {
					primary: "#41CC5D",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-01-12T07:42:11.177572+00:00",
		},
		"664b505fea4c2117b8a55c054ef209664e0a68ddaafd7534df739f97a293fa1d": {
			id: "664b505fea4c2117b8a55c054ef209664e0a68ddaafd7534df739f97a293fa1d",
			name: "Hippo Wallet",
			slug: "hippo-wallet",
			description: " Hippo: Crypto & Bitcoin Wallet",
			homepage: "https://hippowallet.io",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f9570968-45f7-47c1-3189-98cf60e25c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f9570968-45f7-47c1-3189-98cf60e25c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f9570968-45f7-47c1-3189-98cf60e25c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f9570968-45f7-47c1-3189-98cf60e25c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ae/app/hippo-wallet/id1613041499",
				android:
					"https://play.google.com/store/apps/details?id=com.blockchaincommodities.hippo_wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/hippo-wallet/hddkffjleepiafmkhcneldjipkfkkofk",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "hippowallet://",
				universal: "https://hippowallet.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "HippoWallet",
				colors: {
					primary: "#347362",
					secondary: "#EDCB50",
				},
			},
			updatedAt: "2023-02-23T09:51:17.430916+00:00",
		},
		feb6ff1fb426db18110f5a80c7adbde846d0a7e96b2bc53af4b73aaf32552bea: {
			id: "feb6ff1fb426db18110f5a80c7adbde846d0a7e96b2bc53af4b73aaf32552bea",
			name: "Cosmostation",
			slug: "cosmostation",
			description: "Wallet For Cosmos Ecosystem",
			homepage: "https://www.cosmostation.io/",
			chains: [
				"cosmos:cosmoshub-4",
				"cosmos:irishub-1",
				"cosmos:kava-4",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ea26c3c8-adb6-4dc4-ee02-35d6eee02800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ea26c3c8-adb6-4dc4-ee02-35d6eee02800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ea26c3c8-adb6-4dc4-ee02-35d6eee02800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ea26c3c8-adb6-4dc4-ee02-35d6eee02800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/cosmostation/id1459830339",
				android:
					"https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "cosmostation://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cosmostation",
				colors: {
					primary: "#9c6cff",
					secondary: "#372758",
				},
			},
			updatedAt: "2022-02-07T10:47:38.150626+00:00",
		},
		"41f20106359ff63cf732adf1f7dc1a157176c9b02fd266b50da6dcc1e9b86071": {
			id: "41f20106359ff63cf732adf1f7dc1a157176c9b02fd266b50da6dcc1e9b86071",
			name: "Bitizen",
			slug: "bitizen",
			description: "Crypto/Web3 Wallet",
			homepage: "https://bitizen.org/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1001",
				"eip155:1007",
				"eip155:101",
				"eip155:1010",
				"eip155:1012",
				"eip155:102",
				"eip155:1022",
				"eip155:1023",
				"eip155:1024",
				"eip155:1028",
				"eip155:106",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:111",
				"eip155:1139",
				"eip155:12",
				"eip155:122",
				"eip155:124",
				"eip155:127",
				"eip155:128",
				"eip155:1284",
				"eip155:1285",
				"eip155:13",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:15",
				"eip155:16",
				"eip155:162",
				"eip155:163",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:1666700000",
				"eip155:1666700001",
				"eip155:1666700002",
				"eip155:1666700003",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:18",
				"eip155:186",
				"eip155:19",
				"eip155:199",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:21",
				"eip155:210309",
				"eip155:211",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:246",
				"eip155:25",
				"eip155:250",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:28",
				"eip155:288",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:33",
				"eip155:336",
				"eip155:338",
				"eip155:35",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:369",
				"eip155:38",
				"eip155:385",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:41",
				"eip155:42",
				"eip155:420",
				"eip155:42161",
				"eip155:421611",
				"eip155:42220",
				"eip155:43",
				"eip155:43113",
				"eip155:43114",
				"eip155:44",
				"eip155:44787",
				"eip155:499",
				"eip155:5",
				"eip155:50",
				"eip155:51",
				"eip155:52",
				"eip155:53",
				"eip155:55",
				"eip155:558",
				"eip155:56",
				"eip155:58",
				"eip155:59",
				"eip155:595",
				"eip155:6",
				"eip155:60",
				"eip155:61",
				"eip155:62",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:69",
				"eip155:7",
				"eip155:721",
				"eip155:76",
				"eip155:77",
				"eip155:777",
				"eip155:78",
				"eip155:787",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:803",
				"eip155:82",
				"eip155:820",
				"eip155:821",
				"eip155:8217",
				"eip155:83",
				"eip155:85",
				"eip155:86",
				"eip155:880",
				"eip155:9",
				"eip155:940",
				"eip155:95",
				"eip155:97",
				"eip155:977",
				"eip155:99",
				"eip155:998",
				"eip155:999",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "75dd1471-77e9-4811-ce57-ec8fc980ec00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/75dd1471-77e9-4811-ce57-ec8fc980ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/75dd1471-77e9-4811-ce57-ec8fc980ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/75dd1471-77e9-4811-ce57-ec8fc980ec00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/bitizen-defi-web3-eth-wallet/id1598283542",
				android:
					"https://play.google.com/store/apps/details?id=org.bitizen.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://bitizen.org/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isBitizen",
				},
			],
			rdns: null,
			mobile: {
				native: "bitizen://wallet/",
				universal: "https://bitizen.org/wallet/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitizen",
				colors: {
					primary: "#00B78C",
					secondary: null,
				},
			},
			updatedAt: "2022-08-05T12:55:47.193086+00:00",
		},
		"14e7176536cb3706e221daaa3cfd7b88b7da8c7dfb64d1d241044164802c6bdd": {
			id: "14e7176536cb3706e221daaa3cfd7b88b7da8c7dfb64d1d241044164802c6bdd",
			name: "Blocto",
			slug: "blocto",
			description: "Aims to make the Web3 community more accessible",
			homepage: "https://blocto.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:420",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "374258d3-c749-4f37-7815-77e61f798c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/374258d3-c749-4f37-7815-77e61f798c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/374258d3-c749-4f37-7815-77e61f798c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/374258d3-c749-4f37-7815-77e61f798c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1481181682",
				android:
					"https://play.google.com/store/apps/details?id=com.portto.blocto&hl=en&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "blocto://",
				universal: "https://blocto.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Blocto",
				colors: {
					primary: "#0075FF",
					secondary: null,
				},
			},
			updatedAt: "2023-03-16T12:51:10.964276+00:00",
		},
		a92d512c649e87a5acba5885ac03f62662cff1f647c20a63833eb45a71a6f877: {
			id: "a92d512c649e87a5acba5885ac03f62662cff1f647c20a63833eb45a71a6f877",
			name: "HUMBL WALLET",
			slug: "humbl-wallet",
			description:
				"The HUMBL Wallet allows you to buy, sell, receive, store and exchange digital assets such as ETH, BLOCKS, USDC.",
			homepage: "https://www.humbl.com",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1ac55ba2-aa98-4ed0-59b3-b3155dea4200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1ac55ba2-aa98-4ed0-59b3-b3155dea4200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1ac55ba2-aa98-4ed0-59b3-b3155dea4200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1ac55ba2-aa98-4ed0-59b3-b3155dea4200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/humbl-wallet/id1627171234",
				android:
					"https://play.google.com/store/apps/details?id=com.humbl.wallet.app&hl=en_US&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "humblwallet://",
				universal: "https://wallet.search3.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Humbl Wallet",
				colors: {
					primary: "#24ace4",
					secondary: null,
				},
			},
			updatedAt: "2022-09-08T19:30:14.810603+00:00",
		},
		a0e04f1086aac204d4ebdd5f985c12ed226cd0006323fd8143715f9324da58d1: {
			id: "a0e04f1086aac204d4ebdd5f985c12ed226cd0006323fd8143715f9324da58d1",
			name: "SafeMoon",
			slug: "safemoon-1",
			description:
				"A human-focused technology and innovation business expanding blockchain technologies for a brighter tomorrow.",
			homepage: "https://safemoon.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ea0140c7-787c-43a4-838f-d5ab6a342000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ea0140c7-787c-43a4-838f-d5ab6a342000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ea0140c7-787c-43a4-838f-d5ab6a342000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ea0140c7-787c-43a4-838f-d5ab6a342000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/safemoon/id1579735495",
				android:
					"https://play.google.com/store/apps/details?id=net.safemoon.androidwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "safemoon://",
				universal: "https://safemoon.com/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SafeMoon",
				colors: {
					primary: "#00a79d",
					secondary: null,
				},
			},
			updatedAt: "2023-03-24T14:07:42.366874+00:00",
		},
		"0769b03b40fa93ff2cca28cf68582b3554cf10e3f4608e6c81b3089b2a3fcf01": {
			id: "0769b03b40fa93ff2cca28cf68582b3554cf10e3f4608e6c81b3089b2a3fcf01",
			name: "PassPay Wallet",
			slug: "passpay-wallet",
			description:
				"PassPay allows users to manage, send, and receive crypto assets, and is used in a variety of situations in a service called web3.",
			homepage: "https://www.passpay.io/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a1c337f5-c156-4ce8-763b-b4cc65f1c200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a1c337f5-c156-4ce8-763b-b4cc65f1c200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a1c337f5-c156-4ce8-763b-b4cc65f1c200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a1c337f5-c156-4ce8-763b-b4cc65f1c200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/US/app/passpay-wallet-nft-%E4%BB%AE%E6%83%B3%E9%80%9A%E8%B2%A8-%E3%82%A6%E3%82%A9%E3%83%AC%E3%83%83%E3%83%88/id1645009398",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "com.wallet.passpay://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "passpay",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-20T11:10:01.785662+00:00",
		},
		"09d3f710148d94993ca9f3ed095594d7cc90ba46137dd803a8904b6dbb4bd89c": {
			id: "09d3f710148d94993ca9f3ed095594d7cc90ba46137dd803a8904b6dbb4bd89c",
			name: "Ultimate",
			slug: "ultimate",
			description: "Crypto Trading & DeFi Wallet",
			homepage: "https://ultimate.app/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1ed9823d-64dd-4ab6-2f3f-22c8ff228f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1ed9823d-64dd-4ab6-2f3f-22c8ff228f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1ed9823d-64dd-4ab6-2f3f-22c8ff228f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1ed9823d-64dd-4ab6-2f3f-22c8ff228f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1629053410",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ultimate://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ultimate",
				colors: {
					primary: "#6153FC",
					secondary: null,
				},
			},
			updatedAt: "2023-06-21T12:25:30.269822+00:00",
		},
		"7e6bb17d0f776c0449f5e79f8df3b34e7be388c9c527befc4ba92ef8cbe55c44": {
			id: "7e6bb17d0f776c0449f5e79f8df3b34e7be388c9c527befc4ba92ef8cbe55c44",
			name: "Me Wallet",
			slug: "me-wallet",
			description: "A multi-chain smart contract wallet",
			homepage: "https://astrox.me/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:420",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:59144",
				"eip155:80001",
				"eip155:8453",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "e9666b15-4296-4384-3661-7e99a5f2a900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e9666b15-4296-4384-3661-7e99a5f2a900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e9666b15-4296-4384-3661-7e99a5f2a900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e9666b15-4296-4384-3661-7e99a5f2a900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.astrox.me/",
				ios: "https://apps.apple.com/us/app/astrox-me-wallet/id1634031707",
				android: "https://play.google.com/store/apps/details?id=com.astrox.me",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "astrox://me/wcx",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ME Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-21T12:27:24.327071+00:00",
		},
		"19fcea5bdddbf5abbb9ecdcb53acf6fb17da23ab0984ee1dbe487688d8d4ffe7": {
			id: "19fcea5bdddbf5abbb9ecdcb53acf6fb17da23ab0984ee1dbe487688d8d4ffe7",
			name: "THORWallet",
			slug: "thorwallet-1",
			description:
				"Non-custodial wallet, free VISA card, and complementary Swiss bank account for secure crypto management and cross-chain transactions.",
			homepage: "https://www.thorwallet.org/",
			chains: [
				"cosmos:mayachain-mainnet-v1",
				"cosmos:thorchain-mainnet-v1",
				"eip155:1",
				"eip155:10000",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:324",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "45165bea-fdae-454e-7caa-31681f255200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/45165bea-fdae-454e-7caa-31681f255200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/45165bea-fdae-454e-7caa-31681f255200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/45165bea-fdae-454e-7caa-31681f255200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ch/app/thorwallet-defi-wallet/id1592064324",
				android:
					"https://play.google.com/store/apps/details?id=defisuisse.thorwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "thorwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "THORWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-22T07:30:31.857279+00:00",
		},
		"50fa9539a59e22890d7cb2184285618ea424ed8f6699ede7e19450ceb7e878fa": {
			id: "50fa9539a59e22890d7cb2184285618ea424ed8f6699ede7e19450ceb7e878fa",
			name: "Fizz",
			slug: "fizz",
			description: "Self-Custodial Wallet",
			homepage: "https://www.fizzwallet.app/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f9d4db84-2e9f-4fbe-684f-c1e921c98800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f9d4db84-2e9f-4fbe-684f-c1e921c98800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f9d4db84-2e9f-4fbe-684f-c1e921c98800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f9d4db84-2e9f-4fbe-684f-c1e921c98800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/fizz-fun-ezzy-crypto-wallet/id6447460538",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fizz://wallet-connect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Fizz",
				colors: {
					primary: "#8151ff",
					secondary: "#00a1ff",
				},
			},
			updatedAt: "2023-06-22T08:09:25.391896+00:00",
		},
		"36cb321d10f24a11cdc9df6ae2b059448a24b417d846e1bae526f2e79c003af7": {
			id: "36cb321d10f24a11cdc9df6ae2b059448a24b417d846e1bae526f2e79c003af7",
			name: "PiEthereum Hardware",
			slug: "piethereum-hardware",
			description:
				"raspberryPi Ethereum Open Source Hardware: ID + Wallet for Privacy and Autonomy",
			homepage: "https://nftydaze.com/opensource/",
			chains: ["eip155:1", "eip155:137", "eip155:42161"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "310a5036-3c8f-4bfc-0510-cba61d7d5100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/310a5036-3c8f-4bfc-0510-cba61d7d5100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/310a5036-3c8f-4bfc-0510-cba61d7d5100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/310a5036-3c8f-4bfc-0510-cba61d7d5100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: "https://github.com/snarflakes/PiEthereumWallet",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PiEthereumHardware",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-23T14:26:00.407616+00:00",
		},
		"84ec277981a993e80d4c02960e3ba8e89027b6848ec304dc7de48651e4bd353a": {
			id: "84ec277981a993e80d4c02960e3ba8e89027b6848ec304dc7de48651e4bd353a",
			name: "Reunit",
			slug: "reunit",
			description:
				"Reunit is the first omnichain wallet built on top of LayerZero",
			homepage: "https://everywhere.finance",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "98ed357f-1e2d-4679-0e78-1100f7594000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/98ed357f-1e2d-4679-0e78-1100f7594000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/98ed357f-1e2d-4679-0e78-1100f7594000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/98ed357f-1e2d-4679-0e78-1100f7594000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/reunit/nlcccgcedoleehdicpnlnjeccnlfkemh",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "reunitWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Reunit",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-26T12:07:34.696967+00:00",
		},
		dbb64ad8f55b4ed333d909a6d53670cf97d136c22eb1dca800c539ecea165a53: {
			id: "dbb64ad8f55b4ed333d909a6d53670cf97d136c22eb1dca800c539ecea165a53",
			name: "Arianee Wallet",
			slug: "arianee-wallet-1",
			description: "Welcome to the future of ownership !",
			homepage: "https://arianee.org",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:77",
				"eip155:80001",
				"eip155:99",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "13b7fe36-909a-4c83-4f06-5740829a3900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/13b7fe36-909a-4c83-4f06-5740829a3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/13b7fe36-909a-4c83-4f06-5740829a3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/13b7fe36-909a-4c83-4f06-5740829a3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/fr/app/arianee-wallet/id1435782507",
				android:
					"https://play.google.com/store/apps/details?id=com.arianee.wallet&hl=ln&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "com.arianee.wallet://",
				universal: "https://i.arian.ee",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Arianee Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-27T12:17:05.689306+00:00",
		},
		"21af5c7a9c01793077b61aecbb4bb5648e9be62a6a0a42c5f4d2ff05d4e00d5c": {
			id: "21af5c7a9c01793077b61aecbb4bb5648e9be62a6a0a42c5f4d2ff05d4e00d5c",
			name: "Tholos",
			slug: "tholos",
			description:
				"A crypto wallet secured by multiple owners to view assets, transact, and interact with applications on any blockchain.",
			homepage: "https://tholos.app",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "f0f306e6-2dba-4805-e7b9-4f25952e2900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f0f306e6-2dba-4805-e7b9-4f25952e2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f0f306e6-2dba-4805-e7b9-4f25952e2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f0f306e6-2dba-4805-e7b9-4f25952e2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://beta.tholos.app",
				ios: "https://apps.apple.com/app/tholos/id1669578487",
				android:
					"https://play.google.com/store/apps/details?id=app.tholos.tholos",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Tholos",
				colors: {
					primary: "#135fff",
					secondary: "#fafafa",
				},
			},
			updatedAt: "2023-06-27T18:00:09.747606+00:00",
		},
		"71a83ffbaa7fa38419e8f548a6499c78c8d429163631cf483483fc7efc6b7aae": {
			id: "71a83ffbaa7fa38419e8f548a6499c78c8d429163631cf483483fc7efc6b7aae",
			name: "Stickey Wallet",
			slug: "stickey-wallet",
			description:
				"Easy to use for everyone! Everyone gathers! Community-focused wallet",
			homepage: "https://stickey.app",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:321",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "12aab9fb-f3d4-4248-10e0-4eda17a5de00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/12aab9fb-f3d4-4248-10e0-4eda17a5de00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/12aab9fb-f3d4-4248-10e0-4eda17a5de00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/12aab9fb-f3d4-4248-10e0-4eda17a5de00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://stickey.app",
				ios: "https://apps.apple.com/app/id1671113083",
				android: "https://play.google.com/store/apps/details?id=app.stickey",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "stickyapp://",
				universal: "https://stickeyapp.page.link",
			},
			desktop: {
				native: "stickyapp://",
				universal: "https://stickeyapp.page.link",
			},
			metadata: {
				shortName: "Stickey",
				colors: {
					primary: "#1973F6",
					secondary: null,
				},
			},
			updatedAt: "2023-06-27T22:48:46.079199+00:00",
		},
		"3c2c985c0adff6f46a0d0e466b3924ed8a059043882cd1944ad7f2adf697ed54": {
			id: "3c2c985c0adff6f46a0d0e466b3924ed8a059043882cd1944ad7f2adf697ed54",
			name: "Klip",
			slug: "klip",
			description: "Klip for WalletConnect",
			homepage: "https://klipwallet.com/",
			chains: ["eip155:1", "eip155:137", "eip155:8217"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f7b6b2a6-ebe7-4779-6ad1-79a3142e6b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f7b6b2a6-ebe7-4779-6ad1-79a3142e6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f7b6b2a6-ebe7-4779-6ad1-79a3142e6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f7b6b2a6-ebe7-4779-6ad1-79a3142e6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/클립-klip/id1627665524",
				android:
					"https://play.google.com/store/apps/details?id=com.klipwallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "klipwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Klip",
				colors: {
					primary: "#2D6AFF",
					secondary: "#EAF1FF",
				},
			},
			updatedAt: "2023-06-28T07:34:29.762063+00:00",
		},
		bcaec16e531fb5f6dc690d7b70d570421e0209af9a0fe77c6419d516fe0098c2: {
			id: "bcaec16e531fb5f6dc690d7b70d570421e0209af9a0fe77c6419d516fe0098c2",
			name: "CoinStats",
			slug: "coinstats-1",
			description: "Crypto Portfolio Manager & DeFi Wallet",
			homepage: "https://coinstats.app",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b2a00908-f144-4a49-cc0a-9d7422ad5e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b2a00908-f144-4a49-cc0a-9d7422ad5e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b2a00908-f144-4a49-cc0a-9d7422ad5e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b2a00908-f144-4a49-cc0a-9d7422ad5e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1247849330",
				android:
					"https://play.google.com/store/apps/details?id=com.coinstats.crypto.portfolio",
				mac: "https://apps.apple.com/us/app/id1247849330",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "coinstats://",
				universal: "http://coinstats.app",
			},
			desktop: {
				native: "coinstats://",
				universal: null,
			},
			metadata: {
				shortName: "CoinStats",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-28T11:47:35.803196+00:00",
		},
		a309c4e5e51f106e739b70ca64c9c8a78a59e51a35fb0df3711eeb1a4d642b3a: {
			id: "a309c4e5e51f106e739b70ca64c9c8a78a59e51a35fb0df3711eeb1a4d642b3a",
			name: "LikerLand App",
			slug: "liker-land-app",
			description:
				"The most user-friendly LikeCoin wallet for decentralized publishing and Writing NFT.",
			homepage: "https://liker.land/getapp",
			chains: ["cosmos:likecoin-mainnet-2"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "501fa316-f0df-4a1b-ead6-5523251b7100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/501fa316-f0df-4a1b-ead6-5523251b7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/501fa316-f0df-4a1b-ead6-5523251b7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/501fa316-f0df-4a1b-ead6-5523251b7100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/liker-land/id1248232355",
				android: "https://play.google.com/store/apps/details?id=com.oice",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "com.oice://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "LikerLandApp",
				colors: {
					primary: "#28646e",
					secondary: "#aaf1e7",
				},
			},
			updatedAt: "2023-06-28T11:51:09.891609+00:00",
		},
		"92dec31cb291452a58043db64f26b20c886607661fd82e921be6362d5fee7f49": {
			id: "92dec31cb291452a58043db64f26b20c886607661fd82e921be6362d5fee7f49",
			name: "Krystal",
			slug: "krystal",
			description: "Simplest Web3 Wallet for Everyone",
			homepage: "https://krystal.app/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1313161554",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d2b59965-4eb8-4828-d3d4-fbc0b3379e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d2b59965-4eb8-4828-d3d4-fbc0b3379e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d2b59965-4eb8-4828-d3d4-fbc0b3379e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d2b59965-4eb8-4828-d3d4-fbc0b3379e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://defi.krystal.app/",
				ios: "https://apps.apple.com/us/app/krystal-one-platform-all-defi/id1558105691",
				android:
					"https://play.google.com/store/apps/details?id=com.kyrd.krystal",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "krystalwallet://",
				universal: "https://wallet.krystal.app/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Krystal Wallet",
				colors: {
					primary: "#1DE9B6",
					secondary: "#1B1D1C",
				},
			},
			updatedAt: "2023-06-28T12:28:06.990837+00:00",
		},
		fdcaaa47c154988ff2ce28d39248eb10366ec60c7de725f73b0d33b5bb9b9a64: {
			id: "fdcaaa47c154988ff2ce28d39248eb10366ec60c7de725f73b0d33b5bb9b9a64",
			name: "KeepKey Desktop",
			slug: "keepkey-desktop",
			description: "A Desktop Client for the KeepKey Hardware Wallet",
			homepage: "https://www.keepkey.com/",
			chains: ["eip155:1", "eip155:10", "eip155:100", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "eb4227d9-366c-466c-db8f-ab7e45985500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eb4227d9-366c-466c-db8f-ab7e45985500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eb4227d9-366c-466c-db8f-ab7e45985500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eb4227d9-366c-466c-db8f-ab7e45985500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.keepkey.com/",
				ios: null,
				android: null,
				mac: "https://github.com/keepkey/keepkey-desktop/releases/download/v1.1.14/KeepKey-Desktop-1.1.14.dmg",
				windows:
					"https://github.com/keepkey/keepkey-desktop/releases/download/v1.1.14/KeepKey-Desktop-Setup-1.1.14.exe",
				linux:
					"https://github.com/keepkey/keepkey-desktop/releases/download/v1.1.14/keepkey-desktop_1.1.14_amd64.deb",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "keepkey://launch",
				universal: null,
			},
			metadata: {
				shortName: "keepkey",
				colors: {
					primary: "#B39C6F",
					secondary: "#121212",
				},
			},
			updatedAt: "2023-06-28T18:14:15.539709+00:00",
		},
		f3379d4ac112b75ce80222d6e0d18f36667c87086fa65b6d10f118815d224940: {
			id: "f3379d4ac112b75ce80222d6e0d18f36667c87086fa65b6d10f118815d224940",
			name: "Pillar",
			slug: "pillar",
			description:
				"The only community-run, multichain & non-custodial DeFi wallet with one address, low-to-no gas fees and cross-chain super powers.",
			homepage: "https://pillar.fi",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "87737170-f79f-4359-338b-7c30856c9f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/87737170-f79f-4359-338b-7c30856c9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/87737170-f79f-4359-338b-7c30856c9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/87737170-f79f-4359-338b-7c30856c9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1346582238?pt=118878535&ct=pillar.fi&mt=8",
				android:
					"https://play.google.com/store/apps/details?id=com.pillarproject.wallet&referrer=utm_source%3Dpillar.fi%26utm_medium%3Ddownload%26utm_campaign%3Dandroid",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pillarwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PLR",
				colors: {
					primary: "#7501D9",
					secondary: "#A945FF",
				},
			},
			updatedAt: "2023-06-28T18:22:31.821325+00:00",
		},
		bd44a349197c9d22659b98f0db579589f1e99f0ef51ccc06ffab2544b86e68e7: {
			id: "bd44a349197c9d22659b98f0db579589f1e99f0ef51ccc06ffab2544b86e68e7",
			name: "HARTi Wallet",
			slug: "harti-wallet",
			description:
				"HARTi, the curated NFT platform, blends virtual and physical art spaces. Discover, explore, and buy art at harti.tokyo.",
			homepage: "https://harti.io/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d0407f26-fe0b-4f3c-43c3-69bc8fef2e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d0407f26-fe0b-4f3c-43c3-69bc8fef2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d0407f26-fe0b-4f3c-43c3-69bc8fef2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d0407f26-fe0b-4f3c-43c3-69bc8fef2e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/jp/app/harti/id1599921940?l=en",
				android:
					"https://play.google.com/store/apps/details?id=app.harti&hl=ja&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "HARTi://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "HARTi App",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-29T15:00:56.949437+00:00",
		},
		"6ec576d0fbc0b1f6c7bf84cdf84386db7788a89cd2e62b852ca9514229e0e9aa": {
			id: "6ec576d0fbc0b1f6c7bf84cdf84386db7788a89cd2e62b852ca9514229e0e9aa",
			name: "Stasis Wallet",
			slug: "stasis-wallet",
			description: "Non-custodial wallet for stablecoins",
			homepage: "https://stasis.net/",
			chains: [
				"algorand:SGO1GKSzyE7IEPItTxCByw9x8FmnrCDe",
				"algorand:wGHE2Pwdvd7S12BL5FaOP20EGYesN73k",
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:50",
				"eip155:51",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d83223cf-f29a-4757-a21e-8913b12f9f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d83223cf-f29a-4757-a21e-8913b12f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d83223cf-f29a-4757-a21e-8913b12f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d83223cf-f29a-4757-a21e-8913b12f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/stasis-wallet/id1371949230",
				android:
					"https://play.google.com/store/apps/details?id=com.stasis.stasiswallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "stasis://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Stasis Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-29T18:25:31.827006+00:00",
		},
		"43fd1a0aeb90df53ade012cca36692a46d265f0b99b7561e645af42d752edb92": {
			id: "43fd1a0aeb90df53ade012cca36692a46d265f0b99b7561e645af42d752edb92",
			name: "Nova Wallet",
			slug: "nova-wallet",
			description: "The Ultimate Mobile Wallet for Web3",
			homepage: "https://novawallet.io",
			chains: [
				"eip155:1",
				"eip155:686",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4f159b10-419b-483a-f2bf-da3d17855e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4f159b10-419b-483a-f2bf-da3d17855e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4f159b10-419b-483a-f2bf-da3d17855e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4f159b10-419b-483a-f2bf-da3d17855e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/nova-polkadot-kusama-wallet/id1597119355",
				android:
					"https://play.google.com/store/apps/details?id=io.novafoundation.nova.market",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "novawallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Nova Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-06-29T19:30:00.657781+00:00",
		},
		"4c70c9bd85cc4707811ec0912d692855370d465d46188be42530eaeb4a4f3cae": {
			id: "4c70c9bd85cc4707811ec0912d692855370d465d46188be42530eaeb4a4f3cae",
			name: "meta-WONDER-verse",
			slug: "web3asy",
			description:
				"A Web3 project showcases Custonomy's Web3asy, a non-custodial MPC wallet with simple login, without seedphases.",
			homepage: "https://metawonderverse.custonomy.io/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "5cc6d96d-178d-42a6-cba1-ebd9d9415700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5cc6d96d-178d-42a6-cba1-ebd9d9415700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5cc6d96d-178d-42a6-cba1-ebd9d9415700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5cc6d96d-178d-42a6-cba1-ebd9d9415700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://custonomy.io",
				ios: "https://metawonderverse.custonomy.io/",
				android: null,
				mac: "",
				windows: null,
				linux: null,
				chrome: "https://metawonderverse.custonomy.io/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://metawonderverse.custonomy.io/",
			},
			metadata: {
				shortName: "meta-WONDER-verse",
				colors: {
					primary: "#58A7F0",
					secondary: null,
				},
			},
			updatedAt: "2023-06-30T11:09:57.032583+00:00",
		},
		c4a289db34ed1b8e29d7e87a2e97c236bb82d72d60c8d73e27e02769facabd4a: {
			id: "c4a289db34ed1b8e29d7e87a2e97c236bb82d72d60c8d73e27e02769facabd4a",
			name: "DTTD",
			slug: "dttd",
			description: "A Mobile-First Social Wallet Enabling Web3 for Everyone",
			homepage: "https://www.dttd.io/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4a1da9d0-1a81-4e51-4758-b2157f4e6000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4a1da9d0-1a81-4e51-4758-b2157f4e6000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4a1da9d0-1a81-4e51-4758-b2157f4e6000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4a1da9d0-1a81-4e51-4758-b2157f4e6000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://hop.dttd.app/appstoredownload",
				android: "https://hop.dttd.app/googleplaydownload",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "dotted://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "DTTD",
				colors: {
					primary: "#03cafc",
					secondary: "#f4fefa",
				},
			},
			updatedAt: "2023-06-30T15:29:23.767179+00:00",
		},
		c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2: {
			id: "c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2",
			name: "FoxWallet",
			slug: "foxwallet",
			description:
				"Best Multi-chain Web3 Wallet Private & Secure Decentralized & Flexible",
			homepage: "http://foxwallet.com",
			chains: ["eip155:1", "eip155:10", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d994a61e-c1df-49cb-cf4c-10ec51338400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d994a61e-c1df-49cb-cf4c-10ec51338400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d994a61e-c1df-49cb-cf4c-10ec51338400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d994a61e-c1df-49cb-cf4c-10ec51338400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/foxwallet-crypto-web3/id1590983231",
				android:
					"https://play.google.com/store/apps/details?id=com.foxwallet.play",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "foxwallet://",
				universal: "https://link.foxwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "FoxWallet",
				colors: {
					primary: "#EC7D43",
					secondary: "#FEF3ED",
				},
			},
			updatedAt: "2023-07-03T12:40:46.824076+00:00",
		},
		"47c5a25372f22ccf2df09a8431ccd00c02df19f4b73fa0e7c04c1573b90aec7a": {
			id: "47c5a25372f22ccf2df09a8431ccd00c02df19f4b73fa0e7c04c1573b90aec7a",
			name: "HAQQ Wallet",
			slug: "haqq-wallet",
			description:
				"Mobile crypto wallet for secure, halal cryptocurrency storage and instant access",
			homepage: "https://haqq.network/wallet",
			chains: ["eip155:11235", "eip155:54211"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "99fe539d-6a2a-4f52-2211-42fd04a9f300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/99fe539d-6a2a-4f52-2211-42fd04a9f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/99fe539d-6a2a-4f52-2211-42fd04a9f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/99fe539d-6a2a-4f52-2211-42fd04a9f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/haqq-wallet-by-bored-gen/id6443843352",
				android:
					"https://play.google.com/store/apps/details?id=com.haqq.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "haqq://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "HAQQ Wallet",
				colors: {
					primary: "#04D484",
					secondary: null,
				},
			},
			updatedAt: "2023-07-03T14:38:56.32828+00:00",
		},
		e053718e3e968b085a0ae5d11ce1c3d74ba6918c27319c70fc358a48138a5cc4: {
			id: "e053718e3e968b085a0ae5d11ce1c3d74ba6918c27319c70fc358a48138a5cc4",
			name: "tomiPAY",
			slug: "tomipay",
			description: "Digital Payment System",
			homepage: "https://tomi.com/wallet",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bf8bd7b8-b638-40f6-1caa-1d7678bb1900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bf8bd7b8-b638-40f6-1caa-1d7678bb1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bf8bd7b8-b638-40f6-1caa-1d7678bb1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bf8bd7b8-b638-40f6-1caa-1d7678bb1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/tomipay-digital-payment-system/id1643501440",
				android:
					"https://play.google.com/store/apps/details?id=com.tomiapp.production",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/tomipay/feoojlbclclaoifjiedeeenhldlenopl",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "istomiPAY",
				},
			],
			rdns: null,
			mobile: {
				native: "tomiwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "tomiPAY",
				colors: {
					primary: "#00F026",
					secondary: null,
				},
			},
			updatedAt: "2023-07-03T14:39:54.556016+00:00",
		},
		"36dbd7f82df78f406723eb71599640fbbf703b2583682ba1e419a9098a2d4945": {
			id: "36dbd7f82df78f406723eb71599640fbbf703b2583682ba1e419a9098a2d4945",
			name: "StrikeX Wallet",
			slug: "strikex-wallet-1",
			description:
				"Buy, sell, swap, transfer & track crypto on our non-custodial DeFi Crypto wallet.",
			homepage: "https://tradestrike.io/",
			chains: [
				"eip155:1",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "eb2b6db5-1086-4739-a422-4a4bf3a44300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eb2b6db5-1086-4739-a422-4a4bf3a44300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eb2b6db5-1086-4739-a422-4a4bf3a44300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eb2b6db5-1086-4739-a422-4a4bf3a44300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/strikex-wallet/id6443517613",
				android:
					"https://play.google.com/store/apps/details?id=com.tradestrike",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "strikex://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "StrikeX Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-04T11:04:07.477824+00:00",
		},
		"7be8d8b5e39bbcee13867c50e47387cd752c12e03b3e6fdf2e0638c7b4e3b69f": {
			id: "7be8d8b5e39bbcee13867c50e47387cd752c12e03b3e6fdf2e0638c7b4e3b69f",
			name: "Nash",
			slug: "nash",
			description: "Spend, save & invest",
			homepage: "https://nash.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"neo3:mainnet",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "93a15cd2-8f0d-4bf6-1545-6bdf745c2300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/93a15cd2-8f0d-4bf6-1545-6bdf745c2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/93a15cd2-8f0d-4bf6-1545-6bdf745c2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/93a15cd2-8f0d-4bf6-1545-6bdf745c2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/nl/app/nash-spend-save-invest/id1475759236",
				android: "https://play.google.com/store/apps/details?id=io.nash.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Nash",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-04T11:29:28.412319+00:00",
		},
		"15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f": {
			id: "15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f",
			name: "Bybit Wallet",
			slug: "bybit-wallet",
			description:
				"Bybit Wallet connects you to the world of Web3 with best-in-class reliability and security",
			homepage: "https://www.bybit.com/web3/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:250",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b9e64f74-0176-44fd-c603-673a45ed5b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b9e64f74-0176-44fd-c603-673a45ed5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b9e64f74-0176-44fd-c603-673a45ed5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b9e64f74-0176-44fd-c603-673a45ed5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/US/app/id1488296980",
				android:
					"https://play.google.com/store/apps/details?id=com.bybit.app&hl=en",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bybit-wallet/pdliaogehgdbhbnmkklieghmmjkpigpa",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bybitapp://open/route?targetUrl=by://web3/walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bybit Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-05T07:36:33.226143+00:00",
		},
		"9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a": {
			id: "9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a",
			name: "SubWallet",
			slug: "subwallet",
			description: "Comprehensive Polkadot,\nSubstrate & Ethereum wallet",
			homepage: "https://www.subwallet.app/",
			chains: [
				"eip155:1",
				"eip155:1284",
				"eip155:1285",
				"eip155:1287",
				"eip155:56",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "03f5c08c-fb30-46a0-ca5c-d8fdd7250b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/03f5c08c-fb30-46a0-ca5c-d8fdd7250b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/03f5c08c-fb30-46a0-ca5c-d8fdd7250b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/03f5c08c-fb30-46a0-ca5c-d8fdd7250b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/subwallet-polkadot-wallet/id1633050285",
				android:
					"https://play.google.com/store/apps/details?id=app.subwallet.mobile&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/subwallet-polkadot-wallet/onhogfjeacnfoofkfgppdlbmlmnplgbn",
				firefox: "https://addons.mozilla.org/en-US/firefox/addon/subwallet/",
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/subwallet-polkadot-wallet/onhogfjeacnfoofkfgppdlbmlmnplgbn",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isSubWallet",
				},
				{
					namespace: "polkadot",
					injected_id: "subwallet-js",
				},
			],
			rdns: "app.subwallet.wallet",
			mobile: {
				native: "subwallet://",
				universal: "https://mobile.subwallet.app/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SubWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-05T07:49:42.505738+00:00",
		},
		"550b59942eb58a7226381bf7935f22d311e56ee29c3530e44d96b1de0550a35a": {
			id: "550b59942eb58a7226381bf7935f22d311e56ee29c3530e44d96b1de0550a35a",
			name: "Okto",
			slug: "okto",
			description:
				"Okto web3 wallet: Simple gateway to DeFi. Trade 1000+ tokens, 20+ chains, earn from 100+ protocols. Easy investing and trading features.",
			homepage: "https://okto.tech/",
			chains: ["eip155:137", "eip155:250", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "154c69b7-9bb1-4010-5b4c-6b37eeda8900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/154c69b7-9bb1-4010-5b4c-6b37eeda8900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/154c69b7-9bb1-4010-5b4c-6b37eeda8900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/154c69b7-9bb1-4010-5b4c-6b37eeda8900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://okto.go.link/?adj_t=j39b9kp&adj_fallback=https%3A%2F%2Fokto.tech&adj_redirect_macos=https%3A%2F%2Fokto.tech",
				ios: "https://apps.apple.com/in/app/okto-wallet/id6450688229",
				android:
					"https://play.google.com/store/apps/details?id=com.coindcx.okto&hl=en_IN&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome: "https://okto.tech/",
				firefox: null,
				safari: "https://okto.tech/",
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "okto://",
				universal:
					"https://okto.go.link/?adj_t=j39b9kp&adj_fallback=https%3A%2F%2Fokto.tech&adj_redirect_macos=https%3A%2F%2Fokto.tech",
			},
			desktop: {
				native: "",
				universal: "https://okto.tech/",
			},
			metadata: {
				shortName: "Okto",
				colors: {
					primary: "#5166EE",
					secondary: "#03033F",
				},
			},
			updatedAt: "2023-07-05T12:10:18.632894+00:00",
		},
		"0e9aa50bb3211c93ab48626d53dd631518e33b1eb6cf88638a83e2a0a377e3d0": {
			id: "0e9aa50bb3211c93ab48626d53dd631518e33b1eb6cf88638a83e2a0a377e3d0",
			name: "Catecoin Wallet",
			slug: "catecoin-wallet",
			description: "A secure crypto wallet to grow your investments",
			homepage: "https://catecoin.app",
			chains: [
				"cip-34:1-764824073",
				"eip155:1",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d017bc54-db4d-4f07-2de2-69790ce92400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d017bc54-db4d-4f07-2de2-69790ce92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d017bc54-db4d-4f07-2de2-69790ce92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d017bc54-db4d-4f07-2de2-69790ce92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.catecoin.club/",
				ios: "https://apps.apple.com/app/id1637850589?platform=iphone",
				android:
					"https://play.google.com/store/apps/details?id=com.crypto.wallet.catecoin",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "catecoin://",
				universal: "https://catecoin.app/apple-app-site-association",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cate",
				colors: {
					primary: "#FAA528",
					secondary: "#444B58",
				},
			},
			updatedAt: "2023-07-06T08:33:26.529257+00:00",
		},
		bfad79e3d89bfb915b1e230000179a8ffc8e04f3f78a396d2e4f3e1a51223529: {
			id: "bfad79e3d89bfb915b1e230000179a8ffc8e04f3f78a396d2e4f3e1a51223529",
			name: "UKISS Hub",
			slug: "ukiss-hub",
			description:
				"UKISS Hub Mobile Wallet enables UKISS Hugware to connect to DAPP platforms via Wallet Connect ",
			homepage: "https://www.ukiss.io",
			chains: [
				"cip-34:1-764824073",
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:10000",
				"eip155:137",
				"eip155:25",
				"eip155:43114",
				"eip155:56",
				"eip155:61",
				"eip155:66",
				"eip155:8723",
				"near:mainnet",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"stellar:pubnet",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "23f4c933-68e6-46f9-75b6-2d2905ca1300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/23f4c933-68e6-46f9-75b6-2d2905ca1300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/23f4c933-68e6-46f9-75b6-2d2905ca1300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/23f4c933-68e6-46f9-75b6-2d2905ca1300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=io.ukiss.uhub.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ukisshub://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "UKISS",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-10T18:13:54.056873+00:00",
		},
		ad29e6ba814865dc84111a311f0d64692a6f3fdc220aa1c151034b1e948fe2ef: {
			id: "ad29e6ba814865dc84111a311f0d64692a6f3fdc220aa1c151034b1e948fe2ef",
			name: "Tellaw Wallet",
			slug: "tellaw-wallet",
			description: "Web3 portal at fingertips",
			homepage: "https://www.tellaw.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c1cb03f5-e1c2-4c3e-86e1-9a90565ea300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c1cb03f5-e1c2-4c3e-86e1-9a90565ea300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c1cb03f5-e1c2-4c3e-86e1-9a90565ea300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c1cb03f5-e1c2-4c3e-86e1-9a90565ea300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id6446802245",
				android:
					"https://play.google.com/store/apps/details?id=com.tellaw.tellaw",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "tellaw://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Tellaw",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-10T18:14:23.676391+00:00",
		},
		"21030f20fba1a77115858ee3a8bc5841c739ab4537441316e2f4b1d0a8d218af": {
			id: "21030f20fba1a77115858ee3a8bc5841c739ab4537441316e2f4b1d0a8d218af",
			name: "Tangem Wallet",
			slug: "tangem-wallet",
			description:
				"Tangem is a card-shaped self-custodial cold hardware wallet which gives you full control of your private keys",
			homepage: "https://tangem.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:61",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "80679c6f-bb0b-43d0-83e0-462ac268b600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/80679c6f-bb0b-43d0-83e0-462ac268b600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/80679c6f-bb0b-43d0-83e0-462ac268b600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/80679c6f-bb0b-43d0-83e0-462ac268b600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/uz/app/tangem/id1354868448",
				android:
					"https://play.google.com/store/apps/details?id=com.tangem.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "tangem://",
				universal: "https://app.tangem.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Tangem Wallet",
				colors: {
					primary: "#FFFFFF",
					secondary: "#000000",
				},
			},
			updatedAt: "2023-07-10T18:16:04.304876+00:00",
		},
		"7b20b6de13a5ecce036f74f74185669ca8f37cca0ca853d126d8dc2830d38f22": {
			id: "7b20b6de13a5ecce036f74f74185669ca8f37cca0ca853d126d8dc2830d38f22",
			name: "Callback",
			slug: "callback",
			description:
				"Callback Wallet is the coolest way to manage your on-chain items.",
			homepage: "https://callback.is",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "9f50c7a7-2384-4efe-89c3-01e0fec2b700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9f50c7a7-2384-4efe-89c3-01e0fec2b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9f50c7a7-2384-4efe-89c3-01e0fec2b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9f50c7a7-2384-4efe-89c3-01e0fec2b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/en/app/callback-original-stickers/id1543659456",
				android:
					"https://play.google.com/store/apps/details?id=com.thebasicsmeishi",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "callback://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Callback",
				colors: {
					primary: "#181818",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2023-07-10T18:18:23.464473+00:00",
		},
		fa6a09c7efd73c6fe3bc5a68969d0def71b5d760b99a16985e3e8e79d84b0156: {
			id: "fa6a09c7efd73c6fe3bc5a68969d0def71b5d760b99a16985e3e8e79d84b0156",
			name: "SA ASSISTANT",
			slug: "sa-assistant",
			description: "A wallet ",
			homepage: "https://summonersarena.io/",
			chains: [
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:3",
				"eip155:4",
				"eip155:5",
				"eip155:56",
				"eip155:97",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7954b508-9ff0-4416-9aba-16209b571000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7954b508-9ff0-4416-9aba-16209b571000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7954b508-9ff0-4416-9aba-16209b571000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7954b508-9ff0-4416-9aba-16209b571000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.onechain.saas&hl=en-VN",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "saas://success",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SA ASSISTANT",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-12T13:08:50.341095+00:00",
		},
		"7819f9cd07e8d7101a483087869f1e57b7d448f3ec5f4ef3eda63c19b926dc17": {
			id: "7819f9cd07e8d7101a483087869f1e57b7d448f3ec5f4ef3eda63c19b926dc17",
			name: "Xellar",
			slug: "xellar",
			description:
				"The worlds most lightweight & secure hard wallet combined with the decentralized banks within one platform",
			homepage: "https://xellar.co",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "89cf9926-00bf-4152-d98f-cac53d7cad00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/89cf9926-00bf-4152-d98f-cac53d7cad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/89cf9926-00bf-4152-d98f-cac53d7cad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/89cf9926-00bf-4152-d98f-cac53d7cad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/xellar-crypto-wallet/id1671215861",
				android:
					"https://play.google.com/store/apps/details?id=com.xellar.wallets",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "xellar://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Xellar",
				colors: {
					primary: "#000000",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2023-07-12T16:15:00.540049+00:00",
		},
		ede21300a22965815031c9bf07d72f05f3d4bf26ad00e5fc4f348a1ee4c838b8: {
			id: "ede21300a22965815031c9bf07d72f05f3d4bf26ad00e5fc4f348a1ee4c838b8",
			name: "Talken Wallet",
			slug: "talken-wallet",
			description: "Talken Web3 Wallet & NFT Suite",
			homepage: "https://talken.io/",
			chains: [
				"eip155:1",
				"eip155:128",
				"eip155:137",
				"eip155:25",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "3c49e8e7-a4d8-4810-23ef-0a0102cce100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3c49e8e7-a4d8-4810-23ef-0a0102cce100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3c49e8e7-a4d8-4810-23ef-0a0102cce100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3c49e8e7-a4d8-4810-23ef-0a0102cce100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/talken-web3-wallet-nft-suite/id1459475831",
				android:
					"https://play.google.com/store/search?q=talken&c=apps&hl=en-KR",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "talken-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Talken",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-12T16:21:07.707024+00:00",
		},
		"9e14926bb64fa2ff359c3acbd75bb675b09efa6f72aed049616a053827140025": {
			id: "9e14926bb64fa2ff359c3acbd75bb675b09efa6f72aed049616a053827140025",
			name: "U2U Wallet",
			slug: "u2u-wallet",
			description:
				"Easy asset management. Multichain available. Self-custody wallet.  Web3 Dapp browser.  Store cryptocurrencies and NFTs in one place",
			homepage: "https://wallet.uniultra.xyz/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "03bca3fc-c191-4877-592d-0b0d6557c900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/03bca3fc-c191-4877-592d-0b0d6557c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/03bca3fc-c191-4877-592d-0b0d6557c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/03bca3fc-c191-4877-592d-0b0d6557c900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/u2u-wallet/id6446194312?l=vi",
				android: "https://play.google.com/store/apps/details?id=org.u2u.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/u2u-wallet/ebjfhkbnnbjhcedilbedghedfgiaioed",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "u2uwc://",
				universal:
					"https://u2u.page.link/?apn=org.u2u.wallet&isi=6446194312&ibi=org.uniultra.wallet&link=https://uniultra.xyz/?referrer%3D",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "U2U Wallet",
				colors: {
					primary: "#42B485",
					secondary: "#131F2B",
				},
			},
			updatedAt: "2023-07-12T16:23:24.157456+00:00",
		},
		a08293da724b05f0abe569f53d74aaecb67a19283d1c5009f4ea95d0659b7996: {
			id: "a08293da724b05f0abe569f53d74aaecb67a19283d1c5009f4ea95d0659b7996",
			name: "Shido Wallet",
			slug: "shido-wallet",
			description:
				"Shido Wallet Multi-Chain is a next generation Crypto DeFi Wallet with Fiat Support.",
			homepage: "https://www.shidowallet.io/",
			chains: ["eip155:1", "eip155:5", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "dd5c7007-4572-41c7-a9b8-b97d071adb00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/dd5c7007-4572-41c7-a9b8-b97d071adb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/dd5c7007-4572-41c7-a9b8-b97d071adb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/dd5c7007-4572-41c7-a9b8-b97d071adb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.shidowallet.io/",
				ios: "https://apps.apple.com/in/app/shido-wallet/id6443624368",
				android:
					"https://play.google.com/store/apps/details?id=com.shidowallet&hl=en_IN&gl=US",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "shido://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Shido Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-12T16:33:14.632257+00:00",
		},
		"4174944732b68fe84b79b98bc0d75c3f0dce4e6e6e9834439fbc1e51fa6eebf3": {
			id: "4174944732b68fe84b79b98bc0d75c3f0dce4e6e6e9834439fbc1e51fa6eebf3",
			name: "OzoneWallet",
			slug: "ozonewallet",
			description: "Chia Wallet secure and portable",
			homepage: "https://ozonewallet.io/",
			chains: ["eip155:61"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4eb57479-515a-463a-9fcb-c20e9cc60c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4eb57479-515a-463a-9fcb-c20e9cc60c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4eb57479-515a-463a-9fcb-c20e9cc60c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4eb57479-515a-463a-9fcb-c20e9cc60c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.chiatk.apps.ozone1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "chiawallet://ozonewallet.io",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ozone",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-13T11:23:31.254035+00:00",
		},
		ca331388cfe708d3c0fb094f4b08fb3c7ebd7778d3dfdcecb728990e178a3d81: {
			id: "ca331388cfe708d3c0fb094f4b08fb3c7ebd7778d3dfdcecb728990e178a3d81",
			name: "Tidus Wallet ",
			slug: "tidus-wallet",
			description:
				"Tidus Wallet is your fully decentralized gateway to DeFi and The Metaverse. ",
			homepage: "https://tiduswallet.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "797bd108-d862-4d1b-d339-883de9a75000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/797bd108-d862-4d1b-d339-883de9a75000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/797bd108-d862-4d1b-d339-883de9a75000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/797bd108-d862-4d1b-d339-883de9a75000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=co.nycrypto.tiduswallet&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "tiduswallet://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Tidus",
				colors: {
					primary: "#01A9F5",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-07-13T11:23:50.012725+00:00",
		},
		"816d067b6e3387965911bab9666725e2e53d3bfcd3dade708b74917a6d5c8432": {
			id: "816d067b6e3387965911bab9666725e2e53d3bfcd3dade708b74917a6d5c8432",
			name: "Impact Wallet",
			slug: "impact-wallet",
			description: "Impacts Wallet to make a difference.",
			homepage: "https://www.ixo.world/",
			chains: ["cosmos:cosmoshub-4"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "afc85418-2ca6-46cf-cfb9-daf6bc43e400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/afc85418-2ca6-46cf-cfb9-daf6bc43e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/afc85418-2ca6-46cf-cfb9-daf6bc43e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/afc85418-2ca6-46cf-cfb9-daf6bc43e400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/impacts-x/id6444948058",
				android: "https://play.google.com/store/apps/details?id=com.ixo.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "impactsx://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ImpactsX",
				colors: {
					primary: "#002A3F",
					secondary: "#09A8D0",
				},
			},
			updatedAt: "2023-07-13T22:45:36.276772+00:00",
		},
		"34fca0f0eaa51af856cc7f205bacc36ede45545fa987fd81274e53db7718a183": {
			id: "34fca0f0eaa51af856cc7f205bacc36ede45545fa987fd81274e53db7718a183",
			name: "Wirex Wallet",
			slug: "wirex-wallet-1",
			description:
				"Wirex Wallet is a super-secure, non-custodial way to send, store and receive digital assets. Biometric backup, multi-blockchain capability",
			homepage: "https://wirexapp.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"stellar:pubnet",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "66b40d9b-7314-42dd-cacf-4e324b0c2000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/66b40d9b-7314-42dd-cacf-4e324b0c2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/66b40d9b-7314-42dd-cacf-4e324b0c2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/66b40d9b-7314-42dd-cacf-4e324b0c2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/wirex-wallet-crypto-and-defi/id1594165139",
				android:
					"https://play.google.com/store/apps/details?id=com.wirex.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wirexwallet://",
				universal:
					"https://wwallet.app.link/wc?uri=wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1?bridge=https%3A%2F%2Fbridge.walletconnect.org&key=91303dedf64285cbbaf9120f6e9d160a5c8aa3deb67017a3874cd272323f48ae",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Wirex Wallet",
				colors: {
					primary: "#C9FFC6",
					secondary: "#0F110F",
				},
			},
			updatedAt: "2023-07-14T15:09:44.999138+00:00",
		},
		d166c283d59164538cdc50e414546a7433d5f62b9410c9aa563e4e2ec496a106: {
			id: "d166c283d59164538cdc50e414546a7433d5f62b9410c9aa563e4e2ec496a106",
			name: "Zelcore",
			slug: "zelcore-1",
			description:
				"Multi-chain wallet for Desktop & Mobile with Walletconnect, quickswaps, fiat ramps, and more",
			homepage: "https://zelcore.io",
			chains: [
				"eip155:1",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "1b9e652e-1667-425a-f828-707bf9b05400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1b9e652e-1667-425a-f828-707bf9b05400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1b9e652e-1667-425a-f828-707bf9b05400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1b9e652e-1667-425a-f828-707bf9b05400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/zelcore/id1436296839",
				android:
					"https://play.google.com/store/apps/details?id=com.zelcash.zelcore",
				mac: null,
				windows: null,
				linux: "https://zelcore.io/downloads/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "zel://",
				universal: null,
			},
			desktop: {
				native: "zel://",
				universal: null,
			},
			metadata: {
				shortName: "zel",
				colors: {
					primary: "#DA4DFF",
					secondary: "#01060D",
				},
			},
			updatedAt: "2023-07-18T09:44:06.819992+00:00",
		},
		"394046500fb52c9e57e0091ef30305d513bcae143132a49c1f2a69b594126001": {
			id: "394046500fb52c9e57e0091ef30305d513bcae143132a49c1f2a69b594126001",
			name: "DOSI Vault",
			slug: "dosi-vault",
			description: "Blockchain Wallet for All",
			homepage: "https://vault.dosi.world/",
			chains: ["cosmos:cosmoshub-4"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0a0d223e-6bf7-4e12-a5b4-1720deb02000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0a0d223e-6bf7-4e12-a5b4-1720deb02000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0a0d223e-6bf7-4e12-a5b4-1720deb02000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0a0d223e-6bf7-4e12-a5b4-1720deb02000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://vault.dosi.world/",
				ios: "https://apps.apple.com/kr/app/dosi-vault/id1664013594",
				android:
					"https://play.google.com/store/apps/details?id=world.dosi.vault",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/dosi-vault/blpiicikpimmklhoiploliaenjmecabp?hl=en",
				firefox:
					"https://addons.mozilla.org/ko/firefox/addon/dosi-vault/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search",
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "cosmos",
					injected_id: "dosiVault",
				},
			],
			rdns: null,
			mobile: {
				native: "app.dosivault://",
				universal: "https://dosivault.page.link/qL6j",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "DOSI Vault",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-18T09:44:41.288801+00:00",
		},
		b06656d0b04f34279945f36452bc4089e8b62a44e1d58f9e98807525ac37af06: {
			id: "b06656d0b04f34279945f36452bc4089e8b62a44e1d58f9e98807525ac37af06",
			name: "WOW EARN",
			slug: "wow-earn",
			description: "The best crypto wallet",
			homepage: "https://www.ullapay.com/",
			chains: ["eip155:1", "eip155:128", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1985a753-7fd8-4d75-4c50-7998ea68a800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1985a753-7fd8-4d75-4c50-7998ea68a800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1985a753-7fd8-4d75-4c50-7998ea68a800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1985a753-7fd8-4d75-4c50-7998ea68a800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id6443434220",
				android:
					"https://play.google.com/store/apps/details?id=com.hxg.wallet&pli=1",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ullawallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "WOW EARN",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-18T09:45:20.427252+00:00",
		},
		fe9127f49fd95e20e6d877d0e224da6a75062f52d8fb9784856a5cb7ef39e9d2: {
			id: "fe9127f49fd95e20e6d877d0e224da6a75062f52d8fb9784856a5cb7ef39e9d2",
			name: "ELLIPAL",
			slug: "ellipal",
			description:
				"ELLIPAL is an all-in-one cryptocurrency wallet. It combines the safety of a Cold Wallet with the convenience of a mobile one. ",
			homepage: "https://www.ellipal.com",
			chains: ["eip155:1", "eip155:128", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0a5b45a1-c974-4f41-6c14-376714478c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0a5b45a1-c974-4f41-6c14-376714478c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0a5b45a1-c974-4f41-6c14-376714478c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0a5b45a1-c974-4f41-6c14-376714478c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/us/app/ellipal/id1426179665?l=zh&ls=1&mt=8",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ELLIPAL://",
				universal: "https://www.ellipal.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ELLIPAL",
				colors: {
					primary: "#1f84f3",
					secondary: "#1f84f3",
				},
			},
			updatedAt: "2023-07-18T09:45:48.73516+00:00",
		},
		b3adea6c0b9172c4a49da31d264a7c4aacd70ea04f6af8e8977ecb974298b13c: {
			id: "b3adea6c0b9172c4a49da31d264a7c4aacd70ea04f6af8e8977ecb974298b13c",
			name: "Unstoppable Wallet",
			slug: "unstoppable-wallet",
			description:
				"Unstoppable Wallet is a decentralized, open-source, non-custodial, multi-blockchain, cryptocurrency wallet app.",
			homepage: "https://unstoppable.money/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a63cbfce-0726-4f94-9187-a761afb94400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a63cbfce-0726-4f94-9187-a761afb94400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a63cbfce-0726-4f94-9187-a761afb94400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a63cbfce-0726-4f94-9187-a761afb94400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/unstoppable-crypto-wallet/id1447619907",
				android:
					"https://play.google.com/store/apps/details?id=io.horizontalsystems.bankwallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "unstoppable.money://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Unstoppable Wallet",
				colors: {
					primary: "#FFB605",
					secondary: null,
				},
			},
			updatedAt: "2023-07-19T10:30:17.347901+00:00",
		},
		"76260019aec5a3c44dd2421bf78e80f71a6c090d932c413a287193ed79450694": {
			id: "76260019aec5a3c44dd2421bf78e80f71a6c090d932c413a287193ed79450694",
			name: "Aurora Pass",
			slug: "aurora-pass",
			description: "Your gateway to the Aurora ecosystem\n",
			homepage: "https://auroracloud.dev/pass",
			chains: ["eip155:1313161554", "eip155:1313161555", "eip155:1313161556"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6d93eeba-edce-431c-4293-e25784e61f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6d93eeba-edce-431c-4293-e25784e61f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6d93eeba-edce-431c-4293-e25784e61f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6d93eeba-edce-431c-4293-e25784e61f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/aurora-pass-web3-wallet/id6447244286",
				android:
					"https://play.google.com/store/apps/details?id=aurora.pass.android.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "aurora-pass://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Aurora Pass",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-20T13:34:49.330393+00:00",
		},
		e6db14424618cf309697eb50dc330ec18b0ad63395f5ac4669233716df5c18be: {
			id: "e6db14424618cf309697eb50dc330ec18b0ad63395f5ac4669233716df5c18be",
			name: "Bitverse",
			slug: "bitverse",
			description: "Credit Wallet Creates Wealth Web3 Space",
			homepage: "https://www.bitverse.zone",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:288",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "5851c585-0f2b-41a1-a36a-221a18af5200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5851c585-0f2b-41a1-a36a-221a18af5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5851c585-0f2b-41a1-a36a-221a18af5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5851c585-0f2b-41a1-a36a-221a18af5200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/1645515614",
				android:
					"https://play.google.com/store/apps/details?id=com.bitverse.app&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/bitverse-wallet/gkeelndblnomfmjnophbhfhcjbcnemka",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bitverseapp://open/wallet",
				universal:
					"https://bitverseapp.page.link/?apn=com.bitverse.app&afl=https://bitverse.zone/download?deeplink%3Dbitverseapp://open/wallet&isi=1645515614&ibi=com.bitverse.app&link=https://bitverse.zone/download?deeplink%3Dbitverseapp://open/wallet?uri=",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitverse",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-20T14:02:27.002507+00:00",
		},
		de9e8421961e35c5e35cb63e3cd7be9af328a62c7b5a11f95ca116bf128a7424: {
			id: "de9e8421961e35c5e35cb63e3cd7be9af328a62c7b5a11f95ca116bf128a7424",
			name: "Konio",
			slug: "konio",
			description:
				"Native Koinos Wallet - The first multi-platform native wallet for Koinos the first zero fee oriented towards web3 development",
			homepage: "https://konio.io",
			chains: [
				"koinos:EiAAKqFi-puoXnuJTdn7qBGGJa8yd-dc",
				"koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3e",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "305dadb1-395e-4ca8-d4d0-d8ad0cc37000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/305dadb1-395e-4ca8-d4d0-d8ad0cc37000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/305dadb1-395e-4ca8-d4d0-d8ad0cc37000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/305dadb1-395e-4ca8-d4d0-d8ad0cc37000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/konio/id6453561342",
				android:
					"https://play.google.com/store/apps/details?id=com.adrihoke.konio",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "konio://",
				universal: "https://konio.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "konio",
				colors: {
					primary: "#4B0082",
					secondary: "#DDDDDD",
				},
			},
			updatedAt: "2023-07-20T15:59:33.293728+00:00",
		},
		aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588: {
			id: "aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588",
			name: "GateWeb3",
			slug: "gate-wallet",
			description:
				"Wallets, trading, cross-chain, NFTs and DApps all in Gate web3",
			homepage: "https://www.gateweb3.net/web3",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:10000",
				"eip155:1028",
				"eip155:128",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:256",
				"eip155:280",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:5",
				"eip155:53",
				"eip155:56",
				"eip155:65",
				"eip155:66",
				"eip155:71393",
				"eip155:85",
				"eip155:86",
				"eip155:97",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6e528abf-7a7d-47bd-d84d-481f169b1200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6e528abf-7a7d-47bd-d84d-481f169b1200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6e528abf-7a7d-47bd-d84d-481f169b1200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6e528abf-7a7d-47bd-d84d-481f169b1200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://www.gate.io/mobileapp",
				android: "https://www.gate.io/mobileapp",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/gate-wallet/cpmkedoipcpimgecpmgpldfpohjplkpp",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "io.gate.wallet",
			mobile: {
				native: "gtweb3wallet://",
				universal: null,
			},
			desktop: {
				native: "gtweb3wallet://",
				universal: "https://www.gateweb3.net/web3",
			},
			metadata: {
				shortName: "GateWeb3",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-21T09:15:38.809286+00:00",
		},
		"6af02afbc4ac21d339fb4290d048d48f9f73c3b1a307a994f0474329948c0e5a": {
			id: "6af02afbc4ac21d339fb4290d048d48f9f73c3b1a307a994f0474329948c0e5a",
			name: "UTORG",
			slug: "utorg",
			description:
				"A multi-crypto, self-custody wallet with blockchain support. Purchase crypto directly within the app.",
			homepage: "https://utorg.app",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1313161554",
				"eip155:137",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:59144",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "39c77c0b-d6ea-419d-92b7-513a5eac2c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/39c77c0b-d6ea-419d-92b7-513a5eac2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/39c77c0b-d6ea-419d-92b7-513a5eac2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/39c77c0b-d6ea-419d-92b7-513a5eac2c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/utorg-bitcoin-crypto-wallet/id6444720908",
				android: "https://play.google.com/store/apps/details?id=com.utorg",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "utorg://mainactivity",
				universal: "https://link.utorg.com/zp0f",
			},
			desktop: {
				native: "utorg://mainactivity",
				universal: null,
			},
			metadata: {
				shortName: "UTORG",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-21T09:17:07.108407+00:00",
		},
		"2ba89f94faff121a7c1091c3cea124167dc4291ebe87123620c66e0f120197cc": {
			id: "2ba89f94faff121a7c1091c3cea124167dc4291ebe87123620c66e0f120197cc",
			name: "CoinWallet",
			slug: "coinwallet",
			description:
				"MPC-TSS HD Multichain Wallet, support mainnet and testnet, generate address based on custom derivation path, NFT, DApp, and more.",
			homepage: "https://www.coinsdo.com",
			chains: [
				"cip-34:0-1",
				"cip-34:1-764824073",
				"eip155:1",
				"eip155:100",
				"eip155:10000",
				"eip155:10001",
				"eip155:128",
				"eip155:137",
				"eip155:250",
				"eip155:256",
				"eip155:43113",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:59",
				"eip155:61",
				"eip155:65",
				"eip155:66",
				"eip155:80001",
				"eip155:97",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1c0cd352-ce8e-4bcc-f91d-8763eab60b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1c0cd352-ce8e-4bcc-f91d-8763eab60b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1c0cd352-ce8e-4bcc-f91d-8763eab60b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1c0cd352-ce8e-4bcc-f91d-8763eab60b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.coinsdo.com/wallet_coinsdo.html",
				ios: "https://apps.apple.com/us/app/coinsdo-wallet/id1631258517",
				android:
					"https://play.google.com/store/apps/details?id=com.coinsdo.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/coinwallet-btc-crypto-wal/oafedfoadhdjjcipmcbecikgokpaphjk",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "coinwallet://",
				universal: null,
			},
			desktop: {
				native: "coinwallet://",
				universal: null,
			},
			metadata: {
				shortName: "CoinWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-25T19:46:31.595549+00:00",
		},
		"541517cf964687d18414ff05d2623b9c0a122a7a06ac5a5702fd92c4f2b87b1c": {
			id: "541517cf964687d18414ff05d2623b9c0a122a7a06ac5a5702fd92c4f2b87b1c",
			name: "Ammer Wallet",
			slug: "ammerwallet",
			description: "Crypto Wallet and Pay ecosystem",
			homepage: "https://ammer.app/",
			chains: ["eip155:1", "eip155:106", "eip155:137", "eip155:42220"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7d38dd8e-92ee-44bf-1ca4-818531de1900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7d38dd8e-92ee-44bf-1ca4-818531de1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7d38dd8e-92ee-44bf-1ca4-818531de1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7d38dd8e-92ee-44bf-1ca4-818531de1900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ammer-wallet/id1599698329",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ammerwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ammer Wallet",
				colors: {
					primary: "#151515",
					secondary: null,
				},
			},
			updatedAt: "2023-07-25T20:00:41.490481+00:00",
		},
		cbc11415130d01316513f735eac34fd1ad7a5d40a993bbb6772d2c02eeef3df8: {
			id: "cbc11415130d01316513f735eac34fd1ad7a5d40a993bbb6772d2c02eeef3df8",
			name: "Binance.US",
			slug: "binanceus",
			description: "Binance US Web3 Wallet",
			homepage: "https://binance.us",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "48aa1a7d-c5fe-4ad6-c2f2-e5684b296900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/48aa1a7d-c5fe-4ad6-c2f2-e5684b296900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/48aa1a7d-c5fe-4ad6-c2f2-e5684b296900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/48aa1a7d-c5fe-4ad6-c2f2-e5684b296900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/id1492670702",
				android: "https://play.google.com/store/apps/details?id=com.binance.us",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bncus://binance.us",
				universal: "https://binance.us/universal_JHHGDSKDJ",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Binance.US",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-25T20:01:35.281131+00:00",
		},
		"0e36dd863f7cb70a0d8dd507e2b32495b01771abbf5c73817401d958d9938ca7": {
			id: "0e36dd863f7cb70a0d8dd507e2b32495b01771abbf5c73817401d958d9938ca7",
			name: "SISTEMAS",
			slug: "sistemas",
			description: "Sistema de apertura de cuentas",
			homepage: "https://waynance.com",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "eda865c8-746b-4536-9d57-7d7de0555400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eda865c8-746b-4536-9d57-7d7de0555400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eda865c8-746b-4536-9d57-7d7de0555400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eda865c8-746b-4536-9d57-7d7de0555400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://waynance.com",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Waynance Pay",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-25T20:04:06.012708+00:00",
		},
		"47bb562043e570200267c79b256c5fc4e005bde1d5628db9205f072243329fbf": {
			id: "47bb562043e570200267c79b256c5fc4e005bde1d5628db9205f072243329fbf",
			name: "MUZA",
			slug: "muza",
			description:
				"MUZA is a crypto wallet, this application connects you between web3 and the physical world to redeem a privilege from your NFTs.",
			homepage: "https://muza.co",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "f8516ff9-ca2e-4b59-65f6-ed8ef438f100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f8516ff9-ca2e-4b59-65f6-ed8ef438f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f8516ff9-ca2e-4b59-65f6-ed8ef438f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f8516ff9-ca2e-4b59-65f6-ed8ef438f100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/th/app/muza-wallet-nft-web3/id6450735857",
				android:
					"https://play.google.com/store/apps/details?id=com.muza.muza&pcampaignid=web_share",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "muza://",
				universal: "https://muza.co/app",
			},
			desktop: {
				native: "muza://",
				universal: null,
			},
			metadata: {
				shortName: "MUZA",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-25T20:14:12.810396+00:00",
		},
		"30eb3e6d71fd0727d154e451748815e04a99c06972a84b10dfe7ae1b923c0c92": {
			id: "30eb3e6d71fd0727d154e451748815e04a99c06972a84b10dfe7ae1b923c0c92",
			name: "FxWallet",
			slug: "fxwallet",
			description:
				"A Decentralized Multi-Chain Digital Wallet & The Gateway to Web3.",
			homepage: "https://www.fxwallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "46a80541-e639-483d-e230-731fcbf13000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/46a80541-e639-483d-e230-731fcbf13000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/46a80541-e639-483d-e230-731fcbf13000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/46a80541-e639-483d-e230-731fcbf13000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/fxwallet/id1560943983",
				android:
					"https://play.google.com/store/apps/details?id=com.fxfi.fxwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fxwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "FxWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-26T15:55:36.929369+00:00",
		},
		"0d27c5ac4a4a36e8c3f24f8bf3626ee5f0ab41d1a62ea17133f7a67c72efe09d": {
			id: "0d27c5ac4a4a36e8c3f24f8bf3626ee5f0ab41d1a62ea17133f7a67c72efe09d",
			name: "RYIPAY",
			slug: "ryipay",
			description:
				"RYIpay wallet app is built as a decentralized asset management tool for cryptocurrencies ",
			homepage: "https://ryipay.app/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2f0918f7-f135-4b62-4765-ac183f14e500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2f0918f7-f135-4b62-4765-ac183f14e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2f0918f7-f135-4b62-4765-ac183f14e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2f0918f7-f135-4b62-4765-ac183f14e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ryipay-wallet/id1620995066",
				android: "https://play.google.com/store/apps/details?id=io.ryi.pay",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ryipay://",
				universal: "https://ryipay.page.link/3N7P",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "RYIPAY",
				colors: {
					primary: "#000000",
					secondary: "#0b22d3",
				},
			},
			updatedAt: "2023-07-27T16:21:11.339187+00:00",
		},
		"541d5dcd4ede02f3afaf75bf8e3e4c4f1fb09edb5fa6c4377ebf31c2785d9adf": {
			id: "541d5dcd4ede02f3afaf75bf8e3e4c4f1fb09edb5fa6c4377ebf31c2785d9adf",
			name: "Ronin Wallet",
			slug: "ronin-wallet",
			description:
				"Ronin Wallet is the mobile wallet that allows you to use all decentralized applications running on Ronin.",
			homepage: "https://wallet.roninchain.com/",
			chains: ["eip155:1", "eip155:137", "eip155:2020", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bd78de7e-36da-4552-ebdd-2e420ba05900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bd78de7e-36da-4552-ebdd-2e420ba05900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bd78de7e-36da-4552-ebdd-2e420ba05900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bd78de7e-36da-4552-ebdd-2e420ba05900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ronin-wallet/id1592675001",
				android:
					"https://play.google.com/store/apps/details?id=com.skymavis.genesis",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/ronin-wallet/fnjhmkhhmkbjkkabndcnnogagogbneec",
				firefox: "https://addons.mozilla.org/firefox/addon/ronin-wallet/",
				safari: null,
				edge: "https://microsoftedge.microsoft.com/addons/detail/ronin-wallet/kjmoohlgokccodicjjfebfomlbljgfhk",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "window.ronin.provider.isRonin",
				},
			],
			rdns: "com.roninchain.wallet",
			mobile: {
				native: "roninwallet://",
				universal: "https://wallet.roninchain.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ronin Wallet",
				colors: {
					primary: "#1273EA",
					secondary: null,
				},
			},
			updatedAt: "2023-07-28T08:04:28.198174+00:00",
		},
		dd1112ffb1ba02247ac7d22d69a0249c5ec867918c614ce9256c571ba7636882: {
			id: "dd1112ffb1ba02247ac7d22d69a0249c5ec867918c614ce9256c571ba7636882",
			name: "Sequel Wallet",
			slug: "sequel-wallet",
			description:
				"A self-custody, multi-wallet management platform featuring trustless, chain-agnostic social recovery.  Powered by OPAQUE cryptography.",
			homepage: "https://www.sequelfi.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "0c89b2e4-a0cc-4bfc-e3f5-398f4711af00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0c89b2e4-a0cc-4bfc-e3f5-398f4711af00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0c89b2e4-a0cc-4bfc-e3f5-398f4711af00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0c89b2e4-a0cc-4bfc-e3f5-398f4711af00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://app.sequelfi.com",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://app.sequelfi.com",
				firefox: "https://app.sequelfi.com",
				safari: "https://app.sequelfi.com",
				edge: "https://app.sequelfi.com",
				opera: "https://app.sequelfi.com",
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.sequelfi.com/",
			},
			metadata: {
				shortName: "Sequel Wallet",
				colors: {
					primary: "#5656cc",
					secondary: "#ddddf5",
				},
			},
			updatedAt: "2023-07-28T08:48:19.232638+00:00",
		},
		"6544c9ff3ea25bc10a86b6e213c0f0407b04fb335490d7d56f4550c2c6be0502": {
			id: "6544c9ff3ea25bc10a86b6e213c0f0407b04fb335490d7d56f4550c2c6be0502",
			name: "MetaWallet",
			slug: "metawallet",
			description:
				"a feature-rich and secure wallet application designed to help users better manage and control their financial situation",
			homepage: "http://www.dota168.org/",
			chains: [
				"eip155:42161",
				"eip155:421611",
				"eip155:56",
				"eip155:60",
				"eip155:9001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "a18337ad-433f-47c0-ea57-8a6199835e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a18337ad-433f-47c0-ea57-8a6199835e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a18337ad-433f-47c0-ea57-8a6199835e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a18337ad-433f-47c0-ea57-8a6199835e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "http://www.dota168.org/",
				mac: null,
				windows: null,
				linux: null,
				chrome: "http://www.dota168.org/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "window.ethereum.isMetaWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "metawallet://com.metawallet.client",
				universal: "http://www.dota168.org/",
			},
			desktop: {
				native: "metawallet://",
				universal: "http://www.dota168.org/",
			},
			metadata: {
				shortName: "MetaWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-07-28T09:10:23.776924+00:00",
		},
		"94135dbd7aaca4908de49c44e49b8920a79c90164a7ce5803ddb33054c7edc57": {
			id: "94135dbd7aaca4908de49c44e49b8920a79c90164a7ce5803ddb33054c7edc57",
			name: "Altme",
			slug: "altme",
			description:
				"A user-friendly crypto wallet designed to protect your digital identity and simplify your journey in web 3 world.",
			homepage: "https://altme.io/",
			chains: [
				"eip155:1",
				"eip155:11155111",
				"eip155:137",
				"eip155:170",
				"eip155:250",
				"eip155:4002",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7eeac6e8-6852-4d09-8579-e229fd6b9a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7eeac6e8-6852-4d09-8579-e229fd6b9a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7eeac6e8-6852-4d09-8579-e229fd6b9a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7eeac6e8-6852-4d09-8579-e229fd6b9a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/fr/app/altme/id1633216869",
				android:
					"https://play.google.com/store/apps/details?id=co.altme.alt.me.altme",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wc-altme://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "altme",
				colors: {
					primary: "#6600FF",
					secondary: null,
				},
			},
			updatedAt: "2023-08-01T09:20:45.653651+00:00",
		},
		"1796b3d91d6dcaa47a23f7eeb751b89e95d9ced769ade41caa18a8e9759b673c": {
			id: "1796b3d91d6dcaa47a23f7eeb751b89e95d9ced769ade41caa18a8e9759b673c",
			name: "Unido",
			slug: "unido",
			description:
				"Unido offers enterprise-level crypto self-custody solutions for SMEs, sophisticated corporations and institutions.",
			homepage: "https://www.unido.us/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "c22450a3-b4a7-4e86-8855-f5b88d983100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c22450a3-b4a7-4e86-8855-f5b88d983100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c22450a3-b4a7-4e86-8855-f5b88d983100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c22450a3-b4a7-4e86-8855-f5b88d983100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/au/app/unido/id1444261005",
				android:
					"https://play.google.com/store/apps/details?id=com.worldwebms.multisigwallet&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Unido",
				colors: {
					primary: "#f96229",
					secondary: "#aed606",
				},
			},
			updatedAt: "2023-08-01T09:40:19.456895+00:00",
		},
		"1531a2c7dd4506df2ec8660da31c7d4a5f3c9ebbc121c7996c688e12a097f26b": {
			id: "1531a2c7dd4506df2ec8660da31c7d4a5f3c9ebbc121c7996c688e12a097f26b",
			name: "Bitpie",
			slug: "bitpie",
			description:
				"Bitpie is an industry-leading multi-blockchain (BTC/ETH/TRX/USDT etc.) decentralized wallet. ",
			homepage: "https://bitpie.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10000",
				"eip155:128",
				"eip155:1284",
				"eip155:137",
				"eip155:200",
				"eip155:324",
				"eip155:42161",
				"eip155:42220",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:59",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "e6dce4ec-a1a8-49e6-d8e1-8329fdd5c700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e6dce4ec-a1a8-49e6-d8e1-8329fdd5c700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e6dce4ec-a1a8-49e6-d8e1-8329fdd5c700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e6dce4ec-a1a8-49e6-d8e1-8329fdd5c700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/bitpie-universal-crypto-wallet/id1481314229",
				android: "https://bitpie.com/android/",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bitpiewc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitpie",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-01T09:41:30.064542+00:00",
		},
		f5971a12f71b352c3abb3c9fc29818f1044a87d791ce28db6e5ab1962bfff717: {
			id: "f5971a12f71b352c3abb3c9fc29818f1044a87d791ce28db6e5ab1962bfff717",
			name: "MOONSTAKE",
			slug: "moonstake",
			description:
				"MS is one of the top 8 staking providers in the world.As a decentralized e-wallet,we provide a platform for you to fully control asset.",
			homepage: "https://moonstake.io",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "22374fae-244c-4224-2e3d-c14912f98a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/22374fae-244c-4224-2e3d-c14912f98a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/22374fae-244c-4224-2e3d-c14912f98a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/22374fae-244c-4224-2e3d-c14912f98a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/moonstake-wallet/id1502532651",
				android:
					"https://play.google.com/store/apps/details?id=io.moonstake.wallet&hl=en",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "moonstake://",
				universal: "https://moonstake.io/launchApp",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Moonstake Wallet",
				colors: {
					primary: "#0D89FF",
					secondary: "#0BE8A1",
				},
			},
			updatedAt: "2023-08-01T09:42:38.13301+00:00",
		},
		c615c62d619fd52fa1cdf0187229a627f78fa924c90f04391af8960c000b59c5: {
			id: "c615c62d619fd52fa1cdf0187229a627f78fa924c90f04391af8960c000b59c5",
			name: "IndiGG",
			slug: "indigg",
			description:
				"The ultimate Web3 gaming wallet that lets you enter the web3 ecosystem and earn while playing games.",
			homepage: "https://indi.gg",
			chains: ["eip155:137", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8e90a32f-130d-4317-7294-4884510aa300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8e90a32f-130d-4317-7294-4884510aa300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8e90a32f-130d-4317-7294-4884510aa300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8e90a32f-130d-4317-7294-4884510aa300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://indi.gg/",
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.indiggcommunity&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "indigg://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "IndiGG",
				colors: {
					primary: "#9F5ADB",
					secondary: "#224195",
				},
			},
			updatedAt: "2023-08-01T09:48:51.930718+00:00",
		},
		"4b0ef81be0008b86e873d57554e533a7b93b99dd6e9376ae4cbb4fea29b64269": {
			id: "4b0ef81be0008b86e873d57554e533a7b93b99dd6e9376ae4cbb4fea29b64269",
			name: "Yuse Wallet",
			slug: "yuse-wallet",
			description:
				"Yuse Wallet is the official crypto wallet of Yuse Technologies.",
			homepage: "https://yusetoken.io/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2cd61458-59c2-4208-c8ee-98b5e0076b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2cd61458-59c2-4208-c8ee-98b5e0076b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2cd61458-59c2-4208-c8ee-98b5e0076b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2cd61458-59c2-4208-c8ee-98b5e0076b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/yuse-wallet/id6449364813",
				android:
					"https://play.google.com/store/apps/details?id=com.yuse.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "yuse://wallet://",
				universal: "https://yusewallet.page.link/tobR",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Yuse Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-03T19:50:27.536591+00:00",
		},
		a74882bc3c24d2f52e55fd9c9579275885177e92789586ae857208c839335306: {
			id: "a74882bc3c24d2f52e55fd9c9579275885177e92789586ae857208c839335306",
			name: "Coininn Wallet",
			slug: "coininn-wallet",
			description:
				"Securely Hold, Send, Receive, Exchange, Tip and Earn 800+ cryptocurrencies with coinInn",
			homepage: "https://www.coininn.com/coinwallet",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "52efd5a7-65fa-428d-668c-f53ceb4b5f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/52efd5a7-65fa-428d-668c-f53ceb4b5f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/52efd5a7-65fa-428d-668c-f53ceb4b5f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/52efd5a7-65fa-428d-668c-f53ceb4b5f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/id6448525015",
				android:
					"https://play.google.com/store/apps/details?id=com.coininn.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "coininn_wallet_wc://request",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-04T07:33:34.354771+00:00",
		},
		"3cf9e635075cdc1587c444bc6e01a6026c7fd1d518a96a7ac0aa23b905a7c53e": {
			id: "3cf9e635075cdc1587c444bc6e01a6026c7fd1d518a96a7ac0aa23b905a7c53e",
			name: "Safe App Syscoin",
			slug: "safe-syscoin",
			description: "Safe Deployment on Syscoin Networks",
			homepage: "https://syscoin.org/",
			chains: ["eip155:57", "eip155:570", "eip155:5700"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "0b6b29ca-10a4-44cc-a51e-baa4b49fc300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0b6b29ca-10a4-44cc-a51e-baa4b49fc300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0b6b29ca-10a4-44cc-a51e-baa4b49fc300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0b6b29ca-10a4-44cc-a51e-baa4b49fc300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://safe.syscoin.org",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://safe.syscoin.org/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "safe-sys",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-04T07:34:16.775727+00:00",
		},
		"2ebc33a02906094b8ea5d21a7c6e1673ecdefb33dca723897d13c7de3446d354": {
			id: "2ebc33a02906094b8ea5d21a7c6e1673ecdefb33dca723897d13c7de3446d354",
			name: "f(x)Wallet",
			slug: "fxwallet-1",
			description:
				"Developed by Function X Labs, an easy-to-use and secure decentralized wallet app, supporting multiple blockchains",
			homepage: "https://functionx.io/home",
			chains: [
				"cosmos:cosmoshub-4",
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:200",
				"eip155:420",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bdd2f39b-98fa-485d-b180-bf4a42fa6100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bdd2f39b-98fa-485d-b180-bf4a42fa6100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bdd2f39b-98fa-485d-b180-bf4a42fa6100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bdd2f39b-98fa-485d-b180-bf4a42fa6100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/f-x-wallet-by-function-x-labs/id1504798360",
				android:
					"https://play.google.com/store/apps/details?id=com.pundix.functionx",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fxwallet://",
				universal: "https://fx.wallet/wc",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "f(x)Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-04T07:35:10.814825+00:00",
		},
		"016f8161cd78dd01003cf466292b9690c0fd251f2d69415a3cc96659d975e398": {
			id: "016f8161cd78dd01003cf466292b9690c0fd251f2d69415a3cc96659d975e398",
			name: "pockie",
			slug: "pockie",
			description:
				"Pockie is a new, user-friendly wallet here to simplify your crypto journey.\nYou can easily manage digital assets across different chains.",
			homepage: "https://www.pockie.io/ko",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a761beae-1e7e-4402-bcc5-a896a92bfb00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a761beae-1e7e-4402-bcc5-a896a92bfb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a761beae-1e7e-4402-bcc5-a896a92bfb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a761beae-1e7e-4402-bcc5-a896a92bfb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/pockie/id6448715234",
				android:
					"https://play.google.com/store/apps/details?id=com.pilab.pockie&hl=en-KR",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pockie://",
				universal: "https://www.poickie.io/open",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Pockie",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-04T07:35:50.541785+00:00",
		},
		"5d12c54d33abc6af163bc0344eed8df90765281c4973ada8863ed9ae12aa137f": {
			id: "5d12c54d33abc6af163bc0344eed8df90765281c4973ada8863ed9ae12aa137f",
			name: "AmazeWallet",
			slug: "amazewallet",
			description:
				"The Web3 smartwallet. Swap, buy, trade and chat to friends while mining crypto.",
			homepage: "https://amazewallet.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:108",
				"eip155:288",
				"eip155:42220",
				"eip155:43114",
				"eip155:60",
				"eip155:66",
				"eip155:8217",
				"eip155:88",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "38495eb4-efcf-47cb-be73-a695510f9f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/38495eb4-efcf-47cb-be73-a695510f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/38495eb4-efcf-47cb-be73-a695510f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/38495eb4-efcf-47cb-be73-a695510f9f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/gb/app/amazewallet/id1622941204",
				android:
					"https://play.google.com/store/apps/details?id=com.walletamaze.nftwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "amazeapp://amazewallet.com",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "AMT",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-07T12:55:55.127408+00:00",
		},
		c549c59fd32fb1169f317339438fc66b2d917757486fd1507935503c0efd008c: {
			id: "c549c59fd32fb1169f317339438fc66b2d917757486fd1507935503c0efd008c",
			name: "atato custody",
			slug: "atato-custody",
			description:
				"atato wallet is an MPC-based custody wallet that aims to enable SME use cases. For more info, please visit https://atato.com.",
			homepage: "https://atato.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:420",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "53878398-b6da-4384-47dc-bc744acd5b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/53878398-b6da-4384-47dc-bc744acd5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/53878398-b6da-4384-47dc-bc744acd5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/53878398-b6da-4384-47dc-bc744acd5b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.atato.com/",
				ios: "https://apps.apple.com/us/app/atato-custody/id1586312980",
				android:
					"https://play.google.com/store/apps/details?id=com.atato.custody",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "atato custody",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-10T09:51:28.45499+00:00",
		},
		"981ee0b205893921cdb52c94070acedd39283e911de238c478652dedf0e1d76d": {
			id: "981ee0b205893921cdb52c94070acedd39283e911de238c478652dedf0e1d76d",
			name: "Pali Wallet",
			slug: "pali-wallet",
			description: "Your Secure Web3 Companion.",
			homepage: "https://paliwallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:57",
				"eip155:570",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4672cbde-0f96-42f3-84a0-524e9ad70a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4672cbde-0f96-42f3-84a0-524e9ad70a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4672cbde-0f96-42f3-84a0-524e9ad70a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4672cbde-0f96-42f3-84a0-524e9ad70a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/pali-wallet-dex-nft-defi/id6447639615",
				android: "https://play.google.com/store/apps/details?id=io.paliwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://play.google.com/store/apps/details?id=io.paliwallet",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "pali-v2",
				},
			],
			rdns: null,
			mobile: {
				native: "paliwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Pali Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-10T12:16:43.656407+00:00",
		},
		"33c036d8075d28c9f3619d4d43075676a6d294047e3658fb103e5b3424337551": {
			id: "33c036d8075d28c9f3619d4d43075676a6d294047e3658fb103e5b3424337551",
			name: "NuFi",
			slug: "nufi",
			description:
				"A self-custody Web3 wallet to manage crypto, staking, NFTs, trading and Dapps",
			homepage: "https://nu.fi",
			chains: [
				"cip-34:0-1",
				"cip-34:1-764824073",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "65e07e9f-183a-4f6c-6ca5-4964eda1ef00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/65e07e9f-183a-4f6c-6ca5-4964eda1ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/65e07e9f-183a-4f6c-6ca5-4964eda1ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/65e07e9f-183a-4f6c-6ca5-4964eda1ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.nu.fi/wallet_connect/connector/redirect",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/nufi/gpnihlnnodeiiaakbikldcihojploeca",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "cip-34",
					injected_id: "nufi",
				},
				{
					namespace: "solana",
					injected_id: "isNufi",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.nu.fi/wallet_connect/connector/redirect",
			},
			metadata: {
				shortName: "NuFi",
				colors: {
					primary: "#bae440",
					secondary: "#212121",
				},
			},
			updatedAt: "2023-08-16T07:38:16.215327+00:00",
		},
		"7ef337ff00714f179d38b8142398efa2ab902a53430e99ebce02892053d7a310": {
			id: "7ef337ff00714f179d38b8142398efa2ab902a53430e99ebce02892053d7a310",
			name: "EASY",
			slug: "easy",
			description: "Your web3 social wallet",
			homepage: "https://easy.me",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "62feb41a-be1f-4b1c-e089-27f97c0e8d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/62feb41a-be1f-4b1c-e089-27f97c0e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/62feb41a-be1f-4b1c-e089-27f97c0e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/62feb41a-be1f-4b1c-e089-27f97c0e8d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/easy-web3-social-wallet/id1641192503",
				android: "https://play.google.com/store/apps/details?id=co.theeasy.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "co.theeasy.app://",
				universal: "https://link.easy.me",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "EASY",
				colors: {
					primary: "#FFFFFF",
					secondary: "#000000",
				},
			},
			updatedAt: "2023-08-18T14:34:14.439626+00:00",
		},
		"87c00b80517fed78fe3705f43dfefe9b711910859acebb8889aa752556649ef1": {
			id: "87c00b80517fed78fe3705f43dfefe9b711910859acebb8889aa752556649ef1",
			name: "Solace",
			slug: "solace",
			description: "Solace ERC4337 Smart Contract Wallet",
			homepage: "https://solaceprotocol.com",
			chains: ["eip155:25", "eip155:43113", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "4bb93c92-f20b-41d7-97c7-d0e74100bd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4bb93c92-f20b-41d7-97c7-d0e74100bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4bb93c92-f20b-41d7-97c7-d0e74100bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4bb93c92-f20b-41d7-97c7-d0e74100bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.solaceprotocol.com/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.solaceprotocol.com/",
			},
			metadata: {
				shortName: "Solace Protocol",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-18T20:57:09.516872+00:00",
		},
		"7215d406ebbd8e129f6092ee3c6c86c08d444e431bf35414613f6fbb686ab2c9": {
			id: "7215d406ebbd8e129f6092ee3c6c86c08d444e431bf35414613f6fbb686ab2c9",
			name: "Meter Wallet",
			slug: "meter-wallet",
			description: "wallet on ethereum compatible networks",
			homepage: "https://www.meter.io",
			chains: [
				"eip155:1",
				"eip155:1284",
				"eip155:1285",
				"eip155:137",
				"eip155:361",
				"eip155:43114",
				"eip155:56",
				"eip155:82",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "05700788-1b9d-4670-dabd-61fa9b90f900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/05700788-1b9d-4670-dabd-61fa9b90f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/05700788-1b9d-4670-dabd-61fa9b90f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/05700788-1b9d-4670-dabd-61fa9b90f900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.meter.io",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.meter.io",
			},
			metadata: {
				shortName: "meter-wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-18T20:57:25.548017+00:00",
		},
		"37ef631ba83835cae97dbbfe270ff828cdf4c9326e998927bcb03f262f98f144": {
			id: "37ef631ba83835cae97dbbfe270ff828cdf4c9326e998927bcb03f262f98f144",
			name: "SuperWallet",
			slug: "superwallet",
			description: "The Multichain Wallet for Web3",
			homepage: "https://superex.live",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "e8f30122-5537-4b38-d6d5-9cae46771800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e8f30122-5537-4b38-d6d5-9cae46771800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e8f30122-5537-4b38-d6d5-9cae46771800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e8f30122-5537-4b38-d6d5-9cae46771800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://superex.com",
				ios: "https://apps.apple.com/us/app/superex/id1601589888",
				android: "https://play.google.com/store/apps/details?id=com.superex.ex",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "superwallet://",
				universal: "https://www.superex.live",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SuperWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-18T21:07:29.212715+00:00",
		},
		"6464873279d46030c0b6b005b33da6be5ed57a752be3ef1f857dc10eaf8028aa": {
			id: "6464873279d46030c0b6b005b33da6be5ed57a752be3ef1f857dc10eaf8028aa",
			name: "SecuX",
			slug: "secux",
			description: "SecuX Hardware Wallet",
			homepage: "https://secuxtech.com/",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "98183be0-3125-45ee-a6b6-fbd47ebefd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/98183be0-3125-45ee-a6b6-fbd47ebefd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/98183be0-3125-45ee-a6b6-fbd47ebefd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/98183be0-3125-45ee-a6b6-fbd47ebefd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.secuxtech.com/secuxess/#/",
				ios: "https://apps.apple.com/tw/app/secux-mobile/id1477437607",
				android:
					"https://play.google.com/store/apps/details?id=com.secuxapp&hl=zh_TW&gl=US&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "secux://",
				universal: "https://wsweb.secuxtech.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SecuX",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-23T05:46:18.680477+00:00",
		},
		"01d3eae656238d3b3354a995228f8461446e0d701eb15fd71feb34afd98c3b10": {
			id: "01d3eae656238d3b3354a995228f8461446e0d701eb15fd71feb34afd98c3b10",
			name: "DMToken",
			slug: "dmtoken",
			description:
				"A cryptocurrency solution that leverages the best of finance and memecoin",
			homepage: "https://defim.site",
			chains: ["eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "cd19f4a5-9390-4801-7587-233a3bf1d800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cd19f4a5-9390-4801-7587-233a3bf1d800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cd19f4a5-9390-4801-7587-233a3bf1d800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cd19f4a5-9390-4801-7587-233a3bf1d800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://defim.site",
				chrome: "https://defim.site",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://defim.site",
			},
			metadata: {
				shortName: "DMT",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-23T05:47:20.865939+00:00",
		},
		"848a1500e563b3a6151bbd2643fefc8e04ac088312f7f812c75d67b0badbf55f": {
			id: "848a1500e563b3a6151bbd2643fefc8e04ac088312f7f812c75d67b0badbf55f",
			name: "Lode Wallet",
			slug: "lode-wallet",
			description: "Redefining Monetary Systems with Digital Silver and Gold",
			homepage: "https://lode.one/",
			chains: ["eip155:1", "eip155:43114"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "6442d532-b118-4286-1ee4-46624fefbf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6442d532-b118-4286-1ee4-46624fefbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6442d532-b118-4286-1ee4-46624fefbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6442d532-b118-4286-1ee4-46624fefbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://lodewallet.com/",
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://lodewallet.com/",
			},
			metadata: {
				shortName: "Redefining Monetary Systems with Digital Silver and Gold",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-23T05:48:42.416041+00:00",
		},
		a93e0fd6a25178b2fa80eb882150f6b8da53c1e9f3e6d0d92019076671bb07f0: {
			id: "a93e0fd6a25178b2fa80eb882150f6b8da53c1e9f3e6d0d92019076671bb07f0",
			name: "DIDWallet",
			slug: "didwallet",
			description:
				"Multi Asset,\nMulti Chain,\nDecentralized Identity,\nYour digital assets all in one place, secured.",
			homepage: "https://www.didwallet.io/",
			chains: [
				"eip155:1",
				"eip155:3",
				"eip155:5",
				"eip155:56",
				"eip155:61",
				"eip155:63",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "bc66fa57-46f4-4e17-6cb7-5f2d9af9c000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bc66fa57-46f4-4e17-6cb7-5f2d9af9c000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bc66fa57-46f4-4e17-6cb7-5f2d9af9c000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bc66fa57-46f4-4e17-6cb7-5f2d9af9c000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/id1460083542",
				android:
					"https://play.google.com/store/apps/details?id=com.arcblock.wallet.app.product",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "abt://didwallet.io/i",
				universal: "https://didwallet.io/i",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "DID Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-23T06:23:39.157617+00:00",
		},
		"24fd8104e07aa1802aef51426222f23868013b83655fb6686c8478744dd2fda1": {
			id: "24fd8104e07aa1802aef51426222f23868013b83655fb6686c8478744dd2fda1",
			name: "Halo Wallet",
			slug: "halo-wallet",
			description: "Your Social Web3 Gateway\n",
			homepage: "https://halo.social/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:321",
				"eip155:42161",
				"eip155:43110",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "80583973-8b44-4bef-0af9-099cfdbed600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/80583973-8b44-4bef-0af9-099cfdbed600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/80583973-8b44-4bef-0af9-099cfdbed600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/80583973-8b44-4bef-0af9-099cfdbed600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/hk/app/id1633572905",
				android:
					"https://play.google.com/store/apps/details?id=com.kucoin.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/halo-wallet/nbdpmlhambbdkhkmbfpljckjcmgibalo",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isHaloWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "halowallet://",
				universal: "https://link.kuwallet.com/universallink",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Halo Wallet",
				colors: {
					primary: "#08C284",
					secondary: null,
				},
			},
			updatedAt: "2023-08-25T10:47:58.106367+00:00",
		},
		cb9d66ecab6a3a98bff2de2653e4e1ce44e84130a7ab323a2892a5c456956ffe: {
			id: "cb9d66ecab6a3a98bff2de2653e4e1ce44e84130a7ab323a2892a5c456956ffe",
			name: "Sinohope",
			slug: "sinohope",
			description:
				"Sinohope 采用 MPC-CMP 共管私鑰分片與協同簽名，實現安全自託管。支持多層級協同管理、規則引擎和審批流，引入頂級AML/KYT 系統，自動識別高風險轉賬，擁有多重安全保障",
			homepage: "https://www.sinohope.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:11155111",
				"eip155:137",
				"eip155:42170",
				"eip155:43114",
				"eip155:5",
				"eip155:56",
				"near:mainnet",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "06d056b9-aba7-453c-ddaf-a077a448ea00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/06d056b9-aba7-453c-ddaf-a077a448ea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/06d056b9-aba7-453c-ddaf-a077a448ea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/06d056b9-aba7-453c-ddaf-a077a448ea00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/sinohope-hotgroup-wallet/id1672956199",
				android:
					"https://play.google.com/store/apps/details?id=com.sinohope.mpcwallet.app",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "sinohopeapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Sinohope",
				colors: {
					primary: "#08329C",
					secondary: null,
				},
			},
			updatedAt: "2023-08-25T10:56:47.987867+00:00",
		},
		"6bd4cf3fa61dafe9088f9e93e5d1178c7e792fe349489d9a8141cf4354359712": {
			id: "6bd4cf3fa61dafe9088f9e93e5d1178c7e792fe349489d9a8141cf4354359712",
			name: "Ballet Crypto",
			slug: "ballet-crypto-1",
			description: "World’s EASIEST Cryptocurrency Cold Storage",
			homepage: "https://www.ballet.com",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "fd46e96d-350d-4922-a4a9-b2bfe7c92400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fd46e96d-350d-4922-a4a9-b2bfe7c92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fd46e96d-350d-4922-a4a9-b2bfe7c92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fd46e96d-350d-4922-a4a9-b2bfe7c92400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/id1474912942",
				android:
					"https://play.google.com/store/apps/details?id=com.balletcrypto",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "balletcrypto://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ballet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-25T10:58:05.75371+00:00",
		},
		"0e3c4838e5a7c44b6b16299b7dbc7a2bc147ad9c056588d6190e11ec5804fba9": {
			id: "0e3c4838e5a7c44b6b16299b7dbc7a2bc147ad9c056588d6190e11ec5804fba9",
			name: "OPZ Wallet",
			slug: "opz-wallet",
			description:
				"Revolutionizing cryptocurrency with our KeyFusion Technology, and seamless dApp integration.",
			homepage: "https://www.opz.com/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "bb347024-46c1-4be1-dd1f-98e6c51f8600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/bb347024-46c1-4be1-dd1f-98e6c51f8600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/bb347024-46c1-4be1-dd1f-98e6c51f8600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/bb347024-46c1-4be1-dd1f-98e6c51f8600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.opz.dev&pli=1",
				mac: null,
				windows: null,
				linux: "https://www.opz.com/apps/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "opz-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "OPZ",
				colors: {
					primary: "#7303fc",
					secondary: "#131313",
				},
			},
			updatedAt: "2023-08-25T11:26:33.603813+00:00",
		},
		"17a4ec96ceb34ade8e5215220df2051614aeebb832cc80ef19ddd7f33d5ba862": {
			id: "17a4ec96ceb34ade8e5215220df2051614aeebb832cc80ef19ddd7f33d5ba862",
			name: "Fizen Wallet",
			slug: "fizen-wallet",
			description:
				"Fizen Super App for Crypto Spending - The Bridge Between Your Crypto and Real-life Activities",
			homepage: "https://fizen.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:106",
				"eip155:137",
				"eip155:199",
				"eip155:200",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4beaa3f0-c807-4de0-dae3-c1b677fc9600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4beaa3f0-c807-4de0-dae3-c1b677fc9600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4beaa3f0-c807-4de0-dae3-c1b677fc9600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4beaa3f0-c807-4de0-dae3-c1b677fc9600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/fizen-wallet/id1621269508",
				android:
					"https://play.google.com/store/apps/details?id=com.fizen.io.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fw://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Fizen",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-25T13:35:39.015855+00:00",
		},
		deb700f0ebe34fd6c25a937629949b3c4c6c9dafdf87056190e985f189b691d2: {
			id: "deb700f0ebe34fd6c25a937629949b3c4c6c9dafdf87056190e985f189b691d2",
			name: "campux.digital",
			slug: "campuxdigital",
			description:
				"Our soccer sport passport is an interactive digital wallet designed for the global soccer community. ",
			homepage: "https://campux.digital",
			chains: [
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:irishub-1",
				"cosmos:kava-4",
				"cosmos:mayachain-mainnet-v1",
				"cosmos:mayachain-stagenet-v1",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "a635b65a-44b9-4dfa-a28f-83128d8dff00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a635b65a-44b9-4dfa-a28f-83128d8dff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a635b65a-44b9-4dfa-a28f-83128d8dff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a635b65a-44b9-4dfa-a28f-83128d8dff00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://campux.digital",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://campux.digital",
			},
			metadata: {
				shortName: "campUX",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-25T13:53:04.03528+00:00",
		},
		"56bec983b47c8b6eb774890c1c8ae9d95334e10bdb126ab6c11dfaf56fb2b31c": {
			id: "56bec983b47c8b6eb774890c1c8ae9d95334e10bdb126ab6c11dfaf56fb2b31c",
			name: "Kresus SuperApp",
			slug: "kresus-superapp",
			description:
				"Kresus is a consumer-friendly, mobile, high security, non-custodial digital wallet app for crypto and NFTs.",
			homepage: "https://www.kresus.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ee242aea-3ffd-4ad8-db88-e29a1ccd2000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ee242aea-3ffd-4ad8-db88-e29a1ccd2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ee242aea-3ffd-4ad8-db88-e29a1ccd2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ee242aea-3ffd-4ad8-db88-e29a1ccd2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/kresus-crypto-nft-superapp/id6444355152?referrer=singular_click_id%3Da713aaae-ea8e-4919-882d-a053ad9a8278",
				android:
					"https://play.google.com/store/apps/details?id=com.kresus.superapp&pli=1",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "com.kresus.superapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kresus",
				colors: {
					primary: "#030A74",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-08-29T08:08:27.235354+00:00",
		},
		"73232eaed7a1e4876f3efb13c3d526dfe4e293a152e1380095a62eb4c5814a87": {
			id: "73232eaed7a1e4876f3efb13c3d526dfe4e293a152e1380095a62eb4c5814a87",
			name: "midoin",
			slug: "midoin",
			description:
				"Midoin is a mobile-first currency aiming to be fun, easy and fast to use.",
			homepage: "https://midoin.com",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "9728246c-9504-4b0f-9881-ed848c9fa000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9728246c-9504-4b0f-9881-ed848c9fa000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9728246c-9504-4b0f-9881-ed848c9fa000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9728246c-9504-4b0f-9881-ed848c9fa000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/midoin/id1374974523",
				android: "https://play.google.com/store/apps/details?id=com.midoin",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "midoin://",
				universal: "https://midoin.app.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MDN",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-30T13:45:12.675765+00:00",
		},
		"0f5eca2c7f2c9d11c992fdc707575c484ffb751e58e43eaeeea24510bfe8b8dd": {
			id: "0f5eca2c7f2c9d11c992fdc707575c484ffb751e58e43eaeeea24510bfe8b8dd",
			name: "ONTO",
			slug: "onto-1",
			description:
				"A #DID-based #Web3 gateway for 1 million+ users on 30+ popular #blockchains, supporting 700+ dApps.",
			homepage: "https://onto.app",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1024",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1313161554",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:288",
				"eip155:42161",
				"eip155:4689",
				"eip155:50",
				"eip155:56",
				"eip155:58",
				"eip155:66",
				"neo3:mainnet",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "14934596-632e-4b29-d45f-61109e959000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/14934596-632e-4b29-d45f-61109e959000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/14934596-632e-4b29-d45f-61109e959000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/14934596-632e-4b29-d45f-61109e959000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/onto-an-ontology-dapp/id1436009823",
				android:
					"https://play.google.com/store/apps/details?id=com.github.ontio.onto",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ontoprovider://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "ONTO",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-30T13:52:01.263242+00:00",
		},
		"80c7742837ad9455049270303bccd55bae39a9e639b70d931191269d3a76320a": {
			id: "80c7742837ad9455049270303bccd55bae39a9e639b70d931191269d3a76320a",
			name: "UniPass Wallet",
			slug: "unipass-wallet",
			description: "A Seedless and Gasless User Experience for Your Web3 Apps",
			homepage: "https://unipass.id",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:321",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "d0ab3715-811f-4b2e-5293-9339e5b84c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d0ab3715-811f-4b2e-5293-9339e5b84c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d0ab3715-811f-4b2e-5293-9339e5b84c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d0ab3715-811f-4b2e-5293-9339e5b84c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: "https://wallet.unipass.id/",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.unipass.id/",
			},
			metadata: {
				shortName: "UniPass",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-30T13:58:15.534506+00:00",
		},
		b563f5fb9214c9bf33baef0e14e4bf5a479905182e46782023b1ea827f263a60: {
			id: "b563f5fb9214c9bf33baef0e14e4bf5a479905182e46782023b1ea827f263a60",
			name: "Suku Wallet",
			slug: "suku-wallet",
			description:
				"Say hello to Suku Wallet, the social media wallet and easiest way to dive into the crypto universe.",
			homepage: "https://wallet.suku.world",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "69752840-a3a9-47b5-0efc-ce33d2d7c100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/69752840-a3a9-47b5-0efc-ce33d2d7c100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/69752840-a3a9-47b5-0efc-ce33d2d7c100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/69752840-a3a9-47b5-0efc-ce33d2d7c100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "http://extension.suku.world",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/suku-wallet/fopmedgnkfpebgllppeddmmochcookhc",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Suku Wallet",
				colors: {
					primary: "#2BFF40",
					secondary: null,
				},
			},
			updatedAt: "2023-08-30T14:05:44.428809+00:00",
		},
		"37aacf1e6bf6793c892e42c3f7623a61d9ffcb4337010804cc3193c4d596cf5c": {
			id: "37aacf1e6bf6793c892e42c3f7623a61d9ffcb4337010804cc3193c4d596cf5c",
			name: "Oasys Passport",
			slug: "oasys-passport",
			description:
				"Simple and easy wallet for blockchain game beginners on Oasys",
			homepage: "https://www.oasys-wallet.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:19011",
				"eip155:248",
				"eip155:40875",
				"eip155:5",
				"eip155:80001",
				"eip155:9372",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "43eb1fb9-e0db-4c9b-d864-4d7fc5abcc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/43eb1fb9-e0db-4c9b-d864-4d7fc5abcc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/43eb1fb9-e0db-4c9b-d864-4d7fc5abcc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/43eb1fb9-e0db-4c9b-d864-4d7fc5abcc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/jp/app/oasys-passport/id6449960854",
				android:
					"https://play.google.com/store/apps/details?id=com.oasys_wallet.oas_app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "oasys-wallet://",
				universal: "https://www.oasys-wallet.com/wc/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "OAS",
				colors: {
					primary: "#038355",
					secondary: "#05D65A",
				},
			},
			updatedAt: "2023-08-31T14:09:47.999926+00:00",
		},
		"689e621a3585ca018fd44ff404bc89079a0e55e9c632574e8bf8d2b1c7918911": {
			id: "689e621a3585ca018fd44ff404bc89079a0e55e9c632574e8bf8d2b1c7918911",
			name: "GoodDollar",
			slug: "gooddollar",
			description: "Crypto Universal Basic Income",
			homepage: "https://gooddollar.org",
			chains: ["eip155:1", "eip155:122", "eip155:42220"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "371ab65b-e2c8-4843-f18a-cbcf2ba2ed00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/371ab65b-e2c8-4843-f18a-cbcf2ba2ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/371ab65b-e2c8-4843-f18a-cbcf2ba2ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/371ab65b-e2c8-4843-f18a-cbcf2ba2ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://play.google.com/store/apps/details?id=org.gooddollar",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "gooddollar://",
				universal: "https://wallet.gooddollar.org/",
			},
			desktop: {
				native: "gooddollar://",
				universal: "https://wallet.gooddollar.org/",
			},
			metadata: {
				shortName: "GoodDollar",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-08-31T14:11:49.535124+00:00",
		},
		"22086a7044492919c850e06c38b7940dca5a17c83a69d2d61581d72a1526c373": {
			id: "22086a7044492919c850e06c38b7940dca5a17c83a69d2d61581d72a1526c373",
			name: "Hellō",
			slug: "hellō",
			description: "Cloud Identity Wallet",
			homepage: "https://wallet.hello.coop/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "d365f826-0a95-48f5-0642-e25cd47c2100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d365f826-0a95-48f5-0642-e25cd47c2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d365f826-0a95-48f5-0642-e25cd47c2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d365f826-0a95-48f5-0642-e25cd47c2100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.hello.coop/",
				ios: null,
				android: null,
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.hello.coop/",
			},
			metadata: {
				shortName: "Hellō",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-01T09:53:49.703137+00:00",
		},
		be5a8ab0c885357ce212602173781d9b03f8c0308f28aa1211e1e92ce04fb2c9: {
			id: "be5a8ab0c885357ce212602173781d9b03f8c0308f28aa1211e1e92ce04fb2c9",
			name: "Competence.id",
			slug: "competenceid",
			description:
				"Competence.ID, is a self-sovereign identity solution designed to allow you build your reputation in the supply chain industry.",
			homepage: "https://competence.id",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ac1de66e-a82c-4cc5-f460-86b640e56500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ac1de66e-a82c-4cc5-f460-86b640e56500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ac1de66e-a82c-4cc5-f460-86b640e56500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ac1de66e-a82c-4cc5-f460-86b640e56500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/competenceid/id6448860364",
				android: "https://play.google.com/store/apps/details?id=id.competence",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "competenceid://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Competence.ID",
				colors: {
					primary: "#000000",
					secondary: "#25A1A1",
				},
			},
			updatedAt: "2023-09-01T15:18:31.703748+00:00",
		},
		"692ff1d9bd249d5a69ac7b5d4d0e956e9acc5a13bb97d88f484e86ebac03948a": {
			id: "692ff1d9bd249d5a69ac7b5d4d0e956e9acc5a13bb97d88f484e86ebac03948a",
			name: "Dippi",
			slug: "dippi",
			description: "Dippi",
			homepage: "https://dippi.xyz",
			chains: ["eip155:1", "eip155:137", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "0a8e62c2-0f7d-4ade-ccdb-c853e44b2000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0a8e62c2-0f7d-4ade-ccdb-c853e44b2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0a8e62c2-0f7d-4ade-ccdb-c853e44b2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0a8e62c2-0f7d-4ade-ccdb-c853e44b2000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.dippi.xyz",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.dippi.xyz",
			},
			metadata: {
				shortName: "Dippi",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-01T15:19:49.427353+00:00",
		},
		"1897fdb811ff53a69f9217a98696798b0227c7d77fddf103e8295be551a1e3b6": {
			id: "1897fdb811ff53a69f9217a98696798b0227c7d77fddf103e8295be551a1e3b6",
			name: "Spot On Chain Wallet",
			slug: "spot-on-chain-wallet",
			description:
				"Our platform provides accurate and reliable on-chain data analysis to help you spot opportunities in the crypto market.",
			homepage: "https://spotonchain.ai",
			chains: [
				"eip155:1",
				"eip155:1284",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1bcb5ed6-f6c9-4b0d-f891-b70c48b93d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1bcb5ed6-f6c9-4b0d-f891-b70c48b93d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1bcb5ed6-f6c9-4b0d-f891-b70c48b93d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1bcb5ed6-f6c9-4b0d-f891-b70c48b93d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/spot-on-chain/id6449733489",
				android:
					"https://play.google.com/store/apps/details?id=network.spotonchain",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "spotonchain://",
				universal: "https://spotonchain.ai/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Spot On Chain",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-06T10:24:42.396433+00:00",
		},
		"221eb19f9cfdf577c2f75190fc4fa24523624f604904ced78089bc82ebdb983e": {
			id: "221eb19f9cfdf577c2f75190fc4fa24523624f604904ced78089bc82ebdb983e",
			name: "DGG Wallet",
			slug: "dgg-wallet",
			description:
				"A multi-chain wallet for ease of UX and perfect for storing & managing NFTs & Tokens on the security-proven and cost-effective blockchains.",
			homepage: "https://dgg.network",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:421613",
				"eip155:42170",
				"eip155:5",
				"eip155:56",
				"eip155:61",
				"eip155:88",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "e4cbed08-8839-4bce-875f-d8917ceb7e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e4cbed08-8839-4bce-875f-d8917ceb7e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e4cbed08-8839-4bce-875f-d8917ceb7e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e4cbed08-8839-4bce-875f-d8917ceb7e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/nl/app/dgg-wallet-nfts-games/id1659618218",
				android:
					"https://play.google.com/store/apps/details?id=network.dgg.wallet&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://apps.apple.com/nl/app/dgg-wallet-nfts-games/id1659618218",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "DGG",
				},
			],
			rdns: null,
			mobile: {
				native: "dgg://",
				universal: "https://dggnetwork.app.link",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "DGGWallet",
				colors: {
					primary: "#00FFFF",
					secondary: "#050E25",
				},
			},
			updatedAt: "2023-09-06T10:45:08.113519+00:00",
		},
		de0876834d89cfbc7d50c9082727480c784c33ec559349b0541c452cd9e10ed0: {
			id: "de0876834d89cfbc7d50c9082727480c784c33ec559349b0541c452cd9e10ed0",
			name: "Deficloud",
			slug: "deficloud",
			description: "For deficloud.cc ",
			homepage: "https://app.deficloud.cc",
			chains: [
				"eip155:1",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:137",
				"eip155:1666600002",
				"eip155:200",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "f67d45d0-dbeb-4d00-3c3a-51a91bedc100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f67d45d0-dbeb-4d00-3c3a-51a91bedc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f67d45d0-dbeb-4d00-3c3a-51a91bedc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f67d45d0-dbeb-4d00-3c3a-51a91bedc100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.deficloud.cc",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.deficloud.cc",
			},
			metadata: {
				shortName: "DC",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-06T10:46:29.649167+00:00",
		},
		cb53ce18c593e8ea5d93c6a55bf848e4b75010c077f83b012b7c4e5f8fce842a: {
			id: "cb53ce18c593e8ea5d93c6a55bf848e4b75010c077f83b012b7c4e5f8fce842a",
			name: "BeanBag",
			slug: "beanbag",
			description:
				"Mobile crypto currency wallet \n\n\nKEEP getting declined no testing but i have tested and all works how can i show u",
			homepage: "https://besc.llc",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5fad49d2-a138-47bb-ac87-6368d8bd9000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5fad49d2-a138-47bb-ac87-6368d8bd9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5fad49d2-a138-47bb-ac87-6368d8bd9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5fad49d2-a138-47bb-ac87-6368d8bd9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.beanbag.wallet",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "beanbag://wallet/connect",
				universal:
					"https://play.google.com/store/apps/details?id=com.beanbag.wallet",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BESC",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-11T17:45:05.470221+00:00",
		},
		d11e890cc208235fd14e5234b4fc180eb36ade32808c980ee85cc57fe4a08ebb: {
			id: "d11e890cc208235fd14e5234b4fc180eb36ade32808c980ee85cc57fe4a08ebb",
			name: "Smart.Baby",
			slug: "smartbaby",
			description:
				"Smart.Baby is an encrypted wallet using the most cutting-edge AI technology",
			homepage: "https://smart.baby",
			chains: ["eip155:1", "eip155:137", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7f408502-e3d1-48f1-a81f-654a3f338f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7f408502-e3d1-48f1-a81f-654a3f338f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7f408502-e3d1-48f1-a81f-654a3f338f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7f408502-e3d1-48f1-a81f-654a3f338f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://smartbaby.oss-cn-hongkong.aliyuncs.com/wallet/20230912/e94cee9101074e54a717415cccd11a87.apk",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "smartbody://?uri=",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Smart.Baby",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-12T08:28:30.088025+00:00",
		},
		"8631ca765defdf51cd72444ec0284b866c835a947ed082d41c0c756a3b2eb1c2": {
			id: "8631ca765defdf51cd72444ec0284b866c835a947ed082d41c0c756a3b2eb1c2",
			name: "Gridlock Wallet",
			slug: "gridlock-wallet",
			description:
				"Gridlock enables safely navigating Web3, NFT trading and storage, purchasing crypto, and leveraging maximum security with crypto Guardians.",
			homepage: "https://gridlock.network/",
			chains: [
				"eip155:1",
				"eip155:5",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "471e6f61-b95a-453c-670c-029ef3b2bd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/471e6f61-b95a-453c-670c-029ef3b2bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/471e6f61-b95a-453c-670c-029ef3b2bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/471e6f61-b95a-453c-670c-029ef3b2bd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/gridlock-secure-crypto-wallet/id1567057330",
				android:
					"https://play.google.com/store/apps/details?id=network.gridlock.AppGridlock",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "network.gridlock.AppGridlock://",
				universal: "https://gridlock.page.link/Fihx",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Gridlock",
				colors: {
					primary: "#0096EA",
					secondary: "#F6F6F6",
				},
			},
			updatedAt: "2023-09-13T13:35:10.70688+00:00",
		},
		"49c61c13758b7a9f69018f06de961110a29cb0f0be4cb9cbbd4eb3a2bee3b857": {
			id: "49c61c13758b7a9f69018f06de961110a29cb0f0be4cb9cbbd4eb3a2bee3b857",
			name: "New Money",
			slug: "new-money",
			description:
				"The New Money Crypto Wallet designed with accessibility in mind for all users.",
			homepage: "https://newmoney.net",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:5000",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "8d4b8dd1-247d-4cc6-973a-d21122b55700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8d4b8dd1-247d-4cc6-973a-d21122b55700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8d4b8dd1-247d-4cc6-973a-d21122b55700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8d4b8dd1-247d-4cc6-973a-d21122b55700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.radstudio.newmoney",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/new-money/gpnfbmgdageboldhambihnbjdicbeojg",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isNewMoney",
				},
			],
			rdns: null,
			mobile: {
				native: "com.radstudio.newmoney://open",
				universal: "https://app.newmoney.net/open",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "NewMoney",
				colors: {
					primary: "#923FE7",
					secondary: "#000000",
				},
			},
			updatedAt: "2023-09-13T19:28:47.193722+00:00",
		},
		e6d7b916732325821a4016c374dec9e2d58becdac154ce3ea20db1fd9fe1d57e: {
			id: "e6d7b916732325821a4016c374dec9e2d58becdac154ce3ea20db1fd9fe1d57e",
			name: "Zeal",
			slug: "zeal",
			description:
				"The best web3 wallet for builders and on-chain earners on all EVM networks.",
			homepage: "https://www.zeal.app/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10000",
				"eip155:100000",
				"eip155:100001",
				"eip155:100002",
				"eip155:100003",
				"eip155:100004",
				"eip155:100005",
				"eip155:100006",
				"eip155:100007",
				"eip155:100008",
				"eip155:10001",
				"eip155:10003",
				"eip155:1001",
				"eip155:1007",
				"eip155:101",
				"eip155:1010",
				"eip155:10101",
				"eip155:1012",
				"eip155:102",
				"eip155:1022",
				"eip155:1023",
				"eip155:1024",
				"eip155:10243",
				"eip155:1026062157",
				"eip155:1028",
				"eip155:106",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:110000",
				"eip155:110001",
				"eip155:110002",
				"eip155:110003",
				"eip155:110004",
				"eip155:110005",
				"eip155:110006",
				"eip155:110007",
				"eip155:110008",
				"eip155:1101",
				"eip155:111",
				"eip155:11155111",
				"eip155:1122334455",
				"eip155:11235",
				"eip155:11297108099",
				"eip155:11297108109",
				"eip155:1139",
				"eip155:114",
				"eip155:1140",
				"eip155:11888",
				"eip155:12",
				"eip155:12051",
				"eip155:1213",
				"eip155:122",
				"eip155:123456",
				"eip155:124",
				"eip155:127",
				"eip155:1273227453",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1285",
				"eip155:1286",
				"eip155:1287",
				"eip155:1288",
				"eip155:13",
				"eip155:1313114",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:1313500",
				"eip155:13371337",
				"eip155:1350216234",
				"eip155:1351057110",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:1442",
				"eip155:1482601649",
				"eip155:15",
				"eip155:1517929550",
				"eip155:1564830818",
				"eip155:16",
				"eip155:16000",
				"eip155:16001",
				"eip155:1618",
				"eip155:162",
				"eip155:1620",
				"eip155:163",
				"eip155:1657",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:1666700000",
				"eip155:1666700001",
				"eip155:1666700002",
				"eip155:1666700003",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:18",
				"eip155:18289463",
				"eip155:1856",
				"eip155:186",
				"eip155:19",
				"eip155:19011",
				"eip155:1907",
				"eip155:1908",
				"eip155:1987",
				"eip155:199",
				"eip155:1995",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:20001",
				"eip155:200625",
				"eip155:201030",
				"eip155:20181205",
				"eip155:2020",
				"eip155:2021",
				"eip155:2022",
				"eip155:204",
				"eip155:2046399126",
				"eip155:21",
				"eip155:2100",
				"eip155:2101",
				"eip155:210309",
				"eip155:211",
				"eip155:2195",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:24484",
				"eip155:245022926",
				"eip155:245022934",
				"eip155:245022940",
				"eip155:246",
				"eip155:246529",
				"eip155:246785",
				"eip155:24734",
				"eip155:248",
				"eip155:25",
				"eip155:250",
				"eip155:2559",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:274",
				"eip155:28",
				"eip155:280",
				"eip155:288",
				"eip155:2888",
				"eip155:28945486",
				"eip155:295",
				"eip155:296",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:31102",
				"eip155:311752642",
				"eip155:3125659152",
				"eip155:31337",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:324",
				"eip155:32659",
				"eip155:3269",
				"eip155:3270",
				"eip155:33",
				"eip155:333888",
				"eip155:333999",
				"eip155:336",
				"eip155:338",
				"eip155:344106930",
				"eip155:35",
				"eip155:35855456",
				"eip155:36",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:369",
				"eip155:38",
				"eip155:385",
				"eip155:39797",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:40875",
				"eip155:41",
				"eip155:418",
				"eip155:42",
				"eip155:420",
				"eip155:42069",
				"eip155:42161",
				"eip155:421611",
				"eip155:421613",
				"eip155:4216137055",
				"eip155:421614",
				"eip155:42170",
				"eip155:42220",
				"eip155:43",
				"eip155:43110",
				"eip155:43113",
				"eip155:43114",
				"eip155:432201",
				"eip155:432204",
				"eip155:44",
				"eip155:44787",
				"eip155:4689",
				"eip155:4690",
				"eip155:4759",
				"eip155:476158412",
				"eip155:48991",
				"eip155:49797",
				"eip155:499",
				"eip155:5",
				"eip155:50",
				"eip155:5000",
				"eip155:5001",
				"eip155:503129905",
				"eip155:51",
				"eip155:5197",
				"eip155:52",
				"eip155:53",
				"eip155:534351",
				"eip155:534353",
				"eip155:54211",
				"eip155:55",
				"eip155:558",
				"eip155:56",
				"eip155:5611",
				"eip155:56288",
				"eip155:57",
				"eip155:570",
				"eip155:5700",
				"eip155:57000",
				"eip155:58",
				"eip155:5851",
				"eip155:5869",
				"eip155:59",
				"eip155:59140",
				"eip155:59144",
				"eip155:595",
				"eip155:6",
				"eip155:60",
				"eip155:60000",
				"eip155:60001",
				"eip155:60002",
				"eip155:60103",
				"eip155:61",
				"eip155:61717561",
				"eip155:62",
				"eip155:62320",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:69",
				"eip155:7",
				"eip155:70000",
				"eip155:70001",
				"eip155:70002",
				"eip155:70103",
				"eip155:7027",
				"eip155:71393",
				"eip155:721",
				"eip155:73799",
				"eip155:73927",
				"eip155:7518",
				"eip155:7575",
				"eip155:7576",
				"eip155:76",
				"eip155:77",
				"eip155:7762959",
				"eip155:777",
				"eip155:7777777",
				"eip155:78",
				"eip155:78110",
				"eip155:78281",
				"eip155:787",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:8029",
				"eip155:803",
				"eip155:8080",
				"eip155:82",
				"eip155:820",
				"eip155:821",
				"eip155:8217",
				"eip155:8285",
				"eip155:83",
				"eip155:8453",
				"eip155:84531",
				"eip155:85",
				"eip155:86",
				"eip155:8723",
				"eip155:8724",
				"eip155:88",
				"eip155:880",
				"eip155:8888",
				"eip155:8995",
				"eip155:9",
				"eip155:9000",
				"eip155:9001",
				"eip155:9372",
				"eip155:940",
				"eip155:95",
				"eip155:955305",
				"eip155:96970",
				"eip155:97",
				"eip155:9728",
				"eip155:9731",
				"eip155:9732",
				"eip155:977",
				"eip155:99",
				"eip155:99415706",
				"eip155:998",
				"eip155:999",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "5416fb0b-9aec-4ffe-b7cd-c04c79ea4300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5416fb0b-9aec-4ffe-b7cd-c04c79ea4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5416fb0b-9aec-4ffe-b7cd-c04c79ea4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5416fb0b-9aec-4ffe-b7cd-c04c79ea4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/zeal-wallet/heamnjbnflcikcggoiplibfommfbkjpj?hl=en&authuser=0",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isZeal",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Zeal",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-15T09:12:22.824254+00:00",
		},
		d6071018d2199b47d48c9590423f17afe222c35a12727db3f64ca2962a5e5709: {
			id: "d6071018d2199b47d48c9590423f17afe222c35a12727db3f64ca2962a5e5709",
			name: "IApp",
			slug: "iapp",
			description: "Wallet of ivirse team",
			homepage: "https://ivirse.com",
			chains: ["eip155:1", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "5da95e88-2d6c-4880-e6d8-b6a8d0663900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5da95e88-2d6c-4880-e6d8-b6a8d0663900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5da95e88-2d6c-4880-e6d8-b6a8d0663900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5da95e88-2d6c-4880-e6d8-b6a8d0663900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/oomy/id6446265246",
				android:
					"https://play.google.com/store/apps/details?id=com.ivirse.figame",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ivirse://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "IVIRSE",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-18T08:06:38.004943+00:00",
		},
		ce3e70881c4605a03fb86554509735d45eca98c68bd6d72e86073e962dc6e8cb: {
			id: "ce3e70881c4605a03fb86554509735d45eca98c68bd6d72e86073e962dc6e8cb",
			name: "Kayros",
			slug: "kayros",
			description: "The wallet that rewards web3 gamers",
			homepage: "https://www.kayros.games/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1111",
				"eip155:1284",
				"eip155:137",
				"eip155:1666600000",
				"eip155:200",
				"eip155:204",
				"eip155:25",
				"eip155:250",
				"eip155:42220",
				"eip155:43114",
				"eip155:4689",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "b2c3ae20-d3c9-4a47-16a1-a9862a410c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b2c3ae20-d3c9-4a47-16a1-a9862a410c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b2c3ae20-d3c9-4a47-16a1-a9862a410c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b2c3ae20-d3c9-4a47-16a1-a9862a410c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.kayros.games/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/kayros/ionnbdlogiaapopodiglgaakhiifihcl",
				firefox: null,
				safari: null,
				edge: "https://chrome.google.com/webstore/detail/kayros/ionnbdlogiaapopodiglgaakhiifihcl",
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isKayros",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kayros",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-18T08:32:02.848582+00:00",
		},
		"38692c32358198bc36b2775615ae615ad0f40404452993de2384ef64b2c5ca61": {
			id: "38692c32358198bc36b2775615ae615ad0f40404452993de2384ef64b2c5ca61",
			name: "TrustKeys Web3 SocialFi",
			slug: "trustkeys-web3-socialfi",
			description: "Web3 at your fingertips",
			homepage: "https://trustkeys.network",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:3",
				"eip155:42161",
				"eip155:421611",
				"eip155:56",
				"eip155:80001",
				"eip155:88",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "35644c6b-c6f3-4e45-b68b-e888c21afd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/35644c6b-c6f3-4e45-b68b-e888c21afd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/35644c6b-c6f3-4e45-b68b-e888c21afd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/35644c6b-c6f3-4e45-b68b-e888c21afd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/trustkeys-web3-socialfi/id1601968967",
				android:
					"https://play.google.com/store/apps/details?id=com.trustkeysnetwork",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "TKwc://",
				universal: "https://trustkeys.network/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "TrustKeys",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-19T08:46:51.213619+00:00",
		},
		c299d20788465854615bc197864027625c71f8f4735fdb3e210636d1bd1e1935: {
			id: "c299d20788465854615bc197864027625c71f8f4735fdb3e210636d1bd1e1935",
			name: "DS Security SA",
			slug: "ds-security-sa",
			description: "produce cryptocurrencies",
			homepage: "https://www.dssecurity.ch",
			chains: ["eip155:5"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "149a10a6-8914-44ea-424a-236017890100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/149a10a6-8914-44ea-424a-236017890100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/149a10a6-8914-44ea-424a-236017890100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/149a10a6-8914-44ea-424a-236017890100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser:
					"https://wiki.polygon.technology/docs/tools/wallets/metamask/config-polygon-on-metamask#polygon-scan",
				ios: "https://dssecurity.ch",
				android: "https://dssecurity.ch",
				mac: "https://dssecurity.ch",
				windows: "https://dssecurity.ch",
				linux: "https://dssecurity.ch",
				chrome: "https://dssecurity.ch",
				firefox: "https://dssecurity.ch",
				safari: "https://dssecurity.ch",
				edge: "https://dssecurity.ch",
				opera: "https://dssecurity.ch",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "https://nft.coinbase.com",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: "https://www.coinbase.com/wallet/",
			},
			desktop: {
				native: "",
				universal: "https://www.coinbase.com/wallet/",
			},
			metadata: {
				shortName:
					"nft12uam6yr4vtkkjfatkjkdvfgrww7zmwdvca9nvvj650j22xsgj6fsmlzs2d",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-19T08:53:01.965897+00:00",
		},
		"7dcb0e5eb1b4fc6e2e0b143201c489ea6c618259f49527527d4a349d1a95ba7b": {
			id: "7dcb0e5eb1b4fc6e2e0b143201c489ea6c618259f49527527d4a349d1a95ba7b",
			name: "Concordium",
			slug: "concordium",
			description:
				"The only Layer-1 with the protocol level ID, science-backed blockchain for building a safer digital world.",
			homepage: "https://concordium.com/",
			chains: [
				"ccd:4221332d34e1694168c2a0c0b3fd0f27",
				"ccd:9dd9ca4d19e9393877d2c44b70f89acb",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b1ca907e-2f5f-42a8-d11c-86a15a291600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b1ca907e-2f5f-42a8-d11c-86a15a291600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b1ca907e-2f5f-42a8-d11c-86a15a291600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b1ca907e-2f5f-42a8-d11c-86a15a291600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://concordium.com/",
				ios: "https://apps.apple.com/us/app/concordium-blockchain-wallet/id6444703764",
				android:
					"https://play.google.com/store/apps/details?id=software.concordium.mobilewallet.seedphrase.mainnet&pli=1",
				mac: "",
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/concordium-wallet/mnnkpffndmickbiakofclnpoiajlegmg?hl=en-US",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "ccd",
					injected_id: "concordium",
				},
			],
			rdns: null,
			mobile: {
				native: "concordiumwallet://",
				universal: "https://wallet.concordium.software",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Concordium",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-19T08:53:59.694943+00:00",
		},
		"63f5b2024d710b30f7cec57fb8312f4261a67074dc5043c50f2d59f4d982141a": {
			id: "63f5b2024d710b30f7cec57fb8312f4261a67074dc5043c50f2d59f4d982141a",
			name: "Ape Wallet",
			slug: "ape-wallet",
			description:
				"NFT Infrastructure for ERC4337 Account Abstraction & ERC6551 TBAs on zkSync Era. Pioneering the Apes-Themed Experience.\n",
			homepage: "https://www.zkape.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1101",
				"eip155:1442",
				"eip155:204",
				"eip155:280",
				"eip155:324",
				"eip155:420",
				"eip155:421613",
				"eip155:421614",
				"eip155:42170",
				"eip155:5",
				"eip155:5000",
				"eip155:5001",
				"eip155:534351",
				"eip155:534353",
				"eip155:5611",
				"eip155:59140",
				"eip155:59144",
				"eip155:8453",
				"eip155:84531",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "ea66f627-cc46-41c4-8287-dae2f379f700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ea66f627-cc46-41c4-8287-dae2f379f700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ea66f627-cc46-41c4-8287-dae2f379f700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ea66f627-cc46-41c4-8287-dae2f379f700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ape-layer2-wallet/id6450944029",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "ape://",
				universal: "https://zkape.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ape Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-20T08:46:55.665148+00:00",
		},
		a5b3b5055ba7333811fcb80222a421bb6ac541b3eccf99edb6d0e5040bb008e7: {
			id: "a5b3b5055ba7333811fcb80222a421bb6ac541b3eccf99edb6d0e5040bb008e7",
			name: "thirdweb",
			slug: "thirdweb",
			description: "Connect an app's embedded wallet powered by thirdweb.",
			homepage: "https://thirdweb.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:11155111",
				"eip155:137",
				"eip155:200",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:56",
				"eip155:80001",
				"eip155:8453",
				"eip155:84531",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "c8d820ec-54fb-4c0e-210e-5cbbf92e1000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c8d820ec-54fb-4c0e-210e-5cbbf92e1000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c8d820ec-54fb-4c0e-210e-5cbbf92e1000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c8d820ec-54fb-4c0e-210e-5cbbf92e1000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://thirdweb.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://ews.thirdweb.com",
			},
			desktop: {
				native: "",
				universal: "https://ews.thirdweb.com",
			},
			metadata: {
				shortName: "thirdweb",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-22T12:52:47.597945+00:00",
		},
		"59968c4e5ef18efe3a287cb1206c41fd46d69589def8fd5c4990be92401fabcb": {
			id: "59968c4e5ef18efe3a287cb1206c41fd46d69589def8fd5c4990be92401fabcb",
			name: "Oisy Wallet",
			slug: "oisy-wallet",
			description:
				"An on-chain Ethereum wallet hosted on the Internet Computer, uses networked custody for security and Internet Identity for authentication.",
			homepage: "https://oisy.com",
			chains: ["eip155:1", "eip155:11155111"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "8e951613-bf2a-4d74-230c-0b02ca72d700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8e951613-bf2a-4d74-230c-0b02ca72d700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8e951613-bf2a-4d74-230c-0b02ca72d700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8e951613-bf2a-4d74-230c-0b02ca72d700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://oisy.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://oisy.com",
			},
			metadata: {
				shortName: "Oisy",
				colors: {
					primary: "#3B00B9",
					secondary: "#DE5BCA",
				},
			},
			updatedAt: "2023-09-22T13:00:46.69671+00:00",
		},
		"14e5d957c6eb62d3ee8fc6239703ac2d537d7e3552154836ca0beef775f630bc": {
			id: "14e5d957c6eb62d3ee8fc6239703ac2d537d7e3552154836ca0beef775f630bc",
			name: "Pitaka",
			slug: "pitaka",
			description:
				"Secure your wealth from external threats like inflation, seizure, and censorship.",
			homepage: "https://pitaka.io",
			chains: [
				"cosmos:kava-4",
				"eip155:1",
				"eip155:10",
				"eip155:10000",
				"eip155:10001",
				"eip155:1001",
				"eip155:10101",
				"eip155:1028",
				"eip155:11297108099",
				"eip155:11297108109",
				"eip155:1284",
				"eip155:1285",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:1666700000",
				"eip155:1666700001",
				"eip155:1666700002",
				"eip155:1666700003",
				"eip155:19",
				"eip155:199",
				"eip155:200",
				"eip155:25",
				"eip155:28",
				"eip155:288",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:32659",
				"eip155:333888",
				"eip155:333999",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:41",
				"eip155:42",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:44787",
				"eip155:4689",
				"eip155:4690",
				"eip155:5",
				"eip155:56",
				"eip155:6",
				"eip155:61",
				"eip155:62",
				"eip155:63",
				"eip155:65",
				"eip155:66",
				"eip155:8",
				"eip155:8217",
				"eip155:9",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c816aeae-e0d1-4c52-f37a-efde6df1ee00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c816aeae-e0d1-4c52-f37a-efde6df1ee00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c816aeae-e0d1-4c52-f37a-efde6df1ee00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c816aeae-e0d1-4c52-f37a-efde6df1ee00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ph/app/pitaka-blockchain-wallet/id1644341925",
				android:
					"https://play.google.com/store/apps/details?id=com.pitakamobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pitaka://",
				universal: "https://app.pitaka.io",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Pitaka",
				colors: {
					primary: "#613cb0",
					secondary: "#a7a1f7",
				},
			},
			updatedAt: "2023-09-25T12:31:48.996265+00:00",
		},
		f37a272090b3b7620c8be1d2ed40cf435055789fc16ab2b710ecec124b62b634: {
			id: "f37a272090b3b7620c8be1d2ed40cf435055789fc16ab2b710ecec124b62b634",
			name: "LichtBit",
			slug: "lichtbit",
			description: "This Explorer Lichtbit to show Transaction and token",
			homepage: "https://scan.lichtbit.net",
			chains: ["eip155:1", "eip155:5"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "f67b54c7-c5e2-4796-5bc6-eca4f3e6e800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f67b54c7-c5e2-4796-5bc6-eca4f3e6e800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f67b54c7-c5e2-4796-5bc6-eca4f3e6e800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f67b54c7-c5e2-4796-5bc6-eca4f3e6e800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://scan.lichtbit.net",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isMetamask",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://scan.lichtbit.net",
			},
			metadata: {
				shortName: "LCB",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-25T12:32:02.632646+00:00",
		},
		"63488e0e0679da829dd964a670f2d0bf8643f52aa2d5a8a8539c3a4fcad6a78a": {
			id: "63488e0e0679da829dd964a670f2d0bf8643f52aa2d5a8a8539c3a4fcad6a78a",
			name: "Trustee Wallet",
			slug: "trustee-wallet",
			description:
				"Support 31 blockchain & tokens of ERC20, BEP20, TRC10/TRC20, SPL, NFT. Built-in crypto swap & ETH, SOL, TRX, VET staking.",
			homepage: "https://trusteeglobal.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:10001",
				"eip155:106",
				"eip155:137",
				"eip155:1666600000",
				"eip155:199",
				"eip155:250",
				"eip155:3",
				"eip155:4",
				"eip155:56",
				"eip155:61",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"stellar:pubnet",
				"vechain:b1ac3413d346d43539627e6be7ec1b4a",
				"waves:087",
				"xrpl:0",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "853e6f0a-ccb6-4b7e-e73b-89171a4e0a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/853e6f0a-ccb6-4b7e-e73b-89171a4e0a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/853e6f0a-ccb6-4b7e-e73b-89171a4e0a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/853e6f0a-ccb6-4b7e-e73b-89171a4e0a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/trustee-wallet/id1462924276",
				android:
					"https://play.google.com/store/apps/details?id=com.trusteewallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "tw://",
				universal: "https://trusteeglobal.com/link/Pxxum8Yt",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Trustee Wallet",
				colors: {
					primary: "#f7f7f7",
					secondary: "#404040",
				},
			},
			updatedAt: "2023-09-25T12:33:14.590394+00:00",
		},
		c342b7446487de20c6112b356df719506a0b60ce7c2cd78729cc79d75b89e732: {
			id: "c342b7446487de20c6112b356df719506a0b60ce7c2cd78729cc79d75b89e732",
			name: "rss wallet",
			slug: "rss-wallet",
			description: "This is rss token rss-wallet",
			homepage: "https://mugambo.org",
			chains: ["polkadot:91b171bb158e2d3848fa23a9f1c25182"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "920c743d-950f-4d53-64ec-d342e272e500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/920c743d-950f-4d53-64ec-d342e272e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/920c743d-950f-4d53-64ec-d342e272e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/920c743d-950f-4d53-64ec-d342e272e500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://bo.centapey.com/rsswallet_2.0.2.3.apk",
				mac: null,
				windows: null,
				linux: "https://bo.centapey.com/rsswallet_2.0.2.3.apk",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "rsswallet://rss.app.link",
				universal: "https://rss.app.link/rsswallet",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "RSS Wallet",
				colors: {
					primary: "#242629",
					secondary: "#242629",
				},
			},
			updatedAt: "2023-09-27T14:13:21.147697+00:00",
		},
		"303766b6cc6768b384c84d63a02c5f302e1faa1c8d26a624623060316fc1f68c": {
			id: "303766b6cc6768b384c84d63a02c5f302e1faa1c8d26a624623060316fc1f68c",
			name: "Dropp",
			slug: "dropp",
			description:
				"Dropp wallet enables making quick, convenient and cost-effective small value payments in FIAT and digital currencies such as HBAR, USDC.",
			homepage: "https://dropp.cc/",
			chains: ["hedera:mainnet", "hedera:testnet"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "526fb7ea-d0da-482a-ac84-7e38afea1700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/526fb7ea-d0da-482a-ac84-7e38afea1700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/526fb7ea-d0da-482a-ac84-7e38afea1700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/526fb7ea-d0da-482a-ac84-7e38afea1700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/dropp-cc/id1544894404",
				android:
					"https://play.google.com/store/apps/details?id=cc.dropp.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/dropp-extension/hgfpnmhnmmneldokmpncjmeijkapgbbf?hl=en-US",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "dropp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Dropp",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-09-28T08:11:19.514813+00:00",
		},
		"9051a97842275b8157ed5df27b77096def499f002a2f227ef6df0c6c6a0e69fb": {
			id: "9051a97842275b8157ed5df27b77096def499f002a2f227ef6df0c6c6a0e69fb",
			name: "Roam",
			slug: "roam",
			description: "Browser & Wallet",
			homepage: "https://roam.xyz",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:7777777",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "a4500b0c-47e3-4c4a-207e-d72a57f1ca00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a4500b0c-47e3-4c4a-207e-d72a57f1ca00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a4500b0c-47e3-4c4a-207e-d72a57f1ca00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a4500b0c-47e3-4c4a-207e-d72a57f1ca00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://testflight.apple.com/join/hM8Ba1Qd",
				android: "https://google.com",
				mac: "https://google.com",
				windows: "https://google.com",
				linux: "https://google.com",
				chrome: "Roam.xyz",
				firefox: "https://google.com",
				safari: "https://google.com",
				edge: "https://google.com",
				opera: "https://google.com",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isRoam",
				},
			],
			rdns: "xyz.roam.wallet",
			mobile: {
				native: "roam://",
				universal: null,
			},
			desktop: {
				native: "roam://",
				universal: null,
			},
			metadata: {
				shortName: "Roam",
				colors: {
					primary: "#bcbcbc",
					secondary: null,
				},
			},
			updatedAt: "2023-09-29T18:55:23.482474+00:00",
		},
		"96dec48797b543cc455efc53f1f6548d1af490534892b7a639748948fc738e6a": {
			id: "96dec48797b543cc455efc53f1f6548d1af490534892b7a639748948fc738e6a",
			name: "My Cloud Wallet",
			slug: "my-cloud-wallet",
			description:
				"My Cloud Wallet (MCW) is your gateway to explore and engage with WAX Blockchain",
			homepage: "https://www.mycloudwallet.com/",
			chains: [
				"antelope:1064487b3cd1a897ce03ae5b6a865651",
				"antelope:f16b1833c747c43682f4386fca9cbb32",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "2fac85b3-85a3-4dd2-8e9d-8ea2cfa28100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2fac85b3-85a3-4dd2-8e9d-8ea2cfa28100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2fac85b3-85a3-4dd2-8e9d-8ea2cfa28100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2fac85b3-85a3-4dd2-8e9d-8ea2cfa28100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.mycloudwallet.com/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://www.mycloudwallet.com/",
			},
			metadata: {
				shortName: "MyCloudWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-03T12:48:41.478878+00:00",
		},
		b75bd05179c7b969fc6cd830579cef10f4bf36a3a72433294a1c942c5f4af317: {
			id: "b75bd05179c7b969fc6cd830579cef10f4bf36a3a72433294a1c942c5f4af317",
			name: "3mint",
			slug: "3mint",
			description:
				"3mint is a brand-focused web3 loyalty platform based on digital collectibles.",
			homepage: "https://app.3mint.io",
			chains: [
				"eip155:10",
				"eip155:137",
				"eip155:420",
				"eip155:421613",
				"eip155:42170",
				"eip155:71393",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "3143a461-002d-4e49-b0f7-32f58ec6b700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3143a461-002d-4e49-b0f7-32f58ec6b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3143a461-002d-4e49-b0f7-32f58ec6b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3143a461-002d-4e49-b0f7-32f58ec6b700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.3mint.io/wallet",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.3mint.io/wallet",
			},
			metadata: {
				shortName: "3mint",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-04T12:35:48.068808+00:00",
		},
		"6148e2d844e21c546354d04255e9c2bb7f87aaccf3113709f09212c5f3277a9b": {
			id: "6148e2d844e21c546354d04255e9c2bb7f87aaccf3113709f09212c5f3277a9b",
			name: "Qoin Wallet",
			slug: "qoin-wallet",
			description:
				"Discover the future of buying and saving with Qoin Wallet. Download the app, join our community, and enjoy seamless, free transactions.",
			homepage: "https://qoin.world",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:333888",
				"eip155:420",
				"eip155:5",
				"eip155:56",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "0490da30-b59c-4365-fef8-33a3e0aa4700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0490da30-b59c-4365-fef8-33a3e0aa4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0490da30-b59c-4365-fef8-33a3e0aa4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0490da30-b59c-4365-fef8-33a3e0aa4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/au/app/qoin-wallet/id1483718254",
				android:
					"https://play.google.com/store/apps/details?id=com.qoin.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wcqoin://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Qoin",
				colors: {
					primary: "#193a83",
					secondary: null,
				},
			},
			updatedAt: "2023-10-05T12:05:51.713298+00:00",
		},
		a78c2c969af82bc38a9c8fbe8ad9ee682d9c8c76b1a5d0f167e8f90975c3e0c8: {
			id: "a78c2c969af82bc38a9c8fbe8ad9ee682d9c8c76b1a5d0f167e8f90975c3e0c8",
			name: "MELDapp",
			slug: "meldapp",
			description:
				"MELDapp is a web3 multichain non-custodial wallet with integrated financial and crypto tools.",
			homepage: "https://app.meld.com",
			chains: ["cip-34:1-764824073", "eip155:1", "eip155:43114"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "7946da4c-c8b7-4037-f044-8c3f7f1cb200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7946da4c-c8b7-4037-f044-8c3f7f1cb200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7946da4c-c8b7-4037-f044-8c3f7f1cb200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7946da4c-c8b7-4037-f044-8c3f7f1cb200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.meld.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.meld.app",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.meld.com",
			},
			metadata: {
				shortName: "MELD",
				colors: {
					primary: "#E51B44",
					secondary: "#B40025",
				},
			},
			updatedAt: "2023-10-09T09:00:36.451929+00:00",
		},
		fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49: {
			id: "fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49",
			name: "Best Wallet",
			slug: "best-wallet",
			description: "The best independent crypto wallet\n",
			homepage: "https://bestwallet.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:56",
				"eip155:80001",
				"eip155:84531",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "7f9574ed-eb42-4e04-0888-be2939936700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7f9574ed-eb42-4e04-0888-be2939936700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7f9574ed-eb42-4e04-0888-be2939936700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7f9574ed-eb42-4e04-0888-be2939936700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/best-wallet/id6451312105",
				android:
					"https://play.google.com/store/apps/details?id=com.bestwallet.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bw://app/connect",
				universal: "https://best-wallet-prod.web.app/connect",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Best Wallet",
				colors: {
					primary: "#5A63FF",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-10-12T08:07:07.490447+00:00",
		},
		a94328bcaff3ed69de88cb6347b6933370d16455af591e4a46e7e0d7c2a485c8: {
			id: "a94328bcaff3ed69de88cb6347b6933370d16455af591e4a46e7e0d7c2a485c8",
			name: "HyperPay",
			slug: "hyperpay",
			description: "Where your crypto wants to stay!",
			homepage: "https://www.hyperpay.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:128",
				"eip155:137",
				"eip155:250",
				"eip155:324",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:66",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "44abbf25-f8c4-4d04-0ce7-a695e00d8e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/44abbf25-f8c4-4d04-0ce7-a695e00d8e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/44abbf25-f8c4-4d04-0ce7-a695e00d8e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/44abbf25-f8c4-4d04-0ce7-a695e00d8e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/ae/app/hyperpay-bitcoin-crypto-wallet/id1354755812",
				android:
					"https://play.google.com/store/apps/details?id=com.legendwd.hyperpayW&hl&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "hyperPay://",
				universal: null,
			},
			desktop: {
				native: "hyperPay://",
				universal: null,
			},
			metadata: {
				shortName: "HyperPay",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-13T08:06:06.327333+00:00",
		},
		"3e2f036d9c513d07af5468ad7672e42a27432a54eb1242e498d1a1be1f488c4d": {
			id: "3e2f036d9c513d07af5468ad7672e42a27432a54eb1242e498d1a1be1f488c4d",
			name: "Xucre",
			slug: "xucre",
			description:
				"We are a LATAM specific self-custody wallet. We seek to liberate access to cryptocurrencies, obtaining capital and investments.",
			homepage: "https://www.xucre.io",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6a560c0d-817d-4f62-ef21-b91636b30f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6a560c0d-817d-4f62-ef21-b91636b30f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6a560c0d-817d-4f62-ef21-b91636b30f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6a560c0d-817d-4f62-ef21-b91636b30f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=xucre.expo.client",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "xucre.expo.client://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Xucre",
				colors: {
					primary: "#D4E815",
					secondary: "#868686",
				},
			},
			updatedAt: "2023-10-13T08:09:55.550484+00:00",
		},
		b1b0a0916c431f46a062ee2a757d93454c82ff1f22e00bf83ba1a9abd09570ab: {
			id: "b1b0a0916c431f46a062ee2a757d93454c82ff1f22e00bf83ba1a9abd09570ab",
			name: "HERE Wallet",
			slug: "here-wallet",
			description:
				"HERE Wallet - secure, fun and powerful client for crypto exchanges.\n\nKeep money safe and use Binance via HERE app when you really need it.\n\n",
			homepage: "https://herewallet.app",
			chains: ["eip155:1", "eip155:1313161554"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "21cadc07-2f45-4860-3358-83a2057b6300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/21cadc07-2f45-4860-3358-83a2057b6300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/21cadc07-2f45-4860-3358-83a2057b6300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/21cadc07-2f45-4860-3358-83a2057b6300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://download.herewallet.app?ios",
				android: "https://download.herewallet.app?android",
				mac: "",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "herewallet://",
				universal: "https://my.herewallet",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "HERE",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-16T08:13:17.013696+00:00",
		},
		"32caff85195f843b19e79669e63bf3f4ad97b23b3a48b65b3781c0d193a3fcae": {
			id: "32caff85195f843b19e79669e63bf3f4ad97b23b3a48b65b3781c0d193a3fcae",
			name: "Cake Wallet",
			slug: "cake-wallet",
			description:
				"Cake Wallet allows you to safely store, exchange, and spend your Monero, Bitcoin, Ethereum, Litecoin, and Haven.",
			homepage: "https://cakewallet.com/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "547998c5-7908-4f11-bdc3-93da789d8c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/547998c5-7908-4f11-bdc3-93da789d8c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/547998c5-7908-4f11-bdc3-93da789d8c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/547998c5-7908-4f11-bdc3-93da789d8c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/cake-wallet/id1334702542?platform=iphone",
				android:
					"https://play.google.com/store/apps/details?id=com.cakewallet.cake_wallet",
				mac: "https://apps.apple.com/us/app/cake-wallet/id1334702542?platform=mac",
				windows: null,
				linux: "https://github.com/cake-tech/cake_wallet/releases/tag/v4.10.0",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "cakewallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cake Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-17T09:00:09.234356+00:00",
		},
		"8eefa62c2f86b4c73bd68cf5cb178e09d15cbf21399ac5aea234d2b616e9ae9d": {
			id: "8eefa62c2f86b4c73bd68cf5cb178e09d15cbf21399ac5aea234d2b616e9ae9d",
			name: "una Wallet",
			slug: "una",
			description: "Unbound Blockchain experience",
			homepage: "https://unawallet.unagi.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:1111",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "78118aa8-8dfc-4742-86f7-b51d34e7cf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/78118aa8-8dfc-4742-86f7-b51d34e7cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/78118aa8-8dfc-4742-86f7-b51d34e7cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/78118aa8-8dfc-4742-86f7-b51d34e7cf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/una-wallet/id6456397239",
				android:
					"https://play.google.com/store/apps/details?id=app.unawallet.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "unaWallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "una Wallet",
				colors: {
					primary: "#000000",
					secondary: null,
				},
			},
			updatedAt: "2023-10-18T10:32:09.320259+00:00",
		},
		"50cca6f943812d2852752b7e021f251d50d410878b54c18a81ad71bcaa439035": {
			id: "50cca6f943812d2852752b7e021f251d50d410878b54c18a81ad71bcaa439035",
			name: "Ethos Self-Custody Vault",
			slug: "ethos-self-custody-vault",
			description: "Self-custody made faster, easier, and more powerful!",
			homepage: "https://www.ethos.io/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8bc7fb62-6f6b-4473-2e4a-5691a646fc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8bc7fb62-6f6b-4473-2e4a-5691a646fc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8bc7fb62-6f6b-4473-2e4a-5691a646fc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8bc7fb62-6f6b-4473-2e4a-5691a646fc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/ethos-self-custody-vault/id6450948705",
				android:
					"https://play.google.com/store/apps/details?id=com.ethos2.prod&hl=en_US&gl=US&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "myapp://home",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Ethos",
				colors: {
					primary: "#B6FBC9",
					secondary: "#009587",
				},
			},
			updatedAt: "2023-10-19T09:43:21.253638+00:00",
		},
		dd15a3530dc4de4c50ebb22010824c41337403efec713f1187695c72934fb94c: {
			id: "dd15a3530dc4de4c50ebb22010824c41337403efec713f1187695c72934fb94c",
			name: "Plus Wallet",
			slug: "pluswallet",
			description:
				"Plus Wallet is your all-in-one solution for managing digital assets and exploring the fascinating world of blockchain technology. ",
			homepage: "https://pluswallet.app",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2c21491b-58ce-47bb-ace9-bdef71f43600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2c21491b-58ce-47bb-ace9-bdef71f43600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2c21491b-58ce-47bb-ace9-bdef71f43600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2c21491b-58ce-47bb-ace9-bdef71f43600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/plus-wallet-app/id6467651848",
				android: "https://play.google.com/store/apps/details?id=com.PlusWallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pluswallet://",
				universal: "https://pluswallet.app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Plus Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-23T08:05:12.079663+00:00",
		},
		aa01d13483db4c065b7a619b813f9eae1cb60c19bf0fc0ed3c36444a07257bd0: {
			id: "aa01d13483db4c065b7a619b813f9eae1cb60c19bf0fc0ed3c36444a07257bd0",
			name: "AT.Wallet",
			slug: "atwallet",
			description: "The 1st fingerprint enabled Cold Wallet",
			homepage: "https://authentrend.com/at-wallet/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "98bd3b9a-097e-4743-8808-986b4ad1ad00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/98bd3b9a-097e-4743-8808-986b4ad1ad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/98bd3b9a-097e-4743-8808-986b4ad1ad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/98bd3b9a-097e-4743-8808-986b4ad1ad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://authentrend.com/at-wallet/",
				ios: "https://apps.apple.com/app/at-wallet/id1479171310",
				android:
					"https://play.google.com/store/apps/details?id=com.authentrend.atwallet",
				mac: "https://apps.apple.com/app/at-wallet/id1490097962",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "atwallet://",
				universal: null,
			},
			desktop: {
				native: "atwallet://",
				universal: "https://apps.apple.com/us/app/at-wallet/id1490097962?mt=12",
			},
			metadata: {
				shortName: "AT.Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-23T08:08:19.149646+00:00",
		},
		"9654c004e02e492c30904a820154e239886edbf4d66bc5d372060809ef4c9111": {
			id: "9654c004e02e492c30904a820154e239886edbf4d66bc5d372060809ef4c9111",
			name: "Plena-App",
			slug: "plena-app",
			description:
				"Invest in 100,000+ Cryptocurrencies: Send, Receive, Swap and Bridge Your Assets in a Single Tap With The First Crypto SuperApp.",
			homepage: "https://plena.finance",
			chains: [
				"eip155:1101",
				"eip155:137",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "9ba07b43-3db1-4e8d-100e-8c91d8430c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9ba07b43-3db1-4e8d-100e-8c91d8430c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9ba07b43-3db1-4e8d-100e-8c91d8430c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9ba07b43-3db1-4e8d-100e-8c91d8430c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/plena-smartest-defi-wallet/id1608061460",
				android: "https://play.google.com/store/apps/details?id=com.plena",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.plena.finance/app/d7KJGPgkKBKoSSWy9",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Plena Finance",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-23T08:09:40.909095+00:00",
		},
		e1684aeadc8aed904612091e36a948369b1ba8eddb1b1f0fbbddbe3a72a5b87f: {
			id: "e1684aeadc8aed904612091e36a948369b1ba8eddb1b1f0fbbddbe3a72a5b87f",
			name: "Numo Wallet",
			slug: "numo-wallet",
			description: "Your gateway to new money",
			homepage: "https://my.r3vl.xyz",
			chains: [
				"cosmos:xstaxy-1",
				"eip155:1",
				"eip155:1101",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "c1ebcdef-9fba-4bd0-6f6a-e43caefacc00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c1ebcdef-9fba-4bd0-6f6a-e43caefacc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c1ebcdef-9fba-4bd0-6f6a-e43caefacc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c1ebcdef-9fba-4bd0-6f6a-e43caefacc00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://vercel.numo.xyz",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://vercel.numo.xyz",
			},
			metadata: {
				shortName: "Numo",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-23T08:10:10.743996+00:00",
		},
		e90b3efa35dce75097b85d897d4bb80342b74cf820906b6edbf59c934d319ccf: {
			id: "e90b3efa35dce75097b85d897d4bb80342b74cf820906b6edbf59c934d319ccf",
			name: "MG",
			slug: "mg",
			description:
				"Onboarding ETH Users To Your Solana dApp - Pain In The dAss?",
			homepage: "https://moongate.one",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:42170",
				"eip155:43114",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "84946308-413c-4191-5414-a325547a5c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/84946308-413c-4191-5414-a325547a5c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/84946308-413c-4191-5414-a325547a5c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/84946308-413c-4191-5414-a325547a5c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.moongate.one/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: "https://wallet.moongate.one/",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.moongate.one",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "MoonGate",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-26T09:27:41.556769+00:00",
		},
		"841b1ef7139a08ee064e626f9f946154b0a80096c3417abe49ced448217fcf4c": {
			id: "841b1ef7139a08ee064e626f9f946154b0a80096c3417abe49ced448217fcf4c",
			name: "BitFrost",
			slug: "bitfrost",
			description: "Bitfrost is a non-custodial mobile crypto wallet",
			homepage: "https://bifrostwallet.com",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "cd37c47c-95df-464b-0ebf-d7d5313f2900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cd37c47c-95df-464b-0ebf-d7d5313f2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cd37c47c-95df-464b-0ebf-d7d5313f2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cd37c47c-95df-464b-0ebf-d7d5313f2900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/gb/app/bifrost-wallet/id1577198351",
				android:
					"https://play.google.com/store/apps/details?id=com.bifrostwallet.app&hl=en_GB&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bifrostwallet://wallet-connect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BitFrost",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-26T09:31:27.338192+00:00",
		},
		fa9c3adc4f0bbe263db1565d200f776e5da900ead0f1914e0ecbf8b313d268e9: {
			id: "fa9c3adc4f0bbe263db1565d200f776e5da900ead0f1914e0ecbf8b313d268e9",
			name: "WemixWallet",
			slug: "wemixwallet",
			description:
				"WEMIXWallet is a decentralized wallet that can be easily used by any user using the blockchain.",
			homepage: "https://www.wemix.com/",
			chains: ["eip155:1111"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "2439d9a4-2c1e-4d29-3bc6-654fc23a4b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2439d9a4-2c1e-4d29-3bc6-654fc23a4b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2439d9a4-2c1e-4d29-3bc6-654fc23a4b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2439d9a4-2c1e-4d29-3bc6-654fc23a4b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/kr/app/wemix-wallet/id1628230003",
				android:
					"https://play.google.com/store/apps/details?id=com.wemixfoundation.wemixwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wemixwallet30://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "WEMIX3.0",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-31T08:50:23.223464+00:00",
		},
		dcb5cd7bb4a8849288f8b43dea7bf20d7b7e2b096b630964f9ca5bf808531edd: {
			id: "dcb5cd7bb4a8849288f8b43dea7bf20d7b7e2b096b630964f9ca5bf808531edd",
			name: "Gem Wallet",
			slug: "gem-wallet",
			description: "Friendly Crypto Wallet.",
			homepage: "https://gemwallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "28f1b431-9d2a-4083-1bf8-5958939a2300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/28f1b431-9d2a-4083-1bf8-5958939a2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/28f1b431-9d2a-4083-1bf8-5958939a2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/28f1b431-9d2a-4083-1bf8-5958939a2300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id6448712670?mt=8",
				android:
					"https://play.google.com/store/apps/details?id=com.gemwallet.android",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "gem://",
				universal: "https://gemwallet.com",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Gem Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-10-31T08:56:51.138281+00:00",
		},
		"427efc9169b13a348d98d34e40303546a3e7352d725c06b9356882a26773b1a3": {
			id: "427efc9169b13a348d98d34e40303546a3e7352d725c06b9356882a26773b1a3",
			name: "Multix",
			slug: "multix",
			description:
				"Multix is an interface to easily manage complex multisigs on Polkadot, Kusama and their parachains.",
			homepage: "https://multix.chainsafe.io",
			chains: [
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "f7b7e864-29a7-41c2-36b0-c96ef922da00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f7b7e864-29a7-41c2-36b0-c96ef922da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f7b7e864-29a7-41c2-36b0-c96ef922da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f7b7e864-29a7-41c2-36b0-c96ef922da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://multix.chainsafe.io",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://multix.chainsafe.io",
				chrome: "https://multix.chainsafe.io",
				firefox: "https://multix.chainsafe.io",
				safari: "https://multix.chainsafe.io",
				edge: "https://multix.chainsafe.io",
				opera: "https://multix.chainsafe.io",
			},
			injected: [
				{
					namespace: "polkadot",
					injected_id: "sign",
				},
			],
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://multix.chainsafe.io",
			},
			metadata: {
				shortName: "Multix",
				colors: {
					primary: "#4B67A9",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2023-10-31T21:21:09.819396+00:00",
		},
		"9aa288a4c66884bec28af54056921660ebde9debd848ef03902363ed534c96d4": {
			id: "9aa288a4c66884bec28af54056921660ebde9debd848ef03902363ed534c96d4",
			name: "Caesium",
			slug: "caesium",
			description:
				"Caesium is a community-driven platform where gamers get to monetize their cryptos and witness real gaming experiences on the blockchain.",
			homepage: "https://caesiumlab.com/",
			chains: ["eip155:1", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b3a456db-43c7-463c-cc3c-8c550c5b9500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b3a456db-43c7-463c-cc3c-8c550c5b9500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b3a456db-43c7-463c-cc3c-8c550c5b9500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b3a456db-43c7-463c-cc3c-8c550c5b9500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://play.google.com/store/apps/details?id=com.csm_app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "caesium-app://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "caesiumlab",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-02T14:25:23.121198+00:00",
		},
		"79a37a33816cfba8b2ab936c440a69efdd555414e3eed7f43a2f12dab5e8807b": {
			id: "79a37a33816cfba8b2ab936c440a69efdd555414e3eed7f43a2f12dab5e8807b",
			name: "FINTOKEN",
			slug: "fintoken",
			description:
				"FINTOKEN is an incredibly powerful Web3 wallet ,It offers decentralized asset management, supports 100+ public blockchains.",
			homepage: "https://fintoken.pro/",
			chains: [
				"eip155:1",
				"eip155:128",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "420ababa-3c29-4711-4487-84b93bfa5900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/420ababa-3c29-4711-4487-84b93bfa5900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/420ababa-3c29-4711-4487-84b93bfa5900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/420ababa-3c29-4711-4487-84b93bfa5900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/fintoken-web3-crypto-wallet/id6447503215",
				android:
					"https://play.google.com/store/apps/details?id=com.digitalasset.fintoken&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "fintoken://",
				universal: "https://ios.fintoken.pro/app",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "FINTOKEN",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-02T14:26:44.968458+00:00",
		},
		"00ee7b0d5c47fa12142b37c1cf3a6a847fdd277c23d2f40fea45c1d5166e0084": {
			id: "00ee7b0d5c47fa12142b37c1cf3a6a847fdd277c23d2f40fea45c1d5166e0084",
			name: "PEAKDEFI",
			slug: "peakdefi",
			description:
				"PEAKDEFI mobile wallet app - The Ethereum wallet as one-stop solution for taking control of your decentralized finances!",
			homepage: "https://peakdefi.com/",
			chains: ["eip155:1", "eip155:137", "eip155:43114", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "918006e2-2f6d-4233-0e72-10c2caaed500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/918006e2-2f6d-4233-0e72-10c2caaed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/918006e2-2f6d-4233-0e72-10c2caaed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/918006e2-2f6d-4233-0e72-10c2caaed500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.peakdefiwallet&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PEAKDEFI",
				colors: {
					primary: "#3B8EEC",
					secondary: "#3CCCF1",
				},
			},
			updatedAt: "2023-11-06T09:41:18.437088+00:00",
		},
		"4a4de89b894737cd99fe857c3253da54773c093b67381a5cd63723543e888a54": {
			id: "4a4de89b894737cd99fe857c3253da54773c093b67381a5cd63723543e888a54",
			name: "Nodle",
			slug: "nodle",
			description:
				"A mobile wallet and an earning app for NODL tokens, Nodle's native cryptocurrency.",
			homepage: "https://www.nodle.com/products/cash_app",
			chains: ["polkadot:91b171bb158e2d3848fa23a9f1c25182"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "39badb5c-adeb-4188-c803-a63a5cffdf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/39badb5c-adeb-4188-c803-a63a5cffdf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/39badb5c-adeb-4188-c803-a63a5cffdf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/39badb5c-adeb-4188-c803-a63a5cffdf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/nodle-app-to-earn-crypto/id1480763553",
				android: "https://play.google.com/store/apps/details?id=io.nodle.cash",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "nodle://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Nodle",
				colors: {
					primary: "#14CC8F",
					secondary: "#1E273A",
				},
			},
			updatedAt: "2023-11-06T09:49:19.753902+00:00",
		},
		"9dd1c1d121368ddefacbbbeab1626b756c0b85225a19635f743fa09d83f16369": {
			id: "9dd1c1d121368ddefacbbbeab1626b756c0b85225a19635f743fa09d83f16369",
			name: "Hoo!Wallet",
			slug: "hoowallet",
			description:
				"HooWallet is a #cryptocurrency wallet that gives you total control over your digital assets. Secure, private, decentralized.",
			homepage: "https://hwallet.digital",
			chains: [
				"eip155:1",
				"eip155:100",
				"eip155:1313161554",
				"eip155:137",
				"eip155:200",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "3b24d88e-adff-4719-2e17-f4d42ebbbf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/3b24d88e-adff-4719-2e17-f4d42ebbbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/3b24d88e-adff-4719-2e17-f4d42ebbbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/3b24d88e-adff-4719-2e17-f4d42ebbbf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: "https://hwallet.digital",
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://hwallet.digital",
			},
			metadata: {
				shortName: "Hoo!Wallet",
				colors: {
					primary: "#000000",
					secondary: "#0000FF",
				},
			},
			updatedAt: "2023-11-06T09:49:33.283907+00:00",
		},
		"45aa096282002911a77c9e1dba16dade905960e6386e681c2f8d7966f4e475b1": {
			id: "45aa096282002911a77c9e1dba16dade905960e6386e681c2f8d7966f4e475b1",
			name: "Cryptokara",
			slug: "cryptokara",
			description: "CryptoKara - Fully decentralised wallet",
			homepage: "https://cryptokara.com",
			chains: [
				"algorand:SGO1GKSzyE7IEPItTxCByw9x8FmnrCDe",
				"algorand:mFgazF-2uRS1tMiL9dsj01hJGySEmPN2",
				"algorand:wGHE2Pwdvd7S12BL5FaOP20EGYesN73k",
				"antelope:1064487b3cd1a897ce03ae5b6a865651",
				"antelope:f16b1833c747c43682f4386fca9cbb32",
				"ccd:4221332d34e1694168c2a0c0b3fd0f27",
				"ccd:9dd9ca4d19e9393877d2c44b70f89acb",
				"cip-34:0-1",
				"cip-34:0-2",
				"cip-34:1-764824073",
				"cosmos:aura-testnet-2",
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:euphoria-2",
				"cosmos:harpoon-4",
				"cosmos:irishub-1",
				"cosmos:kaiyo-1",
				"cosmos:kava-4",
				"cosmos:likecoin-mainnet-2",
				"cosmos:likecoin-public-testnet-5",
				"cosmos:mayachain-mainnet-v1",
				"cosmos:mayachain-stagenet-v1",
				"cosmos:serenity-testnet-001",
				"cosmos:thorchain-mainnet-v1",
				"cosmos:thorchain-stagenet-v1",
				"cosmos:xstaxy-1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10000",
				"eip155:100000",
				"eip155:100001",
				"eip155:100002",
				"eip155:100003",
				"eip155:100004",
				"eip155:100005",
				"eip155:100006",
				"eip155:100007",
				"eip155:100008",
				"eip155:10001",
				"eip155:10003",
				"eip155:1001",
				"eip155:1007",
				"eip155:101",
				"eip155:1010",
				"eip155:10101",
				"eip155:1012",
				"eip155:102",
				"eip155:1022",
				"eip155:1023",
				"eip155:1024",
				"eip155:10243",
				"eip155:1026062157",
				"eip155:1028",
				"eip155:106",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:110000",
				"eip155:110001",
				"eip155:110002",
				"eip155:110003",
				"eip155:110004",
				"eip155:110005",
				"eip155:110006",
				"eip155:110007",
				"eip155:110008",
				"eip155:1101",
				"eip155:111",
				"eip155:1111",
				"eip155:11155111",
				"eip155:1122334455",
				"eip155:11235",
				"eip155:11297108099",
				"eip155:11297108109",
				"eip155:1139",
				"eip155:114",
				"eip155:1140",
				"eip155:11888",
				"eip155:12",
				"eip155:12051",
				"eip155:1213",
				"eip155:122",
				"eip155:123456",
				"eip155:124",
				"eip155:127",
				"eip155:1273227453",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1285",
				"eip155:1286",
				"eip155:1287",
				"eip155:1288",
				"eip155:13",
				"eip155:1313114",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:1313500",
				"eip155:13371337",
				"eip155:1350216234",
				"eip155:1351057110",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:1442",
				"eip155:1482601649",
				"eip155:15",
				"eip155:1517929550",
				"eip155:15557",
				"eip155:1564830818",
				"eip155:16",
				"eip155:16000",
				"eip155:16001",
				"eip155:1618",
				"eip155:162",
				"eip155:1620",
				"eip155:163",
				"eip155:1657",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:1666700000",
				"eip155:1666700001",
				"eip155:1666700002",
				"eip155:1666700003",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:17777",
				"eip155:18",
				"eip155:18289463",
				"eip155:1856",
				"eip155:186",
				"eip155:19",
				"eip155:19011",
				"eip155:1907",
				"eip155:1908",
				"eip155:1987",
				"eip155:199",
				"eip155:1995",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:20001",
				"eip155:200625",
				"eip155:201030",
				"eip155:20181205",
				"eip155:2020",
				"eip155:2021",
				"eip155:2022",
				"eip155:20231",
				"eip155:2024",
				"eip155:204",
				"eip155:2046399126",
				"eip155:21",
				"eip155:2100",
				"eip155:2101",
				"eip155:210309",
				"eip155:211",
				"eip155:2195",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:24484",
				"eip155:245022926",
				"eip155:245022934",
				"eip155:245022940",
				"eip155:246",
				"eip155:246529",
				"eip155:246785",
				"eip155:24734",
				"eip155:248",
				"eip155:25",
				"eip155:250",
				"eip155:2559",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:274",
				"eip155:28",
				"eip155:280",
				"eip155:288",
				"eip155:2888",
				"eip155:28945486",
				"eip155:295",
				"eip155:296",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:31102",
				"eip155:311752642",
				"eip155:3125659152",
				"eip155:31337",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:324",
				"eip155:32659",
				"eip155:3269",
				"eip155:3270",
				"eip155:33",
				"eip155:333888",
				"eip155:333999",
				"eip155:336",
				"eip155:338",
				"eip155:344106930",
				"eip155:35",
				"eip155:35855456",
				"eip155:36",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:369",
				"eip155:38",
				"eip155:385",
				"eip155:39797",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:40875",
				"eip155:41",
				"eip155:418",
				"eip155:42",
				"eip155:420",
				"eip155:42069",
				"eip155:42161",
				"eip155:421611",
				"eip155:421613",
				"eip155:4216137055",
				"eip155:421614",
				"eip155:42170",
				"eip155:42220",
				"eip155:43",
				"eip155:43110",
				"eip155:43113",
				"eip155:43114",
				"eip155:432201",
				"eip155:432204",
				"eip155:44",
				"eip155:44787",
				"eip155:462",
				"eip155:4689",
				"eip155:4690",
				"eip155:47279324479",
				"eip155:4759",
				"eip155:476158412",
				"eip155:48991",
				"eip155:49797",
				"eip155:499",
				"eip155:5",
				"eip155:50",
				"eip155:5000",
				"eip155:5001",
				"eip155:503129905",
				"eip155:51",
				"eip155:5197",
				"eip155:52",
				"eip155:53",
				"eip155:534351",
				"eip155:534352",
				"eip155:534353",
				"eip155:54211",
				"eip155:55",
				"eip155:5522",
				"eip155:558",
				"eip155:56",
				"eip155:5611",
				"eip155:56288",
				"eip155:56789",
				"eip155:57",
				"eip155:570",
				"eip155:5700",
				"eip155:57000",
				"eip155:58",
				"eip155:5851",
				"eip155:5869",
				"eip155:59",
				"eip155:59140",
				"eip155:59144",
				"eip155:595",
				"eip155:6",
				"eip155:60",
				"eip155:60000",
				"eip155:60001",
				"eip155:60002",
				"eip155:60103",
				"eip155:61",
				"eip155:61717561",
				"eip155:62",
				"eip155:62320",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:68770",
				"eip155:68775",
				"eip155:69",
				"eip155:7",
				"eip155:70000",
				"eip155:70001",
				"eip155:70002",
				"eip155:7001",
				"eip155:70103",
				"eip155:7027",
				"eip155:71393",
				"eip155:721",
				"eip155:73799",
				"eip155:73927",
				"eip155:7518",
				"eip155:7575",
				"eip155:7576",
				"eip155:76",
				"eip155:77",
				"eip155:7762959",
				"eip155:777",
				"eip155:7777777",
				"eip155:78",
				"eip155:78110",
				"eip155:78281",
				"eip155:787",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:8029",
				"eip155:803",
				"eip155:8080",
				"eip155:8081",
				"eip155:8082",
				"eip155:8194",
				"eip155:82",
				"eip155:820",
				"eip155:821",
				"eip155:8217",
				"eip155:8285",
				"eip155:83",
				"eip155:8453",
				"eip155:84531",
				"eip155:85",
				"eip155:86",
				"eip155:8723",
				"eip155:8724",
				"eip155:88",
				"eip155:880",
				"eip155:8866",
				"eip155:888",
				"eip155:8888",
				"eip155:88882",
				"eip155:88888",
				"eip155:8995",
				"eip155:9",
				"eip155:9000",
				"eip155:9001",
				"eip155:919",
				"eip155:9372",
				"eip155:940",
				"eip155:95",
				"eip155:955305",
				"eip155:96970",
				"eip155:97",
				"eip155:9728",
				"eip155:9731",
				"eip155:9732",
				"eip155:977",
				"eip155:99",
				"eip155:99415706",
				"eip155:998",
				"eip155:999",
				"flow:mainnet",
				"flow:testnet",
				"hedera:mainnet",
				"hedera:testnet",
				"koinos:EiAAKqFi-puoXnuJTdn7qBGGJa8yd-dc",
				"koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3e",
				"mvx:1",
				"mvx:D",
				"mvx:T",
				"near:mainnet",
				"near:testnet",
				"neo3:mainnet",
				"neo3:testnet",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"reef:7834781d38e4798d548e34ec947d19de",
				"reef:b414a8602b2251fa538d38a932239150",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
				"stacks:1",
				"stacks:2147483648",
				"stellar:pubnet",
				"stellar:testnet",
				"tvm:42",
				"vechain:87721b09ed2e15997f466536b20bb127",
				"vechain:b1ac3413d346d43539627e6be7ec1b4a",
				"waves:083",
				"waves:084",
				"waves:087",
				"xrpl:0",
				"xrpl:1",
				"xrpl:2",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "028ef53f-b5d9-4a63-2bf0-d384c8522500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/028ef53f-b5d9-4a63-2bf0-d384c8522500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/028ef53f-b5d9-4a63-2bf0-d384c8522500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/028ef53f-b5d9-4a63-2bf0-d384c8522500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.cryptokara&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.cryptokara",
			mobile: {
				native: "cryptokara://StartScreen",
				universal:
					"https://play.google.com/store/apps/details?id=com.cryptokara&hl=en_US",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cryptokara",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-08T09:31:22.879989+00:00",
		},
		badac6b72f6a4599d4f6c06af60f99884c71be6bed90f5ddb24f339bc799fb4b: {
			id: "badac6b72f6a4599d4f6c06af60f99884c71be6bed90f5ddb24f339bc799fb4b",
			name: "poolswallet",
			slug: "poolswallet",
			description: "Poolswallet",
			homepage: "https://wallet.poolsmobility.com/",
			chains: ["eip155:1", "eip155:3", "eip155:4", "eip155:5", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1bd15c9e-cd7c-48e2-af7d-47fda2236600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1bd15c9e-cd7c-48e2-af7d-47fda2236600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1bd15c9e-cd7c-48e2-af7d-47fda2236600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1bd15c9e-cd7c-48e2-af7d-47fda2236600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/pools-wallet/id6446882533",
				android:
					"https://play.google.com/store/apps/details?id=com.wallet.pools&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "poolswallet://deeplink.poolsmobility.com",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "PoolsWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-08T09:33:40.168046+00:00",
		},
		f714fdf911e330128463abe9684b8c02f492826db185afecef1e9070410efeec: {
			id: "f714fdf911e330128463abe9684b8c02f492826db185afecef1e9070410efeec",
			name: "VeWorld Mobile",
			slug: "veworld-mobile",
			description:
				"A Vechain Thor mobile wallet to manage your assets and interact with dApps",
			homepage: "https://veworld.com",
			chains: [
				"vechain:87721b09ed2e15997f466536b20bb127",
				"vechain:b1ac3413d346d43539627e6be7ec1b4a",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "afa5084b-02da-4dd4-418b-9f6410e34e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/afa5084b-02da-4dd4-418b-9f6410e34e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/afa5084b-02da-4dd4-418b-9f6410e34e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/afa5084b-02da-4dd4-418b-9f6410e34e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://www.veworld.com/",
				android: "https://www.veworld.com/",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "veworld://org.vechain.veworld.app/",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "VeWorld",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-10T14:07:23.220189+00:00",
		},
		"60ce5c2246a0808b32dd4eab598435e1c5bd122cc3e9fab7b0542871ac2a35c7": {
			id: "60ce5c2246a0808b32dd4eab598435e1c5bd122cc3e9fab7b0542871ac2a35c7",
			name: "AZCoiner",
			slug: "azcoiner",
			description:
				"AZCoiner is proud to be the Super App - An All-in-One Platform in the blockchain and crypto sector",
			homepage: "https://azcoiner.com/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:200",
				"eip155:250",
				"eip155:42161",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "c6601184-7eb7-46c9-f6ad-0808cfd16100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c6601184-7eb7-46c9-f6ad-0808cfd16100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c6601184-7eb7-46c9-f6ad-0808cfd16100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c6601184-7eb7-46c9-f6ad-0808cfd16100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/azcoiner/id6467728839?l=vi",
				android:
					"https://play.google.com/store/apps/details?id=com.azc.azcoiner&hl=vi-VN",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "azcoiner://",
				universal: "https://azcoiner.com/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "AZC",
				colors: {
					primary: "#1A74E4",
					secondary: "#191B24",
				},
			},
			updatedAt: "2023-11-10T14:07:58.841844+00:00",
		},
		ca3f372788f7eaceda2429be20dd4f9c8c148f0d103bb7ba8576f8138104f18c: {
			id: "ca3f372788f7eaceda2429be20dd4f9c8c148f0d103bb7ba8576f8138104f18c",
			name: "Jambo",
			slug: "jambowallet",
			description:
				"Jambo is building the web3 superapp to educate, bank and entertain our African community.",
			homepage: "https://www.jambo.technology/",
			chains: ["eip155:1", "eip155:420", "eip155:61", "eip155:84531"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4feba888-eb07-4c20-f3b7-985dc7488100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4feba888-eb07-4c20-f3b7-985dc7488100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4feba888-eb07-4c20-f3b7-985dc7488100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4feba888-eb07-4c20-f3b7-985dc7488100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://play.google.com/store/apps/details?id=com.jambo",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "jbw://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Jambo",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-10T14:08:35.782821+00:00",
		},
		c4cf2554856778945254c47b5b2f6f6218b668b1942b13d20f4d54aab43ec054: {
			id: "c4cf2554856778945254c47b5b2f6f6218b668b1942b13d20f4d54aab43ec054",
			name: "UIIC",
			slug: "ui-wallet",
			description: "this is a web3 wallet",
			homepage: "https://api.uincubator.vc/download/?company_id=NTU2NjIwMDA=",
			chains: ["eip155:1", "eip155:137", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "cdd77592-c1ba-4dc2-f2a6-c454e2c19800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/cdd77592-c1ba-4dc2-f2a6-c454e2c19800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/cdd77592-c1ba-4dc2-f2a6-c454e2c19800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/cdd77592-c1ba-4dc2-f2a6-c454e2c19800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://api.uincubator.vc/download/?company_id=NTU2NjIwMDA=",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "uiwallet://?uri=",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "UIIC",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-14T14:28:53.165346+00:00",
		},
		f71e9b2c658264f7c6dfe938bbf9d2a025acc7ba4245eea2356e2995b1fd24d3: {
			id: "f71e9b2c658264f7c6dfe938bbf9d2a025acc7ba4245eea2356e2995b1fd24d3",
			name: "M1NTY",
			slug: "m1nty",
			description:
				"Collect and trade NFTs from your favourite brands and events.",
			homepage: "https://m1nty.app",
			chains: ["eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "6ccb7754-acd8-4de2-797e-a587a53d3900",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/6ccb7754-acd8-4de2-797e-a587a53d3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/6ccb7754-acd8-4de2-797e-a587a53d3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/6ccb7754-acd8-4de2-797e-a587a53d3900?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://m1nty.app",
				ios: "https://apps.apple.com/app/m1nty/id1643737318",
				android:
					"https://play.google.com/store/apps/details?id=app.m1nty.android",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "m1nty://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "M1NTY",
				colors: {
					primary: "#BFFF0C",
					secondary: "#151515",
				},
			},
			updatedAt: "2023-11-14T14:29:57.680105+00:00",
		},
		bb9d9ff64c877aedc2ec5f596e2c4806aa5b26bafa04e835624e1f8e2b87176b: {
			id: "bb9d9ff64c877aedc2ec5f596e2c4806aa5b26bafa04e835624e1f8e2b87176b",
			name: "Nova Spektr",
			slug: "nova-spektr",
			description: "Full-spectrum Polkadot Desktop Wallet",
			homepage: "https://novaspektr.io",
			chains: [
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Desktop Wallets",
			image_id: "733e8649-3608-43d6-6956-167fb7eb8000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/733e8649-3608-43d6-6956-167fb7eb8000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/733e8649-3608-43d6-6956-167fb7eb8000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/733e8649-3608-43d6-6956-167fb7eb8000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://github.com/novasamatech/nova-spektr/releases/latest",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "novaspektr://",
				universal: null,
			},
			metadata: {
				shortName: "Nova Spektr",
				colors: {
					primary: "#4649F6",
					secondary: "#ED03B4",
				},
			},
			updatedAt: "2023-11-14T14:54:32.276143+00:00",
		},
		eae2c4b9da3b33b72b20d929a6342830bf54b541665e54c3a46024118e9e0e81: {
			id: "eae2c4b9da3b33b72b20d929a6342830bf54b541665e54c3a46024118e9e0e81",
			name: "Noone Wallet",
			slug: "noone-wallet",
			description:
				"White-label non-custodial wallet. Сustomize your crypto journey with tailored features designed exclusively for your business",
			homepage: "https://noone.io/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:250",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fbae89d3-d6cb-4b98-bd1c-b2007b61ed00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fbae89d3-d6cb-4b98-bd1c-b2007b61ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fbae89d3-d6cb-4b98-bd1c-b2007b61ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fbae89d3-d6cb-4b98-bd1c-b2007b61ed00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/noone-wallet/id1668333995",
				android:
					"https://play.google.com/store/apps/details?id=io.noone.androidwallet&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/noone-wallet/bgfhmafjampalkbjicjcjiikhlaggdnm",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "<root>://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Noone Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-16T14:25:41.588187+00:00",
		},
		ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204: {
			id: "ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204",
			name: "GateWallet",
			slug: "gatewallet",
			description:
				"Wallets, trading, cross-chain, NFTs and DApps all in Gate web3",
			homepage: "https://www.gateweb3.net/web3",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:10000",
				"eip155:1028",
				"eip155:128",
				"eip155:137",
				"eip155:199",
				"eip155:25",
				"eip155:3",
				"eip155:42161",
				"eip155:43114",
				"eip155:52",
				"eip155:56",
				"eip155:66",
				"eip155:85",
				"eip155:86",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "aaf2193b-c4e4-4552-bb5b-dc1e1d2ff300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/aaf2193b-c4e4-4552-bb5b-dc1e1d2ff300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/aaf2193b-c4e4-4552-bb5b-dc1e1d2ff300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/aaf2193b-c4e4-4552-bb5b-dc1e1d2ff300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://www.gateex.cc/zh/mobileapp",
				android: "https://www.gateex.cc/zh/mobileapp",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/gate-wallet/cpmkedoipcpimgecpmgpldfpohjplkpp",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isWeb3Wallet",
				},
			],
			rdns: "io.gate.wallet",
			mobile: {
				native: "gtweb3wallet://",
				universal: null,
			},
			desktop: {
				native: "gtweb3wallet://",
				universal: "https://www.gateex.cc/web3",
			},
			metadata: {
				shortName: "GateWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-17T12:52:47.748649+00:00",
		},
		"682095eadf8c068064ffc2777e7e9d936469debda32c997b301b79f9cd87cd54": {
			id: "682095eadf8c068064ffc2777e7e9d936469debda32c997b301b79f9cd87cd54",
			name: "Youba",
			slug: "youba",
			description:
				"With our wallet you can deliver with one simple tap, any blockchain asset to your customers securely and conveniently.",
			homepage: "https://youba.io",
			chains: ["eip155:1", "eip155:137", "eip155:5", "eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "f8f6dd04-65eb-4063-3166-3cf4020f7800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f8f6dd04-65eb-4063-3166-3cf4020f7800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f8f6dd04-65eb-4063-3166-3cf4020f7800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f8f6dd04-65eb-4063-3166-3cf4020f7800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/de/app/youba/id6449247166",
				android: "https://play.google.com/store/apps/details?id=io.yooba.main",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Youba",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-17T12:52:54.725211+00:00",
		},
		"372945354184bfbf3eb51e38d8fafde15a4bc11b88904334e7bad7c8229e6bb1": {
			id: "372945354184bfbf3eb51e38d8fafde15a4bc11b88904334e7bad7c8229e6bb1",
			name: "Bitso Web3 Wallet",
			slug: "bitso-web3-wallet",
			description:
				"Interact with trusted Web3 apps to swap tokens, collect NFTs and diversify your crypto holdings as you explore different blockchain Networks",
			homepage: "https://bitso.com/web3-wallet",
			chains: ["eip155:1", "eip155:137", "eip155:42161"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "7e4953fb-de22-4761-1ff6-8c494549aa00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/7e4953fb-de22-4761-1ff6-8c494549aa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/7e4953fb-de22-4761-1ff6-8c494549aa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/7e4953fb-de22-4761-1ff6-8c494549aa00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://bitso.com/web3-wallet",
				ios: "https://apps.apple.com/br/app/bitso-%C3%A9-f%C3%A1cil-comprar-bitcoin/id1292836438",
				android:
					"https://play.google.com/store/apps/details?id=com.bitso.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bitso://bitso.com/web3-wallet",
				universal: "https://bitso.com/web3-wallet",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Bitso Web3 Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-20T13:01:29.814621+00:00",
		},
		"7ee7b95f4ae8b3e08aab5158be7fe8e71f79bcd3717594254b34fa1f3cd4611a": {
			id: "7ee7b95f4ae8b3e08aab5158be7fe8e71f79bcd3717594254b34fa1f3cd4611a",
			name: "Puzzle Wallet",
			slug: "puzzle-wallet",
			description: "Your portal to web3 privacy.",
			homepage: "https://puzzle.online",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "08cb0a68-6271-4e25-90c3-bcc3c0226a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/08cb0a68-6271-4e25-90c3-bcc3c0226a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/08cb0a68-6271-4e25-90c3-bcc3c0226a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/08cb0a68-6271-4e25-90c3-bcc3c0226a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wiseguy-dev.puzzle.online",
				ios: "https://itero.plasmo.com/ext/nhohlebgfennhnakfdbidhminlmkbold",
				android: null,
				mac: null,
				windows: null,
				linux: "https://itero.plasmo.com/ext/nhohlebgfennhnakfdbidhminlmkbold",
				chrome: "https://itero.plasmo.com/ext/nhohlebgfennhnakfdbidhminlmkbold",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "puzzleapp://",
				universal: "https://jigsaw-dev.puzzle.online/",
			},
			desktop: {
				native: "",
				universal: "https://walletconnect.puzzle.online",
			},
			metadata: {
				shortName: "Puzzle",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-20T13:02:44.507244+00:00",
		},
		bd25c370dbc2e62b0580bb1eea9df6a812a66d6fff7045107173a6ac5e7c6f57: {
			id: "bd25c370dbc2e62b0580bb1eea9df6a812a66d6fff7045107173a6ac5e7c6f57",
			name: "SmartRush",
			slug: "smartrush",
			description: "GameDefi",
			homepage: "https://smartmoneyrush.com/",
			chains: [
				"eip155:204",
				"eip155:5611",
				"eip155:56288",
				"eip155:80001",
				"eip155:9728",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "330df9c4-66c2-4306-1ca1-09b4c9921800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/330df9c4-66c2-4306-1ca1-09b4c9921800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/330df9c4-66c2-4306-1ca1-09b4c9921800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/330df9c4-66c2-4306-1ca1-09b4c9921800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://www.smartmoneyrush.com/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://www.smartmoneyrush.com/",
			},
			metadata: {
				shortName: "smr",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-20T13:04:02.983385+00:00",
		},
		"19c54e02e79e8628512fae334d6dbbac7e2e8b53848a68a06191f3769ede92ff": {
			id: "19c54e02e79e8628512fae334d6dbbac7e2e8b53848a68a06191f3769ede92ff",
			name: "BlackFort Wallet",
			slug: "blackfort-wallet",
			description:
				"BlackFort Wallet, native wallet of the BlackFort Exchange Network chain and ecosystem.",
			homepage: "https://blackfort.network",
			chains: ["eip155:1", "eip155:4777", "eip155:4999"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "f2cf0909-3e1e-4f67-8c3f-2b69f7a5eb00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f2cf0909-3e1e-4f67-8c3f-2b69f7a5eb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f2cf0909-3e1e-4f67-8c3f-2b69f7a5eb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f2cf0909-3e1e-4f67-8c3f-2b69f7a5eb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/pl/app/blackfort-wallet/id6447954137",
				android:
					"https://play.google.com/store/apps/details?id=exchange.blackfort.blackfortwallet&hl=en_US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "blackfortwc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BXN",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-22T09:04:53.43362+00:00",
		},
		"9f14837e4392582c3974613ed8e1acc7ed84b2ae3910ad27cb9542a3758df800": {
			id: "9f14837e4392582c3974613ed8e1acc7ed84b2ae3910ad27cb9542a3758df800",
			name: "Bitcuit",
			slug: "bitcuit",
			description:
				"We create a connection so that transactions can be made by connecting to our exchange.",
			homepage: "https://bitcuit.com",
			chains: [
				"cosmos:aura-testnet-2",
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:euphoria-2",
				"cosmos:harpoon-4",
				"cosmos:irishub-1",
				"cosmos:kaiyo-1",
				"cosmos:kava-4",
				"cosmos:likecoin-mainnet-2",
				"cosmos:likecoin-public-testnet-5",
				"cosmos:mayachain-mainnet-v1",
				"cosmos:mayachain-stagenet-v1",
				"cosmos:serenity-testnet-001",
				"cosmos:thorchain-mainnet-v1",
				"cosmos:thorchain-stagenet-v1",
				"cosmos:xstaxy-1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:101",
				"eip155:102",
				"eip155:106",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:111",
				"eip155:11155111",
				"eip155:11297108099",
				"eip155:12",
				"eip155:122",
				"eip155:124",
				"eip155:127",
				"eip155:128",
				"eip155:13",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:15",
				"eip155:16",
				"eip155:16000",
				"eip155:16001",
				"eip155:162",
				"eip155:163",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:18",
				"eip155:18289463",
				"eip155:186",
				"eip155:19",
				"eip155:199",
				"eip155:1995",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:204",
				"eip155:211",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:246",
				"eip155:25",
				"eip155:250",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:28",
				"eip155:280",
				"eip155:288",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:324",
				"eip155:33",
				"eip155:336",
				"eip155:338",
				"eip155:35",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:38",
				"eip155:4",
				"eip155:40",
				"eip155:41",
				"eip155:42",
				"eip155:420",
				"eip155:42161",
				"eip155:421611",
				"eip155:421613",
				"eip155:421614",
				"eip155:42170",
				"eip155:42220",
				"eip155:43",
				"eip155:432201",
				"eip155:432204",
				"eip155:44",
				"eip155:44787",
				"eip155:4689",
				"eip155:4690",
				"eip155:499",
				"eip155:5",
				"eip155:50",
				"eip155:51",
				"eip155:52",
				"eip155:53",
				"eip155:55",
				"eip155:5522",
				"eip155:558",
				"eip155:56",
				"eip155:56789",
				"eip155:58",
				"eip155:5851",
				"eip155:59",
				"eip155:6",
				"eip155:60",
				"eip155:61",
				"eip155:62",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:69",
				"eip155:7",
				"eip155:71393",
				"eip155:76",
				"eip155:77",
				"eip155:78",
				"eip155:78281",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:82",
				"eip155:821",
				"eip155:83",
				"eip155:85",
				"eip155:86",
				"eip155:88",
				"eip155:9",
				"eip155:95",
				"eip155:96970",
				"eip155:97",
				"eip155:99",
				"koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3e",
				"mvx:1",
				"mvx:D",
				"mvx:T",
				"near:mainnet",
				"near:testnet",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "2fc793ee-4c3a-4d84-85a1-3f9ff98ece00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2fc793ee-4c3a-4d84-85a1-3f9ff98ece00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2fc793ee-4c3a-4d84-85a1-3f9ff98ece00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2fc793ee-4c3a-4d84-85a1-3f9ff98ece00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://bitcuit.com/login",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://bitcuit.com/login",
			},
			metadata: {
				shortName: "bitcuit",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-22T09:05:12.055082+00:00",
		},
		"4e21a70acc8f11aa35f87733de2fbada29a2dd08e9011d34d92522fb8ad0e3d2": {
			id: "4e21a70acc8f11aa35f87733de2fbada29a2dd08e9011d34d92522fb8ad0e3d2",
			name: "Armana Portal",
			slug: "armana-portal",
			description:
				"Unlock the potential of the decentralized world with Portal, your all-in-one gateway to the Koinos blockchain. ",
			homepage: "https://portal.armana.io",
			chains: ["koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3e"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "fe3c264d-b595-437d-e5f9-5e5833dd4300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/fe3c264d-b595-437d-e5f9-5e5833dd4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/fe3c264d-b595-437d-e5f9-5e5833dd4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/fe3c264d-b595-437d-e5f9-5e5833dd4300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://arman.io/mint",
				ios: "https://apps.apple.com/us/app/armana-portal/id6448726023",
				android:
					"https://play.google.com/store/apps/details?id=io.armana.portal",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "armanaportal://",
				universal: "https://portal.armana.io/wc?uri=",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Portal",
				colors: {
					primary: "#0C53DE",
					secondary: "#EDB240",
				},
			},
			updatedAt: "2023-11-23T22:24:07.587385+00:00",
		},
		"4eca426b5c9debee28d1954b646c1a2bc79582c3c2b7e6da79ebc4ad460d8b75": {
			id: "4eca426b5c9debee28d1954b646c1a2bc79582c3c2b7e6da79ebc4ad460d8b75",
			name: "BharatBox App",
			slug: "bharatbox-main-wallet",
			description:
				"BharatBox App: Play games, earn rewards, and manage assets in one platform.",
			homepage: "https://bharatbox.io/",
			chains: ["eip155:1", "eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8bef836e-9d6a-4318-ebda-930c39246300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8bef836e-9d6a-4318-ebda-930c39246300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8bef836e-9d6a-4318-ebda-930c39246300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8bef836e-9d6a-4318-ebda-930c39246300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/bharatbox/id6472478382",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "bharatbox://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BharatBox",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-27T15:34:53.525889+00:00",
		},
		"21662ea26f2284c26ac44bfbb176340b477a3b4039d5a3793e86ba8177514901": {
			id: "21662ea26f2284c26ac44bfbb176340b477a3b4039d5a3793e86ba8177514901",
			name: "eth-q1",
			slug: "eth-q1",
			description: "A convenient and fast wallet tool",
			homepage: "https://webeasy.xyz",
			chains: [
				"cosmos:aura-testnet-2",
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:irishub-1",
				"cosmos:kava-4",
				"cosmos:mayachain-mainnet-v1",
				"cosmos:mayachain-stagenet-v1",
				"cosmos:thorchain-mainnet-v1",
				"cosmos:thorchain-stagenet-v1",
				"eip155:1",
				"eip155:101",
				"eip155:111",
				"eip155:20",
				"eip155:22",
				"eip155:23",
				"eip155:3",
				"eip155:4",
				"eip155:5",
				"eip155:6",
				"eip155:7",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "027f3d70-61ad-43d7-6c77-da305bf64500",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/027f3d70-61ad-43d7-6c77-da305bf64500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/027f3d70-61ad-43d7-6c77-da305bf64500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/027f3d70-61ad-43d7-6c77-da305bf64500?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: "https://ernns.top/",
				chrome: "https://ernns.top/",
				firefox: null,
				safari: null,
				edge: "https://ernns.top/",
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "eth",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-11-27T15:35:32.797363+00:00",
		},
		c3d4800aeb5eb7b00dd5e97247993461df84e6630cc3a89bdf2ca522f0ec07d1: {
			id: "c3d4800aeb5eb7b00dd5e97247993461df84e6630cc3a89bdf2ca522f0ec07d1",
			name: "Blanq",
			slug: "blanq",
			description: "The first distributed-key hardware wallet",
			homepage: "https://www.blanqlabs.com",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "d02dcb81-d279-4414-627a-681dcad51200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d02dcb81-d279-4414-627a-681dcad51200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d02dcb81-d279-4414-627a-681dcad51200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d02dcb81-d279-4414-627a-681dcad51200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/blanq-wallet-buy-store-crypto/id6470715307",
				android:
					"https://play.google.com/store/apps/details?id=xyz.blanq.mobile",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.blanqlabs.wallet",
			mobile: {
				native: "blanq://blanqlabs.com/settings/app",
				universal: "https://www.blanq.app/link/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Blanq",
				colors: {
					primary: "#ff846c",
					secondary: "#13121d",
				},
			},
			updatedAt: "2023-12-04T15:09:41.022639+00:00",
		},
		c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2: {
			id: "c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2",
			name: "Kaikas",
			slug: "kaikas",
			description:
				"Kaikas is a non-custodial wallet that stores and manages digital assets such as coin/token/NFT.",
			homepage: "https://klaytn.foundation",
			chains: ["eip155:1", "eip155:1001", "eip155:8217"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "48651c11-ea5b-4879-176f-673427dc9000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/48651c11-ea5b-4879-176f-673427dc9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/48651c11-ea5b-4879-176f-673427dc9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/48651c11-ea5b-4879-176f-673427dc9000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: "https://appdistribution.firebase.dev/i/5a91cf33f0b3aa59",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kaikas://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kaikas",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-04T15:10:03.516598+00:00",
		},
		"6342f00659512c995c5b271236fbe8e8c86951bd94f533fa0237d2a3e606c4da": {
			id: "6342f00659512c995c5b271236fbe8e8c86951bd94f533fa0237d2a3e606c4da",
			name: "Blockaura",
			slug: "blockaura",
			description:
				'BlockAura ("we," "us," or "our") is committed to protecting the privacy and security of your personal information',
			homepage: "https://greengloryglobal.com/",
			chains: ["eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8cc7ddbc-a257-4383-4f84-3847e707bb00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8cc7ddbc-a257-4383-4f84-3847e707bb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8cc7ddbc-a257-4383-4f84-3847e707bb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8cc7ddbc-a257-4383-4f84-3847e707bb00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.blockaura_classic",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "blockaura://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BLOCKAURA",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-04T15:10:15.084098+00:00",
		},
		"53156573bc146ecf92638a53fa9b031e040513f95296a7f8b97404e751a279bf": {
			id: "53156573bc146ecf92638a53fa9b031e040513f95296a7f8b97404e751a279bf",
			name: "X9Wallet",
			slug: "x9wallet",
			description: "Web3Wallet of X9Wallet",
			homepage: "https://x9wallet.com",
			chains: ["eip155:1", "eip155:5", "eip155:56", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ab278eaf-57ac-40dc-dfa6-42c813052100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ab278eaf-57ac-40dc-dfa6-42c813052100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ab278eaf-57ac-40dc-dfa6-42c813052100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ab278eaf-57ac-40dc-dfa6-42c813052100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://expo.dev/accounts/teza8899x/projects/x9wallet/updates/d8aa245f-076d-4634-b2ba-e23489b04dde",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "x9wallet://a",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "X9 Wallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-04T15:10:50.469149+00:00",
		},
		"66a1b8d00aa8da714ae07a123a565f6226c44156a54ed04ffc6ee5ffe1f56e64": {
			id: "66a1b8d00aa8da714ae07a123a565f6226c44156a54ed04ffc6ee5ffe1f56e64",
			name: "Mirai App",
			slug: "mirai-app",
			description:
				"Mirai App is your trusted partner for navigating the dynamic world of digital assets with the MPC technology.",
			homepage: "https://miraiapp.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:42161",
				"eip155:421613",
				"eip155:56",
				"eip155:80001",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "f18751e8-6e0c-471d-e2b3-0ce2dddc1f00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f18751e8-6e0c-471d-e2b3-0ce2dddc1f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f18751e8-6e0c-471d-e2b3-0ce2dddc1f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f18751e8-6e0c-471d-e2b3-0ce2dddc1f00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://miraiapp.io",
				ios: "https://apps.apple.com/vn/app/mirai-app-mpc-powered-wallet/id6472134236",
				android:
					"https://play.google.com/store/apps/details?id=co.mirailabs.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "miraiapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Mirai",
				colors: {
					primary: "#617DEA",
					secondary: "#8A8AA8",
				},
			},
			updatedAt: "2023-12-04T15:12:42.227237+00:00",
		},
		"43c76a3d2a84cb7a10f6329a34261eb6794ac830e74bc8136b42ee05ee54efb1": {
			id: "43c76a3d2a84cb7a10f6329a34261eb6794ac830e74bc8136b42ee05ee54efb1",
			name: "Kigo",
			slug: "kigo",
			description:
				"Kigo partners with loyalty programs to allow users to redeem for digital assets. Our wallet is where these tokens can be claimed and used.",
			homepage: "https://kigo.io",
			chains: [
				"eip155:1",
				"eip155:11155111",
				"eip155:137",
				"eip155:5",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "ad83d869-de11-4685-2a24-d3ce93a86400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ad83d869-de11-4685-2a24-d3ce93a86400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ad83d869-de11-4685-2a24-d3ce93a86400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ad83d869-de11-4685-2a24-d3ce93a86400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/kigo-digital/id6449599872",
				android: "https://play.google.com/store/apps/details?id=com.augeo.kigo",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "kigo-mobile-app://",
				universal: "https://kigo-digital.app.link/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Kigo",
				colors: {
					primary: "#4B55FD",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2023-12-04T15:13:25.122739+00:00",
		},
		f45cb3dd0fd2a31bb7b1d19baa9f4a043609debdc7c494edf73b9b56da77a642: {
			id: "f45cb3dd0fd2a31bb7b1d19baa9f4a043609debdc7c494edf73b9b56da77a642",
			name: "Levain",
			slug: "levain",
			description:
				"Levain is an all-in-one platform that makes it easy to start, run, and grow a crypto business.",
			homepage: "https://levain.tech/",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:11155111",
				"eip155:137",
				"eip155:420",
				"eip155:42161",
				"eip155:421613",
				"eip155:421614",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:8453",
				"eip155:84531",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "504f6043-9d47-4dd9-5332-49ea67e93100",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/504f6043-9d47-4dd9-5332-49ea67e93100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/504f6043-9d47-4dd9-5332-49ea67e93100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/504f6043-9d47-4dd9-5332-49ea67e93100?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.levain.tech/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "tech.levain",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.levain.tech/",
			},
			metadata: {
				shortName: "Levain",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-04T15:18:38.550921+00:00",
		},
		"56d1854ddbc8d9ecdf5d7e2f19228314f475ee110c38fbfe3fa0b72baa41ca14": {
			id: "56d1854ddbc8d9ecdf5d7e2f19228314f475ee110c38fbfe3fa0b72baa41ca14",
			name: "KAX-Wallet",
			slug: "kax-wallet",
			description: "Kaxaa Real Estate Blockchain Wallet",
			homepage: "https://kaxaa.com/",
			chains: ["eip155:137"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Cloud-based Wallets",
			image_id: "06c2aa4f-12a9-4c91-7b27-1755813c9c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/06c2aa4f-12a9-4c91-7b27-1755813c9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/06c2aa4f-12a9-4c91-7b27-1755813c9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/06c2aa4f-12a9-4c91-7b27-1755813c9c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://ewallet.kaxaa.com/",
				ios: "https://ewallet.kaxaa.com",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/kaxaa/njlpbnllmbgdjcmjogmlheldgkcdjnol",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://link.kaxaa.com/",
			},
			metadata: {
				shortName: "KAX",
				colors: {
					primary: "#9fc93a",
					secondary: "#3c5432",
				},
			},
			updatedAt: "2023-12-05T13:48:10.782173+00:00",
		},
		b6d18ff342920bb492f810bb070a064d6031ec2c3d6fffecb6ca233c8a591e00: {
			id: "b6d18ff342920bb492f810bb070a064d6031ec2c3d6fffecb6ca233c8a591e00",
			name: "Face Wallet",
			slug: "face-wallet",
			description:
				"Face Wallet is the world’s most user-friendly, non-custodial in-game wallet SDK by providing a Web2-like experience.",
			homepage: "https://facewallet.xyz",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:19011",
				"eip155:248",
				"eip155:56",
				"eip155:7518",
				"eip155:8217",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "51ab77a7-8df7-4217-ad98-b313cfbbe700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/51ab77a7-8df7-4217-ad98-b313cfbbe700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/51ab77a7-8df7-4217-ad98-b313cfbbe700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/51ab77a7-8df7-4217-ad98-b313cfbbe700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://my.facewallet.xyz",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://my.facewallet.xyz",
			},
			metadata: {
				shortName: "Face Wallet",
				colors: {
					primary: "#0026F5",
					secondary: null,
				},
			},
			updatedAt: "2023-12-05T13:55:40.594313+00:00",
		},
		"344d0e58b139eb1b6da0c29ea71d52a8eace8b57897c6098cb9b46012665c193": {
			id: "344d0e58b139eb1b6da0c29ea71d52a8eace8b57897c6098cb9b46012665c193",
			name: "Timeless X",
			slug: "timeless-x",
			description:
				"Timeless X is a social account abstraction wallet combining self-custodial security with convenient social features.",
			homepage: "https://timelesswallet.xyz",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:280",
				"eip155:324",
				"eip155:4002",
				"eip155:420",
				"eip155:421613",
				"eip155:42170",
				"eip155:5",
				"eip155:56",
				"eip155:59140",
				"eip155:59144",
				"eip155:80001",
				"eip155:8453",
				"eip155:84531",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "92f1ead4-0bc4-4932-10d3-761c509b4d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/92f1ead4-0bc4-4932-10d3-761c509b4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/92f1ead4-0bc4-4932-10d3-761c509b4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/92f1ead4-0bc4-4932-10d3-761c509b4d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/timeless-x/id6470180600",
				android:
					"https://play.google.com/store/apps/details?id=xyz.timelesswallet.aa",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/ifcpkdgcgcepjdkfcogehpnhpipejgho",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isTimelessX",
				},
			],
			rdns: null,
			mobile: {
				native: "timeless-x://connect",
				universal: "https://timelesswallet.xyz/x",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Timeless",
				colors: {
					primary: "#2D3A66",
					secondary: "#B35F8D",
				},
			},
			updatedAt: "2023-12-11T14:55:15.179095+00:00",
		},
		"75aa577b08b3dc3a1777c9c74cbbb79b5bfd73caaff222a28a53ce4e76cbb41c": {
			id: "75aa577b08b3dc3a1777c9c74cbbb79b5bfd73caaff222a28a53ce4e76cbb41c",
			name: "JoyID Passkey",
			slug: "joyid-passkey",
			description:
				"JoyID is a multichain, cross-platform, passwordless, and mnemonic-free wallet solution based on FIDO Webauthn protocol and Nervos CKB. ",
			homepage: "https://joy.id/",
			chains: [
				"eip155:1",
				"eip155:11155111",
				"eip155:137",
				"eip155:15557",
				"eip155:42161",
				"eip155:421611",
				"eip155:421613",
				"eip155:80001",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "eef16f1f-1882-41c9-00c3-558444e0d000",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/eef16f1f-1882-41c9-00c3-558444e0d000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/eef16f1f-1882-41c9-00c3-558444e0d000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/eef16f1f-1882-41c9-00c3-558444e0d000?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.joy.id",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://app.joy.id",
			},
			metadata: {
				shortName: "JoyID",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-15T11:58:10.54547+00:00",
		},
		"8bee2c65ad37240fab769ff3c9f56f94914e53c8d99087e1a805cc5ef39a44f3": {
			id: "8bee2c65ad37240fab769ff3c9f56f94914e53c8d99087e1a805cc5ef39a44f3",
			name: "Cogni ",
			slug: "cogni",
			description: "Non-custodial Web 2 to Web 3",
			homepage: "https://www.getcogni.com/",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"xrpl:0",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b650cd08-21eb-4769-8ef9-96feb6e38e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b650cd08-21eb-4769-8ef9-96feb6e38e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b650cd08-21eb-4769-8ef9-96feb6e38e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b650cd08-21eb-4769-8ef9-96feb6e38e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/cogni-mobile-banking/id1483957512",
				android:
					"https://play.google.com/store/apps/details?id=com.getcogni.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "getcogni://web3/",
				universal: "https://getcogni.com/web3_wc_connect",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Cogni Wallet",
				colors: {
					primary: "#000000",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2023-12-21T09:01:30.145983+00:00",
		},
		"0a418f1b991744b9e77ed957b8f809e11d19201d49a2a476816c13a22420ec4c": {
			id: "0a418f1b991744b9e77ed957b8f809e11d19201d49a2a476816c13a22420ec4c",
			name: "37x",
			slug: "37x",
			description:
				"37xDubai is a space where Art and Technology come together to create the most unique expressions of creativity.",
			homepage: "https://37xdubai.com",
			chains: ["eip155:1", "eip155:5"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "c85a6bf2-f505-481c-9e7d-9a7190042c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c85a6bf2-f505-481c-9e7d-9a7190042c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c85a6bf2-f505-481c-9e7d-9a7190042c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c85a6bf2-f505-481c-9e7d-9a7190042c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://37xdubai.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://37xdubai.com",
			},
			metadata: {
				shortName: "37x",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-21T09:17:15.254756+00:00",
		},
		c5252f423aae35a7df6194c342b14480b0cf83c3f4d9134fe45098c2f74befd9: {
			id: "c5252f423aae35a7df6194c342b14480b0cf83c3f4d9134fe45098c2f74befd9",
			name: "Rovi Money",
			slug: "rovi-money",
			description:
				"Store. Buy. Sell. Send. Receive Crypto on Messaging, Email, Wherever!\n\nWorld's Simplest, Safest\nCrypto Wallet",
			homepage: "https://rovi.money",
			chains: ["eip155:137", "eip155:56", "eip155:80001", "eip155:97"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "b4bd79a6-11e3-4391-fd60-70111e60ef00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b4bd79a6-11e3-4391-fd60-70111e60ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b4bd79a6-11e3-4391-fd60-70111e60ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b4bd79a6-11e3-4391-fd60-70111e60ef00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://rovi.money/app",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/rovi-wallet-balance-exten/egmdbfmomfghplmfboifancngbcjmdbc?hl=en",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://rovi.money/app",
			},
			metadata: {
				shortName: "RoviMoney",
				colors: {
					primary: "#7446ec",
					secondary: "#daa71d",
				},
			},
			updatedAt: "2023-12-21T09:17:32.349097+00:00",
		},
		f26de15901fa07f464e3be824e36e63bdc587d34f04654ad6cd3f0a018889fca: {
			id: "f26de15901fa07f464e3be824e36e63bdc587d34f04654ad6cd3f0a018889fca",
			name: "NoF",
			slug: "nof",
			description: "Number One Fun",
			homepage: "https://nof.town",
			chains: ["eip155:80001"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "0f822531-ac3b-4cc0-d0da-c2ab57d0fe00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/0f822531-ac3b-4cc0-d0da-c2ab57d0fe00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/0f822531-ac3b-4cc0-d0da-c2ab57d0fe00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/0f822531-ac3b-4cc0-d0da-c2ab57d0fe00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://nof.town",
				android: "https://nof.town",
				mac: "https://nof.town",
				windows: "https://nof.town",
				linux: "https://nof.town",
				chrome: "https://nof.town",
				firefox: "https://nof.town",
				safari: "https://nof.town",
				edge: "https://nof.town",
				opera: "https://nof.town",
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "NoF",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-21T09:17:51.786008+00:00",
		},
		"270948c8e635aaa76911c980059acf565dd33e235282d79021537b398bcaa57b": {
			id: "270948c8e635aaa76911c980059acf565dd33e235282d79021537b398bcaa57b",
			name: "Toric Wallet",
			slug: "toric-wallet",
			description: "Your Trusted Wallet for the Torus Network",
			homepage: "https://toric.io",
			chains: ["eip155:1", "eip155:5", "eip155:8194"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "b147f2a9-a282-4a54-98c8-876b37b9c800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b147f2a9-a282-4a54-98c8-876b37b9c800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b147f2a9-a282-4a54-98c8-876b37b9c800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b147f2a9-a282-4a54-98c8-876b37b9c800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/toric-wallet/id6466638359",
				android:
					"https://play.google.com/store/apps/details?id=io.toric.androidwallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "toric://wallet",
				universal: "https://app.toric.io/",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Toric",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2023-12-21T09:18:21.982068+00:00",
		},
		fb6ed96272ec885008e896c6146002048d8dc88c0b7e0e6fa42bcadf052a1569: {
			id: "fb6ed96272ec885008e896c6146002048d8dc88c0b7e0e6fa42bcadf052a1569",
			name: "Enkrypt",
			slug: "enkrypt",
			description:
				"A multichain crypto wallet Hold, buy, send, receive, and swap tokens. Manage your NFTs. Access web3 apps across multiple blockchains.",
			homepage: "https://www.enkrypt.com",
			chains: [
				"eip155:1",
				"eip155:200",
				"eip155:30",
				"eip155:31",
				"eip155:336",
				"eip155:5",
				"eip155:61",
				"eip155:66",
				"eip155:686",
				"eip155:88",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1"],
			sdks: ["sign_v1"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "5aafd680-95a8-41e6-6df0-632ea23f4700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/5aafd680-95a8-41e6-6df0-632ea23f4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/5aafd680-95a8-41e6-6df0-632ea23f4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/5aafd680-95a8-41e6-6df0-632ea23f4700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://google.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/enkrypt-multichain-crypto/kkpllkodjeloidieedojogacfhpaihoh",
				firefox: "https://addons.mozilla.org/en-US/firefox/addon/enkrypt/",
				safari:
					"https://apps.apple.com/us/app/enkrypt-web3-wallet/id1640164309?mt=12",
				edge: "https://microsoftedge.microsoft.com/addons/detail/gfenajajnjjmmdojhdjmnngomkhlnfjl",
				opera: "https://addons.opera.com/en/extensions/details/enkrypt/",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isEnkrypt",
				},
			],
			rdns: "com.enkrypt",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Enkrypt",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-06T06:46:47.898668+00:00",
		},
		"5ff1c0982092f5b41df67c6ad636feddfc3e9d8c771777ddc26e5335fc91ab2f": {
			id: "5ff1c0982092f5b41df67c6ad636feddfc3e9d8c771777ddc26e5335fc91ab2f",
			name: "Scramble",
			slug: "scramble",
			description:
				"Scramble is a multichain crypto wallet that is designed to simplify your Web3 experience! ",
			homepage: "https://scramble-wallet.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Injected Wallets",
			image_id: "c00ef3da-aacb-4c27-66ae-3cb9537a4800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/c00ef3da-aacb-4c27-66ae-3cb9537a4800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/c00ef3da-aacb-4c27-66ae-3cb9537a4800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/c00ef3da-aacb-4c27-66ae-3cb9537a4800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/scramble-evm-btc-polkadot/dfkkefmblkgnecepjogenokjpagglfhj",
				firefox:
					"https://addons.mozilla.org/en-US/firefox/addon/scramble-multichain-wallet/",
				safari:
					"https://apps.apple.com/at/app/scramble-multichain-wallet/id6463812878?mt=12",
				edge: "https://chrome.google.com/webstore/detail/scramble-evm-btc-polkadot/dfkkefmblkgnecepjogenokjpagglfhj",
				opera:
					"https://chrome.google.com/webstore/detail/scramble-evm-btc-polkadot/dfkkefmblkgnecepjogenokjpagglfhj",
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isScramble",
				},
			],
			rdns: "com.scramble",
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Scramble",
				colors: {
					primary: "#5B00F4",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2024-01-08T13:46:20.338638+00:00",
		},
		"6db5c2cd78ea5a09e820b7543dacc90bf3b1727e5bbaddff544b301de1f74f39": {
			id: "6db5c2cd78ea5a09e820b7543dacc90bf3b1727e5bbaddff544b301de1f74f39",
			name: "Fastex Wallet",
			slug: "fastex-wallet",
			description:
				"Fastex Wallet is a self-custody multi-chain wallet. Offering a secure and user-friendly way to manage your digital assets.",
			homepage: "https://fastexwallet.com",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a38db32b-8291-4d25-9aae-4bf4b6e6f300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a38db32b-8291-4d25-9aae-4bf4b6e6f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a38db32b-8291-4d25-9aae-4bf4b6e6f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a38db32b-8291-4d25-9aae-4bf4b6e6f300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/fastex-wallet/id6474118944",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: "com.fastex.wallet",
			mobile: {
				native: "fastex-wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Fastex Wallet",
				colors: {
					primary: "#d83488",
					secondary: "#ffffff",
				},
			},
			updatedAt: "2024-01-08T13:51:39.922659+00:00",
		},
		"2c724cd7e745016e6e4acccebdcc49464e13ec17ee23141c4fb2fe8004be2504": {
			id: "2c724cd7e745016e6e4acccebdcc49464e13ec17ee23141c4fb2fe8004be2504",
			name: "Wallacy",
			slug: "wallacy",
			description: "A Gamified and Hybrid Crypto Wallet",
			homepage: "https://wallacy.io",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:5",
				"eip155:56",
				"eip155:80001",
				"eip155:8217",
				"eip155:8453",
				"eip155:97",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "9496c3d8-8b60-495f-bd55-c3af19519d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/9496c3d8-8b60-495f-bd55-c3af19519d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/9496c3d8-8b60-495f-bd55-c3af19519d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/9496c3d8-8b60-495f-bd55-c3af19519d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/wallacy-crypto-btc-wallet/id6448592576",
				android:
					"https://play.google.com/store/apps/details?id=io.wallacy.cryptowallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wallacy://",
				universal: null,
			},
			desktop: {
				native: "wallacy://",
				universal: null,
			},
			metadata: {
				shortName: "Wallacy",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:25:06.451991+00:00",
		},
		c3b76dabb8b8161b2848a4c313b559a2cd719b43732c3e0da00f345e571241e5: {
			id: "c3b76dabb8b8161b2848a4c313b559a2cd719b43732c3e0da00f345e571241e5",
			name: "Talk+",
			slug: "talk-1",
			description:
				'With "Talk+", users can easily buy crypto online instantly or transfer them to friends by using its blockchain wallet feature.  ',
			homepage: "https://www.talkapp.org/",
			chains: [
				"eip155:1",
				"eip155:56",
				"eip155:59",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "396f66fb-5096-4d4f-9ea9-6b4e06ce9700",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/396f66fb-5096-4d4f-9ea9-6b4e06ce9700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/396f66fb-5096-4d4f-9ea9-6b4e06ce9700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/396f66fb-5096-4d4f-9ea9-6b4e06ce9700?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/hk/app/talk-%E5%8A%A0%E5%AF%86%E8%B2%A8%E5%B9%A3%E4%BA%A4%E6%98%93%E5%8F%8Aai%E8%81%8A%E5%A4%A9%E9%80%9A%E8%A8%8A%E8%BB%9F%E4%BB%B6/id1547227377",
				android:
					"https://play.google.com/store/apps/details?id=org.talkapp&hl=en&gl=US",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "talkapp://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Talk+",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:25:11.124954+00:00",
		},
		"32074fa3b78ad30b7847e28ce1ffd6aecbd36415156cfe9f5fe5aa15f31c596e": {
			id: "32074fa3b78ad30b7847e28ce1ffd6aecbd36415156cfe9f5fe5aa15f31c596e",
			name: "Zelus Wallet",
			slug: "zelus-wallet",
			description: "The easiest-to-use multichain wallet",
			homepage: "https://zelus.io",
			chains: [
				"eip155:1",
				"eip155:137",
				"eip155:250",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "a173eba6-05b4-43f4-0df6-400563637b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/a173eba6-05b4-43f4-0df6-400563637b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/a173eba6-05b4-43f4-0df6-400563637b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/a173eba6-05b4-43f4-0df6-400563637b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://rollingloud.bridge.zelus.io",
				ios: "https://apps.apple.com/us/app/zelus/id1588430343",
				android:
					"https://play.google.com/store/apps/details?id=com.zelus.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "zeluswallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Zelus Wallet",
				colors: {
					primary: "#D63D91",
					secondary: "#E18A4A",
				},
			},
			updatedAt: "2024-01-08T15:26:06.121904+00:00",
		},
		"82061ee410cab0e705cf38830db84ba965effc51a1e1bf43da6d39ff70ae94fb": {
			id: "82061ee410cab0e705cf38830db84ba965effc51a1e1bf43da6d39ff70ae94fb",
			name: "Capsule",
			slug: "capsule",
			description:
				"Capsule makes it easy to create cross-app, embedded MPC wallets with just an email or social login.",
			homepage: "https://usecapsule.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10000",
				"eip155:100000",
				"eip155:100001",
				"eip155:100002",
				"eip155:100003",
				"eip155:100004",
				"eip155:100005",
				"eip155:100006",
				"eip155:100007",
				"eip155:100008",
				"eip155:10001",
				"eip155:10003",
				"eip155:1001",
				"eip155:1007",
				"eip155:101",
				"eip155:1010",
				"eip155:10101",
				"eip155:1012",
				"eip155:102",
				"eip155:1022",
				"eip155:1023",
				"eip155:1024",
				"eip155:10243",
				"eip155:1026062157",
				"eip155:1028",
				"eip155:106",
				"eip155:1073",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:110000",
				"eip155:110001",
				"eip155:110002",
				"eip155:110003",
				"eip155:110004",
				"eip155:110005",
				"eip155:110006",
				"eip155:110007",
				"eip155:110008",
				"eip155:1101",
				"eip155:111",
				"eip155:1111",
				"eip155:11155111",
				"eip155:1122334455",
				"eip155:11235",
				"eip155:11297108099",
				"eip155:11297108109",
				"eip155:1139",
				"eip155:114",
				"eip155:1140",
				"eip155:11888",
				"eip155:12",
				"eip155:12051",
				"eip155:1213",
				"eip155:122",
				"eip155:123456",
				"eip155:124",
				"eip155:127",
				"eip155:1273227453",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1285",
				"eip155:1286",
				"eip155:1287",
				"eip155:1288",
				"eip155:13",
				"eip155:1313114",
				"eip155:1313161554",
				"eip155:1313161555",
				"eip155:1313161556",
				"eip155:1313500",
				"eip155:13371337",
				"eip155:1350216234",
				"eip155:1351057110",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:1442",
				"eip155:148",
				"eip155:1482601649",
				"eip155:15",
				"eip155:1517929550",
				"eip155:15557",
				"eip155:1564830818",
				"eip155:16",
				"eip155:16000",
				"eip155:16001",
				"eip155:1618",
				"eip155:162",
				"eip155:1620",
				"eip155:163",
				"eip155:1657",
				"eip155:1666600000",
				"eip155:1666600001",
				"eip155:1666600002",
				"eip155:1666600003",
				"eip155:1666700000",
				"eip155:1666700001",
				"eip155:1666700002",
				"eip155:1666700003",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:17777",
				"eip155:18",
				"eip155:18289463",
				"eip155:1856",
				"eip155:186",
				"eip155:19",
				"eip155:19011",
				"eip155:1907",
				"eip155:1908",
				"eip155:1987",
				"eip155:199",
				"eip155:1995",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:20001",
				"eip155:200625",
				"eip155:201030",
				"eip155:20181205",
				"eip155:2020",
				"eip155:2021",
				"eip155:2022",
				"eip155:20231",
				"eip155:2024",
				"eip155:204",
				"eip155:2046399126",
				"eip155:21",
				"eip155:2100",
				"eip155:2101",
				"eip155:210309",
				"eip155:211",
				"eip155:2195",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:24484",
				"eip155:245022926",
				"eip155:245022934",
				"eip155:245022940",
				"eip155:246",
				"eip155:246529",
				"eip155:246785",
				"eip155:24734",
				"eip155:248",
				"eip155:25",
				"eip155:250",
				"eip155:2559",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:274",
				"eip155:28",
				"eip155:280",
				"eip155:288",
				"eip155:2888",
				"eip155:28945486",
				"eip155:295",
				"eip155:296",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:31102",
				"eip155:311752642",
				"eip155:3125659152",
				"eip155:31337",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:324",
				"eip155:32659",
				"eip155:3269",
				"eip155:3270",
				"eip155:33",
				"eip155:333888",
				"eip155:333999",
				"eip155:336",
				"eip155:338",
				"eip155:344106930",
				"eip155:35",
				"eip155:35855456",
				"eip155:36",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:369",
				"eip155:38",
				"eip155:385",
				"eip155:39797",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:40875",
				"eip155:41",
				"eip155:418",
				"eip155:42",
				"eip155:420",
				"eip155:42069",
				"eip155:42161",
				"eip155:421611",
				"eip155:421613",
				"eip155:4216137055",
				"eip155:421614",
				"eip155:42170",
				"eip155:42220",
				"eip155:43",
				"eip155:43110",
				"eip155:43113",
				"eip155:43114",
				"eip155:432201",
				"eip155:432204",
				"eip155:44",
				"eip155:44787",
				"eip155:462",
				"eip155:4689",
				"eip155:4690",
				"eip155:47279324479",
				"eip155:4759",
				"eip155:476158412",
				"eip155:4777",
				"eip155:48991",
				"eip155:49797",
				"eip155:499",
				"eip155:4999",
				"eip155:5",
				"eip155:50",
				"eip155:5000",
				"eip155:5001",
				"eip155:503129905",
				"eip155:51",
				"eip155:5197",
				"eip155:52",
				"eip155:53",
				"eip155:534351",
				"eip155:534352",
				"eip155:534353",
				"eip155:54211",
				"eip155:55",
				"eip155:5522",
				"eip155:558",
				"eip155:56",
				"eip155:5611",
				"eip155:56288",
				"eip155:56789",
				"eip155:57",
				"eip155:570",
				"eip155:5700",
				"eip155:57000",
				"eip155:58",
				"eip155:5851",
				"eip155:5869",
				"eip155:59",
				"eip155:59140",
				"eip155:59144",
				"eip155:595",
				"eip155:6",
				"eip155:60",
				"eip155:60000",
				"eip155:60001",
				"eip155:60002",
				"eip155:60103",
				"eip155:61",
				"eip155:61717561",
				"eip155:62",
				"eip155:62320",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:68770",
				"eip155:68775",
				"eip155:69",
				"eip155:7",
				"eip155:70000",
				"eip155:70001",
				"eip155:70002",
				"eip155:7001",
				"eip155:70103",
				"eip155:7027",
				"eip155:71393",
				"eip155:721",
				"eip155:73799",
				"eip155:73927",
				"eip155:7518",
				"eip155:7575",
				"eip155:7576",
				"eip155:76",
				"eip155:77",
				"eip155:7762959",
				"eip155:777",
				"eip155:7777777",
				"eip155:78",
				"eip155:78110",
				"eip155:78281",
				"eip155:787",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:8029",
				"eip155:803",
				"eip155:8080",
				"eip155:8081",
				"eip155:8082",
				"eip155:82",
				"eip155:820",
				"eip155:821",
				"eip155:8217",
				"eip155:8285",
				"eip155:83",
				"eip155:8453",
				"eip155:84531",
				"eip155:85",
				"eip155:86",
				"eip155:8723",
				"eip155:8724",
				"eip155:88",
				"eip155:880",
				"eip155:8866",
				"eip155:888",
				"eip155:8888",
				"eip155:88882",
				"eip155:88888",
				"eip155:8995",
				"eip155:9",
				"eip155:9000",
				"eip155:9001",
				"eip155:919",
				"eip155:9372",
				"eip155:940",
				"eip155:95",
				"eip155:955305",
				"eip155:96970",
				"eip155:97",
				"eip155:9728",
				"eip155:9731",
				"eip155:9732",
				"eip155:977",
				"eip155:99",
				"eip155:99415706",
				"eip155:998",
				"eip155:999",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "8308dacf-028c-4f0a-9636-1ccd95768300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8308dacf-028c-4f0a-9636-1ccd95768300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8308dacf-028c-4f0a-9636-1ccd95768300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8308dacf-028c-4f0a-9636-1ccd95768300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://walletconnect.usecapsule.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://walletconnect.usecapsule.com/",
			},
			desktop: {
				native: "",
				universal: "https://walletconnect.usecapsule.com/",
			},
			metadata: {
				shortName: "Capsule",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:27:23.9546+00:00",
		},
		"6f43d35d53ae12c86681df65342315b643026e2085e41bf8b8cc67287150c375": {
			id: "6f43d35d53ae12c86681df65342315b643026e2085e41bf8b8cc67287150c375",
			name: "ShimmerSea",
			slug: "shimmersea",
			description:
				"ShimmerSea is a leading decentralized exchange (DEX) on Shimmer focused on offering a premier trading experience.",
			homepage: "https://shimmersea.finance",
			chains: ["eip155:148"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "2e97da0b-225a-44c2-2e72-9125d8504a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2e97da0b-225a-44c2-2e72-9125d8504a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2e97da0b-225a-44c2-2e72-9125d8504a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2e97da0b-225a-44c2-2e72-9125d8504a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://shimmersea.finance",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://shimmersea.finance",
			},
			metadata: {
				shortName: "ShimmerSea",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:27:28.734995+00:00",
		},
		cb7d4e00e9c6ba5ca1ef3b3464ce1ccf0468f7526fbda116106302525f929807: {
			id: "cb7d4e00e9c6ba5ca1ef3b3464ce1ccf0468f7526fbda116106302525f929807",
			name: "Savl",
			slug: "savl",
			description:
				"Savl Wallet is the Swiss Army knife of applications for DeFi and Web3. It offers more features than any other digital wallet on the market.",
			homepage: "https://savl.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "067b2a53-e8d0-4256-9c64-4e404db11800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/067b2a53-e8d0-4256-9c64-4e404db11800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/067b2a53-e8d0-4256-9c64-4e404db11800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/067b2a53-e8d0-4256-9c64-4e404db11800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/savl-wallet-bitcoin-solana/id1369912925",
				android: "https://play.google.com/store/apps/details?id=com.savl",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "savl://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Savl",
				colors: {
					primary: "#2A9A62",
					secondary: "#173828",
				},
			},
			updatedAt: "2024-01-08T15:30:09.059335+00:00",
		},
		"2564a702f3b709b000a7747ccfc6f6dcc58b638c57f29791cd01514fe91cc5f8": {
			id: "2564a702f3b709b000a7747ccfc6f6dcc58b638c57f29791cd01514fe91cc5f8",
			name: "Sinum",
			slug: "sinum",
			description: "World's first crypto SuperApp",
			homepage: "https://sinum.app/",
			chains: ["eip155:1"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "382b3cb1-3aaa-466d-44fc-24007f6a3d00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/382b3cb1-3aaa-466d-44fc-24007f6a3d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/382b3cb1-3aaa-466d-44fc-24007f6a3d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/382b3cb1-3aaa-466d-44fc-24007f6a3d00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.sinum.io",
				ios: "https://apps.apple.com/gb/app/sinumapp/id6466151819?mt=8",
				android:
					"https://play.google.com/store/apps/details?id=io.sinum.wallet&referrer=af_tranid%3DnU7l_g2aK5j8sdqWOykU7g%26c%3DMAIN+WEBSITE+TO+ANDROID%26pid%3DMAIN+ANDROID&pli=1",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "sinum://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Sinum",
				colors: {
					primary: "#9634D1",
					secondary: "#4E22FF",
				},
			},
			updatedAt: "2024-01-08T15:33:47.045681+00:00",
		},
		"6d47c10f046c322b4882dbb6a4d8c8e5e439019402ff872412d3b79bd3a859f4": {
			id: "6d47c10f046c322b4882dbb6a4d8c8e5e439019402ff872412d3b79bd3a859f4",
			name: "SoulSwap",
			slug: "soulswap",
			description:
				"We make DeFi accessible with an embedded browser, ERC token balances, collectibles, multi-chain portfolios, and crypto news.",
			homepage: "https://app.soulswap.finance",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:250",
				"eip155:4002",
				"eip155:42161",
				"eip155:43113",
				"eip155:43114",
				"eip155:56",
				"eip155:8453",
				"eip155:97",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
			],
			versions: ["1"],
			sdks: ["sign_v1", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "24fc6e6e-a276-4c95-fa77-91ec1097d600",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/24fc6e6e-a276-4c95-fa77-91ec1097d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/24fc6e6e-a276-4c95-fa77-91ec1097d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/24fc6e6e-a276-4c95-fa77-91ec1097d600?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/soulwallet-defi-portal/id6469735252",
				android: null,
				mac: "https://apps.apple.com/us/app/soulwallet-defi-portal/id6469735252",
				windows: null,
				linux: null,
				chrome:
					"https://apps.apple.com/us/app/soulwallet-defi-portal/id6469735252",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: [
				{
					namespace: "eip155",
					injected_id: "isSoulWallet",
				},
				{
					namespace: "polkadot",
					injected_id: "isSoulWallet",
				},
			],
			rdns: null,
			mobile: {
				native: "soulwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "SoulSwap",
				colors: {
					primary: "#6A00FF",
					secondary: "#000000",
				},
			},
			updatedAt: "2024-01-08T15:46:47.981954+00:00",
		},
		"4457c130df49fb3cb1f8b99574b97b35208bd3d0d13b8d25d2b5884ed2cad13a": {
			id: "4457c130df49fb3cb1f8b99574b97b35208bd3d0d13b8d25d2b5884ed2cad13a",
			name: "ShapeShift",
			slug: "shapeshift-1",
			description:
				"ShapeShift Wallet is an open-source, community-owned wallet supporting 13 chains. Create or import a wallet on web, desktop, or mobile.",
			homepage: "https://shapeshift.com",
			chains: [
				"cosmos:cosmoshub-4",
				"cosmos:thorchain-mainnet-v1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:137",
				"eip155:42161",
				"eip155:43114",
				"eip155:56",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "f8de2385-7d9b-4b31-bfed-5555b14fad00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/f8de2385-7d9b-4b31-bfed-5555b14fad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/f8de2385-7d9b-4b31-bfed-5555b14fad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/f8de2385-7d9b-4b31-bfed-5555b14fad00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.shapeshift.com",
				ios: "https://apps.apple.com/us/app/shapeshift-crypto-platform/id996569075",
				android:
					"https://play.google.com/store/apps/details?id=com.shapeshift.droid_shapeshift&hl=en_US&gl=US&pli=1",
				mac: "https://apps.apple.com/us/app/shapeshift-crypto-platform/id996569075",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://shapeshift.app.link/",
			},
			desktop: {
				native: "",
				universal: "https://app.shapeshift.com",
			},
			metadata: {
				shortName: "ShapeShift",
				colors: {
					primary: "#386FF9",
					secondary: "#0D182D",
				},
			},
			updatedAt: "2024-01-08T15:46:54.340732+00:00",
		},
		"259d07628a06aee49007266630381867688fbe96c331adc1b285aa7995380815": {
			id: "259d07628a06aee49007266630381867688fbe96c331adc1b285aa7995380815",
			name: "Panaroma Wallet",
			slug: "panaroma-wallet",
			description:
				"Panaroma Wallet is Decentralized excellence on 10 blockchains, seamlessly connecting with Panaroma Swap for a secure crypto experience.",
			homepage: "https://panaroma.finance",
			chains: [
				"algorand:SGO1GKSzyE7IEPItTxCByw9x8FmnrCDe",
				"algorand:mFgazF-2uRS1tMiL9dsj01hJGySEmPN2",
				"algorand:wGHE2Pwdvd7S12BL5FaOP20EGYesN73k",
				"antelope:1064487b3cd1a897ce03ae5b6a865651",
				"antelope:f16b1833c747c43682f4386fca9cbb32",
				"ccd:4221332d34e1694168c2a0c0b3fd0f27",
				"ccd:9dd9ca4d19e9393877d2c44b70f89acb",
				"cip-34:0-1",
				"cip-34:0-2",
				"cip-34:1-764824073",
				"cosmos:aura-testnet-2",
				"cosmos:columbus-4",
				"cosmos:cosmoshub-4",
				"cosmos:euphoria-2",
				"cosmos:harpoon-4",
				"cosmos:irishub-1",
				"cosmos:kaiyo-1",
				"cosmos:kava-4",
				"cosmos:likecoin-mainnet-2",
				"cosmos:likecoin-public-testnet-5",
				"cosmos:mayachain-mainnet-v1",
				"cosmos:mayachain-stagenet-v1",
				"cosmos:serenity-testnet-001",
				"cosmos:thorchain-mainnet-v1",
				"cosmos:thorchain-stagenet-v1",
				"cosmos:xstaxy-1",
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:10001",
				"eip155:10003",
				"eip155:1001",
				"eip155:1007",
				"eip155:101",
				"eip155:1010",
				"eip155:1012",
				"eip155:102",
				"eip155:1022",
				"eip155:1023",
				"eip155:1024",
				"eip155:1028",
				"eip155:106",
				"eip155:108",
				"eip155:11",
				"eip155:110",
				"eip155:1101",
				"eip155:111",
				"eip155:11155111",
				"eip155:1139",
				"eip155:1140",
				"eip155:12",
				"eip155:1213",
				"eip155:122",
				"eip155:124",
				"eip155:127",
				"eip155:128",
				"eip155:1280",
				"eip155:1284",
				"eip155:1285",
				"eip155:1286",
				"eip155:1287",
				"eip155:1288",
				"eip155:13",
				"eip155:137",
				"eip155:14",
				"eip155:142",
				"eip155:1442",
				"eip155:15",
				"eip155:15557",
				"eip155:16",
				"eip155:1618",
				"eip155:162",
				"eip155:1620",
				"eip155:163",
				"eip155:1657",
				"eip155:17",
				"eip155:170",
				"eip155:172",
				"eip155:17777",
				"eip155:18",
				"eip155:1856",
				"eip155:186",
				"eip155:19",
				"eip155:1987",
				"eip155:199",
				"eip155:2",
				"eip155:20",
				"eip155:200",
				"eip155:2021",
				"eip155:2022",
				"eip155:204",
				"eip155:2100",
				"eip155:2101",
				"eip155:211",
				"eip155:22",
				"eip155:222",
				"eip155:23",
				"eip155:246",
				"eip155:25",
				"eip155:250",
				"eip155:2559",
				"eip155:256",
				"eip155:262",
				"eip155:269",
				"eip155:27",
				"eip155:28",
				"eip155:288",
				"eip155:2888",
				"eip155:3",
				"eip155:30",
				"eip155:31",
				"eip155:32",
				"eip155:321",
				"eip155:322",
				"eip155:33",
				"eip155:336",
				"eip155:338",
				"eip155:35",
				"eip155:361",
				"eip155:363",
				"eip155:364",
				"eip155:365",
				"eip155:369",
				"eip155:38",
				"eip155:385",
				"eip155:4",
				"eip155:40",
				"eip155:4002",
				"eip155:41",
				"eip155:42",
				"eip155:420",
				"eip155:42161",
				"eip155:421613",
				"eip155:421614",
				"eip155:42170",
				"eip155:43",
				"eip155:43113",
				"eip155:43114",
				"eip155:44",
				"eip155:4689",
				"eip155:4690",
				"eip155:499",
				"eip155:5",
				"eip155:50",
				"eip155:51",
				"eip155:5197",
				"eip155:52",
				"eip155:53",
				"eip155:55",
				"eip155:558",
				"eip155:56",
				"eip155:5611",
				"eip155:56288",
				"eip155:59",
				"eip155:595",
				"eip155:6",
				"eip155:60",
				"eip155:61",
				"eip155:62",
				"eip155:63",
				"eip155:64",
				"eip155:65",
				"eip155:66",
				"eip155:67",
				"eip155:68",
				"eip155:686",
				"eip155:69",
				"eip155:7",
				"eip155:721",
				"eip155:76",
				"eip155:77",
				"eip155:777",
				"eip155:78",
				"eip155:787",
				"eip155:8",
				"eip155:80",
				"eip155:80001",
				"eip155:803",
				"eip155:82",
				"eip155:820",
				"eip155:821",
				"eip155:83",
				"eip155:85",
				"eip155:86",
				"eip155:88",
				"eip155:880",
				"eip155:8888",
				"eip155:9",
				"eip155:940",
				"eip155:95",
				"eip155:97",
				"eip155:9728",
				"eip155:977",
				"eip155:99",
				"eip155:998",
				"flow:mainnet",
				"flow:testnet",
				"hedera:mainnet",
				"hedera:previewnet",
				"hedera:testnet",
				"koinos:EiAAKqFi-puoXnuJTdn7qBGGJa8yd-dc",
				"koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3e",
				"mvx:1",
				"mvx:D",
				"mvx:T",
				"near:mainnet",
				"near:testnet",
				"neo3:mainnet",
				"neo3:testnet",
				"polkadot:91b171bb158e2d3848fa23a9f1c25182",
				"polkadot:b0a8d493285c2df73290dfb7e61f870f",
				"reef:7834781d38e4798d548e34ec947d19de",
				"reef:b414a8602b2251fa538d38a932239150",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
				"stacks:1",
				"stacks:2147483648",
				"stellar:pubnet",
				"stellar:testnet",
				"tvm:42",
				"vechain:87721b09ed2e15997f466536b20bb127",
				"vechain:b1ac3413d346d43539627e6be7ec1b4a",
				"waves:083",
				"waves:084",
				"waves:087",
				"xrpl:0",
				"xrpl:1",
				"xrpl:2",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "4394f728-0c57-4560-acba-48bfd82ddf00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/4394f728-0c57-4560-acba-48bfd82ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/4394f728-0c57-4560-acba-48bfd82ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/4394f728-0c57-4560-acba-48bfd82ddf00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"http://play.google.com/store/apps/details?id=com.panaroma.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "panaromawallet://walletconnect",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Panaroma Wallet",
				colors: {
					primary: "#3772FF",
					secondary: "#0045EA",
				},
			},
			updatedAt: "2024-01-08T15:47:00.991542+00:00",
		},
		"855481a23310c2bccf2a6134367449d61bd2f1c8793f929516c4f68a6aaace7a": {
			id: "855481a23310c2bccf2a6134367449d61bd2f1c8793f929516c4f68a6aaace7a",
			name: "NEOPIN",
			slug: "neopin",
			description:
				"A one-stop, non-custodial CeDeFi protocol for secure crypto use within regulatory frameworks while leveraging the benefits of CeFi and DeFi.",
			homepage: "https://neopin.io/",
			chains: ["eip155:1", "eip155:137", "eip155:8217"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "424c54b5-b786-4c14-871f-61d5c5ded800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/424c54b5-b786-4c14-871f-61d5c5ded800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/424c54b5-b786-4c14-871f-61d5c5ded800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/424c54b5-b786-4c14-871f-61d5c5ded800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://itunes.apple.com/app/id1600381072",
				android:
					"https://play.google.com/store/apps/details?id=com.blockchain.crypto.wallet.neopin",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "nptwc://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "NEOPIN",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:47:59.52775+00:00",
		},
		d50cc807305f886f712206c9a8a7e47a776a266a7367bc080fe8ce939fcfa2b8: {
			id: "d50cc807305f886f712206c9a8a7e47a776a266a7367bc080fe8ce939fcfa2b8",
			name: "Alicebob Wallet",
			slug: "alicebob-wallet",
			description:
				"Securely store, buy Bitcoin & altcoins with cards, trade 1000+ cryptos on CEX & DEX, track Bitcoin price, and more!",
			homepage: "https://alicebob.com/",
			chains: [
				"eip155:1",
				"eip155:10000",
				"eip155:137",
				"eip155:43114",
				"eip155:56",
				"eip155:61",
				"eip155:97",
				"solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ",
				"solana:8E9rvCKLFQia2Y35HXjjpWzj8weVo44K",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "15be8ddd-0bef-4948-56d1-6101347a6b00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/15be8ddd-0bef-4948-56d1-6101347a6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/15be8ddd-0bef-4948-56d1-6101347a6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/15be8ddd-0bef-4948-56d1-6101347a6b00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/alicebob-crypto-btc-wallet/id6467197622",
				android:
					"https://play.google.com/store/apps/details?id=com.crypto.ab.wallet.app",
				mac: "https://apps.apple.com/us/app/alicebob-crypto-btc-wallet/id6467197622",
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/alicebob-wallet/mjmpkbeecljmaoojijflpfpmpdhnpabd",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "crypto.ab.wallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Alicebob",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-08T15:48:07.789048+00:00",
		},
		ce216ac7310971159adda1ac271fc6e6590d2c02da43b894ac11919084196e40: {
			id: "ce216ac7310971159adda1ac271fc6e6590d2c02da43b894ac11919084196e40",
			name: "CyberWallet",
			slug: "cyberwallet",
			description:
				"CyberWallet - your ERC-4337-Compatible Web3 Smart Account, built by the CyberConnect team.",
			homepage: "https://wallet.cyber.co",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:137",
				"eip155:204",
				"eip155:42161",
				"eip155:534352",
				"eip155:56",
				"eip155:59144",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "24887576-8e74-4518-36b3-3c5e13f11a00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/24887576-8e74-4518-36b3-3c5e13f11a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/24887576-8e74-4518-36b3-3c5e13f11a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/24887576-8e74-4518-36b3-3c5e13f11a00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://wallet.cyber.co",
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://wallet.cyber.co",
			},
			desktop: {
				native: "",
				universal: "https://wallet.cyber.co",
			},
			metadata: {
				shortName: "CyberWallet",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-12T11:27:19.79544+00:00",
		},
		"984e44f2d80a93c0febf58d9ca396b5817151fc075f09333a3d387c981f75f2c": {
			id: "984e44f2d80a93c0febf58d9ca396b5817151fc075f09333a3d387c981f75f2c",
			name: "DexTrade",
			slug: "dextrade",
			description:
				"DexTrade is a non-custodial, multi-currency crypto wallet with decentralized P2P exchange, personal exchange creation and a rating system.",
			homepage: "https://dextrade.com",
			chains: ["eip155:1", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "d33237c0-a4e1-4339-9db8-a1087311c400",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d33237c0-a4e1-4339-9db8-a1087311c400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d33237c0-a4e1-4339-9db8-a1087311c400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d33237c0-a4e1-4339-9db8-a1087311c400?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://pwa.dextrade.com",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chromewebstore.google.com/detail/dextrade/nmladckinifchdidkoaagbcpnieocoah?hl=en",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://pwa.dextrade.com",
			},
			metadata: {
				shortName: "DextTrade",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-12T11:28:02.176081+00:00",
		},
		a29498d225fa4b13468ff4d6cf4ae0ea4adcbd95f07ce8a843a1dee10b632f3f: {
			id: "a29498d225fa4b13468ff4d6cf4ae0ea4adcbd95f07ce8a843a1dee10b632f3f",
			name: "HashPack",
			slug: "hashpack",
			description: "The leading Hedera wallet",
			homepage: "https://hashpack.app",
			chains: ["hedera:mainnet", "hedera:testnet"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "8d55dd5a-7c9f-4929-d2d1-00564e41ac00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8d55dd5a-7c9f-4929-d2d1-00564e41ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8d55dd5a-7c9f-4929-d2d1-00564e41ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8d55dd5a-7c9f-4929-d2d1-00564e41ac00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://wallet.hashpack.app/",
				ios: "https://apps.apple.com/app/id6444389849",
				android:
					"https://play.google.com/store/apps/details?id=app.hashpack.wallet.twa",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: "https://wallet.hashpack.app/",
			},
			metadata: {
				shortName: "HashPack",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-12T11:28:15.111395+00:00",
		},
		a2eb8a1c403a4440b2f578e9deb185b8e22cf4ec2a2a58441032b84b13aaab87: {
			id: "a2eb8a1c403a4440b2f578e9deb185b8e22cf4ec2a2a58441032b84b13aaab87",
			name: "The Pulse Wallet",
			slug: "the-pulse-wallet",
			description:
				"The safest and fastest way to explore and master PulseChain. Store, manage, trade, and swap crypto and NFT anywhere anytime.",
			homepage: "https://thepulsewallet.org/",
			chains: ["eip155:369"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2", "auth_v1"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "1f3d46b8-2569-4601-5084-845f7e64da00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/1f3d46b8-2569-4601-5084-845f7e64da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/1f3d46b8-2569-4601-5084-845f7e64da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/1f3d46b8-2569-4601-5084-845f7e64da00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/vn/app/pulse-wallet/id6458346951",
				android:
					"https://play.google.com/store/apps/details?id=com.wallet.thepulse",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "wallet.pulse://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "The Pulse Wallet",
				colors: {
					primary: "#A60FFF",
					secondary: "#0D9EFF",
				},
			},
			updatedAt: "2024-01-18T08:15:23.258064+00:00",
		},
		bb71b54ced62aa11f76e4f3edacb37a41300807506db840b98b740379f99cc71: {
			id: "bb71b54ced62aa11f76e4f3edacb37a41300807506db840b98b740379f99cc71",
			name: "Pintu",
			slug: "pintu",
			description:
				"The simplest and most secure wallet for everyone, helping you easily access the entire Web3 ecosystem.",
			homepage: "https://pintu.co.id/",
			chains: ["eip155:1", "eip155:137", "eip155:42161", "eip155:56"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "MPC-based wallets",
			image_id: "b9c5dfd6-ca26-46c2-bc79-bc2570495800",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/b9c5dfd6-ca26-46c2-bc79-bc2570495800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/b9c5dfd6-ca26-46c2-bc79-bc2570495800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/b9c5dfd6-ca26-46c2-bc79-bc2570495800?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://pintu.co.id/",
				ios: "https://apps.apple.com/id/app/pintu-buy-invest-crypto/id1494119678",
				android:
					"https://play.google.com/store/apps/details?id=com.valar.pintu&hl=en&gl=US",
				mac: "https://apps.apple.com/id/app/pintu-buy-invest-crypto/id1494119678",
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pintu://web3wallet",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Pintu",
				colors: {
					primary: "#0A68F4",
					secondary: null,
				},
			},
			updatedAt: "2024-01-22T10:14:08.498734+00:00",
		},
		a9104b630bac1929ad9ac2a73a17ed4beead1889341f307bff502f89b46c8501: {
			id: "a9104b630bac1929ad9ac2a73a17ed4beead1889341f307bff502f89b46c8501",
			name: "Blade Wallet",
			slug: "blade-wallet",
			description:
				"Integrate our secure wallet solutions within your apps and leverage our one-click user onboarding. Unlock the power of Web3 with Blade.",
			homepage: "https://bladewallet.io",
			chains: [
				"eip155:1",
				"eip155:11155111",
				"hedera:mainnet",
				"hedera:testnet",
			],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "8fa87652-b043-4992-3a45-78e438d1cd00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/8fa87652-b043-4992-3a45-78e438d1cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/8fa87652-b043-4992-3a45-78e438d1cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/8fa87652-b043-4992-3a45-78e438d1cd00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/app/apple-store/id1623849951",
				android:
					"https://play.google.com/store/apps/details?id=org.bladelabs.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome:
					"https://chrome.google.com/webstore/detail/blade-%E2%80%93-hedera-web3-digit/abogmiocnneedmmepnohnhlijcjpcifd",
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "org.bladelabs.bladewallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Blade",
				colors: {
					primary: "#FFFFFF",
					secondary: "#961E7A",
				},
			},
			updatedAt: "2024-01-22T10:14:29.787443+00:00",
		},
		fa737fa41e6e5ad02c536bd21afea4e21c061885a039e17237e08802ca5bf0bf: {
			id: "fa737fa41e6e5ad02c536bd21afea4e21c061885a039e17237e08802ca5bf0bf",
			name: "Pandoshi Wallet",
			slug: "pandoshi-wallet",
			description:
				"Explore the world of digital finance with Pandoshi Wallet, a non-custodial, HD wallet designed for EVM-compatible networks.",
			homepage: "https://pandoshi.com",
			chains: [
				"eip155:1",
				"eip155:10",
				"eip155:100",
				"eip155:1101",
				"eip155:1111",
				"eip155:128",
				"eip155:1313161554",
				"eip155:137",
				"eip155:25",
				"eip155:250",
				"eip155:295",
				"eip155:324",
				"eip155:32659",
				"eip155:369",
				"eip155:40",
				"eip155:42161",
				"eip155:43114",
				"eip155:4689",
				"eip155:5000",
				"eip155:534352",
				"eip155:56",
				"eip155:59144",
				"eip155:66",
				"eip155:8217",
				"eip155:8453",
			],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Mobile Wallets",
			image_id: "d5be0305-ff38-4412-6089-a94c2e445300",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/d5be0305-ff38-4412-6089-a94c2e445300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/d5be0305-ff38-4412-6089-a94c2e445300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/d5be0305-ff38-4412-6089-a94c2e445300?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=com.pandoshi.wallet",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "pandoshiwallet://",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Pandoshi Wallet",
				colors: {
					primary: "#fedd6e",
					secondary: "#ffa98d",
				},
			},
			updatedAt: "2024-01-22T10:14:51.563156+00:00",
		},
		b508bac65989c98cd5ca664738ebd50b4fdaa06420f2c0c3b049a01a0856bd79: {
			id: "b508bac65989c98cd5ca664738ebd50b4fdaa06420f2c0c3b049a01a0856bd79",
			name: "Keychain",
			slug: "keychain",
			description: "A simple web3 wallet",
			homepage: "http://keychain.money/",
			chains: ["eip155:137"],
			versions: ["1"],
			sdks: ["sign_v1", "auth_v1"],
			app_type: "wallet",
			category: "Web App Wallets",
			image_id: "2b6e9e4b-7dca-45dd-45d5-d96f45010200",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/2b6e9e4b-7dca-45dd-45d5-d96f45010200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/2b6e9e4b-7dca-45dd-45d5-d96f45010200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/2b6e9e4b-7dca-45dd-45d5-d96f45010200?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: "https://app.keychain.money/",
				ios: null,
				android: null,
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: "https://app.keychain.money/",
			},
			desktop: {
				native: "",
				universal: "https://app.keychain.money/",
			},
			metadata: {
				shortName: "key",
				colors: {
					primary: "#000000",
					secondary: "#F73558",
				},
			},
			updatedAt: "2024-01-22T10:15:14.707088+00:00",
		},
		f1f4ce9c495cb3db6862230ad961edca8563bf85c245bd774d4d78b7de884641: {
			id: "f1f4ce9c495cb3db6862230ad961edca8563bf85c245bd774d4d78b7de884641",
			name: "BitBox",
			slug: "bitbox",
			description: "BitBox Wallet App",
			homepage: "https://bitbox.swiss",
			chains: ["eip155:1", "eip155:5"],
			versions: ["2"],
			sdks: ["sign_v2"],
			app_type: "wallet",
			category: "Hardware Wallets",
			image_id: "e8373489-de33-4d1f-ffdf-1c435a050e00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/e8373489-de33-4d1f-ffdf-1c435a050e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/e8373489-de33-4d1f-ffdf-1c435a050e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/e8373489-de33-4d1f-ffdf-1c435a050e00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: null,
				android:
					"https://play.google.com/store/apps/details?id=ch.shiftcrypto.bitboxapp&utm_source=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
				mac: null,
				windows: null,
				linux: "https://bitbox.swiss/download/#linux-downloads",
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "",
				universal: null,
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "BBApp",
				colors: {
					primary: null,
					secondary: null,
				},
			},
			updatedAt: "2024-01-25T15:50:10.199724+00:00",
		},
		"7475258cb07b3e5fbab715aab27f383fc177154f4f0461790c57d97077a47547": {
			id: "7475258cb07b3e5fbab715aab27f383fc177154f4f0461790c57d97077a47547",
			name: "Volt Wallet",
			slug: "volt-wallet",
			description:
				"Experience Effortless DeFi with Account Abstraction — Making Managing Crypto Easy and Intuitive.",
			homepage: "https://voltage.finance",
			chains: ["eip155:122"],
			versions: ["1", "2"],
			sdks: ["sign_v1", "sign_v2"],
			app_type: "wallet",
			category: "Smart Contract Wallets",
			image_id: "ffddf01a-337f-45c1-61c9-f6d3dd3d3c00",
			image_url: {
				sm: "https://explorer-api.walletconnect.com/v3/logo/sm/ffddf01a-337f-45c1-61c9-f6d3dd3d3c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				md: "https://explorer-api.walletconnect.com/v3/logo/md/ffddf01a-337f-45c1-61c9-f6d3dd3d3c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
				lg: "https://explorer-api.walletconnect.com/v3/logo/lg/ffddf01a-337f-45c1-61c9-f6d3dd3d3c00?projectId=cb811e291b0e2ca79a6d90c7749879bf",
			},
			app: {
				browser: null,
				ios: "https://apps.apple.com/us/app/volt-wallet/id6444159237",
				android:
					"https://play.google.com/store/apps/details?id=finance.voltage.app",
				mac: null,
				windows: null,
				linux: null,
				chrome: null,
				firefox: null,
				safari: null,
				edge: null,
				opera: null,
			},
			injected: null,
			rdns: null,
			mobile: {
				native: "volt://",
				universal: "https://get.voltage.finance",
			},
			desktop: {
				native: "",
				universal: null,
			},
			metadata: {
				shortName: "Volt",
				colors: {
					primary: "#70E000",
					secondary: "#FFFFFF",
				},
			},
			updatedAt: "2024-01-25T15:54:01.770587+00:00",
		},
	},
	count: 417,
	total: 417,
};

export default walletLists;
