import { useState } from "react";
import Landing from "./pages/Landing";
import ChooseWallet from "./pages/ChooseWallet";

function App() {
	const [showWallets, setShowWallets] = useState(false);
	const [showLanding, setShowLanding] = useState(true);
	return (
		<div className="h-screen max-h-screen w-full overflow-x-hidden bg-hero1 ">
			{showLanding && (
				<Landing
					setShowWallets={setShowWallets}
					setShowLanding={setShowLanding}
				/>
			)}
			{showWallets && <ChooseWallet />}
		</div>
	);
}

export default App;
